import React from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { useFormikContext } from "formik";
import helperFunc from "../../../../_metronic/_helpers/helperFunc";

export default function VisitorList(props) {
  const { visitorDetailsArr, setActionVisitorIdAndType } = props;
  const { setFieldValue } = useFormikContext();

  const handleActionPerformOnVisitor = (item, action, setFieldValue) => {
    let action_details = {
      details: item,
      action_type: action,
    };
    setActionVisitorIdAndType(action_details, setFieldValue);
  };
  const setVehicleType = (vehicleType) => {
    switch (vehicleType) {
      case "vehicle_ev":
        return "electric_car";
      case "vehicle":
        return "parking";
      case "bike":
        return "bike";
      default:
        return "";
    }
  };

  return (
    <>
      {!visitorDetailsArr
        ? ""
        : visitorDetailsArr?.map((item, index) => (
            <div
              className="visitor-list-wrapper-div d-flex align-items-center mt-5"
              key={index}
            >
              <div className="visitor-list-icon-div">
                <span className="ml-4">
                  <SVG
                    className=""
                    src={toAbsoluteUrl("/media/svg/icons/task.svg")}
                  />
                </span>
              </div>
              <div className="visitor-list-data-div">
                {item?.selected_visitor && (
                  <>
                    <div className="d-flex">
                      <p>
                        Visitor: <b>{item?.selected_visitor}</b>
                      </p>
                    </div>
                  </>
                )}
                {item?.selected_start_time && (
                  <>
                    <div className="d-flex">
                      <p>
                        Time:{" "}
                        <b>
                          {item?.selected_start_time} -{" "}
                          {item?.selected_end_time}
                        </b>
                      </p>
                    </div>
                  </>
                )}
                {item?.selected_parking_space && (
                  <>
                    <div className="d-flex">
                      <p>
                        Parking:{" "}
                        {helperFunc.setVehicleImage(
                          setVehicleType(item?.selected_parking_space)
                        )}
                      </p>
                    </div>
                  </>
                )}
              </div>
              <div className="d-flex action-icon-div">
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    handleActionPerformOnVisitor(item, "edit", setFieldValue)
                  }
                >
                  <span className="">
                    <SVG
                      className=""
                      src={toAbsoluteUrl("/media/svg/icons/rounded-edit.svg")}
                    />
                  </span>
                </div>
                <div
                  onClick={() => handleActionPerformOnVisitor(item, "delete")}
                  className="cursor-pointer"
                >
                  <span className="">
                    <SVG
                      className=""
                      src={toAbsoluteUrl("/media/svg/icons/rounded-delete.svg")}
                    />
                  </span>
                </div>
              </div>
            </div>
          ))}
    </>
  );
}
