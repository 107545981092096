import moment from "moment";
import React, { useState } from "react";
import { toAbsoluteUrl } from "../../../_helpers";
import SVG from "react-inlinesvg";
import { Dropdown } from "react-bootstrap";
import * as parkingAction from "../../../../app/modules/Booking/Parking/_redux/Action";
import * as dayPassAction from "../../../../app/modules/Booking/DayPass/_redux/Action";
import * as meetingAction from "../../../../app/modules/Booking/Meeting/_redux/Actions";
import * as visitorAction from "../../../../app/modules/Booking/Visitor/_redux/Action";
import * as visitorSignInAction from "../../../../app/modules/Reception/VisitorSignIn/_redux/Actions";
import * as dashboardActions from "../../../../app/pages/_redux/Actions";
import * as eventsActions from "../../../../app/modules/Booking/Event/_redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import helperFunc from "../../../_helpers/helperFunc";
import DeleteDialog from "../../../../app/components/DeleteDialog";
import "animate.css/animate.min.css";
import ScrollAnimation from "react-animate-on-scroll";

export function BookingCard({ item, type, handleSignInClick, cancelItem }) {
  const dispatch = useDispatch();
  const userRole = helperFunc.getUserRole();
  const currentUser = helperFunc.getUserData();
  const [isDeleteShow, setIsDeleteShow] = useState(false);
  const [deleteItemRow, setDeleteItemRow] = useState(undefined);
  const dashboardStore = useSelector((state) => state.dashboards);

  const getbgClassName = () => {
    if (type === "day_pass") {
      return "day-pass-bg";
    }
    if (type === "parking") {
      return "parking-bg";
    }
    if (type === "other") {
      return "mitting-bg";
    }
    if (type === "host") {
      return "visitor-bg";
    }
    if (type === "events") {
      return "event-bg";
    }
  };

  const deleteItemConfirm = (item) => {
    setIsDeleteShow(true);
    setDeleteItemRow(item);
  };
  const userId = helperFunc.getUserId();
  const handelCancelItem = () => {
    hideDeleteConfirm();
    // const params = {
    //   type: deleteItemRow?.type,
    // };
    if (!deleteItemRow?.type === "events") {
      cancelItem(deleteItemRow?.id, deleteItemRow?.type);
    } else {
      if (deleteItemRow?.user_id === userId) {
        dispatch(eventsActions.cancelBooking(deleteItemRow?.id)).then((res) => {
          if (res?.message === "Booking canceled") {
            dispatch(
              dashboardActions.fetchBookingList(
                dashboardStore?.dashboardFilters
              )
            );
          }
        });
      } else {
        const params = {
          event_id: deleteItemRow?.id,
          user_id: deleteItemRow?.user?.id,
        };
        dispatch(eventsActions.cancelvent(params));
      }
    }
  };
  const hideDeleteConfirm = () => {
    setIsDeleteShow(false);
    setDeleteItemRow(undefined);
  };
  const editItem = (item) => {
    if (item.type === "day_pass") {
      console.log("day_pass", item);
      dispatch(dayPassAction.setEditItem(item));
    }
    if (item.type === "parking") {
      dispatch(parkingAction.setEditItem(item));
    }
    if (item.type === "other") {
      dispatch(meetingAction.setEditItem(item));
    }
    if (item.type === "host") {
      dispatch(visitorAction.setEditItem(item));
    }
  };
  const handleCancelKiosk = (item) => {
    dispatch(
      visitorSignInAction.cancelKiosk(
        currentUser?.id,
        item?.guests[0]?.id,
        item?.id
      )
    ).then((response) => {
      dispatch(
        dashboardActions.fetchBookingList(dashboardStore?.dashboardFilters)
      );
    });
  };
  return (
    <>
      <DeleteDialog
        show={isDeleteShow}
        name={
          (deleteItemRow?.site && deleteItemRow?.site[0]?.title) ||
          (deleteItemRow?.space && deleteItemRow?.space?.title) ||
          (deleteItemRow?.space && deleteItemRow?.space?.floor?.title) ||
          ""
        }
        customMessage={true}
        custom_title="Cancel Booking"
        custom_description={"Are you sure you want to Cancel this booking?"}
        item={deleteItemRow}
        onDelete={handelCancelItem}
        onHide={hideDeleteConfirm}
        type="Cancel"
      />
      <ScrollAnimation
        animateIn="animate__fadeInUp"
        offset={100}
        duration={0.8}
        animateOnce
      >
        <div className="booking-item mb-10 mr-4">
          <div className="booking_name_onhover">
            {type === "day_pass"
              ? "Day Pass Booking"
              : type === "parking"

                ? "Parking Booking"
                : type === "other"
                  ? "Meeting Room Booking"
                  // : type === "events"
                  //   ? "Event Booking"
                  : "Visitor Booking"}



          </div>
          <div
            className={`booking-item-date text-uppercase position-relative ${getbgClassName()}`}
          >
            {type === "other" && (
              <>
                <div className="booking-card-type-icon-block">
                  <div className="booking-card-type-icon">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/mitting-pink.svg")}
                    />
                  </div>
                </div>
              </>
            )}
            {type === "day_pass" && (
              <div className="booking-card-type-icon-block">
                <div className="booking-card-type-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/color-daypass.svg")}
                  />
                </div>
              </div>
            )}
            {type === "host" && (
              <div className="booking-card-type-icon-block">
                <div className="booking-card-type-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/color-visitor.svg")}
                  />
                </div>
              </div>
            )}
            {type === "parking" && (
              <>
                {item.parking && (
                  <div className="booking-card-type-icon-block">
                    <div className="booking-card-type-icon">
                      <SVG
                        src={toAbsoluteUrl("/media/svg/icons/parking.svg")}
                      />
                    </div>
                    {
                      item?.park_for == "host" ? <div className="booking-card-type-icon-block">
                        <div className="booking-card-type-icon" style={{ marginLeft: 10 }}>
                          <SVG
                            src={toAbsoluteUrl("/media/svg/icons/color-visitor.svg")}
                          />
                        </div>
                      </div> : <></>}
                  </div>
                )}
              </>
            )}
            {type === "events" && (
              <div className="booking-card-type-icon-block">
                <div className="booking-card-type-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/eventicon.svg")} />
                </div>
              </div>
            )}

            <span className="item-month">
              {moment(item.starts_at).format("MMMM")}
            </span>
            <span className="item-date">
              {moment(item.starts_at).format("D")}
            </span>
            <span className="item-day">
              {moment(item.starts_at).format("dddd")}
            </span>
            {(type === "day_pass" || type === "parking") && (
              <span className="item-time">Full Day</span>
            )}
            {(type === "other" || type === "host" || type === "events") && (
              <>
                <span className="item-time">
                  {moment(item.starts_at).format("HH:mm")} -
                  {moment(item.ends_at).format("HH:mm")}
                </span>
                <span
                  className="timezone-label"
                  style={{ textAlign: "center" }}
                >{`(${currentUser?.site?.time_zone?.title})`}</span>
              </>
            )}
          </div>

          <div className="booking-item-details">
            <div>
              <div className="booking-item-menu">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="light"
                    className="main_toggler"
                    style={{ background: "#fff !important" }}
                  >
                    <img
                      src={toAbsoluteUrl("/media/svg/icons/three-dots.svg")}
                      style={{ objectFit: "cover", height: 15, width: 45 }}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        editItem(item);
                      }}
                    >
                      <SVG src={toAbsoluteUrl("/media/svg/icons/edit.svg")} />
                      EDIT BOOKING
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => deleteItemConfirm(item)}
                      className="cancel-item"
                    >
                      <SVG
                        src={toAbsoluteUrl("/media/svg/icons/red-cross.svg")}
                      />
                      CANCEL BOOKING
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="booking-item-title">
                <span className="meeting_room_name d-block mb-1">
                  {(item?.type !== "events" &&
                    item?.space &&
                    item?.space?.title) ||
                    ""}
                </span>
                <span className="d-block mb-1">
                  {(type === "other" && item?.space?.floor?.site?.title) || ""}
                </span>
                {(item?.site && item?.site[0]?.title) || ""}
                {item?.type !== "events" &&
                  ((item?.space && item?.space?.floor?.title) || ",Floor 1")}
              </div>
              <div className="booking-item-owner">
                {type === "parking" && "Vehicle Parking"}
              </div>
              <div className="booking-item-owner">
                {type === "parking" && (
                  <>
                    <span>Vehicle Reg: </span>
                    <span className="booking-item-owner-name">
                      {item?.vehicle?.reg}
                    </span>
                  </>
                )}
              </div>
              {type === "parking" && item?.vehicle && item?.vehicle?.make && (
                <div className="booking-item-owner">
                  <span>Make: </span>
                  <span className="booking-item-owner-name">
                    {item?.vehicle?.make}
                  </span>
                </div>
              )}
              {type === "parking" && item?.vehicle && item?.vehicle?.model && (
                <div className="booking-item-owner">
                  <span>Model: </span>
                  <span className="booking-item-owner-name">
                    {item?.vehicle?.model}
                  </span>
                </div>
              )}
              {type === "parking" && item?.vehicle && item?.vehicle?.color && (
                <div className="booking-item-owner">
                  <span>Color: </span>
                  <span className="booking-item-owner-name">
                    {item?.vehicle?.color}
                  </span>
                </div>
              )}
              {type === "day_pass" && (
                <div className="booking-item-owner">
                  Day Pass Owner:{" "}
                  <span className="booking-item-owner-name">
                    {item?.booked_for?.full_name}
                  </span>
                </div>
              )}
              {type === "other" && (
                <>
                  <div className="daypass-item-owner">
                    Host:{" "}
                    <span className="font-weight-bolder">
                      {item?.booked_for?.full_name}
                    </span>
                  </div>
                </>
              )}

              {
                type === "events" && (
                  <div className="booking-item-title mb-3">
                    <span className="d-block mb-1">
                      {item?.event?.event_type === "external"
                        ? `${item?.event?.address_1},${item?.event?.address_1},${item?.event?.city}`
                        : `${item?.event?.space
                          ? `${item?.event?.space?.title}, ${item?.event?.space?.floor?.title}`
                          : ""
                        }`}
                    </span>
                    <div className="d-flex">
                      {item?.event?.url_cover_image && (
                        <img
                          src={item?.event?.url_cover_image}
                          alt="event"
                          height={60}
                          width={60}
                          style={{ borderRadius: "10px" }}
                        />
                      )}
                      <div className="mt-3">
                        <span className="booking-item-owner-name font-weight-bolder ml-3">
                          {item?.event?.name}
                        </span>
                        <span className="ml-3 d-block">
                          Host:<b> {item?.user && item?.user?.full_name}</b>
                        </span>
                      </div>
                    </div>
                  </div>
                )
              }

              {
                (type === "parking" ||

                  type === "other" ||
                  type === "day_pass") && (
                  <div className="daypass-item-owner">
                    {(
                      type === "day_pass" ||
                      type === "other") &&
                      "Created by: "}
                    {type === "other: " && "Host: "}
                    <span className="booking-item-owner-name">
                      {item?.created_by_me ? "ME" : item?.created_by?.full_name}
                    </span>
                  </div>
                )
              }
              {/* {(type === "parking") && (
                <div className="daypass-item-owner">
                  {(type === "parking") &&
                    "Vehicle Owner: "}
                  {type === "other: " && "Host: "}
                  <span className="booking-item-owner-name">
                    {item?.created_by_me ? "ME" : item?.created_by?.full_name}
                  </span>
                </div>
              )} */}

              {
                (type == "parking") && (item?.created_by?.full_name == item?.vehicle_user?.full_name) &&
                (
                  <div className="daypass-item-owner">
                    Vehicle Owner:
                    <span className="booking-item-owner-name">
                      {item?.created_by_me ? "ME" : item?.created_by?.full_name}
                    </span>
                  </div>
                )
              }

              {
                (type == "parking") && (item?.created_by?.full_name != item?.vehicle_user?.full_name) &&
                (
                  <div className="daypass-item-owner">
                    Vehicle Owner:
                    <span className="booking-item-owner-name">
                      {item?.created_by_me ? "ME" : item?.vehicle_user?.full_name}
                    </span>
                  </div>
                )
              }
              {
                (type == "parking") && (item?.created_by?.full_name != item?.vehicle_user?.full_name) &&
                (
                  <div className="daypass-item-owner">
                    Created By:
                    <span className="booking-item-owner-name">
                      {item?.created_by_me ? "ME" : item?.created_by?.full_name}
                    </span>
                  </div>
                )
              }



              {
                type === "other" && (
                  <>
                    <div className="daypass-item-owner">
                      Status: {item?.status == "checked_in" ? "Checked In" : <></>}
                      {item?.status == "check_out" ? "Ended" : <></>}
                      {item?.status == "auto_canceled" ? "No Show" : <></>}
                      {item?.status == "user_canceled" ? "Cancelled" : <></>}
                      {item?.status == "booked" ? "Booked" : <></>}

                    </div>
                    {item?.status == "user_canceled" ?
                      <div className="daypass-item-owner">
                        Cancelled By :{" "}
                        <span className="font-weight-bolder">
                          {item?.booked_for?.full_name}
                        </span>
                      </div> : <></>}
                  </>
                )
              }

              {/* {item?.guests && item?.guests?.length > 0 && (
                <div className="booking-item-owner">
                  Invitees:
                  <span className="booking-item-owner-name">
                    {item?.guests?.length}
                  </span>
                </div>
              )} */}

              {/* NFC Checkin & work station */}
              {
                type === "day_pass" && item?.workstation !== null && (
                  <div className="booking-item-owner">
                    <SVG src="/media/svg/dashboard/workstation.svg" />
                    <span className="booking-item-owner-name mt-1 ml-2">
                      {item?.workstation?.title}
                    </span>
                  </div>
                )
              }
              {
                type === "day_pass" && item?.checkin !== null && (
                  <div className="booking-item-owner">
                    <SVG
                      src="/media/svg/dashboard/nfc_blue.svg"
                      className="ml-1"
                    />
                    <SVG src="/media/svg/dashboard/arrow_blue.svg" />
                    <span className="booking-item-owner-name mt-2">
                      {moment(item?.checkin?.checkin).format("HH:mm")}
                      {item?.checkin?.checkout !== null &&
                        -moment(item?.checkin?.checkout).format("HH:mm")}
                    </span>
                    {item?.checkin?.checkout !== null && (
                      <>
                        <SVG
                          src="/media/svg/dashboard/nfc_red.svg"
                          className=""
                        />
                        <SVG src="/media/svg/dashboard/arrow_red.svg" />
                      </>
                    )}
                  </div>
                )
              }
              {
                type === "host" && (
                  <>
                    <div className="booking-item-owner">
                      {"Visitor: "}
                      <span className="booking-item-owner-name">
                        {item?.guest && item?.guest?.full_name}
                      </span>
                    </div>
                    <div className="booking-item-owner">
                      {"Host: "}
                      <span className="booking-item-owner-name">
                        {item?.created_by_me ? "ME" : item?.created_by?.full_name}
                      </span>
                    </div>
                    {item?.host?.full_name != item?.created_by?.full_name ?
                      < div className="booking-item-owner">
                        {"Booking Created By: "}
                        <span className="booking-item-owner-name">
                          {item?.host?.full_name ? item?.host?.full_name : "ME"}
                        </span>
                      </div>
                      :
                      <></>
                    }

                  </>
                )
              }
              {/* Visitor sign in section */}
              {
                type === "host" && userRole === "receptionist" && (
                  <>
                    {moment().isSame(item?.guest?.last_check_in, "day") ? (
                      <>
                        {"Signed in at: "}
                        <b>

                          {
                            item?.guest?.last_check_in
                              ? moment(item?.guest?.last_check_in).format("hh:mm A")

                              : ""
                          }
                        </b >
                      </>
                    ) : item?.sign_in === "0" ||
                      item?.guest?.last_check_in === null ? (
                      <div className="booking-item-owner d-flex justify-content-between align-items-center">
                        <div
                          className="button-cursor"
                          onClick={() => {
                            if (handleSignInClick) {
                              handleSignInClick(item);
                              dispatch(
                                visitorSignInAction?.VisitorVehicleAction(item)
                              );
                            }
                          }}
                        >
                          {"Sign In "}
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/singin-tick-circle.svg"
                            )}
                          />
                        </div>
                      </div>
                    ) : (
                      <div
                        className="button-cursor cancel-kiosk-button mr-3"
                        onClick={() => {
                          handleCancelKiosk(item);
                        }}
                      >
                        Cancel kiosk request
                      </div>
                    )
                    }
                  </>
                )
              }
              {/* {type === "host" && (
                <>
                  {item?.parking[0] ?
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/parking.svg")}
                    />
                    :
                    <></>
                  }

                </>
              )} */}

              {/* End: Visitor sign in section */}
              {
                type === "other" && (
                  <>
                    <div className="booking-item-status font-18px font-weight-bold updated">
                      {item.space?.max_user &&
                        item.space?.max_user !== undefined &&
                        item.space?.max_user > 0 && (
                          <>
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/healthicons_man-outline.svg"
                              )}
                              className="mr-1"
                            />
                            <span className="max-user-count">
                              {item.space?.max_user}+
                            </span>
                          </>
                        )}
                    </div>
                  </>
                )
              }
              <div className="booking-item-status updated">
                {type === "parking" && (
                  <>
                    {item.parking && (
                      <SVG
                        src={toAbsoluteUrl("/media/svg/icons/parking.svg")}
                      />
                    )}
                    {item.ev ? (
                      <SVG
                        className="ml-3"
                        src={toAbsoluteUrl("/media/svg/icons/ev.svg")}
                      />
                    ) : (
                      ""
                    )}
                    {item.disability == "true" ? (
                      <SVG
                        className="ml-3"
                        src={toAbsoluteUrl("/media/svg/dashboard/disability.svg")}
                      />
                    ) : (
                      ""
                    )}
                  </>
                )}

                {type === "host" && (
                  <>
                    {item.park_type && item.park_type == "vehicle_ev" && (
                      <SVG
                        src={toAbsoluteUrl("/media/svg/icons/ev.svg")}
                      />
                    )}
                    {item.park_type && item.park_type == "bike" && (
                      <SVG
                        src={toAbsoluteUrl("/media/svg/icons/Booking/motorcycle.svg")}
                      />
                    )}
                    {item.park_type && item.park_type == "vehicle" && (
                      <SVG
                        src={toAbsoluteUrl("/media/svg/icons/parking.svg")}
                      />
                    )}
                  </>
                )}
              </div>
            </div >
          </div >
        </div >
      </ScrollAnimation >
    </>
  );
}
