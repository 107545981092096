import { Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../_redux/Actions";
import * as eventsAction from "../_redux/Actions";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { TimePickerField } from "../../../../../_metronic/_partials/controls";
import CustomDatePicker from "../../../../components/CustomDatePicker/CustomDatePicker";
import "./BulletinStepPublish.scss";
import * as Yup from "yup";
import helperFunc from "../../../../../_metronic/_helpers/helperFunc";
import CreatableSelect from 'react-select/creatable';
import axios from "axios";
const BASE_URL = process.env.REACT_APP_API_URL;

const initialValues = {
    app_notification: "",
    email_notification: ""
};

const BulletinStep22 = (props) => {
    const { selectType, handleNext } = props;
    const [formInitialValues, setFormInitialValues] = useState(initialValues);
    const { bulletin } = useSelector((state) => state.bulletin);
    console.log(bulletin, "bulletin");
    const [appNotification, setAppNotification] = useState(false);
    const [emailNotification, setEmailNotification] = useState(false);
    const [isInclude, setIsInclude] = useState(true);
    const [isExclude, setIsExclude] = useState(true);

    const [isVisibile, setIsVisibile] = useState(bulletin?.stepTwo?.is_visiable && bulletin?.stepTwo?.is_visiable === true ? true : false);

    const [siteFloors, setSiteFloors] = useState([]);
    const [selectFloorName, setSelectFloorName] = useState(null);
    const [community, setCommunity] = useState([]);
    const [CommunityName, setCommunityName] = useState(null);
    const [users, SetUsers] = useState([]);
    const [visitors, SetVisitors] = useState([]);
    const [excludeUserId, setExcludeUserId] = useState([]);

    const dispatch = useDispatch();
    var format = "hh:mm:ss";


    const validations = Yup.object().shape({
    });

    const [loading, setLoading] = useState(true);
    const siteId = helperFunc.getUserData();

    const handleChangeCommunity = (id) => {
        setSelectFloorName(id.value);
    };
    const handleChangeFloor = (id) => {
        setSelectFloorName(id.value);
    };
    const handleChangeIncludeUser = (id) => {

        const data = [];
        let includeUser = id?.map((item) => {
            data.push(item.value);
        });
        if (data.length > 0) {
            setIsExclude(false);
        }
        else {
            setIsExclude(true);
        }
        setExcludeUserId(data);
    };

    const handleChangeExcludeUser = (id) => {

        const data = [];
        let excludeUser = id?.map((item) => {
            data.push(item.value);
        });
        if (data.length > 0) {
            setIsInclude(false);
        }
        else {
            setIsInclude(true);
        }
        setExcludeUserId(data);
    };

    const getFloorList = (isVisibile) => {
        if (isVisibile) {
            const params = siteId?.site_id;
            dispatch(eventsAction.getAllEventFloor(params))
                .then((res) => {
                    res.forEach((element) => {
                        setSiteFloors((prev) => [
                            ...prev,
                            {
                                value: element.id,
                                label: element.title,
                            },
                        ]);
                    });
                    //  setLoading(false);
                })
                .catch((err) => {
                    throw err;
                });
        }
    };

    const getSelectedUser = (ids) => {
        if (ids !== null) {
            if (typeof ids === 'string') {
                ids = ids.split(',')
            }
            console.log(ids, "ids");
            let dataUser = [];
            if (ids.length > 0) {
                users.map((item) => {
                    ids.map((user_id) => {
                        if (user_id == item.value) {

                            dataUser.push(item);
                        }
                    })
                });

                return dataUser;
            }
        }
    }

    const getCommunityList = (isVisibile) => {
        if (isVisibile) {
            const params = siteId?.site_id;
            console.log(params, "params");
            dispatch(eventsAction.getCommunityList(params))
                .then((res) => {
                    res.data.forEach((element) => {
                        setCommunity((prev) => [
                            ...prev,
                            {
                                value: element.id,
                                label: element.name,
                            },
                        ]);
                    });
                    //  setLoading(false);
                })
                .catch((err) => {
                    throw err;
                });
        }
    };

    async function getvisitorList() {
        const id = siteId?.site_id;
        await axios.get(`${BASE_URL}/api/user/listing?account_id=${Number(id)}&perPage=2000&page=1`).then((response) => {
            if (response) {
                console.log("KK", response.data.users);
                let data = [];
                let s_user = [];
                if (response.data.users && response.data.users.length > 0) {
                    data = response.data.users.map((item) => {
                        s_user.push(
                            {
                                value: item.id,
                                label: item.full_name,
                                email: item.email,
                                url_image: item.url_image,
                            }
                        );
                    });
                    SetUsers(s_user);
                    console.log(s_user, "useres");
                }

            }
        }
        )
    }
    useEffect(() => {
        const params = siteId?.site_id;
        getvisitorList()
        if (bulletin?.stepTwo?.is_visiable.length === 0) {
            setIsVisibile(true)
        }
    }, []);


    return (
        <div className="d-flex justify-content-center event-step2-main w-100">
            <Formik
                initialValues={{
                    app_notification: bulletin.stepPublish.app_notification || false,
                    email_notification: bulletin.stepPublish.email_notification || false
                }}

                onSubmit={(values, { setSubmitting }) => {
                    let formData;
                    formData = {
                        is_visiable: isVisibile,
                        community_id: CommunityName,
                        floor_id: selectFloorName,
                        include_exclude_users: excludeUserId

                    };


                    dispatch(actions.handleChangeStepTwo(formData));
                }}
            >
                {({
                    values,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                    errors,
                    touched,
                    handleBlur,
                }) => (
                    <Form className="w-100">
                        <div className="row " style={{ minHeight: "500px" }}>
                            <div className="col-lg-12 event-select-time rounded p-5">
                                <span className="font-weight-bolder d-flex justify-content-start ">
                                    2.Visibility
                                </span>
                                <div className="teams-main-class">
                                    {/* {defaultAccessData?.is_team_link && ( */}
                                    <div className="d-flex justify-content-between  mt-5">
                                        {/* <SVG src="/media/event/teams.svg" /> */}
                                        <span className="mt-1 link-generate">
                                            Visible to all
                                        </span>

                                        <div className="button-cursor custom-control custom-switch align-self-sm-center">
                                            <input
                                                name={`is_visiable`}
                                                type="checkbox"
                                                id={"is_visiable"}
                                                className="custom-control-input"
                                                checked={isVisibile}
                                                onChange={() => {
                                                    setIsVisibile(!isVisibile);
                                                    getFloorList(isVisibile)
                                                    getCommunityList(isVisibile)
                                                }}
                                            />
                                            <label
                                                title=""
                                                htmlFor={"is_visiable"}
                                                className="custom-control-label"
                                            ></label>
                                        </div>
                                    </div>
                                    {/* )} */}
                                    {!isVisibile && (
                                        <div>
                                            <span className="font-weight-bolder d-flex justify-content-start who-will-see mt-5">
                                                Who Will see Bulletin?
                                            </span>
                                            <div className="mt-5">
                                                {/* <Select2
                          placeholder="Select Community"
                          data={community}
                          value={CommunityName}
                          update={(value) => {
                            handleChangeCommunity(value);
                          }}
                          className="mr-3"
                        ></Select2> */}
                                                <CreatableSelect
                                                    placeholder="Select Community"
                                                    isClearable
                                                    name="community"
                                                    // onChange={visitorListValue}
                                                    onChange={e => { handleChangeCommunity(e); }}
                                                    //onChange={e => { setFieldValue('selected_user_id', e.map((option) => option.value)); }}
                                                    //value={selectCategoryName}
                                                    storeEntityName="category"
                                                    options={community}

                                                // onInputChange={visitorListValueChange}
                                                />
                                            </div>

                                            <div className="mt-5">
                                                {/* <Select2
                          placeholder="Select Floor"
                          data={siteFloors}
                          value={selectFloorName}
                          update={(value) => {
                            handleChangeFloor(value);
                          }}
                          className="mr-3"
                        ></Select2> */}

                                                <CreatableSelect
                                                    placeholder="Select Floor"
                                                    isClearable
                                                    name="floor"
                                                    // onChange={visitorListValue}
                                                    // onChange={e => { visitorListValue(e); setFieldValue('visitor', e) }}
                                                    //onChange={e => { setFieldValue('selected_user_id', e.map((option) => option.value)); }}
                                                    //value={selectFloorName}
                                                    storeEntityName="floor"
                                                    //onChange={e => {  setFieldValue('new_selected_user_id', e.map((option) => option.value)); }}
                                                    onChange={e => { handleChangeFloor(e) }}
                                                    options={siteFloors}

                                                // onInputChange={visitorListValueChange}
                                                />
                                            </div>
                                            {isInclude ?
                                                < div className="mt-5">
                                                    <CreatableSelect
                                                        placeholder="Include User"
                                                        isClearable
                                                        name="include_user"
                                                        onChange={e => { handleChangeIncludeUser(e); }}
                                                        storeEntityName="include_user"
                                                        options={users}
                                                        value={getSelectedUser(excludeUserId)}
                                                        isMulti
                                                    />
                                                </div>
                                                : <></>
                                            }

                                            {isExclude
                                                ?
                                                < div className="mt-5">
                                                    <CreatableSelect
                                                        placeholder="Exclude User"
                                                        isClearable
                                                        name="exclude_user"
                                                        onChange={e => { handleChangeExcludeUser(e) }}
                                                        storeEntityName="category"
                                                        options={users}
                                                        value={getSelectedUser(excludeUserId)}
                                                        isMulti
                                                    />
                                                </div>
                                                :
                                                <></>
                                            }



                                        </div>
                                    )}

                                </div>

                            </div>

                            <div className="col-lg-12 px-5">
                                <button
                                    id="kt_login_signin_submit"
                                    type="submit"
                                    className={`btn btn-primary d-flex justify-content-between align-items-center font-weight-bolder mt-5 w-100`}
                                    onClick={() => {
                                        handleSubmit();

                                        handleNext();

                                    }}
                                >
                                    <span />
                                    <span>NEXT</span>
                                    <span>
                                        <SVG
                                            src={toAbsoluteUrl(
                                                "/media/svg/icons/akar-icons_arrow-right.svg"
                                            )}
                                        />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div >
    );
};

export default BulletinStep22;
