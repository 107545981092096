import axios from "axios";

export const API_URL = `${process.env.REACT_APP_API_URL}`;

export function createBulletin(queryParams) {
  return axios.post(`${API_URL}/api/bulletin/create`, queryParams);
}

//get events
export function getEvents(params, filterParams) {
  console.log(filterParams, params, "filterParams");
  return !filterParams?.event_type
    ? axios.get(`${API_URL}/api/events/get?page=${params?.page}`)
    : axios.get(
      `${API_URL}/api/events/get?${params?.page &&
      `page=${params?.page}`}${filterParams?.space_id &&
      `&space_id=${filterParams?.space_id}`}${filterParams?.floor_id &&
      `&floor_id=${filterParams?.floor_id}`}${filterParams?.event_type &&
      `&event_type=${filterParams?.event_type}`}${filterParams?.starts_at &&
      `&starts_at=${filterParams?.starts_at}`}${filterParams?.ends_at &&
      `&ends_at=${filterParams?.ends_at}`}${filterParams?.host_id &&
      `&host_id=${filterParams?.host_id}`}`
    );
}

export function getAllEventFloor(params) {
  return axios.get(`${API_URL}/api/floors?site_id=${params}`);
}
export function getCommunityList(params) {
  return axios.get(`${API_URL}/api/wall/community/all-community?type=all`);
}
export function getBecons() {
  return axios.get(`${API_URL}/api/space/beacon-spaces`);
}

export function getCategoryList(params) {
  return axios.get(`${API_URL}/api/bulletin/category/get`);
}


export function getAllEventSpace(params) {
  return axios.get(`${API_URL}/api/spaces/spaceList?floor_id=${params}&is_event_space=1`);
}

export function getEventHost(params) {
  return axios.get(`${API_URL}/api/user?site_id=${params}`);
}

export function confirmEvent(params) {
  return axios.get(
    `${API_URL}/api/events/confirm?event_id=${params?.event_id}&user_id=${params?.user_id}`
  );
}

export function cancelEvent(params) {
  return axios.get(
    `${API_URL}/api/events/cancelConfirm?event_id=${params?.event_id}&user_id=${params?.user_id}`
  );
}

export function resendRequestToInvitee(params) {
  return axios.get(
    `${API_URL}/api/events/confirm?event_id=${params.event_id}&user_id=${params?.user_id}`
  );
}
