import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormCheck, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../Vehicle/_redux/Action";
import * as parkingAction from "../Parking/_redux/Action";
import * as siteFloorAction from "../../../../app/components/SelectComponents/_redux/Actions";
import helperFunc from "../../../../_metronic/_helpers/helperFunc";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatePicker";
import SiteFloorSelectInput from "../../../components/SelectComponents/SiteFloorSelectInput";
import SearchSelectInput from "../../../components/SelectComponents/SearchSelectInput";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import * as Yup from "yup";

const initialValues = {
  color: "",
  duration: "",
  ev: "",
  make: "",
  reg: "",
  site_id: "",
  starts_at: "",
  user_id: "",
  vehicle_id: "",
  parking_space: false,
  day_pass: false,
  disability: false,
  electric_vehicle: false,
};
const initialAvailableState = {
  motorbike: 0,
  parking: 0,
  parking_ev: 0,
};

const ParkingForm = (props) => {
  const userDetails = helperFunc.getUserData();

  const { show, handleAddVehicle, modalProps, id, user_id } = props;
  const [toggleOwnerMenu, setToggleOwnerMenu] = useState(false);
  const [selectedSiteId, setSelectedSiteId] = useState("");
  const [userId, setUserId] = useState(helperFunc.getUserId());
  const [occupancyShow, setOccupancyShow] = useState(true);
  const [formInitialValues, setFormInitialValues] = useState(initialValues);
  const [availableData, setAvailableData] = useState(initialAvailableState);
  // eslint-disable-next-line no-unused-vars
  const [manageDatePickerDate, setManageDatePickerDate] = useState();
  const dashboardStore = useSelector((state) => state.dashboards);

  const dispatch = useDispatch();

  console.log(
    modalProps,
    id,
    userId,
    user_id,

    "modalProps"
  );

  useEffect(() => {
    if (userDetails?.site_id) {
      dispatch(siteFloorAction.getSite({ siteId: userDetails.site_id }));
    }
    if (userDetails?.floor_id) {
      dispatch(siteFloorAction.getFloor({ floorId: userDetails.floor_id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  // console.log(object);

  const searchDataStore = useSelector((state) => state.searchData);
  const parkingStore = useSelector((state) => state.parkingPass);
  const datepickerStore = useSelector((state) => state.customDatePicker);

  useEffect(() => {
    if (searchDataStore?.selectedSite) {
      setSelectedSiteId(searchDataStore?.selectedSite?.id);
    }
  }, [searchDataStore]);

  useEffect(() => {
    let data = { ...initialValues };

    if (!parkingStore?.lastError && parkingStore?.parkingEntities.length > 0) {
      props.handleAddParkingSuccess && props.handleAddParkingSuccess();
      props.onHide && props.onHide();
    }
    if (parkingStore?.parkingEditItem) {
      let responseData = {};
      let editItem = parkingStore?.parkingEditItem;
      for (const key in editItem) {
        if (Object.hasOwnProperty.call(editItem, key)) {
          if (key === "starts_at") {
            responseData[`${key}`] = moment(editItem[key]);
            continue;
          }
          if (key === "site_id" && editItem[key]) {
            setSelectedSiteId(editItem[`${key}`]);
            continue;
          }
          if (key === "ev" && editItem[key] === null) {
            responseData[`${key}`] = false;
            continue;
          }
          responseData[`${key}`] = editItem[key];
        }
      }
      data = { ...data, ...responseData };
      setOccupancyShow(false);
      dispatch(siteFloorAction.getSite({ siteId: data?.site_id }));
    } else {
      setOccupancyShow(true);
    }
    setFormInitialValues(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parkingStore]);

  useEffect(() => {
    let bookingStatus = datepickerStore?.available;
    if (bookingStatus) {
      setManageDatePickerDate(datepickerStore.date);
      setAvailableData(bookingStatus);
    } else {
      setAvailableData(initialAvailableState);
    }
  }, [datepickerStore]);

  const handleToggleOwnerClick = (value) => {
    setToggleOwnerMenu(value);
    if (!value) {
      setUserId(helperFunc.getUserId());
    }
  };

  useEffect(() => {
    dispatch(actions.setCommonData({ userId: userId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const getVehicleData = (vehicleID) => {
    let vehicle = searchDataStore?.entityData?.vehicleData?.find((item) => {
      return item.id === vehicleID;
    });

    return vehicle;
  };

  const handleClose = () => {
    if (props?.onHide) {
      props.onHide();
    }
    setToggleOwnerMenu(false);
    dispatch(siteFloorAction.resetSiteAndFloor());
    dispatch(parkingAction.setEditItem(undefined));
  };
  useEffect(() => {
    if (parkingStore.parkingEntities.length !== 0) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parkingStore.parkingEntities.length]);

  useEffect(() => {
    if (id && parkingStore?.parkingEditItem?.vehicle_id) {
      setFormInitialValues((existingValues) => ({
        ...existingValues,
        parking_space: true,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const parkingSchema = Yup.object().shape({
    vehicle_id: Yup.number().required("Please select a vehicle."),
  });

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="modal-right"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <b>{modalProps?.title.toUpperCase()}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={formInitialValues}
          validationSchema={parkingSchema}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            const vehicleDetails = getVehicleData(values.vehicle_id);
            const add_parking_update = {};
            const add_parking = {
              color: vehicleDetails?.color,
              duration: 480,
              ev: vehicleDetails?.ev,
              make: vehicleDetails?.make,
              reg: vehicleDetails?.reg,
              site_id: searchDataStore.siteId,
              starts_at: values.starts_at.format("YYYY-MM-DDTHH:mm:ss"),
              user_id: userId,
              vehicle_id: values.vehicle_id,
              disability: values.disability,
              list_user_id: [],
            };
            if (id) {
              add_parking_update["ev"] = values.ev;
              add_parking_update["day_pass"] = values.day_pass;
              add_parking_update["disability"] = values.disability;
              add_parking_update["reg"] = vehicleDetails?.reg;
              // add_parking_update["user_id"] = userId;
              add_parking_update["starts_at"] = values?.starts_at?.format(
                "YYYY-MM-DDTHH:mm:ss"
              );
              add_parking_update["vehicle_id"] = values.vehicle_id; // It's fixed for the parking booking
              add_parking_update["duration"] = 480; // It's fixed for the parking booking

              dispatch(parkingAction.updateItem(id, add_parking_update)).then(
                (res) => {
                  if (res.data && props?.dashboardActions) {
                    dispatch(
                      props.dashboardActions.fetchBookingList(
                        dashboardStore?.dashboardFilters
                      )
                    );
                  }
                }
              );
            } else {
              console.log(add_parking);
              dispatch(parkingAction.createItem(add_parking)).then((res) => {
                if (
                  res.data &&
                  !res.response.data.errors &&
                  props?.dashboardActions
                ) {
                  dispatch(
                    props.dashboardActions.fetchBookingList(
                      dashboardStore?.dashboardFilters
                    )
                  );
                }
              });
            }
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            errors,
          }) => (
            <Form className="form form-label-right" onSubmit={handleSubmit}>
              {/* {!id && (
                <>
                  <SiteFloorSelectInput />
                  <div className="space-1"></div>
                </>
              )} */}
              <div className="select-time">
                {id ? (
                  <div className="vehicle-text font-weight-bolder">
                    CHANGE DATE
                  </div>
                ) : (
                  <div className="vehicle-text font-weight-bolder">
                    SELECT DATE
                  </div>
                )}
                <div className="space-1"></div>
                <div className="p-3">
                  <CustomDatePicker
                    className="bookify-date-picker"
                    name="starts_at"
                    type="parking"
                    // value={values.starts_at}
                    occupancyShow={occupancyShow}
                    editId={id}
                  />

                  {!id && (
                    <div className="mt-5">
                      <div className="d-flex justify-content-between">
                        <div className="pt-5 pr-5 pb-1 pl-2">
                          The booking is for someone else
                        </div>
                        <div
                          className="pt-5 pb-1 "
                          onClick={() => {
                            if (searchDataStore?.siteId == null) {
                              NotificationManager.error(
                                "Please select Site.",
                                "Error"
                              );
                            }
                          }}
                        >
                          <FormCheck
                            type="switch"
                            name="select_owner"
                            id="select_owner"
                            label=""
                            className="button-cursor"
                            checked={values.select_owner}
                            onClick={() => {
                              handleToggleOwnerClick(!toggleOwnerMenu);
                            }}
                            disabled={searchDataStore?.siteId == null}
                          />
                        </div>
                      </div>
                      {toggleOwnerMenu ? (
                        <div className="space-1">
                          <div className="p-">
                            <Field
                              name="owner"
                              component={SearchSelectInput}
                              placeholder="Select Owner"
                              source={`user?site_id=${selectedSiteId}`}
                              returnData="users"
                              onChange={handleChange}
                              value={values.owner}
                              onSelect={(item) => {
                                if (item?.id) {
                                  setUserId(item.id);
                                }
                              }}
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  )}
                </div>
              </div>

              <div className="p-3">
                <div className="selcet-vehicle">
                  Select Vehicle from the list:
                </div>
                <Field
                  name="vehicle_id"
                  type="text"
                  component={SearchSelectInput}
                  id="vehicle_id"
                  placeholder="Select Vehicle"
                  source={`vehicle/user/list?user_id=${id !== undefined ? user_id : userId
                    }`}
                  onChange={handleChange}
                  value={Number(values.vehicle_id)}
                  storeEntityName="vehicleData"
                />
              </div>
              <div className="d-flex justify-content-between align-items-center pb-3">
                {errors.vehicle_id && (
                  <p className="text-danger text-center mb-0">
                    {errors.vehicle_id}
                  </p>
                )}
                <div className="ml-auto">
                  <span className="available-status-label">Available:</span>{" "}
                  <span className="ml-2 mb-1">
                    <img
                      src="/media/svg/icons/Booking/electric-sign.svg"
                      alt="electric"
                    />
                  </span>
                  <span className="available-counter-text">
                    {" "}
                    {availableData?.parking_ev}
                  </span>{" "}
                  <span className="ml-2 mb-1">
                    <img
                      src="/media/svg/icons/Booking/parking-sign.svg"
                      alt="parking"
                    />
                  </span>
                  <span className="available-counter-text">
                    {" "}
                    {availableData?.parking}
                  </span>{" "}
                  <span className="ml-2 mb-1">
                    <img
                      src="/media/svg/icons/Booking/motorcycle.svg"
                      alt="motorbike"
                    />
                  </span>
                  <span className="available-counter-text">
                    {" "}
                    {availableData?.motorbike}
                  </span>{" "}
                </div>
              </div>

              {/* {!id && (
                <>
                  <div className="mt-3 select-time rounded">
                    <div className="d-flex justify-content-between">
                      <div className="p-5">Book multiple parking spaces?</div>
                      <div className=" p-5 pb-4 p-07">
                        <FormCheck
                          type="switch"
                          id="parking_space"
                          name="parking_space"
                          label=""
                          className="button-cursor"
                          checked={values.parking_space}
                          onChange={() =>
                            setFieldValue(
                              "parking_space",
                              !values.parking_space
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              {(formInitialValues.parking_space || values.parking_space) && (
                <>
                  <div className="space-1"></div>
                  <div className="select-time bg-white border">
                    <div className="select-vehicle">
                      <Row className="font-weight-bolder">
                        <Col md={12} className="d-flex justify-content-between">
                          <div className=" select-vehicle title-text">
                            SELECT VEHICLE
                          </div>
                          <div
                            className="button-cursor select-vehicle add-vehicle"
                            onClick={handleAddVehicle}
                          >
                            + ADD NEW
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="space-1"></div>
                    <div className="p-3">
                      <div className="selcet-vehicle">
                        Select Vehicle from the list:
                      </div>
                      <Field
                        multiple
                        name="vehicle_id"
                        type="text"
                        component={SearchSelectInput}
                        id="vehicle_id"
                        placeholder="Select Vehicle"
                        source={`vehicle/user/list?user_id=${userId}`}
                        onChange={handleChange}
                        value={Number(values.vehicle_id)}
                        storeEntityName="vehicleData"
                      />
                    </div>
                    <div className="d-flex justify-content-end align-items-center mt-5 mr-4 pb-3">
                      <span className="available-status-label">Available:</span>{" "}
                      <span className="ml-2 mb-1">
                        <img
                          src="/media/svg/icons/Booking/electric-sign.svg"
                          alt="electric"
                        />
                      </span>
                      <span className="available-counter-text">
                        {" "}
                        {availableData?.parking_ev}
                      </span>{" "}
                      <span className="ml-2 mb-1">
                        <img
                          src="/media/svg/icons/Booking/parking-sign.svg"
                          alt="parking"
                        />
                      </span>
                      <span className="available-counter-text">
                        {" "}
                        {availableData?.parking}
                      </span>{" "}
                      <span className="ml-2 mb-1">
                        <img
                          src="/media/svg/icons/Booking/motorcycle.svg"
                          alt="motorcycle"
                        />
                      </span>
                      <span className="available-counter-text">
                        {" "}
                        {availableData?.motorbike}
                      </span>{" "}
                    </div>

                    <div className="p-3">
                      <div className="d-flex justify-content-between">
                        <div className="pr-5 pb-3 pl-2">
                          Electric Charging Point?
                        </div>
                        <div className="pb-3">
                          <FormCheck
                            type="switch"
                            name="electric_vehicle"
                            id="electric_vehicle"
                            label=""
                            className="button-cursor"
                            checked={values.electric_vehicle}
                            onChange={() => {
                              setFieldValue(
                                "electric_vehicle",
                                !values.electric_vehicle
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )} */}

              {!id && (
                <>
                  <div className="space-1"></div>
                  <div className="select-time">
                    <div className="title-text">BOOK DAY PASS</div>
                    <div className="space-1"></div>
                    <div className="p-3">
                      <div className="d-flex justify-content-between">
                        <div className="pr-5 pb-3 pl-2">Day Pass?</div>
                        <div className="pb-3">
                          <FormCheck
                            type="switch"
                            name="day_pass"
                            id="day_pass"
                            label=""
                            className="button-cursor"
                            checked={values.day_pass}
                            onChange={() => {
                              setFieldValue("day_pass", !values.day_pass);
                            }}
                          />
                        </div>
                      </div>
                    </div>


                    <div className="p-3">
                      <div className="d-flex justify-content-between">
                        <div className="pr-5 pb-3 pl-2">Disability</div>
                        <div className="pb-3">
                          <FormCheck
                            type="switch"
                            name="disability"
                            id="disability"
                            label=""
                            className="button-cursor"
                            checked={values.disability}
                            onChange={() => {
                              setFieldValue("disability", !values.disability);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                  </div>
                </>
              )}

              <Modal.Footer className="pt-5 pl-0 pr-0">
                <Button
                  className="w-100 font-weight-bolder custom-button"
                  variant="primary"
                  type="submit"
                  disabled={
                    availableData?.motorbike && !isSubmitting ? "" : "disabled"
                  }
                >
                  Done
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default ParkingForm;
