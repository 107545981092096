import React, { useState, useEffect } from "react";
import Header from "./Header";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import VisitorSignInVehicleDetails from "./VisitorSignInVehicleDetails";
import helperFunc from "../../../../_metronic/_helpers/helperFunc";
import * as visitorSignInAction from "./_redux/Actions";
import VisitorSignInSelectVehicle from "./VisitorSignInSelectVehicle";
import VisitorSignInThankYou from "./VisitorSignInThankYou";
import * as dashboardActions from "../../../pages/_redux/Actions";

export default function SignInOption(props) {
  const {
    show,
    bookingId,
    setVisitorSignInShow,
    handleVisitorSignInClose,
  } = props;

  const [currentStep, setCurrentStep] = useState("sign_in_options");
  const [bookingData, setBookingData] = useState();
  const [isThankYouMsg, setIsThankYouMsg] = useState(false);
  const currentUser = helperFunc.getUserData();
  const dashboardStore = useSelector((state) => state.dashboards);

  const handleVisitorSelectVehicleShow = () => {
    setCurrentStep("select_vehicle");
  };

  const handleIsNewVehicleAddShow = () => {
    setCurrentStep("add_vehicle");
  };

  const handleThankYouShow = () => {
    setIsThankYouMsg(true);
    setCurrentStep("thank_you");
  };

  const handleAddNewVehicle = () => {
    setCurrentStep("select_vehicle");
  };
  const dispatch = useDispatch();
  const { dashboardState } = useSelector(
    (state) => ({ dashboardState: state.dashboards }),
    shallowEqual
  );

  useEffect(() => {
    if (bookingId) {
      let booking = dashboardState?.bookingList?.entities?.filter((item) => {
        return item.id === Number(bookingId) && item.type === "host";
      });
      if (booking.length > 0) {
        let bookingDetails = booking[0];
        setBookingData(bookingDetails);
        let params = {
          visitor_id: bookingDetails?.guests[0]?.id,
          host_id: bookingDetails?.host?.id,
          floor_id: currentUser?.floor_id, // TODO : need to change it to dynamic when it comes from the API call
        };
        dispatch(
          visitorSignInAction.getVisitorVehicleList(
            `api/booking/visitor/details`,
            params
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingId]);

  const handleLisok = () => {
    dispatch(
      visitorSignInAction.createKiosk(
        currentUser?.id,
        bookingData?.guests[0]?.id,
        bookingData?.id
      )
    ).then((response) => {
      setIsThankYouMsg(false);
      handleVisitorSignInClose();
      dispatch(
        dashboardActions.fetchBookingList(dashboardStore?.dashboardFilters)
      );
    });
  };

  const handleThankYouClose = () => {
    setCurrentStep("sign_in_options");
    handleVisitorSignInClose();
    dispatch(
      dashboardActions.fetchBookingList(dashboardStore?.dashboardFilters)
    );
  };
  const handlePopUpClose = () => {
    handleVisitorSignInClose();
    setCurrentStep("sign_in_options");
  };

  const handleGoBack = () => {
    if (currentStep === "select_vehicle") {
      setCurrentStep("sign_in_options");
    } else if (currentStep === "add_vehicle") {
      setCurrentStep("select_vehicle");
    } else if (currentStep === "thank_you") {
      setCurrentStep("select_vehicle");
    } else {
      setCurrentStep("sign_in_options");
    }
  };
  return (
    <>
      <Modal
        show={show}
        centered={true}
        dialogClassName="visitor-booking-sing-in-modal"
      >
        <Modal.Header
          className={isThankYouMsg ? "thank-you-header-background" : ""}
        >
          <Header bookingData={bookingId} isThankYouMsg={isThankYouMsg} />
        </Modal.Header>
        <Modal.Body>
          {currentStep === "sign_in_options" && (
            <>
              <div className="d-flex justify-content-center">
                <h4 className="option-selection-text">
                  Please,select an option
                </h4>
              </div>
              <div className="d-flex justify-content-center select-option-visitor-signin">
                <div className="d-flex justify-content-around">
                  <div className="select-option-div">
                    <span className="">
                      <img
                        src="media/svg/icons/Rectangle513.png"
                        alt="sign in now"
                      />
                    </span>
                    <div className="mt-5">
                      <button
                        className="visitor-signin-btn text-light"
                        // onClick={handleVisitorSignInVehicleDetails}
                        onClick={() => {
                          handleVisitorSelectVehicleShow();
                        }}
                      >
                        VISITOR SIGN IN NOW{" "}
                        <SVG
                          src={toAbsoluteUrl(
                            "media/svg/icons/akar-icons_arrow-right.svg"
                          )}
                        />
                      </button>
                    </div>
                  </div>
                  <div className="select-option-div">
                    <span className="">
                      <img
                        src="media/svg/icons/Rectangle514.png"
                        alt="sign in to kisok"
                      />
                    </span>
                    <div className="rounded-circle mt-5" onClick={handleLisok}>
                      <button className="visitor-signin-btn text-light">
                        SEND SIGN IN TO KIOSK{" "}
                        <SVG
                          src={toAbsoluteUrl(
                            "media/svg/icons/akar-icons_arrow-right.svg"
                          )}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="rounded-circle mt-5 d-flex justify-content-center"
                onClick={handlePopUpClose}
              >
                <button className="visitor-signin-btn text-light">
                  Cancel
                  {/* <SVG src={toAbsoluteUrl("media/svg/icons/akar-icons_arrow-right.svg")} /> */}
                </button>
              </div>
            </>
          )}

          {currentStep === "select_vehicle" && (
            <>
              {/* List of visitor vehicle */}
              <VisitorSignInSelectVehicle
                handleIsNewVehicleAddShow={handleIsNewVehicleAddShow}
                handleThankYouShow={handleThankYouShow}
                bookingData={bookingData}
                setCurrentStep={setCurrentStep}
                handlePopUpClose={handlePopUpClose}
                bookingId={bookingId}
              />
              {/* End List of visitor vehicle */}
            </>
          )}
          {currentStep === "add_vehicle" && (
            <>
              {/* New vehicle form */}
              <VisitorSignInVehicleDetails
                bookingData={bookingData}
                handleAddNewVehicle={handleAddNewVehicle}
                setCurrentStep={setCurrentStep}
                handlePopUpClose={handlePopUpClose}
              />
              {/* End: New vehicle form */}
            </>
          )}
          {currentStep === "thank_you" && (
            <>
              <VisitorSignInThankYou
                host={bookingData?.host[0]}
                handleVisitorSignInClose={handleThankYouClose}
              />
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
