/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useLayoutEffect, useEffect } from "react"
import objectPath from "object-path"
import { useLocation } from "react-router-dom"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../_helpers"
import { Link } from "react-router-dom"
import { Dropdown } from "react-bootstrap"
import { getBreadcrumbsAndTitle, useSubheader } from "../../_core/MetronicSubheader"
import { Formik } from "formik"
import { useHtmlClassService } from "../../_core/MetronicLayout"
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export function SubHeader() {
  const uiService = useHtmlClassService()
  const location = useLocation()
  const subheader = useSubheader()
  // const [frmInitialValues, setFrmInitialValues] = useState({ q: "" });

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderFixed: objectPath.get(uiService.config, "subheader.fixed"),
      subheaderMobileToggle: objectPath.get(uiService.config, "subheader.mobile-toggle"),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses("subheader_container", true),
    }
  }, [uiService])

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname)
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname)
    const breadcrumbs =
      aside && aside.breadcrumbs.length > 0 ? aside.breadcrumbs : header.breadcrumbs
    subheader.setBreadcrumbs(breadcrumbs)
    const isSearchOn = aside && aside.breadcrumbs.length > 0 ? true : false
    const searchText = aside && aside.breadcrumbs.length > 0 ? null : false
    subheader.setBreadcrumbs(breadcrumbs)
    subheader.setIsSearch(isSearchOn)
    subheader.setSearchText(searchText)

    subheader.setTitle(aside && aside.title && aside.title.length > 0 ? aside.title : header.title)
    // eslint-disable-next-line
  }, [location.pathname])
  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => { }, [subheader])
  const applyFilter = (values) => {
    subheader.setSearchText(values)
  }

  const openSidebar = (e) => {
    e.stopPropagation()
    e.preventDefault()
    const body = document.getElementById("kt_body")
    body.classList.remove("aside-minimize")
  }

  return (
    <div id="kt_subheader" className={`subheader py-3 py-lg-8 ${layoutProps.subheaderCssClasses}`}>
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
      >
        {/* Info */}
        <div className="align-items-center d-flex flex-wrap w-100">
          <div className="col-md-3">
            {!layoutProps.subheaderMobileToggle && (
              <div className=" open-sidebar-icon">
                <SVG
                  onClick={(e) => openSidebar(e)}
                  className="open-menu-icon brand-toggle"
                  src={toAbsoluteUrl("/media/svg/menu/menu.svg")}
                />
              </div>
            )}
          </div>
          {/* begin::Mobile Toggle */}
          {layoutProps.subheaderMobileToggle && (
            <button
              className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
              id="kt_subheader_mobile_toggle"
            >
              <span />
            </button>
          )}
          {/* end::Mobile Toggle */}

          <div className="d-flex col-md-6 align-items-baseline col-md-6">
            {/* <Formik
              initialValues={{ q: "" }}
              onSubmit={(values) => {
                applyFilter(values);
              }}
            >
              {({
                handleSubmit,
                handleBlur,
                handleChange,
                setFieldValue,
                values,
              }) => (
                <form
                  onSubmit={handleSubmit}
                  className="form w-100"
                  autoComplete="off"
                >
                  <div className="d-flex w-100">
                    <div className="bg-white border col-md-12 d-flex flex-center px-6 rounded-pill">
                      <input
                        type="text"
                        className="form-control border-0 font-weight-bold pl-2"
                        name="q"
                        placeholder="Search"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue("q", e.target.value);
                        }}
                      />
                      <span className="svg-icon svg-icon-lg svg-icon-primary">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/Search.svg"
                          )}
                        />
                      </span>
                    </div>
                  </div>
                </form>
              )}
            </Formik> */}
          </div>
          <div className="align-items-center col-md-3 d-flex flex-wrap justify-content-end">
            <Dropdown>
              <Dropdown.Toggle variant="light">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>

                  <Link to={"/settings"}>SETTINGS</Link>

                </Dropdown.Item>
                <Dropdown.Item>
                  <Link to={"/logout"}>LOGOUT</Link>
                </Dropdown.Item>
                {/* <Dropdown.Item href="/my-profile">Profile</Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>
            {/* Button */}
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="latest-project" className="tooltip-class" >
                  <span  >Notification</span>
                </Tooltip>
              }
            >
              <button className="btn font-weight-bold">
                <SVG src={toAbsoluteUrl("/media/svg/menu/notification.svg")} />
              </button>
            </OverlayTrigger>
          </div>
          {/* end::Heading */}
        </div>
        {/* Info */}
      </div>
    </div>
  )
}
