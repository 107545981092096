import React, { useState, useEffect } from "react";
import { Formik, Field } from "formik";
import { Button, Form, FormCheck, Modal } from "react-bootstrap";
import moment, { isMoment } from "moment";
import {
  CustomeSelect,
  TimePickerField,
} from "../../../../_metronic/_partials/controls";
import helperFunc from "../../../../_metronic/_helpers/helperFunc";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatePicker";
import SearchSelectInput from "../../../components/SelectComponents/SearchSelectInput";
import { useDispatch, useSelector } from "react-redux";
import * as meetingRoomAction from "./_redux/Actions";
import * as dashboardAction from "../../../pages/_redux/Actions";

const initialValues = {
  host: "",
  starts_at: "",
  change_host: false,
  meeting_room: "",
  startTime: undefined,
  endTime: undefined,
};
export default function EditMeetingRoom(props) {
  const { isMeetingEdit, handleMeetingClose, id } = props;
  console.log(isMeetingEdit, "isMeetingEdit")
  const dispatch = useDispatch();
  const meetingRoomStore = useSelector((state) => state.meetingRooms);
  const dashboardStore = useSelector((state) => state.dashboards);

  const { meetingEditItem, listLoading, availability } = meetingRoomStore;
  const userDetails = helperFunc.getUserData();

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [nightTime, setNightTime] = useState();
  const [frmInitialValues, setFrmInitialValues] = useState(initialValues);
  const [toggleOwnerMenu, setToggleOwnerMenu] = useState(false);
  const [meetingRooms, setMeetingRooms] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState();

  var format = "hh:mm:ss";
  var time = moment(nightTime, format);
  var afterTime = moment("18:01:00", format);
  var beforeTime = moment("07:00:00", format).add(1, "day");
  var timeBetween = time.isBetween(afterTime, beforeTime);

  const manageMeetingStoreData = () => {
    const frmValues = frmInitialValues;
    if (meetingEditItem?.starts_at) {
      frmValues.startTime = meetingEditItem?.starts_at;
    }
    if (meetingEditItem?.ends_at) {
      frmValues.endTime = meetingEditItem?.ends_at;
    }
    setFrmInitialValues(frmValues);
  };

  const applyTimeFilter = (data, type, setFieldValue) => {

    var timePickerTime = moment(data);
    if (type === "startTime") {
      let startTime = moment(timePickerTime);
      // let endTime = timePickerTime.add(60, "minutes");
      setStartTime(startTime);
      // setEndTime(moment(endTime));
      if (setFieldValue) {
        setFieldValue("startTime", startTime);
        // setFieldValue("endTime", endTime);
      }
    }

    if (type === "endTime") {
      let endTime = moment(timePickerTime);
      // let startTime = timePickerTime.subtract(60, "minutes");
      // console.log(endTime.format("H:m:s"), "endTime");
      // console.log(startTime.format("H:m:s"), "startTime");
      console.log(parseInt(moment(endTime).format("H")), parseInt(moment(startTime).format("H")), "times");
      if (parseInt(moment(endTime).format("H")) > parseInt(moment(startTime).format("H"))) {
        setEndTime(endTime);
        // setStartTime(moment(startTime));
        if (setFieldValue) {
          // setFieldValue("startTime", startTime);
          setFieldValue("endTime", endTime);
        }
      }

    }
  };
  useEffect(() => {
    if (meetingEditItem?.starts_at) {
      applyTimeFilter(meetingEditItem?.starts_at, "startTime");
    }
    if (meetingEditItem?.ends_at) {
      applyTimeFilter(meetingEditItem?.ends_at, "endTime");
    }
    setSelectedRoom(meetingEditItem?.space);
  }, [meetingEditItem]);

  useEffect(() => {
    if (id && !listLoading) {
      manageMeetingStoreData();
      // setTimeout(() => {
      let params = `floor_id=1&start_date=` + moment(meetingEditItem?.starts_at).format("YYYY-MM-DD");

      dispatch(
        meetingRoomAction.meetingRoomList(params)
      );
      // }, 100)
    }
  }, [id, meetingEditItem]);

  useEffect(() => {
    const tempArr = Object.keys(availability);
    if (tempArr && tempArr.length > 0) {
      const tempTitle = tempArr[tempArr.length - 1];
      setMeetingRooms(availability[tempTitle]);
    }
  }, [availability]);

  useEffect(() => {
    let data = { ...initialValues };
    if (meetingEditItem) {
      let responseData = {};
      let editItem = meetingEditItem;
      for (const key in editItem) {
        if (Object.hasOwnProperty.call(editItem, key)) {
          if (key === "starts_at") {
            let resKey = key;
            responseData[`${resKey}`] = moment(editItem[key]);
            continue;
          }
          responseData[`${key}`] = editItem[key];
        }
      }
      data = { ...data, ...responseData };
    }
    setFrmInitialValues(data);
  }, [meetingEditItem]);

  // const countingDuration = (startTime, endTime) => {

  const countingDuration = (startTime, endTime) => {
    return endTime.diff(startTime, "minutes");
  };
  return (
    <>
      <Modal
        show={isMeetingEdit}
        onHide={handleMeetingClose}
        className="modal-right edit-meeting-room"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Meeting Room</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={frmInitialValues}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              if (!moment.isMoment(values.startTime))
                values.startTime = moment(values.startTime);
              if (!moment.isMoment(values.endTime))
                values.endTime = moment(values.endTime);

              let durationInMinutes = countingDuration(
                values.startTime,
                values.endTime
              );
              let starts_at = values.starts_at
                .set("hour", values.startTime.get("hour"))
                .set("minute", values.startTime.get("minute"))
                .set("second", values.startTime.get("second"));
              const edit_meeting_room_data = {
                duration: durationInMinutes,
                starts_at: values?.startTime,
                ends_at: values?.endTime,
                space_id: values?.meeting_room || values.space_id,
                booking_id: meetingEditItem?.id,
                type: "space",
              };

              if (values?.host) {
                edit_meeting_room_data["host"] = values?.host;
              }

              console.log(values.startTime, values.endTime, "Values");

              dispatch(
                meetingRoomAction.editMeetingRoom(edit_meeting_room_data)
              ).then((res) => {
                if (res.data) {
                  dispatch(
                    dashboardAction.fetchBookingList(
                      dashboardStore?.dashboardFilters
                    )
                  );
                }
              });
            }}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <Form className="form form-label-right" onSubmit={handleSubmit}>
                <div className="space-1"></div>
                <div className="select-time">
                  <div className="title-text select-meeting font-weight-bolder mb-2">
                    EDIT TIME AND TYPE
                  </div>
                  <div className="content-inner">
                    <div className="mt-5">
                      <div className="d-flex justify-content-between pl-3 pr-1 mb-5">
                        <div className="py-1">Change Host</div>
                        <div className="py-1 d-flex align-align-items-center justify-content-end">
                          <FormCheck
                            type="switch"
                            name="change_host"
                            id="change_host"
                            label=""
                            className="button-cursor"
                            checked={values.meeting_host}
                            onChange={() =>
                              setFieldValue("change_host", !values.change_host)
                            }
                            onClick={() => {
                              setToggleOwnerMenu(!toggleOwnerMenu);
                            }}
                          // disabled={searchDataStore?.siteId == null}
                          />
                        </div>
                      </div>

                      {toggleOwnerMenu ? (
                        <div className="space-1">
                          <div className="">
                            <Field
                              name="host"
                              id="host"
                              component={SearchSelectInput}
                              placeholder="Select Host"
                              source={`user?site_id=${meetingEditItem?.site_id}`}
                              returnData="users"
                              onChange={handleChange}
                              value={values.host}
                            // onSelect={(item) => {
                            //     if (item?.id) {
                            //         setUserId(item.id)
                            //     }
                            // }}
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>

                    {/* <div className="space-15">
                                            <Button
                                                className="w-100"
                                            variant="primary"
                                            type="button"
                                            onClick={() => {
                                                setButtonClick("rooms")
                                                props.rediractionLink("rooms")
                                            }}
                                            disabled={availableData?.day_passes && !isSubmitting ? "" : "disabled"}
                                            >
                                                CANCEL BOOKING
                                            </Button>
                                        </div> */}

                    <div className="mt-5 mb-5">
                      <p>Change date(London GMT +1):</p>
                    </div>
                    <CustomDatePicker
                      className="bookify-date-picker"
                      name="starts_at"
                      type="meetingRoom"
                      editId={id}
                      date={values.start_at}
                      // date={values.starts_at}
                      floor_id={userDetails?.floor_id}
                    />

                    <div className="time-picker-main mt-5 mb-5">
                      {timeBetween && (
                        <>
                          <div className="night-div-meeting w-100 d-flex justify-content-around">
                            <div className="d-inline-flex ">
                              <span className="svg-icon night-moon-span m-2">
                                <img
                                  src="/media/svg/icons/moon-night.png"
                                  alt="nightMoon"
                                  className="nightMoon"
                                />
                              </span>
                            </div>
                            <div className="d-inline-flex ">
                              <h6 className="mt-3 ml-2 mr-2">
                                Opps!{" "}
                                <b>
                                  It appears the selected time is outside of
                                  regular working hours
                                </b>
                              </h6>
                            </div>
                          </div>
                        </>
                      )}
                      <div className="d-flex justify-content-between mt-5 mb-5">
                        <div
                          className={
                            "time-picker-inner w-100 mr-3" +
                            (startTime ? " time-picker-show" : "") +
                            (timeBetween ? " moonlight-input" : "")
                          }
                        >
                          {timeBetween ? (
                            <img
                              src="/media/svg/icons/warning-red.png"
                              alt="nightMoon"
                              className="timepicker-moonlight-warning-icon"
                            />
                          ) : (
                            <SVG
                              className=""
                              src={toAbsoluteUrl("/media/svg/icons/clock.svg")}
                            />
                          )}

                          <TimePickerField
                            onDateSet={(data) => {
                              applyTimeFilter(data, "startTime", setFieldValue);
                              setNightTime(moment(data));
                            }}
                            stepping={15}
                            name="startTime"
                            placeholder={"Start Time"}
                            date={values.startTime}
                          />
                        </div>

                        <div
                          className={
                            "time-picker-inner w-100" +
                            (endTime ? " time-picker-show" : "") +
                            (timeBetween ? " moonlight-input" : "")
                          }
                        >
                          {timeBetween ? (
                            <img
                              src="/media/svg/icons/warning-red.png"
                              alt="nightMoon"
                              className="timepicker-moonlight-warning-icon"
                            />
                          ) : (
                            <SVG
                              className=""
                              src={toAbsoluteUrl("/media/svg/icons/clock.svg")}
                            />
                          )}
                          <TimePickerField
                            onDateSet={(data) =>
                              applyTimeFilter(data, "endTime", setFieldValue)
                            }
                            stepping={15}
                            name="endTime"
                            placeholder={"End Time"}
                            value={values.endTime}
                            minDate={moment().format("hh:mm a")}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mt-5">
                      <div className="d-flex justify-content-between mb-5">
                        <div className="py-1">Change Meeting Room:</div>
                      </div>

                      <div className="space-1">
                        <div className="">
                          {meetingRooms && meetingRooms.length > 0 && (
                            <CustomeSelect
                              name="meeting_room"
                              component={SearchSelectInput}
                              placeholder="Select Meeting Room"
                              data={meetingRooms}
                              labelKey="title"
                              valueKey="id"
                              onChange={handleChange}
                              value={values.meeting_room}
                              selectedRoom={frmInitialValues.space_id}
                              onSelect={(item) => {
                                setSelectedRoom(item);
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    {selectedRoom && (
                      <>
                        {selectedRoom?.url_image ? (
                          <>
                            <div className="selected-room-image">
                              <img
                                src={selectedRoom?.url_image}
                                alt={`${selectedRoom?.url_image}`}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            {selectedRoom?.image?.url && (
                              <>
                                <div className="selected-room-image">
                                  <img
                                    src={selectedRoom?.image?.url}
                                    alt={`${selectedRoom?.image?.url_image}`}
                                  />
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="space-15">
                  <Button className="w-100" variant="primary" type="submit">
                    SAVE
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}
