import axios from "axios";

export const API_URL = `${process.env.REACT_APP_API_URL}`;

export function createMeeting(item) {
  // return item/visitorsList
  // return axios.post(`${API_URL}/api/booking/create/visitor`, item);
}
export function meetingRoomList(queryParams) {
  return axios.get(
    `${API_URL}/api/booking/calendar/availability?${queryParams}`
  );
}
export function editMeetingRoom(queryParams) {
  console.log(queryParams, "Query");
  return axios.post(
    `${API_URL}/api/update/booking-post
`,
    queryParams
  );
}
