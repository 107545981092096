import { CircularProgress, TextField, Typography } from "@material-ui/core";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { useDispatch, useSelector } from "react-redux";
import * as eventsAction from "../_redux/Actions";
import { Select2 } from "select2-react-component";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { TimePickerField } from "../../../../../_metronic/_partials/controls";
import CustomDatePicker from "../../../../components/CustomDatePicker/CustomDatePicker";
import SearchMultiSelectInput from "../../../../components/SelectComponents/SearchMultiSelectInput";
import "./EventStep2.scss";
import * as Yup from "yup";
import helperFunc from "../../../../../_metronic/_helpers/helperFunc";

const initialValues = {
  color: "",
  duration: "",
  ev: "",
  make: "",
  reg: "",
  site_id: "",
  starts_at: "",
  user_id: "",
  vehicle_id: "",
  parking_space: false,
  day_pass: false,
  electric_vehicle: false,
};

const EventStep2 = (props) => {
  const { selectType, handleNext } = props;
  const [formInitialValues, setFormInitialValues] = useState(initialValues);
  const [nightTime, setNightTime] = useState();
  const [unassignedList, setUnassignedList] = useState([]);
  const [selectUnassign, setSelectUnassign] = useState("");
  const { events } = useSelector((state) => state.events);

  const dispatch = useDispatch();

  var format = "hh:mm:ss";
  var time = moment(nightTime, format);
  var afterTime = moment("18:01:00", format);
  var beforeTime = moment("07:00:00", format).add(1, "day");
  var timeBetween = time.isBetween(afterTime, beforeTime);

  const validations = Yup.object().shape({
    created_at: Yup.string().required("Event Date is required"),
    starts_at: Yup.string().required("Start time is required"),
    ends_at: Yup.string().required("End time is required"),
    city: Yup.string().required("City is required."),
    address_1: Yup.string().required("Initial Address is required."),
  });

  const [loading, setLoading] = useState(true);
  const [siteFloors, setSiteFloors] = useState([]);
  const [selectFloorName, setSelectFloorName] = useState({
    name: "",
    id: "",
  });
  const [selectSiteName, setSelectSiteName] = useState({
    name: "",
    id: "",
  });
  const [Site, setSite] = useState([]);
  const siteId = helperFunc.getUserData();

  useEffect(() => {
    if (events?.stepOne?.event_type === "internal") {
      setLoading(true);
      const params = siteId?.site_id;
      dispatch(eventsAction.getAllEventFloor(params))
        .then((res) => {
          res.forEach((element) => {
            setSiteFloors((prev) => [
              ...prev,
              {
                value: element.id,
                label: element.title,
              },
            ]);
          });
          setLoading(false);
        })
        .catch((err) => {
          throw err;
        });
    }
  }, [events?.stepOne?.event_type]);

  const handleChangeFloor = (id) => {
    const filtered = siteFloors.filter((one) => one.value === id);
    setSelectFloorName({ name: filtered[0].label, id: id });
  };

  const handleChangeSite = (id) => {

    const filtered = Site.filter((one) => one.value === id);
    setSelectSiteName({ name: filtered[0].label, id: id });
  };

  useEffect(() => {
    if (selectFloorName.id !== "") {
      setLoading(true);
      const params = selectFloorName.id;
      dispatch(eventsAction.getAllEventSpace(params)).then((res) => {
        console.log(res, "selectFloorName");
        return res?.forEach((element) => {
          setSite((prev) => [
            ...prev,
            {
              value: element.id,
              label: element.title,
            },
          ]);
          setLoading(false);
        });
      });
    }
  }, [selectFloorName.id]);

  useEffect(() => {
    console.log(siteFloors, "siteFloors");
  }, [siteFloors]);

  return (
    <div className="d-flex justify-content-center event-step2-main w-100">
      <Formik
        initialValues={{
          created_at: events.stepTwo.created_at || "",
          starts_at: events.stepTwo.starts_at || "",
          ends_at: events.stepTwo.ends_at || "",
          map_url: events.stepTwo.map_url || "",
          city: events.stepTwo.city || "",
          address_1: events.stepTwo.address_1 || "",
          address_2: events.stepTwo.address_2 || "",
          postcode: events.stepTwo.postcode || "",
        }}
        validationSchema={
          events?.stepOne?.event_type === "external" ? validations : ""
        }
        onSubmit={(values, { setSubmitting }) => {
          let formData;
          if (events?.stepOne?.event_type === "internal") {
            formData = {
              created_at: values.created_at,
              starts_at: values.starts_at,
              ends_at: values.ends_at,
              floor_id: selectFloorName.id,
              floor_name: selectFloorName.name,
              site_id: selectSiteName.id,
              site_name: selectSiteName.name,
            };
          } else {
            formData = {
              created_at: values.created_at,
              starts_at: values.starts_at,
              ends_at: values.ends_at,
              map_url: values.map_url,
              city: values.city,
              address_1: values.address_1,
              address_2: values.address_2,
              postcode: values.postcode,
            };
          }
          dispatch(eventsAction.handleChangeStepTwo(formData));
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          errors,
          touched,
          handleBlur,
        }) => (
          <Form className="w-100">
            <div className="row">
              <div className="col-lg-12 event-select-time rounded p-5">
                <span className="font-weight-bolder d-flex justify-content-start mb-3">
                  SELECT TIME
                </span>
                <CustomDatePicker
                  className="bookify-date-picker mt-3"
                  name="created_at"
                  type="event"
                  // onBlur={handleBlur}
                  onDateSet={(date) => {
                    setFieldValue("created_at", date);
                  }}
                />
                {errors.created_at && touched.created_at && (
                  <p className="text-danger">{errors.created_at}</p>
                )}
                <div className="event-day-status rounded p-2">
                  <span className="event-calendar">
                    <SVG src="/media/event/calendar.svg" />
                  </span>
                  <span className="event-date ml-4">
                    Day Status:{" "}
                    <span className="font-weight-bolder">
                      {moment(values.created_at).format("dddd[, ]Do, MMM")}
                    </span>
                  </span>
                  <span className="ml-18">
                    <SVG src="/media/event/event-traffic.svg" />
                  </span>
                </div>
                <div className="event-time-picker mt-3">
                  <div className="time-picker-main">
                    {timeBetween && (
                      <>
                        <div className="night-div-meeting w-100 d-flex justify-content-around">
                          <div className="d-inline-flex ">
                            <span className="svg-icon night-moon-span m-2">
                              <img
                                src="/media/svg/icons/moon-night.png"
                                alt="nightMoon"
                                className="nightMoon"
                              />
                            </span>
                          </div>
                          <div className="d-inline-flex ">
                            <h6 className="mt-3 ml-2 mr-2">
                              Opps!{" "}
                              <b>
                                It appears the selected time is outside of
                                regular working hours
                              </b>
                            </h6>
                          </div>
                        </div>
                      </>
                    )}
                    {/* <label>Select Time:</label> */}
                    <div className="d-flex justify-content-between">
                      <div
                        className={
                          "time-picker-inner w-100 mr-3" +
                          // (startTime ? " time-picker-show" : "") +
                          (timeBetween ? " moonlight-input" : "")
                        }
                      >
                        {timeBetween ? (
                          <img
                            src="/media/svg/icons/warning-red.png"
                            alt="nightMoon"
                            className="timepicker-moonlight-warning-icon"
                          />
                        ) : (
                          <SVG
                            className=""
                            src={toAbsoluteUrl("/media/svg/icons/clock.svg")}
                          />
                        )}

                        <TimePickerField
                          // onDateSet={(data) => {
                          //   setFieldValue("starts_at", data);
                          // }}
                          // date={moment(values.starts_at).format("hh:mm a")}
                          // minDate={moment().format("hh:mm a")}
                          maxDate={values.ends_at}
                          name="starts_at"
                          placeholder={"Start Time"}
                          date={values.startTime}
                        />
                      </div>

                      <div
                        className={
                          "time-picker-inner w-100" +
                          // (endTime ? " time-picker-show" : "") +
                          (timeBetween ? " moonlight-input" : "")
                        }
                      >
                        {timeBetween ? (
                          <img
                            src="/media/svg/icons/warning-red.png"
                            alt="nightMoon"
                            className="timepicker-moonlight-warning-icon"
                          />
                        ) : (
                          <SVG
                            className=""
                            src={toAbsoluteUrl("/media/svg/icons/clock.svg")}
                          />
                        )}
                        {/* <TimePicker
                            // minDate={moment().add(15, 'minutes').format("LT")}
                            format="hh:mm a"
                            stepping={15}
                            onChange={(e) => {
                              setEndTime(moment(e.date).format("hh:mm a"))
                            }} */}
                        {values?.starts_at ? (
                          <TimePickerField
                            onDateSet={(data) => setFieldValue("ends_at", data)}
                            //minDate={values?.startTime}
                            date={moment(values.ends_at).format("hh:mm a")}
                            // minDate={moment().format("hh:mm a")}
                            name="ends_at"
                            placeholder={"End Time"}
                          //date={values.endTime}
                          />
                        ) : (
                          <TimePickerField
                            onDateSet={(data) => setFieldValue("ends_at", data)}
                            name="ends_at"
                            placeholder={"End Time"}
                          //date={values.endTime}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`col-lg-12 ${selectType === "external" && selectType === "internal"
                  ? "event-select-location"
                  : ""
                  } rounded p-5 mt-5 w-100`}
              >
                <span className="font-weight-bolder d-flex justify-content-start">
                  {events?.stepOne?.event_type === "external"
                    ? "SELECT LOCATION"
                    : events?.stepOne?.event_type === "internal"
                      ? "SELECT FLOOR"
                      : null}
                </span>
                {events?.stepOne?.event_type === "external" ? (
                  <>
                    <span>
                      <TextField
                        id="outlined-basic"
                        label="Google Map Url"
                        variant="outlined"
                        className="mt-4 mb-5"
                        name="map_url"
                        value={values.map_url}
                        onChange={handleChange}
                      />
                    </span>
                    <TextField
                      id="outlined-basic"
                      label="City"
                      variant="outlined"
                      className="mb-5"
                      name="city"
                      value={values.city}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {errors.city && touched.city && (
                      <p className="text-danger">{errors.city}</p>
                    )}
                    <TextField
                      id="outlined-basic"
                      label="Address Line 1"
                      variant="outlined"
                      className="mb-5"
                      name="address_1"
                      value={values.address_1}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {errors.address_1 && touched.address_1 && (
                      <p className="text-danger">{errors.address_1}</p>
                    )}
                    <TextField
                      id="outlined-basic"
                      label="Address Line 2"
                      variant="outlined"
                      className="mb-5"
                      name="address_2"
                      value={values.address_2}
                      onChange={handleChange}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Postcode"
                      variant="outlined"
                      className="mb-5"
                      name="postcode"
                      value={values.postcode}
                      onChange={handleChange}
                      type="text"
                    />
                  </>
                ) : events?.stepOne?.event_type === "internal" ? (
                  <>
                    <div className="mt-5">
                      {loading && selectFloorName.id === "" ? (
                        <Typography align="center">
                          <CircularProgress
                            disableShrink
                            size={14}
                            color="#000"
                          />
                        </Typography>
                      ) : (
                        <>
                          <Select2
                            placeholder="Select Floor"
                            data={siteFloors}
                            value={selectFloorName}
                            update={(value) => {
                              handleChangeFloor(value);
                            }}
                            className="mr-3"
                          ></Select2>

                          {!loading && selectFloorName.id && (
                            <div className="mt-5">
                              <Select2
                                placeholder="Select Meeting Room"
                                data={Site}
                                value={selectSiteName}
                                update={(value) => handleChangeSite(value)}
                                className="mr-3"
                              ></Select2>
                            </div>
                          )}
                          {loading && selectFloorName.id && (
                            <div className="mt-5">
                              <Typography align="center">
                                <CircularProgress
                                  size={14}
                                  color="#000"
                                  disableShrink
                                />
                              </Typography>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-lg-12 px-5">
                <button
                  id="kt_login_signin_submit"
                  type="submit"
                  className={`btn btn-primary d-flex justify-content-between align-items-center font-weight-bolder mt-5 w-100`}
                  onClick={() => {
                    handleSubmit();
                    if (
                      events?.stepOne?.event_type === "internal" ||
                      events?.stepOne?.event_type === "virtual"
                    ) {
                      handleNext();
                    } else if (
                      Object.entries(errors).length === 0 &&
                      values.starts_at !== "" &&
                      values.city !== "" &&
                      values.address_1 !== "" &&
                      events?.stepOne?.event_type === "external"
                    ) {
                      handleNext();
                    }
                  }}
                >
                  <span />
                  <span>NEXT</span>
                  <span>
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/akar-icons_arrow-right.svg"
                      )}
                    />
                  </span>
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EventStep2;
