import React, { useState } from "react";
import Header from "./Header";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export default function VisitorSignInThankYou(props) {
  const { host, handleVisitorSignInClose } = props;
  return (
    <>
      <div className="thank-you-body-wrap">
        <div className="d-flex justify-content-center align-items-center">
          <SVG src={toAbsoluteUrl("media/svg/icons/bell.svg")} className="" />
          <SVG src={toAbsoluteUrl("media/svg/icons/dots.svg")} className="ml-3 mr-3" />
          <div className="svg-icon host-image">
            {host?.url_image ? (
              <img
                src={host?.url_image || ""}
                alt={host?.full_name || ""}
                className="selected-friend-circle-user-img"
              ></img>
            ) : (
              <SVG
                src={toAbsoluteUrl("media/svg/icons/default_user.svg")}
                className="selected-friend-circle-user-img"
              ></SVG>
            )}
          </div>
        </div>
        <h2 className="thank-you-text">
          <b>THANK YOU</b>
        </h2>
        <h4 className="thank-you-message-text">
          Your Host <b>{host?.full_name}</b> has been informed that you are in reception
        </h4>
        <div className="rounded-circle mt-5 d-flex justify-content-center">
          <button
            className="visitor-signin-btn vehicle-details-visitor-btn text-light"
            type="submit"
            onClick={handleVisitorSignInClose}
          >
            DONE{" "}
          </button>
        </div>
      </div>
    </>
  )
}
