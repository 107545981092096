import React, { Suspense, lazy, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import helperFunc from "../_metronic/_helpers/helperFunc";
import ReactGA from "react-ga";
const TRACKING_ID = "UA-233080853-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const StaffUser = lazy(() => import("./modules/StaffUser/index"));

const Roles = lazy(() => import("./modules/Roles/index"));
const Booking = lazy(() => import("./modules/Booking/index"));
const AvailabilityScheduler = lazy(() =>
  import("./modules/RoomAvailability/index")
);

const Languages = lazy(() => import("./modules/Languages/index"));

const Accounts = lazy(() => import("./modules/Accounts/index"));

const Calendar = lazy(() => import("./modules/Calendar/index"));
const Settings = lazy(() => import("./modules/Settings/index"));
const Reporting = lazy(() => import("./modules/Reporting/index"));
const Feedback = lazy(() => import("./modules/Feedback/index"));
const Bulletin = lazy(() => import("./modules/Bulletin/index"));
const LiveFloorPlan = lazy(() => import("./modules/LiveFloorPlan/Index"));
const Events = lazy(() => import("./modules/Booking/Event/Index"));
const Coverage = lazy(() => import("./modules/Coverage/Coverage"));
const SocialWall = lazy(() => import("./modules/SocialWall/Index"));
const CommunityDetails = lazy(() =>
  import("./modules/SocialWall/CommunityDetails")
);

export default function BasePage() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  const userdata = JSON.parse(localStorage.getItem("userData"));
  return (
    <>
      <Suspense fallback={<LayoutSplashScreen />}>
        {/* <Redirect from="/auth" to="/" /> */}
        <Switch>
          {/* Redirect from root URL to /dashboard. */
            (userdata.role &&
              userdata.role.toLowerCase() === "super_super_admin") ||
              userdata.role.toLowerCase() === "admin" ? (
              <Redirect exact from="/" to="/accounts/list" />
            ) :
              (userdata.role &&
                userdata.role.toLowerCase() === "super_admin") ? (
                <Redirect exact from="/" to={`/accounts/profile/${helperFunc.getUserData().account_id}/sites`} />
              )

                : helperFunc.isMenuShow("normalDashboard") ? (
                  <Redirect exact from="/" to="/dashboard" />
                ) : (
                  <Redirect exact from="/" to="/settings/list" />
                )}
          {
            /* Redirect from login URL to /. */
            <Redirect exact from="/auth/login" to="/" />
          }
          {
            /* Redirect from registration URL to /. */
            <Redirect exact from="/auth/registration" to="/" />
          }
          <ContentRoute path="/dashboard" component={DashboardPage} />
          <ContentRoute path="/booking" component={Booking} />
          <ContentRoute
            path="/availability-scheduler"
            component={AvailabilityScheduler}
          />
          <ContentRoute path="/accounts" component={Accounts} />
          <ContentRoute path="/builder" component={BuilderPage} />
          <ContentRoute path="/my-page" component={MyPage} />
          <ContentRoute path="/staff-user" component={StaffUser} />
          <ContentRoute path="/calendar" component={Calendar} />
          <ContentRoute path="/roles" component={Roles} />

          <ContentRoute path="/reporting" component={Reporting} />
          <ContentRoute path="/settings" component={Settings} />
          <ContentRoute path="/languages" component={Languages} />
          <ContentRoute path="/feedback" component={Feedback} />
          <ContentRoute path="/bulletin" component={Bulletin} />
          <ContentRoute path="/livefloorplan" component={LiveFloorPlan} />
          <ContentRoute path="/events" component={Events} />
          <ContentRoute path="/coverage" component={Coverage} />
          <ContentRoute path="/social-wall" component={SocialWall} />
          <ContentRoute path="/community/:id" component={CommunityDetails} />

          <Redirect to="error/error-v1" />
        </Switch>
      </Suspense>
    </>
  );
}
