import axios from "axios";

export const API_URL = `${process.env.REACT_APP_API_URL}`;

// CREATE =>  POST: add a new StaffUser to the server
export function createItem(item) {
  // return item/
  return axios.post(`${API_URL}/api/booking/day_pass`, item);
}
// CREATE =>  POST: add a new StaffUser to the server
export function createMultiDayPasses(item) {
  // return item/
  return axios.post(`${API_URL}/api/multiply/day_pass`, item);
}
// CREATE =>  POST: add a new StaffUser to the server
export function createMultiDatePassesForSameUser(item) {
  // return item/
  return axios.post(`${API_URL}/api/multiple/day-pass/same-user`, item);
}

// UPDATE =>  PATCH: add a new StaffUser to the server
export function updateItem(item) {
  // return item/
  return axios.patch(`${API_URL}/api/update/booking`, item);
}

export function getWorkCircleList(params) {
  return axios.post(`${API_URL}/api/friend-circle/lsiting`, params);
}
