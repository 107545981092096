import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_helpers";
import { default as AddVehicle } from "../../../../../app/modules/Vehicle/CommonForm";
import ParkingForm from "../../../../../app/modules/Booking/Parking/ParkingForm";
import AddParkingSuccess from "../../../../../app/modules/Booking/Parking/AddParkingSuccess";
import { useDispatch, useSelector } from "react-redux";
import * as parkingAction from "../../../../../app/modules/Booking/Parking/_redux/Action";
import * as dashboardActions from "../../../../../app/pages/_redux/Actions";

const modalInitialValues = {
  name: "addParking",
  title: "BOOK PARKING",
};
export const AddParking = (props) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [showAddVehicle, setShowAddVehicle] = useState(false);
  const [addParkingSuccess, setAddParkingSuccess] = useState(false);
  const [parkingUi, setParkingUi] = useState(false);
  const [modalProps, setModalProps] = useState(modalInitialValues);
  const [id, setId] = useState();
  const [userID, setUserID] = useState();

  const handleShow = () => {
    setShow(true);
    props.handleParentClose();
  };

  const handleClose = () => {
    setShow(false);
    setId(undefined);
    props.handleParentClose();
  };

  const parkingStore = useSelector((state) => state.parkingPass);
  const { parkingEditItem } = parkingStore;

  useEffect(() => {
    if (parkingEditItem) {
      let props = { ...modalProps };
      props.name = "editParking";
      props.title = "Edit Parking Booking";
      handleParkingUi();
      setModalProps(props);
      handleShow();
      setId(parkingEditItem?.id);
      setUserID(parkingEditItem?.user_id);
    } else {
      setModalProps(modalInitialValues);
      setId(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parkingStore]);

  // setShowAdd;
  const handleAddVehicle = () => setShowAddVehicle(true);
  const handleAddVehicleClose = () => setShowAddVehicle(false);

  const handleAddParkingSuccess = () => setAddParkingSuccess(true);
  const handleAddParkingSuccessClose = () => {
    dispatch(parkingAction.clearData());
    setAddParkingSuccess(false);
  };

  //Manage Parking UI
  const handleParkingUi = () => {
    setShow(true);
    setParkingUi(!parkingUi);
    // parkingUi ? setParkingUi(false) : setParkingUi(true);
  };

  return (
    <>
      <div className="button-cursor pt-5" onClick={handleParkingUi}>
        <div className="d-inline-flex pl-5">
          <span className="svg-icon">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Booking/parking.svg")}
            ></SVG>
          </span>
        </div>
        <div className="d-inline-flex pl-5 pt-3">
          <h6>BOOK PARKING</h6>
        </div>
      </div>
      <ParkingForm
        dashboardActions={dashboardActions}
        show={show}
        onHide={handleClose}
        modalProps={modalProps}
        handleAddVehicle={handleAddVehicle}
        showAdd={showAddVehicle}
        showAddClose={handleAddVehicleClose}
        handleParkingUi={handleParkingUi}
        parkingUi={parkingUi}
        handleAddParkingSuccess={handleAddParkingSuccess}
        handleAddParkingSuccessClose={handleAddParkingSuccessClose}
        id={id}
        user_id={userID}
      />
      <AddVehicle show={showAddVehicle} showAddClose={handleAddVehicleClose} />
      <AddParkingSuccess
        show={addParkingSuccess}
        showAddClose={handleAddParkingSuccessClose}
        id={id}
        status={parkingStore?.status}
      />
    </>
  );
};
