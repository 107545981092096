import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  spaceTypeForEdit: {},
  lastError: null,
  refreshList: false,
}
export const callTypes = {
  list: "list",
  action: "action",
}
export const Slice = createSlice({
  name: "spaceTypes",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
    },
    startCall: (state, action) => {
      state.error = null
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    // singleItemFetched
    singleItemFetched: (state, action) => {
      state.actionsLoading = false
      state.spaceTypeForEdit = action.payload.spaceTypeForEdit
      state.error = null
    },
    // listFetched
    listFetched: (state, action) => {
      const { totalCount, entities } = action.payload
      state.listLoading = false
      state.error = null
      state.entities = entities
      state.totalCount = totalCount
      state.refreshList = false
    },
    // itemCreated
    itemCreated: (state, action) => {
      state.actionsLoading = false
      state.error = null
      state.entities = []
      state.refreshList = true
    },
    // itemUpdated
    itemUpdated: (state, action) => {
      state.error = null
      state.actionsLoading = false
      state.entities = []
      state.refreshList = true
    },
    // itemDeleted
    itemDeleted: (state, action) => {
      state.error = null
      state.actionsLoading = false
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      )
    },
    // itemsDeleted
    itemsDeleted: (state, action) => {
      state.error = null
      state.actionsLoading = false
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      )
    },
  },
})
