import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import moment from "moment";
import { useEffect } from "react";
import helperFunc from "../../../_metronic/_helpers/helperFunc";
const messages = {
  createMsg: "You have successfully made a booking!",
  updateMsg: "You have successfully update a booking!",
};
export default function AddDaySuccess({ show, showAddClose, id, status }) {
  const dayPassStore = useSelector((state) => state?.dayPass);

  const userDetails = helperFunc.getUserData();

  const [msgTxt, setMsgTxt] = useState(messages.createMsg);

  useEffect(() => {
    if (status === "update") {
      setMsgTxt(messages.updateMsg);
    } else {
      setMsgTxt(messages.createMsg);
    }
  }, [status]);

  return (
    <>
      {!dayPassStore.dayPass?.lastError && (
        <>
          <Modal show={show} className="common-success-popup" centered>
            <div className="success-popup-heading-logo">
              <div className=" d-flex justify-content-center">
                <SVG
                  className=" button-cursor"
                  src={toAbsoluteUrl(
                    "/media/svg/icons/successfully-booking.svg"
                  )}
                />
              </div>
            </div>

            <div className="d-flex justify-content-center">
              <p>{msgTxt}</p>
            </div>
            <Modal.Body className="pt-0">
              {dayPassStore?.dayPassEntities?.length > 1 &&
              dayPassStore?.status === "add_multi_dates" ? (
                dayPassStore?.dayPassEntities?.map((item, index) => {
                  return (
                    <div className="success-modal-div w-100 mt-5" key={index}>
                      <div className="success-popup-heading-logo d-inline-flex bg-white">
                        <span className="svg-icon">
                          <SVG
                            src={toAbsoluteUrl("/media/svg/icons/calendar.svg")}
                          ></SVG>
                        </span>
                      </div>
                      <div className="d-inline-flex ml-3">
                        <h6 className="">
                          Date:{" "}
                          <b>
                            {moment(item?.starts_at).format("dddd[,] Do MMM")}
                          </b>
                        </h6>
                      </div>
                    </div>
                  );
                })
              ) : (
                <>
                  <div className="success-modal-div w-100">
                    <div className="success-popup-heading-logo d-inline-flex bg-white">
                      <span className="svg-icon">
                        <SVG
                          src={toAbsoluteUrl("/media/svg/icons/calendar.svg")}
                        ></SVG>
                      </span>
                    </div>
                    <div className="d-inline-flex ml-3">
                      <h6 className="">
                        Date:{" "}
                        <b>
                          {dayPassStore?.dayPassEntities?.length > 0
                            ? moment(
                                dayPassStore?.dayPassEntities?.[0]?.starts_at
                              ).format("dddd[,] Do MMM")
                            : "-"}
                        </b>
                      </h6>
                    </div>
                  </div>
                </>
              )}
              <div className="success-modal-div w-100  mt-4">
                <div className="success-popup-heading-logo bg-white d-inline-flex">
                  <span className="svg-icon">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/ticket.svg")}
                    ></SVG>
                  </span>
                </div>
                <div className="d-inline-flex ml-3">
                  <h6 className="">
                    Day Pass Owner:<b>{userDetails?.full_name}</b>
                  </h6>
                </div>
              </div>
              {dayPassStore?.status === "add_work_circle" && (
                <>
                  <div className="mt-3 p-3 pt-0 select-time rounded">
                    <div className="d-flex mt-5 selected-friend-circle-div justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <div className="selected-group-circle-image-wrap">
                          <div>
                            <span className="svg-icon">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/friend_circle_group.svg"
                                )}
                                className="selected-group-circle-image"
                              ></SVG>
                            </span>
                          </div>
                        </div>
                        <div className="colleagues-count-div">
                          <p className="friend-circle-colleagues-title">
                            Colleagues
                          </p>
                          <div className="d-flex">
                            <span className="svg-icon">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/color-daypass.svg"
                                )}
                                className="mr-3"
                              ></SVG>
                            </span>
                            <p>
                              {dayPassStore?.dayPassEntities?.length} People
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Modal.Body>
            <Modal.Footer className="">
              <Button
                className="w-100 font-weight-bolder custom-button"
                onClick={showAddClose}
              >
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
}
{
  /* <h6 className="">
  Day Pass Owner: <b>{dayPassStore.auth.user.full_name}</b>
</h6> */
}
