import React, { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import axios from "axios";
import { API_URL } from "./_redux/Crud";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Select2 } from "select2-react-component";
import SVG from "react-inlinesvg";
import { useSelector, useDispatch } from "react-redux";
import { Avatar } from "@material-ui/core";
import { Form, Formik } from "formik";
import moment from "moment";
import { NotificationManager } from "react-notifications"
import { Image } from "react-bootstrap";
import {
    Button,
    FormControl,
    InputLabel,
    Menu,
    MenuItem,
    MenuList,
    Select,
    TextField,
    Typography,
} from "@material-ui/core";

import "./VehicalReg.scss";
import { useParams } from "react-router-dom";
import * as actions from "../../Vehicle/_redux/Action";

const VisitorReceptionSignInStep2 = (
    props
) => {
    const host_id = props.location.state.host_id;
    const [workstationCoordinates, setWorkStationCoordinates] = useState([]);
    const [xyzCirc, setXyzCirc] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const BASE_URL = process.env.REACT_APP_API_URL;
    const [selectedItemsPath, setSelectedItemsPath] = useState([]);
    const [thankyou, setThankyou] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [bookingData, setBookingData] = useState();
    const [error, setError] = useState();
    const [isError, setIsError] = useState(false);
    const [parkingData, setParkingData] = useState([]);
    const [siteData, setSiteData] = useState([]);
    const [hostData, sethostData] = useState([]);
    const [siteListOption, setsiteListOption] = useState([]);
    const [file, setFile] = useState(undefined);
    const [selectedFile, setSelectedFile] = useState(undefined);
    const dispatch = useDispatch();

    const { booking_id } = useParams();

    const handleSubmit = () => {
    }
    let history = useHistory();

    const getUserList = async (host_id) => {

        const API_URL = `${process.env.REACT_APP_API_URL}/api/user?site_id=9`;

        const response = await axios.get(API_URL);
        let dataArray = [];

        if (response.data.guests && response.data.guests.length > 0) {
            dataArray = response.data.guests.map((item) => {
                return {
                    value: item.id,
                    label: item.full_name,
                };
            });
            console.log(dataArray, "responses")
            setsiteListOption(dataArray);
            return siteListOption;
        }


    };


    const getUserData = async (host_id) => {
        console.log(host_id, "hostDataid");
        axios.get(`${BASE_URL}/api/single-user?id=${host_id}`).then((response) => {

            if (response) {
                sethostData(response.data);

            }
        });


    };



    useEffect(() => {
        axios.get(`${BASE_URL}/api/sites/9`).then((response) => {

            if (response) {
                setSiteData(response.data)

                // window.location.replace('https://portal.bookify.space/auth/login');
            }
        });
    }, [bookingData])



    useEffect(() => {


        getUserList(host_id);
        getUserData(host_id)

    }, [])


    return (
        <>
            <div className="pad-12-reg">
                <img
                    className="img_header_reg"
                    src={toAbsoluteUrl("/media/svg/menu/logo-icon.svg")}
                    width="50px"
                    alt="Tick Icon"
                />
            </div>

            <div className="rect_header_reg">
                <img
                    className="img_logo_reg"
                    src={siteData?.account_setting?.kiosk_logo_url}
                    width="50px"
                    alt="Tick Icon"
                />
            </div>


            <div className="menu_reg">

            </div>



            <div className="content_paragraph_reg_step">
                Welcome to {siteData?.title} , {siteData?.address}
            </div>


            {thankyou ? <></> :
                <div className="pop_up_reg">
                    <Formik
                        className="width-full mb-form"
                        enableReinitialize={true}
                        initialValues={{
                            host_id: host_id,
                            visitor_id: "",

                        }}

                        onSubmit={(values) => {
                            // debugger;
                            setSubmit(true);

                            // history.push({
                            //     pathname: `/vehicalRegPolicy`,
                            //     //state: { formData: formData, bookingDataForm: bookingDataForm, file: file },
                            // });

                            axios.post(`${BASE_URL}/api/booking/visitors/buy-booking`, values).then((response) => {

                                if (response) {

                                    if (response.data.error) {
                                        console.log(response.data.error, "error");
                                        setError(response.data.error);
                                        setIsError(true);
                                    }
                                    else {
                                        let bookingId = response.data[0].id;
                                        console.log(bookingId, "bookingId")
                                        history.push({
                                            pathname: `/vehicalRegImage`,
                                            state: { booking_id: bookingId },
                                        });

                                    }
                                    // window.location.replace('https://portal.bookify.space/auth/login');
                                }
                            });

                            // dispatch(actions.updateGlobalAccountDetails(formData)).then(
                            //     (response) => {

                            //     }
                            // );
                        }}
                    >
                        {({
                            handleSubmit,
                            values,
                            errors,
                            touched,
                            handleChange,
                            setFieldValue,
                        }) => (
                            <Form onSubmit={handleSubmit} className="width-full mb-form">

                                <div className="visitor-list">
                                    <span  >What is your name ?</span>
                                </div>
                                <div className="form-group row">
                                    <div className="col-lg-12">
                                        <Select2
                                            name="visitor"
                                            placeholder="Visitor"
                                            data={siteListOption}
                                            update={(value) => setFieldValue("visitor_id", value)}
                                            value={values.visitor_id}
                                        />
                                    </div>
                                </div>
                                <div className="visitor-list-host">
                                    <div><span className="p-10px" >Your Host:</span></div>

                                    <div className=" host-image-div">
                                        <Avatar
                                            src={hostData?.url_image}

                                            className="avatar-size"
                                        //   style={{ height: "50px", width: "50px" }}
                                        />
                                        <span className="name-padding"  >{hostData?.full_name}</span>
                                    </div>

                                </div>
                                {
                                    isError ?
                                        <></>
                                        :
                                        <div>
                                            <span className="error-complete">Complete the pre visitor register from for contactless sign in</span>
                                        </div>
                                }

                                {isError ?
                                    <div >
                                        <span className="error-complete">{error} </span>
                                    </div>
                                    : <></>

                                }
                                <div    >

                                    {submit ?
                                        <button className="button-form-reg remove-click-reg button-top-none" type="submit">
                                            <span className="ml-3 spinner spinner-white spinner-reg "></span>
                                        </button>
                                        :


                                        <button className="button-form-reg button-top-none" type="submit">
                                            <span className="text-sign-reg"> Next </span>
                                        </button>

                                    }
                                </div>

                            </Form>
                        )}
                    </Formik>

                </div >
            }



        </>
    );
};

export default VisitorReceptionSignInStep2;
