import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect, useParams } from "react-router-dom";
import * as Yup from "yup";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { requestPassword } from "../_redux/authCrud";

const initialValues = {
  email: "",
  password: "",
  password_confirmation: "",
};

function ForgotPassword(props) {
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);

  const { token } = useParams();
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a vaild email address.")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage(
          {
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
          },
          { name: "Email" }
        )
      ),
  });
  const resetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, "Minimum 8 character")
      .max(50, "Maximum 50 character")
      .required(
        intl.formatMessage(
          {
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
          },
          { name: "Password" }
        )
      ),
    password_confirmation: Yup.string()
      .required(
        intl.formatMessage(
          {
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
          },
          { name: "Repeat Password" }
        )
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and repeat password didn't match."
        ),
      }),
  });

  useEffect(() => {
    //  dispatch(actions.fetchSingle(id))
  }, [token]);

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: token ? resetPasswordSchema : ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      let params = {};
      if (token) {
        params = {
          token: token,
          password: values.password,
          password_confirmation: values.password_confirmation,
        };
      } else {
        params = {
          email: values.email,
        };
      }

      const type = token ? "reset" : "request";

      requestPassword(params, type)
        .then(() => {
          setStatus("done");
          setTimeout(() => {
            setIsRequested(true);
            setSubmitting(false);
          }, 3000);
        })
        .catch(() => {
          setStatus(
            intl.formatMessage(
              { id: "AUTH.VALIDATION.NOT_FOUND" },
              { name: values.email }
            )
          );
        });
    },
  });

  return (
    <>
      {isRequested && <Redirect to="/auth/login" />}
      {!isRequested && (
        <div className="login-form" style={{ display: "block" }}>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            <div className="pb-5 pb-lg-15">
              <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">
                Restore your password, enter email below
              </h3>
            </div>
            {formik.status && formik.status === "done" && (
              <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {!token &&
                    "Request sent successfully! Please check your mail to reset your password."}
                  {token && "Password Reset successfully!"}
                </div>
              </div>
            )}
            {formik.status && formik.status !== "done" && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            {!token && (
              <div className="form-group fv-plugins-icon-container">
                <input
                  type="email"
                  className={`form-control h-auto px-6 rounded-pill ${getInputClasses(
                    "email"
                  )}`}
                  placeholder="Email"
                  name="email"
                  {...formik.getFieldProps("email")}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.email}</div>
                  </div>
                ) : null}
              </div>
            )}
            {token && (
              <>
                <div className="form-group fv-plugins-icon-container">
                  <input
                    type="password"
                    className={`form-control h-auto px-6 rounded-pill ${getInputClasses(
                      "password"
                    )}`}
                    placeholder="password"
                    name="password"
                    {...formik.getFieldProps("password")}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.password}
                      </div>
                    </div>
                  ) : null}
                </div>
                {/* begin: Confirm Password */}
                <div className="form-group fv-plugins-icon-container">
                  <input
                    placeholder="Repeat Password"
                    type="password"
                    className={`form-control h-auto px-6 rounded-pill ${getInputClasses(
                      "password_confirmation"
                    )}`}
                    name="password_confirmation"
                    {...formik.getFieldProps("password_confirmation")}
                  />
                  {formik.touched.password_confirmation &&
                  formik.errors.password_confirmation ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.password_confirmation}
                      </div>
                    </div>
                  ) : null}
                </div>
                {/* end: Confirm Password */}
              </>
            )}
            <div className="form-group d-flex flex-wrap position-relative justify-content-between align-items-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary position-relative font-weight-bolder my-3 px-8 w-100"
                disabled={formik.isSubmitting}
              >
                <FormattedMessage id="AUTH.GENERAL.SEND" />
              </button>
            </div>
            <div className="d-flex flex-wrap w-100 justify-content-center">
              <Link to="/auth" className="btn-link d-flex ml-1">
                Remembered my password
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
