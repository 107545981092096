import React, { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import axios from "axios";
import { API_URL } from "./_redux/Crud";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { useSelector, useDispatch } from "react-redux";
import { Form, Formik } from "formik";
import moment from "moment";
import { Image } from "react-bootstrap";
import {
    Button,
    FormControl,
    InputLabel,
    Menu,
    MenuItem,
    MenuList,
    Select,
    TextField,
    Typography,
} from "@material-ui/core";

import "./VehicalReg.scss";
import { useParams } from "react-router-dom";
import * as actions from "../../Vehicle/_redux/Action";

const VehicalRegImage = (
    props
) => {
    // const formData = props.location.state.formDataProp;
    // const bookingDataForm = props.location.state.bookingData;
    const { booking_id } = useParams();
    const bookingId = props?.location?.state?.booking_id ? props?.location?.state?.booking_id  : booking_id;
    const [workstationCoordinates, setWorkStationCoordinates] = useState([]);
    const [xyzCirc, setXyzCirc] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const BASE_URL = process.env.REACT_APP_API_URL;

    const [selectedItemsPath, setSelectedItemsPath] = useState([]);


    const [floorData, setFloorData] = useState([]);
    const [thankyou, setThankyou] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [bookingData, setBookingData] = useState([]);
    const [parkingData, setParkingData] = useState([]);
    const [visitorId, setVisitorId] = useState("");
    const [file, setFile] = useState(undefined);
    const [selectedFile, setSelectedFile] = useState(undefined);
    const dispatch = useDispatch();

    
    const getImageUrl = (event) => {
        if (event.target.files && event.target.files[0]) {
            setSelectedFile({
                file: event.target.files[0],
                path: event.target.value,
            });
           // formData.append("image", event.target.files[0], event.target.value);

            let img = event.target.files[0];
            var reader = new FileReader();
            reader.readAsDataURL(img);

            reader.onloadend = function (e) {
                setFile(reader.result);
            };
        }
    };
    const handleSubmit = () => {
    }
    let history = useHistory();
    useEffect(() => {

       
        axios.get(`${BASE_URL}/api/booking/${bookingId}`).then((response) => {

            if (response.data) {
                setBookingData(response.data);
                setVisitorId(response.data.users[0].id);
                setParkingData(response.data.parking[0]);
            }
        });
    }, []);


    return (
        <>
            <div className="pad-12-reg">
                <img
                    className="img_header_reg"
                    src={toAbsoluteUrl("/media/svg/menu/logo-icon.svg")}
                    width="50px"
                    alt="Tick Icon"
                />
            </div>

            <div className="rect_header_reg">
                <img
                    className="img_logo_reg"
                    src={bookingData?.account_setting?.kiosk_logo_url}
                    width="50px"
                    alt="Tick Icon"
                />
            </div>


            <div className="menu_reg">

            </div>
            {thankyou ?
                <div className="content_header_reg green_tick_reg">
                    <span>Thank your for confirming your Vehicle details</span>
                </div>
                :
                <div className="content_header_reg ">
                    <span>Vehicle Details</span>
                </div>
            }

            {thankyou ? <div className="content_paragraph_reg ">
                <img
                    className="img_reg"
                    src={toAbsoluteUrl("/media/vehicles/green_tick.png")}
                    width="50px"
                    alt="Tick Icon"
                />
            </div>
                :
                <div className="content_paragraph_reg">
                    {bookingData?.site?.id == 1 ?

                        <span>  Hi Lee, for your visit to No5. The Heights on  {moment(bookingData?.starts_at).format("DD/MM/YYYY")} at {moment(bookingData?.starts_at).format("HH:mm")} can you please provide vehicle details below</span>
                        :
                        <span>Hi Lee, for your visit to {bookingData?.site?.title} on  {moment(bookingData?.starts_at).format("DD/MM/YYYY")} , {moment(bookingData?.starts_at).format("HH:mm")} please provide car reg details below before you arrive at site </span>}

                </div>
            }

            {thankyou ? <></> :
                <div className="pop_up_reg">
                    <Formik
                        className="width-full mb-form"
                        enableReinitialize={true}
                        initialValues={{
                            visitor_id : visitorId ? visitorId : "",
                            image : selectedFile ? selectedFile : "", 

                        }}

                        onSubmit=    {async(values) => {
                            // debugger;
                            setSubmit(true);
                            console.log("submit");
                          
                            const formData = new FormData();
                            formData.append("visitor_id",visitorId);    
                            formData.append("image", selectedFile.file, selectedFile.path);
                           await axios.post(`${BASE_URL}/api/booking/visitor/upload-image`, formData).then((response) => {

                                if (response) {
                                    console.log(response, "responsedata")
                                    // window.location.replace('https://portal.bookify.space/auth/login');
                                }
                            });
                            if(bookingData?.parking[0] )
                            {
                                history.push(`/vehicalReg/${bookingId}`)
                            }
                            else{
                                
                                history.push({
                                    pathname: `/vehicalRegPolicy`,
                                    state: { bookingDataForm: bookingData },
                                });
                            }
                            

                          
                           
                        }}
                    >
                        {({
                            handleSubmit,
                            values,
                            errors,
                            touched,
                            handleChange,
                            setFieldValue,
                        }) => (
                            <Form onSubmit={handleSubmit} className="width-full mb-form">

                                <div className="upload_image_vehical">
                                    <span  >Can We Take Your Picture?</span>
                                </div>
                                {/*
                                <div style={{ marginTop: "20px" }}>
                                    <img
                                        className="img_reg"
                                        src={toAbsoluteUrl("/media/vehicles/upload.png")}
                                        width="50px"
                                        alt="Upload Icon "
                                    />
                                </div> */}

                                <div className="col-lg-12">
                                    {/* begin: Image */}
                                    <div
                                        className={`square-image-display  upload-file ${file
                                            ? "align-items-baseline"
                                            : "align-items-center"
                                            } `}
                                    >
                                        <label
                                            className="align-items-center d-flex flex-column flex-wrap justify-content-center min-h-120px"
                                            data-action="change"
                                            title="Change Image"
                                        >
                                            {!file && (
                                                <>
                                                    <img
                                                        className="image_icon_upload"
                                                        src={toAbsoluteUrl("/media/vehicles/upload.png")}
                                                        width="50px"
                                                        alt="Upload Icon "
                                                    />

                                                </>
                                            )}
                                            {file && (
                                                <Image
                                                    src={file}
                                                    style={{
                                                        width: "100%",
                                                        height: "28vh",
                                                        backgroundSize: "cover",
                                                    }}
                                                    alt="progile-image1"
                                                />
                                            )}
                                            {/* <i className="fa fa-pen icon-sm text-muted"></i> */}
                                            <input
                                                type="file"
                                                name="file"
                                                accept=".png, .jpg, .jpeg"
                                                onChange={getImageUrl}
                                            />
                                        </label>
                                    </div>
                                    {/* end: Image */}
                                </div>



                                <div    >
                                    {submit ?
                                        <button className="button-form-reg remove-click-reg" type="submit">
                                            <span className="ml-3 spinner spinner-white spinner-reg "></span>
                                        </button>
                                        :


                                        <button className="button-form-reg button-top-none" type="submit">
                                            <span className="text-sign-reg"> Next </span>
                                        </button>

                                    }
                                </div>

                            </Form>
                        )}
                    </Formik>

                </div >
            }



        </>
    );
};

export default VehicalRegImage;
