import React from "react"
import { useField, useFormikContext } from "formik"
import { DateTimePicker } from "react-tempusdominus-bootstrap"

export function DatePickerField({ ...props }) {
  const { setFieldValue, errors, touched } = useFormikContext()

  const [field] = useField(props)

  const UpdateValue = (value) => {
    setFieldValue(field.name, value.date)

    if (props.onDateSet) {
      props.onDateSet(value.date, props)
    }
  }
  return (
    <>
      {props.label && <label className={props.isRequired ? "required" : ""}>{props.label}</label>}
      <DateTimePicker
        key={field.name}
        format={props.format ? props.format : "DD-MM-YYYY"}
        onChange={(val) => UpdateValue(val)}
        date={props.date ? props.date : field.value || null}
        defaultDate={props?.defaultDate || null}
        minDate={props.minDate || false}
        maxDate={props.maxDate ? props.maxDate : false}
        readOnly={props.readOnly ? props.readOnly : false}
        extraFormats={false}
      // useStrict={true}
      />
      {errors[field.name] && touched[field.name] ? (
        <div className="invalid-datepicker-feedback">{errors[field.name].toString()}</div>
      ) : (
        !props?.hideFeedBack && (
          <div className="feedback">
            {/* Please enter <b>{props.label}</b> in 'mm/dd/yyyy' format */}
          </div>
        )
      )}
    </>
  )
}
