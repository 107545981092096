import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  listLoading: false,
  actionsLoading: false,
  parkingEntities: [],
  parkingEditItem: undefined,
  lastError: {},
  error: null,
  status: null
}
export const callTypes = {
  list: "list",
  action: "action",
}
export const Slice = createSlice({
  name: "parkingPass",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.lastError = true
      state.error = action.payload.error.response.data.errors
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
    },
    clearError: (state, action) => {
      state.error = null
    },
    startCall: (state, action) => {
      state.error = null
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    // itemCreated
    createParkingData: (state, action) => {
      state.actionsLoading = false
      state.error = null
      state.lastError = null
      state.status = "add";
      state.parkingEntities.push(action.payload.item)
    },
    updateParkingData: (state, action) => {
      state.actionsLoading = false
      state.error = null
      state.lastError = null
      state.status = "update"
      state.parkingEntities = []
      state.parkingEntities.push(action.payload.item)
    },
    // setEditItem
    setEditItem: (state, action) => {
      state.parkingEditItem = action.payload.item
    },
    clearData: (state, action) => {
      state.status = null;
      state.parkingEntities = [];
      state.parkingEditItem = undefined;
    }
  },
})
