import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  vehicleEntities: [],
  bookingCounts: {
    bike_parking: 0,
    day_pass: 0,
    floor_id: 0,
    parking: 7,
    parking_ev: 0,
  },
  lastError: {},
  defaultAccessData: {},
};
export const callTypes = {
  list: "list",
  action: "action",
};
export const Slice = createSlice({
  name: "bookingslice",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = action.payload.error.response.data.errors;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // itemCreated
    itemCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.vehicleEntities.push(action.payload.item);
    },
    // itemCreated
    fetchedCount: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.bookingCounts = action.payload;
    },
    defaultAccess: (state, action) => {
      state.defaultAccessData = action.payload;
    },
  },
});
