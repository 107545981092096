/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Formik, Field } from "formik";
import { Button, Col, Form, FormCheck, Modal, Row } from "react-bootstrap";
import SearchSelectInput from "../../../../../app/components/SelectComponents/SearchSelectInput";
import * as actions from "../../../../../app/modules/Booking/Visitor/_redux/Action";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { TimePickerField } from "../../../../_partials/controls";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_helpers";
import * as Yup from "yup";
import CustomDatePicker from "../../../../../app/components/CustomDatePicker/CustomDatePicker";
import helperFunc from "../../../../_helpers/helperFunc";
import * as selectAction from "../../../../../app/components/SelectComponents/_redux/Actions";
import { Select2 } from "select2-react-component";
import VisitorList from "../../../../../app/modules/Booking/Visitor/VisitorList";
import { NotificationManager } from "react-notifications";
import DayWiseParkingOptions from "../../../../../app/modules/Booking/DayPass/Components/DayWiseParkingOptions";
import SelectInfiniteScrollDropDown from "../../../../../app/components/SelectComponents/SelectInfiniteScrollDropDown";
import './action.css';
import { CloseOutlined, ColorizeSharp } from "@material-ui/icons";
import axios from "axios";
import { async } from "react-tempusdominus-bootstrap";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';

const initialValues = {
  // site_id: "",
  // floor_id: "",
  starts_at: "",
  duration: "",
  visitor_toggle: false,
  all_day_parking: false,
  visitors: [
    {
      car_park: false,
      disability: false,
      park_type: "",
      company: "",
      email: "",
      full_name: "",
      host_id: "",
      number: "",
      visitor: "",
      select_visitor_toggle: false,
      isFullDay: false,
    },
  ],
  startTime: undefined,
  endTime: undefined,
  startTimeManage: null,
  endTimeManage: null,
};

const initialParkingType = [
  {
    vehicle_image: "image_One",
    vehicle_type: "vehicle",
  },
  {
    vehicle_image: "image_Two",
    vehicle_type: "Electric Vehicle",
  },
  {
    vehicle_image: "image_Three",
    vehicle_type: "Motorcycle",
  },
];
const ActionModalVisitor = (props) => {
  const userDetails = helperFunc.getUserData();
  console.log(props.visitorBookingItem, "editvisitoritem")
  const {
    show,
    onHide,
    handleAddVisitor,
    handleAddVisitorSuccess,
    visitorBookingItem,
    id,
    modalProps,
    listUpdate,
  } = props;

  const [parkingType, setParkingType] = useState(initialParkingType);
  const [userId, setUserId] = useState(helperFunc.getUserId());
  const [selectSiteId, setSelectSiteId] = useState(1);
  const [isHost, setIsHost] = useState(false);
  const [selectVisitor, setSelectVisitor] = useState(null);
  const [manageDatePickerDate, setManageDatePickerDate] = useState();
  const [occupancyShow, setOccupancyShow] = useState(true);
  const [selectedSiteId, setSelectedSiteId] = useState("");
  const [isParking, setIsParking] = useState(false);
  const [disability, setIsDisibality] = useState(false);
  const [addNewAlVisitor, setAddNewAllVisitor] = useState(true);
  const [addedNewVisitorData, setAddedNewVisitorData] = useState(false);
  const [timeErr, setTimeErr] = useState("");
  const [visitorErr, setVisitorErr] = useState("");
  const [visitorOptionList, setVisitorOptionList] = useState([]);
  const [isShowInfiniteScrollPopUp, setIsShowInfiniteScrollPopUp] = useState(
    false
  );
  const [usersData, setUserData] = useState(undefined);
  const [endTimeManage, setEndTimeManage] = useState(false);
  const [startTimeManage, setStartTimeManage] = useState(false);
  const initialDateValues = {
    day_passes: 0,
    parking_ev: 0,
    parking: 0,
    motorbike: 0,
  };

  const vehicleTypes = [
    // { value: "", label: "Select Vehicle Type" },
    { value: "vehicle", label: "Vehicle", icon: "smallCar" },
    { value: "vehicle_ev", label: "Electric vehicle", icon: "eleCar" },
    { value: "bike", label: "Motorcycle", icon: "motorcycle" },
  ];

  const [availableData, setAvailableData] = useState(initialDateValues);

  const [isFullDay, setIsFullDay] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const [multipleDates, setMultipleDates] = useState();
  const [startTime, setStartTime] = useState(visitorBookingItem?.starts_at ? visitorBookingItem?.starts_at : null);
  const [endTime, setEndTime] = useState(null);
  const [visitors, setVisitors] = useState([]);
  const [frmInitialValues, setFrmInitialValues] = useState(initialValues);
  const [visitorName, setVisitorName] = useState();
  const [isValidDates, setIsValidDates] = useState();
  const [isEmpty, setIsEmpty] = useState([]);
  const [disableBtnForSingleDate, setDisableBtnForSingleDate] = useState();
  const [visitorDetailsArr, setVisitorDetailsArr] = useState([]);
  const [actionVisitorIdAndType, setActionVisitorIdAndType] = useState();
  const [selectedParkingDates, setSelectedParkingDates] = useState();
  const [editVisitor, setEditVisitor] = useState(false);
  const [editVisitorDetails, setEditVisitorDetails] = useState([]);
  const [visitorListId, setVisitorListId] = useState(1);

  console.log("startTime", startTime);


  const [selectedTime, setSelectedTime] = useState({
    start_time: "",
    ends_at: "",
  });

  const dispatch = useDispatch();
  const [toggleOwnerMenu, setToggleOwnerMenu] = useState(false);
  const handleToggleOwnerClick = (value) => {
    setToggleOwnerMenu(value);
    if (!value) {
      setUserId(helperFunc.getUserId());
    }
  };

  const searchDataStore = useSelector((state) => state.searchData);
  const datepickerStore = useSelector((state) => state.customDatePicker);
  //const visitorStore = useSelector((state) => state.visitors);
  const [visitorStore, setVisitorStore] = useState([]);
  const dashboardStore = useSelector((state) => state.dashboards);
  const [siteId, setSiteId] = useState(userDetails?.site_id);
  console.log(visitorBookingItem, "visitor- stpore");
  useEffect(() => {
    if (userDetails?.site_id) {
      dispatch(selectAction.getSite({ siteId: userDetails.site_id }));
    }
    if (userDetails?.floor_id) {
      dispatch(selectAction.getFloor({ floorId: userDetails.floor_id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (searchDataStore?.selectedSite) {
      setSelectSiteId(searchDataStore?.siteId);
    }
  }, [searchDataStore]);

  var duration;
  if (startTime && endTime) {
    console.log("startTT", startTime)
    duration = moment.duration(endTime.diff(startTime));
    duration = parseInt(duration.asMinutes());
  }

  useEffect(() => {
    let data = { ...initialValues };
    if (
      !visitorStore?.lastError &&
      visitorStore?.visitorEntities &&
      typeof visitorStore?.visitorEntities === "object"
    ) {
      setAddedNewVisitorData(true);
    } else {
      setAddedNewVisitorData(false);
    }
    if (visitorStore?.visitorEditItem) {
      let responseData = {};
      let editItem = visitorStore?.visitorEditItem;
      for (const key in editItem) {
        if (Object.hasOwnProperty.call(editItem, key)) {
          if (key === "starts_at") {
            let resKey = key;
            responseData[`${resKey}`] = moment(editItem[key]);
            responseData[`startTime`] = moment(editItem[key]);
            continue;
          }
          if (key === "ends_at") {
            let resKey = key;
            responseData[`${resKey}`] = moment(editItem[key]);
            responseData[`endTime`] = moment(editItem[key]);
            continue;
          }
          if (key === "site_id" && editItem[key]) {
            setSelectedSiteId(editItem[`${key}`]);
          }
          if (key === "ev" && editItem[key] === null) {
            responseData[`${key}`] = false;
            continue;
          }
          responseData[`${key}`] = editItem[key];
        }
      }
      data = { ...data, ...responseData };

      setOccupancyShow(false);
      dispatch(selectAction.getSite({ siteId: data?.site_id }));
    } else {
      setOccupancyShow(true);
    }
    setFrmInitialValues(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitorStore]);

  // useEffect(() => {
  //   if (isValidDates) {
  //     let isEmptyArray = isValidDates?.filter((color) => color == "bg-red")
  //     setIsEmpty(isEmptyArray)
  //   }
  // }, [isValidDates])

  // Validation schema
  const validationSchema = Yup.object().shape({
    // is_host: Yup.boolean(),
    // visitor_host: Yup
    //     .string()
    //     .when("is_host", {
    //         is: false,
    //         then: Yup.string().required("Host is required")
    //     }),
    visitor: Yup.string().required("Visitor is required"),
  });

  const changeDateFormat = (date) => {
    return moment(date).format("dddd [,] Do MMM");
  };
  useEffect(() => {
    if (visitorStore?.visitorEditItem?.guests[0]?.full_name) {
      setVisitorName(visitorStore?.visitorEditItem?.guest?.full_name);
    }
  }, [visitorStore]);

  const clearState = () => {
    setIsHost(false);
    setIsFullDay(false);
    setStartTime();
    setEndTime();
    setAvailableData(initialDateValues);
  };

  console.log(
    visitorStore?.visitorEditItem?.guest?.full_name,
    "visitorStore?.visitorEditItem?.guest?.full_name"
  );

  useEffect(() => {
    clearState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    let bookingStatus = datepickerStore?.available;
    if (bookingStatus) {
      setManageDatePickerDate(datepickerStore.date);
      setAvailableData(bookingStatus);
    } else {
      setAvailableData(initialDateValues);
    }
    if (datepickerStore?.multiDateSelectedData) {
      setMultipleDates(datepickerStore?.multiDateSelectedData);
    } else {
      setMultipleDates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datepickerStore]);

  const getVisitorData = (visitorId) => {
    let visitor = searchDataStore?.entityData?.visitorData?.find((item) => {
      return item.id === visitorId;
    });
    return visitor;
  };

  const handleClose = () => {
    if (onHide) {
      onHide();
    }

    dispatch(selectAction.resetSiteAndFloor());
    dispatch(actions.setEditItem(undefined));
  };

  const [singleVisitor, setSingleVisitor] = useState([]);
  const BASE_URL = process.env.REACT_APP_API_URL;


  // const selectVisitorFunction = (id) => {
  //   const formDataStructured = {
  //     id: id
  //   }
  //   if (id) {
  //     console.log(id);
  //     axios.post(`${BASE_URL}/api/visitor/by_name`, formDataStructured).then((response) => {
  //       if (response) {
  //         setSingleVisitor(response.data);
  //       }
  //     });
  //   }
  // };

  async function getvisitorList(visitorType) {
    await axios.get(`${BASE_URL}/api/user/guest-listing?site_id=${Number(
      searchDataStore?.siteId
    )}&my_visitor=${visitorType}&perPage=2000&page=1`).then((response) => {
      if (response) {
        console.log("KK", response.data.guests);
        let data = [];
        if (response.data.guests && response.data.guests.length > 0) {
          data = response.data.guests.map((item) => {
            return {
              value: item.id,
              label: item.full_name,
            };
          });
          setVisitorOptionList(data);
          // setFloorList(data);
          return visitorOptionList;
        }

      }
    }
    )
  }

  useEffect(() => {
    getvisitorList(true)
  }, []);

  async function visitorListValue(value) {

    // getvisitorList(false);
    if (typeof value && value != null) {
      if (typeof value.__isNew__ !== 'undefined') {
        console.log(value.__isNew__);
        handleAddVisitor()
      }
      else {
        const formDataStructured = {
          id: value.value
        }
        if (value.value) {
          console.log(value.value);
          axios.post(`${BASE_URL}/api/visitor/by_name`, formDataStructured).then((response) => {
            if (response) {
              setSingleVisitor(response.data);
            }
          });
        }
        console.log(value.value);
      }
    }
  }

  function visitorListValueChange(inputValue, actionMeta) {
    if (typeof inputValue === 'string') {
      const formattedValue = inputValue.replace(/\W/g, ''); // Example: remove all non-alphanumeric characters
      if (formattedValue === "") {
        getvisitorList(true)
      }
      else {
        const inputLength = formattedValue.length;
        if (inputLength == 1) {
          getvisitorList(false)
        }

      }


      // Your code here
    }
  }




  // const selectVisitorFunction = async (id) => {
  //   console.log("id", id);
  //   if (id) {
  //     await axios.get(`${BASE_URL}/api/visitor/by_name?id=${id}`).then((response) => {
  //       if (response) {
  //         setSingleVisitor(response.data);
  //       }
  //     });
  //   }

  // };
  // useEffect(() => {

  //   selectVisitorFunction();

  // }, []);

  useEffect(() => {
    if (visitorDetailsArr.length > 0) {
      setIsEmpty(false);
    } else {
      setIsEmpty(true);
    }


  }, [visitorDetailsArr]);

  const applyTimeFilter = (data, type, setFieldValue, values) => {
    var timePickerTime = moment(data);
    console.log(
      "type",
      type,
      "data",
      data,
      "values",
      values,
      "endTime",
      endTime
    );
    if (type === "startTime") {
      let startTime = moment(timePickerTime);
      //let endTime = timePickerTime.add(30, "minutes")
      setStartTime(startTime);
      //setEndTime(moment(endTime))
      //setFieldValue("endTime", endTime)
      setFieldValue("startTime", startTime);
    }
    if (type === "endTime") {
      let endTime = moment(timePickerTime);
      //let startTime = timePickerTime.subtract(30, "minutes")
      setEndTime(endTime);
      //setStartTime(moment(startTime))
      //setFieldValue("startTime", startTime)
      setFieldValue("endTime", endTime);
    }
  };

  const handleSelectedVisitorData = (
    parkingSpace,
    visitor,
    setFieldValue,
    start_time,
    ends_time
  ) => {
    if (visitor == undefined) {
      return;
    }


    // setVisitorDetailsArr
    let visitorDetailsData = searchDataStore?.entityData?.visitorData?.find(
      (item) => {
        return item.id === visitor;
      }
    );

    var stopDuplication = visitorDetailsArr.filter(
      (d) => d?.selected_visitor_id == visitor
    );

    let commonVisitorDetails = {
      // selected_visitor_id: visitor, // Visitor id
      // selected_visitor: visitorDetailsData?.full_name
      //   ? visitorDetailsData?.full_name
      //   : "",
      // selected_visitor_email: visitorDetailsData?.email
      //   ? visitorDetailsData?.email
      //   : "",
      selected_visitor_id: singleVisitor.id,
      selected_visitor_email: singleVisitor?.email ? singleVisitor?.email : "",
      selected_visitor_company: singleVisitor?.comapny ? singleVisitor?.comapny : "",
      selected_visitor: singleVisitor?.full_name
        ? singleVisitor?.full_name
        : "",
      selected_visitor_number: singleVisitor?.number
        ? singleVisitor?.number
        : "",
      // selected_visitor_company: visitorDetailsData?.company
      //   ? visitorDetailsData?.company
      //   : "",
      selected_visitor_number: visitorDetailsData?.number
        ? visitorDetailsData?.number
        : "",

      selected_parking_space: parkingSpace,
      isFullDay: isFullDay,
      selected_start_time: isFullDay ? "09:00 am" : start_time,
      selected_end_time: isFullDay ? "05:00 pm" : ends_time,
    };

    //Create a new visitor
    if (stopDuplication.length === 0 && !editVisitor) {
      let selectedVisitorObj = {
        listId: visitorListId,
        ...commonVisitorDetails,
      };
      setVisitorDetailsArr([...visitorDetailsArr, selectedVisitorObj]);
      setVisitorListId(visitorListId + 1);
    }

    //Edit A Visitor
    else if (editVisitorDetails && editVisitor) {
      let editObj = {
        listId: editVisitorDetails?.listId,
        ...commonVisitorDetails,
      };
      const updateItem = visitorDetailsArr.map((item) => {
        if (editVisitorDetails?.listId == item.listId) {
          return editObj;
        } else {
          return item;
        }
      });
      setVisitorDetailsArr(updateItem);
      setEditVisitor(false);
    }

    //Warning For Select Same User
    else {
      NotificationManager.error("Visitor Already selected.", "Warning");
    }
  };

  const handleVisitorListAction = (action_details, setFieldValue) => {

    if (action_details?.action_type === "edit") {
      if (action_details?.details?.selected_start_time) {
        let startTime = moment(action_details?.details?.selected_start_time);
        setStartTime(startTime);
      }
      if (action_details?.details?.isFullDay) {
        setIsFullDay(true);
      } else {
        setIsFullDay(false);
      }
      setFieldValue("startTime", action_details?.details?.selected_start_time);

      if (action_details?.details?.selected_end_time) {
        let endTime = moment(action_details?.details?.selected_end_time);
        setEndTime(endTime);
      }
      setFieldValue("endTime", action_details?.details?.selected_end_time);

      setFieldValue("visitor", action_details?.details?.selected_visitor_id);

      setFieldValue(
        "car_park",
        action_details?.details?.selected_parking_space ? true : false
      );
      setIsParking(
        action_details?.details?.selected_parking_space ? true : false
      );
      setFieldValue(
        "park_type",
        action_details?.details?.selected_parking_space
      );
      setFieldValue(
        "disability",
        action_details?.details?.disability
      );
      setActionVisitorIdAndType(action_details);
      setEditVisitor(true);
      setEditVisitorDetails(action_details?.details);
    } else if (
      action_details?.details &&
      action_details?.action_type === "delete"
    ) {
      setVisitorDetailsArr(
        visitorDetailsArr.filter(
          (item) =>
            item.selected_visitor_id !==
            action_details?.details?.selected_visitor_id
        )
      );
    }
  };

  const handleSelectedParkingDates = (dates) => {
    setSelectedParkingDates(dates);
  };
  const handleCancelAddVisitor = (setFieldValue) => {
    setFieldValue("startTime", "");
    setStartTime();
    setEndTime();
    setFieldValue("endTime", "");
    setFieldValue("park_type", "");
    setFieldValue("disability", "");
    setFieldValue("visitor", undefined);
    setFieldValue("car_park", false);
    setFieldValue("select_visitor_toggle", false);
    setIsParking(false);
    setEditVisitor(false);
  };


  const handleInfiniteScrollPopUp = () => {
    setIsShowInfiniteScrollPopUp(false);
  };
  console.log(
    visitorStore?.visitorEditItem,
    "visitorStore?.visitorEditItem?.id"
  );
  const handleReqResend = () => {
    dispatch(actions.ResendReqVisitor(visitorStore?.visitorEditItem?.id));
  };
  const optionList = [
    { value: "red", label: "Red" },
    { value: "green", label: "Green" },
    { value: "yellow", label: "Yellow" },
    { value: "blue", label: "Blue" },
    { value: "white", label: "White" }
  ];


  return (
    <div>
      <SelectInfiniteScrollDropDown
        isShowInfiniteScrollPopUp={isShowInfiniteScrollPopUp}
        fieldName="select_infinite_scroll_drop_down"
        handleInfiniteScrollPopUp={handleInfiniteScrollPopUp}
        name="SELECT HOST"
        selectType="single"
        setUserData={setUserData}
      />
      <Modal
        show={show}
        onHide={handleClose}
        className="modal-right visitor-booking-popup"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <b>{modalProps?.title.toUpperCase()}</b>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize={true}
            initialValues={frmInitialValues}
            // validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setVisitorDetailsArr([]);
              setSubmitting(false);
              duration = values.isFullDay
                ? 480
                : moment.duration(
                  moment(values.endTime, "HH:mm").diff(
                    moment(values.startTime, "HH:mm")
                  )
                );
              duration = values.isFullDay
                ? 480
                : parseInt(duration.asMinutes());

              let starts_at_formt_date = moment(values.starts_at).format(
                "YYYY-MM-DD"
              );
              let starts_at_formt_time = moment(values.startTime).format(
                "HH:mm:ss"
              );

              const visitorDetails = getVisitorData(values.visitor);
              const add_visitor_booking_update = {};
              if (id) {

                // Edit booking
                let visitor;
                if (!usersData?.id) {
                  visitor = [];
                } else {
                  visitor = [
                    {
                      host_id: usersData?.id
                        ? usersData?.id
                        : visitorStore?.visitorEditItem?.host?.id,
                      id: visitorStore?.visitorEditItem?.guest?.id,
                    },
                  ];
                }
                add_visitor_booking_update["booking_id"] =
                  visitorStore?.visitorEditItem?.id;
                add_visitor_booking_update["duration"] = duration;
                add_visitor_booking_update["starts_at"] =
                  starts_at_formt_date + " " + starts_at_formt_time;
                add_visitor_booking_update["type"] = "visitor";
                add_visitor_booking_update["visitors"] = visitor;

                dispatch(
                  actions.updateBookingVisitor(add_visitor_booking_update)
                ).then((res) => {
                  if (props?.dashboardActions) {
                    dispatch(
                      props.dashboardActions.fetchBookingList(
                        dashboardStore?.dashboardFilters
                      )
                    );
                    onHide();
                    setVisitorDetailsArr([]);
                  }
                });
              } else {
                // Create the booking
                if (multipleDates && Object.keys(multipleDates).length > 0) {
                  // multiple dates
                  let inputs = {};
                  let dates = [];
                  for (const date in multipleDates) {
                    if (Object.hasOwnProperty.call(multipleDates, date)) {
                      // const date = multipleDates[key]

                      let input_data = {};
                      input_data["date"] = moment.utc(date).format();
                      input_data["parking"] = selectedParkingDates.includes(
                        date
                      );
                      dates.push(input_data);
                    }
                  }
                  inputs["dates"] = dates;
                  //  For visitor data
                  let visitors = [];
                  for (const key in visitorDetailsArr) {
                    console.log("visitor", visitorDetailsArr);
                    if (Object.hasOwnProperty.call(visitorDetailsArr, key)) {
                      const visitor = visitorDetailsArr[key];
                      let selected_start_time = visitor?.selected_start_time;
                      let selected_end_time = visitor?.selected_end_time;
                      let startTime = moment(
                        new Date().toDateString() + " " + selected_start_time
                      );
                      let endTime = moment(
                        new Date().toDateString() + " " + selected_end_time
                      );
                      let input_data = {};
                      input_data["full_name"] = visitor?.selected_visitor || "";
                      input_data["email"] =
                        visitor?.selected_visitor_email || "";
                      input_data["company"] =
                        visitor?.selected_visitor_company || "";
                      input_data["number"] =
                        visitor?.selected_visitor_number || "";
                      input_data["car_park"] = visitor?.selected_parking_space
                        ? true
                        : false;
                      input_data["disability"] = disability
                        ? true
                        : false;
                      input_data["park_type"] =
                        visitor?.selected_parking_space || "";
                      input_data["starts_at"] = moment.utc(startTime).format();
                      input_data["ends_at"] =
                        moment.utc(endTime).format() || "";
                      input_data["duration"] = 30; // Todo: can set it in variable for manage the duration time
                      input_data["host_id"] = userId ? userId : usersData?.id;

                      visitors.push(input_data);
                    }
                  }
                  inputs["visitors"] = visitors;
                  dispatch(actions.createMultiDateBookingVisitor(inputs)).then(
                    (res) => {
                      if (props?.dashboardActions) {
                        dispatch(
                          props.dashboardActions.fetchBookingList(
                            dashboardStore?.dashboardFilters
                          )
                        );
                        onHide();
                        setVisitorDetailsArr([]);
                      }
                    }
                  );
                } else {
                  let visitors = [];
                  let visitorsData = {};
                  for (const key in visitorDetailsArr) {
                    if (Object.hasOwnProperty.call(visitorDetailsArr, key)) {
                      const visitor = visitorDetailsArr[key];
                      let selected_start_time = visitor?.selected_start_time;
                      let selected_end_time = visitor?.selected_end_time;
                      let startTime = moment(
                        new Date().toDateString() + " " + selected_start_time
                      );
                      let endTime = moment(
                        new Date().toDateString() + " " + selected_end_time
                      );
                      let input_data = {};
                      input_data["full_name"] = visitor?.selected_visitor || "";
                      input_data["email"] =
                        visitor?.selected_visitor_email || "";
                      input_data["company"] =
                        visitor?.selected_visitor_company || "";
                      input_data["number"] =
                        visitor?.selected_visitor_number || "";
                      input_data["car_park"] = visitor?.selected_parking_space
                        ? true
                        : false;
                      input_data["disability"] = disability
                        ? true
                        : false;
                      input_data["park_type"] =
                        visitor?.selected_parking_space || "";
                      input_data["starts_at"] = values.isFullDay
                        ? moment
                          .utc()
                          .set("hour", "09")
                          .set("minute", "00")
                          .set("second", "00")
                        : starts_at_formt_date + " " + starts_at_formt_time;
                      // input_data["ends_at"] =
                      //   moment.utc(endTime).format() || "";
                      input_data["duration"] = duration; // Todo: can set it in variable for manage the duration time
                      input_data["host_id"] = userId ? userId : usersData?.id;

                      visitors.push(input_data);
                    }
                  }
                  visitorsData["visitors"] = visitors;
                  console.log(visitors);
                  // single dates but multiple visitors
                  dispatch(
                    actions.createBookingVisitor(visitorsData, undefined)
                  ).then((res) => {
                    if (props?.dashboardActions) {
                      dispatch(
                        props.dashboardActions.fetchBookingList(
                          dashboardStore?.dashboardFilters
                        )
                      );
                      onHide();
                      setVisitorDetailsArr([]);
                    }
                  });
                }
              }
            }}
          >
            {({
              values,
              handleSubmit,
              handleChange,
              setFieldValue,
              isSubmitting,
            }) => (
              <Form className="form form-label-right" onSubmit={handleSubmit}>
                {/* {!id && (
                  <>
                    <SiteFloorSelectInput />
                    <div className="space-1"></div>
                  </>
                )} */}

                <div className="select-time">
                  {id ? (
                    <div className="title-text select-visitor font-weight-bolder">
                      CHANGE DATE
                    </div>
                  ) : (
                    <div className="title-text select-visitor font-weight-bolder">
                      SELECT DATE
                    </div>
                  )}
                  <div className="space-1"></div>
                  <div className="p-3 ">
                    <CustomDatePicker
                      className="bookify-date-picker"
                      name="starts_at"
                      type="visitor"
                      occupancyShow={occupancyShow}
                      multiple_date_picker="multiple_date_picker"
                      editId={id}
                      setIsValidDates={setIsValidDates}
                      setDisableBtnForSingleDate={setDisableBtnForSingleDate}
                    />
                    <div className="mt-5">
                      <div className="d-flex justify-content-between ">
                        <div className=" pr-5 pb-1 pl-2">
                          This booking is from someone else
                        </div>

                        <div
                          className="pb-1"
                        // onClick={() => {
                        //   if (searchDataStore?.siteId == null) {
                        //     NotificationManager.error("Please select Site.", "Error")
                        //   }
                        // }}
                        >
                          {/* <FormCheck
                            name="is_host"
                            type="switch"
                            id="custom-switch-is-host"
                            label=""
                            onChange={() => {
                              setIsHost(!isHost);
                              setIsEmpty(false);
                            }}
                            checked={isHost}
                          // disabled={searchDataStore?.siteId == null}
                          /> */}
                          <FormCheck
                            type="switch"
                            name="select_owner"
                            id="select_owner"
                            label=""
                            className="button-cursor"
                            checked={values.select_owner}
                            onClick={() => {
                              handleToggleOwnerClick(!toggleOwnerMenu);
                            }}
                            disabled={searchDataStore?.siteId == null}
                          />
                        </div>
                      </div>
                      {toggleOwnerMenu ? (
                        <div className="space-1">
                          <div className="p-">
                            <Field
                              name="owner"
                              component={SearchSelectInput}
                              placeholder="Select Owner"
                              source={`user?site_id=${siteId}`}
                              returnData="users"
                              onChange={handleChange}
                              value={values.owner}
                              onSelect={(item) => {
                                if (item?.id) {
                                  setUserId(item.id);
                                }
                              }}
                            />
                          </div>
                        </div>
                      ) : null}

                      {isHost && (
                        <div className="space-1">
                          <div className="btn-section d-flex">
                            <button
                              className="border border-1 border-dark btn rounded-pill w-100"
                              onClick={(e) => {
                                e.preventDefault();
                                setIsShowInfiniteScrollPopUp(
                                  !isShowInfiniteScrollPopUp
                                );
                              }}
                            >
                              {usersData !== undefined ? (
                                <>
                                  <img
                                    width="15px"
                                    className="mb-1"
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/Booking/edit-sign.svg"
                                    )}
                                    alt="Plus"
                                  />
                                  &nbsp;&nbsp;&nbsp;{usersData?.full_name}
                                </>
                              ) : (
                                <>
                                  <img
                                    width="15px"
                                    className="mb-1"
                                    src={toAbsoluteUrl(
                                      "/media/vehicles/plus.svg"
                                    )}
                                    alt="Plus"
                                  />
                                  &nbsp;&nbsp;&nbsp;SELECT HOST
                                </>
                              )}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <VisitorList
                  visitorDetailsArr={visitorDetailsArr}
                  setActionVisitorIdAndType={(
                    action_details,
                    setFieldValue
                  ) => {
                    handleVisitorListAction(action_details, setFieldValue);
                  }}
                />

                <div className=""></div>
                <div className="select-time">
                  <div className="title-text select-visitor">
                    SELECT VISITOR
                  </div>
                  <div className="inner-padding visitor">
                    {/* <div className="space-1"> */}
                    {!id && (
                      <>
                        {/* <div className="d-flex justify-content-between">
                          <div className="p-5">Add new from All Visitors</div>
                          <div className=" p-5 pb-4 p-07">
                            <FormCheck
                              type="switch"
                              id="custom-switch-select-visitor"
                              label=""
                              name="select_visitor_toggle"
                              onChange={() => {
                                setAddNewAllVisitor(!addNewAlVisitor);
                                setFieldValue(
                                  "select_visitor_toggle",
                                  !values.select_visitor_toggle
                                );
                              }}
                            />
                          </div>
                        </div> */}
                      </>
                    )}
                    {/* </div> */}
                    {!id && (
                      <>
                        {/* <Field
                          name="visitor"
                          component={SearchSelectInput}
                          placeholder="Select a visitor"
                          // source={`user/guest?site_id=1`}
                          source={`user/guest-listing?site_id=${Number(
                            searchDataStore?.siteId
                          )}&my_visitor=false&perPage=2000&page=1`}
                          returnData="guests"
                          //onChange={handleChange}
                          onChange={selectVisitorFunction(values.visitor)}
                          value={values.visitor}
                          storeEntityName="visitorData"
                          addedNewData={addedNewVisitorData}
                          listUpdate={listUpdate}
                        /> */}

                        {/* <Select
                          options={optionList}
                          // options={`user/guest-listing?site_id=${Number(
                          //   searchDataStore?.siteId
                          // )}&my_visitor=false&perPage=2000&page=1`}
                          placeholder="Select a visitor"
                          onChange={visitorListValue}
                          onInputChange={visitorListValueChange}
                        /> */}

                        <CreatableSelect
                          placeholder="Select a visitor"
                          isClearable
                          name="visitor"
                          // onChange={visitorListValue}
                          onChange={e => { visitorListValue(e); setFieldValue('visitor', e) }}
                          value={values.visitor}
                          storeEntityName="visitorData"
                          options={visitorOptionList}
                          onInputChange={visitorListValueChange}
                        />
                      </>
                    )}
                    {!id && (
                      <>
                        <div className="space-1">
                          <Row>
                            <Col md={6}>
                              {visitorErr && values.visitor === undefined && (
                                <p className="pl-4 text-danger mb-0">
                                  {visitorErr}
                                </p>
                              )}
                            </Col>
                            <Col md={6}>
                              {/* <div
                                onClick={handleAddVisitor}
                                className="new-visitor-row"
                              >
                                New visitor? Add visitor to list{" "}
                              </div> */}
                            </Col>
                          </Row>
                        </div>
                      </>
                    )}

                    {!id && (
                      <>
                        <div className="">
                          <div className="d-flex justify-content-between">
                            <div className="p-4" style={{ paddingLeft: "4px" }}>Full Day</div>
                            <div className=" p-4 p-07">
                              <FormCheck
                                type="switch"
                                id="custom-switch-full-day"
                                label=""
                                onChange={() => {
                                  console.log("start", isFullDay);
                                  setIsFullDay(!isFullDay);
                                  setFieldValue("isFullDay", !values.isFullDay);
                                  console.log("end", isFullDay);
                                }}
                                checked={isFullDay}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}



                    {!isFullDay && (
                      <div className="px-4 time-picker-main">
                        {id && (
                          <>
                            <div className="d-flex align-items-center mb-3">
                              <span className="ml-4">
                                <SVG
                                  className=""
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/editVisitor.svg"
                                  )}
                                />
                              </span>
                              <div className="d-flex">
                                <p className="ml-1 mb-0">Visitor:</p>
                                <b className="ml-2">
                                  {
                                    visitorBookingItem?.guest
                                      ?.full_name
                                  }
                                </b>
                              </div>
                            </div>
                          </>
                        )}
                        <Row>
                          <Col md={6}>
                            <div
                              className={
                                "time-picker-inner" +
                                (values.startTime ? " time-picker-blue" : "")
                              }
                            >
                              <SVG
                                className=""
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/clock.svg"
                                )}
                              />

                              <TimePickerField
                                // onDateSet={(data) => {
                                //   changeData();
                                //   applyTimeFilter(data, "startTime", setFieldValue, values)
                                // }}
                                minDate={moment().format("hh:mm a")}
                                // maxDate={values.endTime}
                                value={values.endTime}
                                name="startTime"
                                placeholder={"Start Time"}
                              //date={values.startTime}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div
                              className={
                                "time-picker-inner" +
                                (values.endTime ? " time-picker-blue" : "")
                              }
                            >
                              <SVG
                                className=""
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/clock.svg"
                                )}
                              />
                              {values?.startTime ? (
                                <TimePickerField
                                  // onDateSet={(data) =>
                                  //   applyTimeFilter(data, "endTime", setFieldValue, values)
                                  // }
                                  //minDate={values?.startTime}
                                  minDate={moment().format("hh:mm a")}
                                  name="endTime"
                                  placeholder={"End Time"}
                                //date={values.endTime}
                                />
                              ) : (
                                <TimePickerField
                                  // onDateSet={(data) =>
                                  //   applyTimeFilter(data, "endTime", setFieldValue, values)
                                  // }
                                  name="endTime"
                                  placeholder={"End Time"}
                                //date={values.endTime}
                                />
                              )}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </div>
                  {(timeErr &&
                    (values.startTime === undefined ||
                      values.endTime === undefined)) ||
                    moment(values.startTime, "HH:mm").isAfter(
                      moment(values.endTime, "HH:mm")
                    ) ||
                    moment(values.startTime, "HH:mm").isSame(
                      moment(values.endTime, "HH:mm")
                    ) ? (
                    <p className="text-danger mb-0 text-center">{timeErr}</p>
                  ) : (
                    ""
                  )}
                  {!id && (
                    <>
                      <div className="d-flex justify-content-between">
                        <div className="p-5">Add Parking Space</div>
                        <div className=" p-5 pb-4 p-07">
                          <FormCheck
                            type="switch"
                            id="car_park"
                            name="car_park"
                            label=""
                            className="button-cursor"
                            checked={isParking}
                            onChange={() => {
                              setIsParking(!isParking);
                              setFieldValue("car_park", !values.car_park);
                            }}
                          />
                        </div>
                      </div>
                      {values.car_park && (
                        <div className="p-3 pb-4 p-07 rounded-pill">
                          <Select2
                            className="rounded-pill"
                            data={vehicleTypes}
                            name="park_type"
                            // value={}
                            onChange={handleChange}
                            update={(value) =>
                              setFieldValue("park_type", value)
                            }
                            placeholder="Select vehicle type"
                            value={values.park_type}
                          ></Select2>
                          {/* <div className="d-flex justify-content-between disability">
                            <div className="pr-5 pb-3 pl-2">Disability</div>
                            <div className="pb-3">
                              <FormCheck
                                type="switch"
                                name="disability"
                                id="disability"
                                label=""
                                className="button-cursor"
                                checked={disability}
                                onChange={handleChange}
                                update={(value) => {
                                  setFieldValue("disability", !values.disability)
                                  setIsDisibality(value)
                                }
                                }

                              />
                            </div>
                          </div> */}
                          <div className="d-flex justify-content-between">
                            <div className="p-5">Disability</div>
                            <div className=" p-5 pb-4 p-07">
                              <FormCheck
                                type="switch"
                                id="disability"
                                name="disability"
                                label=""
                                className="button-cursor"
                                checked={disability}
                                onChange={() => {
                                  setIsDisibality(!disability);
                                  setFieldValue("disability", !values.disability);
                                }}
                              />
                            </div>
                          </div>
                        </div>

                      )}
                      <div className="space-15">
                        <Button
                          className="select-visitor-btn"
                          variant=""
                          type="button"
                          onClick={() => {
                            let start_time = isFullDay ? moment(
                              "09:00:00",
                              "HH:mm"
                            ).format("hh:mm A") : moment(
                              values.startTime,
                              "HH:mm"
                            ).format("hh:mm A");

                            let ends_time = isFullDay ? moment(
                              "17:00:00",
                              "HH:mm"
                            ).format("hh:mm A") : moment(
                              values.endTime,
                              "HH:mm"
                            ).format("hh:mm A");

                            if (isFullDay) {
                              // setStartTime(moment("09:00:00").format(
                              //   "HH:mm:ss"
                              // ))
                              // setEndTime(moment("17:00:00").format(
                              //   "HH:mm:ss"
                              // ))
                              // setStartTime("09:00")
                              // setEndTime("17:00")
                            }

                            if (values.visitor === undefined) {
                              setVisitorErr("Please select a visitor.");
                              return;
                            }
                            // if (
                            //   values.startTime === undefined ||
                            //   values.endTime === undefined
                            // )
                            if (
                              start_time === undefined ||
                              ends_time === undefined
                            ) {
                              setTimeErr("Please select a valid time.");
                              setVisitorErr("");
                              return;
                            } else if (
                              moment(values.startTime, "HH:mm").isAfter(
                                moment(values.endTime, "HH:mm")
                              ) ||
                              moment(values.startTime, "HH:mm").isSame(
                                moment(values.endTime, "HH:mm")
                              )
                            ) {
                              setTimeErr(
                                "End time should be greater than start time."
                              );
                              setVisitorErr("");
                              return;
                            } else {
                              setVisitorErr("");
                              setTimeErr("");
                              handleSelectedVisitorData(
                                values.park_type,
                                values.visitor,
                                setFieldValue,
                                start_time,
                                ends_time
                              );
                            }

                            // setSelectedTime({
                            //   start_time: moment(values.startTime).format(
                            //     "HH:mm AA"
                            //   ),
                            //   ends_at: moment(values.startTime).format(
                            //     "HH:mm AA"
                            //   ),
                            // });
                          }}
                        >
                          {editVisitor ? (
                            <b>Edit Visitor</b>
                          ) : (
                            <b>Confirm</b>
                          )}
                        </Button>
                      </div>
                      <div className="space-15">
                        <Button
                          className="select-visitor-btn"
                          variant=""
                          type="button"
                          onClick={() => {
                            handleCancelAddVisitor(setFieldValue);
                          }}
                        >
                          <b>Cancel</b>
                        </Button>
                      </div>
                      <div className="d-flex justify-content-end align-items-center mt-5 mr-4 pb-3">
                        <span className="available-status-label">
                          Available:
                        </span>{" "}
                        <span className="ml-2 mb-1">
                          {helperFunc.setVehicleImage("electric_car")}
                        </span>
                        <span className="available-counter-text">
                          {" "}
                          {availableData?.parking_ev}
                        </span>{" "}
                        <span className="ml-2 mb-1">
                          {helperFunc.setVehicleImage("parking")}
                        </span>
                        <span className="available-counter-text">
                          {" "}
                          {availableData?.parking}
                        </span>{" "}
                        <span className="ml-2 mb-1">
                          {helperFunc.setVehicleImage("bike")}
                        </span>
                        <span className="available-counter-text">
                          {" "}
                          {availableData?.motorbike}
                        </span>{" "}
                      </div>
                    </>
                  )}
                  {id && (
                    <span
                      className="btn btn-primary"
                      style={{ margin: "10px" }}
                      onClick={() => handleReqResend()}
                    >
                      Resend Invitation
                    </span>
                  )}
                </div>
                {multipleDates && (
                  <div className="space-1">
                    <div className="select-time">
                      <DayWiseParkingOptions
                        value={values.all_day_parking}
                        handleSelectedParkingDates={handleSelectedParkingDates}
                      />
                    </div>
                  </div>
                )}
                <div className="space-15">
                  {visitorBookingItem?.guest ?
                    <Button
                      className="w-100 custom-button"
                      variant="primary"
                      type="submit"
                    //disabled={isEmpty ? "disabled" : ""}
                    >
                      Done
                    </Button>
                    :
                    <Button
                      className="w-100 custom-button"
                      variant="primary"
                      type="submit"
                      disabled={isEmpty ? "disabled" : ""}
                    >
                      Done
                    </Button>
                  }

                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ActionModalVisitor;
