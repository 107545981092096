import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DatePickerField } from "../../../_metronic/_partials/controls";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import moment from "moment";
import * as actions from "./_redux/Actions";
import CommonOccupancy from "./CommonOccupancy";
import MultiDatePickerPopUp from "./MultiDatePicker/MultiDatePickerPopUp";
import MultipleDatePickerAvailabilitySpace from "./MultiDatePicker/MultipleDatePickerAvailabilitySpace";
import { useFormikContext } from "formik";

const CustomDatePicker = ({ ...props }) => {
  const { setIsValidDates, setDisableBtnForSingleDate, editId } = props;
  const [selectedDate, setSelectedDate] = useState(props?.date);
  const [dateData, setDateData] = useState();
  const [isMultipleDatePicker, setIsMultiDatePicker] = useState(false);
  const [selectedMultipleDate, setSelectedMultipleDate] = useState();
  const dispatch = useDispatch();
  const { setFieldValue } = useFormikContext();

  const searchDataStore = useSelector((state) => state?.searchData);
  useEffect(() => {
    let currentDateTime = moment();
    if (selectedDate) {
      let date = moment(selectedDate)
        .set("hour", currentDateTime.get("hour"))
        .set("minute", currentDateTime.get("minute"))
        .set("second", currentDateTime.get("second"))
        .add("1", "minute");
      date = moment.utc(date).format();

      let params = {
        starts_at: date,
        floor_id: props?.floor_id || 1,
      };
      dispatch(actions.fetchList(params));
      setSelectedMultipleDate(undefined);
    } else {
      setSelectedDate(currentDateTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  const handleMultiDatePickerPopUpShow = () => {
    setIsMultiDatePicker(true);
  };
  const handleMultiDatePickerPopUpClose = () => {
    setIsMultiDatePicker(false);
  };
  const handleSingleDateSelect = (date) => {

    setSelectedDate(date);
    props.date = date;
    console.log(props.date, " selecteddartes");
    // if (selectedMultipleDate === undefined) {
    // }
    setSelectedMultipleDate(undefined);
  };
  useEffect(() => {
    const queryParams = {
      start_at: selectedMultipleDate,
      floor_id: searchDataStore?.floorId || 1,
    };
    if (selectedMultipleDate !== undefined) {
      dispatch(actions.multipleDateSelection(queryParams));
    } else {
      dispatch(actions.clearMultipleDateSelection());
      setSelectedMultipleDate(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMultipleDate]);

  useEffect(() => {
    if (dateData && props?.setDisableBtnForSingleDate) {
      setDisableBtnForSingleDate(dateData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateData]);

  const datePickerSection = () => {
    return (
      <>
        <DatePickerField
          className={props?.className}
          name={props?.name || "date"}
          onDateSet={(selectedDate) => handleSingleDateSelect(selectedDate)}
          defaultDate={!props.date ? moment().format("DD-MM-yyyy") : null}
          format="dddd[, ]Do MMM"
          hideFeedBack={true}
          date={props.date ? props.date : null}
          minDate={moment().format("DD-MM-yyyy")}
        />
        <SVG
          className="date-picker-calender button-cursor"
          src={toAbsoluteUrl("/media/svg/icons/calendar.svg")}
          onClick={props.multiple_date_picker && handleMultiDatePickerPopUpShow}
        />
      </>
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          {!props.multiple_date_picker ? (
            <label className="w-100">{datePickerSection()}</label>
          ) : (
            <div className="mb-2">{datePickerSection()}</div>
          )}
          {/* multiple_date_picker */}
        </div>
      </div>

      {// if(props?.occupancyShow && selectedDate && selectedMultipleDate )
        props?.occupancyShow && selectedDate && !selectedMultipleDate ? (
          <CommonOccupancy
            setDateData={setDateData}
            selectedDate={selectedDate}
            dateData={dateData}
          />
        ) : (
          !editId && (
            <MultipleDatePickerAvailabilitySpace
              bookingType={props?.type}
              selectedDates={selectedMultipleDate}
              setSelectedMultipleDate={setSelectedMultipleDate}
              setIsValidDates={setIsValidDates}
            />
          )
        )}

      <MultiDatePickerPopUp
        bookingType={props?.type}
        floor_id={searchDataStore?.floorId}
        isMultipleDatePicker={isMultipleDatePicker}
        handleMultiDatePickerPopUpClose={handleMultiDatePickerPopUpClose}
        handleMultipleSelectedDates={(dates) => {
          if (dates.length === 1) {

            let date = moment.utc(dates[0]);
            setFieldValue(props?.name, date);
            handleSingleDateSelect(date);
            setSelectedMultipleDate(undefined);
          } else if (dates.length > 1) {
            setSelectedMultipleDate(dates);
          }
        }}
      />
    </>
  );
};

export default CustomDatePicker;
