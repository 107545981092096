import { Badge, Card } from "@material-ui/core"
import { Field } from "formik"
import React, { useState, useEffect } from "react"
import { shallowEqual, useSelector } from "react-redux"
import ModalImage from "react-modal-image"

const CardRadioInput = (props) => {
  const item = props.item

  const onChangeHandle = (value, name) => {
    var cleanFloorState = false
    if (name === "site") {
      cleanFloorState = true
    }
    console.log(cleanFloorState)
    props.onChange(value, cleanFloorState)
  }
  return (
    <>
      <Card>
        <Field
          onClick={(e) => onChangeHandle(e.target.value, e.target.name)}
          type="radio"
          id={item.id}
          name={props.field}
          value={item.id}
          checked={props.isChecked}
        />
        <label htmlFor={item.id}>
          <div className="d-flex">
            <div className="site-image">
              <ModalImage
                small={item.url_image}
                large={item.url_image}
                className="width-55rem"
                // style={{ maxWidth: "5.5rem" }}
                alt={item.label}
              />
            </div>
            <div>
              <span className="title">{item.label}</span>
              {item?.badges && (
                <div className="d-flex">
                  {item.badges.map((item, index) => {
                    return (
                      <Badge key={index} className="site-badge">
                        {item.count}&nbsp; {item.title}
                      </Badge>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
        </label>
      </Card>
    </>
  )
}

export default CardRadioInput
