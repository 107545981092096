import React from "react"
import helperFunc from "../../_metronic/_helpers/helperFunc"
import * as actions from "./_redux/Actions"
import { NormalUserDashboard } from "../../_metronic/_partials/dashboards/NormalUserDashboard"

export const DashboardPage = (props) => {
  const userRole = helperFunc.getUserRole();

  switch (userRole) {
    default:
      return <NormalUserDashboard actions={actions} type={helperFunc.getUserType()} />
  }
}
