import { createSlice } from "@reduxjs/toolkit";

const initialStaffUsersState = {
  listLoading: false,
  actionsLoading: false,
  upload: {},
  imageField : undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};
export const Slice = createSlice({

  name: "upload",
  initialState: initialStaffUsersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // search list
    imageUploaded: (state, action) => {
      const { entities, imageField} = action.payload;
      state.error = null;
      state.upload = entities;
      state.imageField = imageField;
    },
    
  }
});
