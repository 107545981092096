import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  events: {
    stepOne: {
      event_type: "",
      name: "",
      details: "",
      cover_image: undefined,
      document: undefined,
    },
    stepTwo: {
      created_at: "",
      starts_at: "",
      ends_at: "",
      site_id: "",
      site_name: "",
      floor_id: "",
      floor_name: "",
      map_url: "",
      city: "",
      address_1: "",
      address_2: "",
      postcode: "",
    },
    stepThree: {
      invitees: [
        {
          users: [],
          visitors: [],
          workCircleUsers: [],
        },
      ],
      host: {},
      generateLink: false,
      openEvent: false,
    },
  },
  eventsDetails: [],
  eventFloor: [],
  eventSpace: [],
  eventHost: [],
  eventFilter: {},
  confirmEventData: [],
  cancelEventData: [],
  resendOptiondata: [],
};

export const Slice = createSlice({
  name: "event",
  initialState: initialState,
  reducers: {
    handleStateChangeStepOne: (state, action) => {
      state.events.stepOne.name = action.payload.name;
      state.events.stepOne.details = action.payload.details;
      state.events.stepOne.event_type = action.payload.event_type;
      state.events.stepOne.cover_image = action.payload.cover_image;
      state.events.stepOne.document = action.payload.document;
    },
    handleStateChangeStepTwo: (state, action) => {
      state.events.stepTwo.created_at = action.payload.created_at;
      state.events.stepTwo.starts_at = action.payload.starts_at;
      state.events.stepTwo.ends_at = action.payload.ends_at;
      state.events.stepTwo.floor_id = action.payload.floor_id;
      state.events.stepTwo.floor_name = action.payload.floor_name;
      state.events.stepTwo.site_id = action.payload.site_id;
      state.events.stepTwo.site_name = action.payload.site_name;
      state.events.stepTwo.map_url = action.payload.map_url;
      state.events.stepTwo.city = action.payload.city;
      state.events.stepTwo.address_1 = action.payload.address_1;
      state.events.stepTwo.address_2 = action.payload.address_2;
      state.events.stepTwo.postcode = action.payload.postcode;
    },
    handleStateChangeStepThree: (state, action) => {
      state.events.stepThree.host = action.payload.host;
      state.events.stepThree.invitees[0].users =
        action?.payload?.invitees?.[0]?.users;
      state.events.stepThree.invitees[0].visitors =
        action?.payload?.invitees?.[0]?.visitors;
      state.events.stepThree.invitees[0].workCircleUsers =
        action?.payload?.invitees?.[0]?.workCircleUsers;
      state.events.stepThree.generateLink = action.payload.generateLink;
      state.events.stepThree.openEvent = action.payload.openEvent;
    },
    handleStateChangeStepFour: (state, action) => {
      state.events.stepOne.name = "";
      state.events.stepOne.details = "";
      state.events.stepOne.event_type = "";
      state.events.stepOne.cover_image = "";
      state.events.stepOne.document = "";
      state.events.stepTwo.created_at = "";
      state.events.stepTwo.starts_at = "";
      state.events.stepTwo.ends_at = "";
      state.events.stepTwo.map_url = "";
      state.events.stepTwo.city = "";
      state.events.stepTwo.address_1 = "";
      state.events.stepTwo.address_2 = "";
      state.events.stepTwo.postcode = "";
      state.events.stepTwo.floor_id = "";
      state.events.stepTwo.site_id = "";
      state.events.stepThree.host = {};
      state.events.stepThree.invitees = [];
      state.events.stepThree.generateLink = false;
    },
    getEventsDetails: (state, action) => {
      state.eventsDetails = action.payload;
    },
    getFloorEvent: (state, action) => {
      state.eventFloor = action.payload;
    },
    getSpaceEvent: (state, action) => {
      state.eventSpace = action.payload;
    },
    getEventHost: (state, action) => {
      state.eventHost = action.payload;
    },
    filterData: (state, action) => {
      state.eventFilter = action.payload;
    },
    confirmEvent: (state, action) => {
      state.confirmEventData = action.payload;
    },
    cancelvent: (state, action) => {
      state.cancelEventData = action.payload;
    },
    resendRequest: (state, action) => {
      state.resendOptiondata = action.payload;
    },
  },
});
