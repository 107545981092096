import React from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_helpers";
import SVG from "react-inlinesvg";

export function Brand() {
  const closeSidebar = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const body = document.getElementById("kt_body");
    const classList = body.classList;
    body.classList = classList + " aside-minimize";
  };

  return (
    <>
      {/* begin::Brand */}
      <div
        className={`aside-brand d-flex flex-column align-items-center flex-column-auto py-5 py-lg-1`}
      >
        {/* begin::Logo */}
        <Link to="" className="brand-logo">
          <img
            alt="logo"
            src={toAbsoluteUrl("/media/logos/logo.png")}
            className="max-h-50px full-logo"
          />
          <img
            alt="logo"
            src={toAbsoluteUrl("/media/svg/menu/logo-icon.svg")}
            className="max-h-50px icon-logo"
          />
          <SVG
            onClick={(e) => closeSidebar(e)}
            className="close-menu-icon brand-toggle"
            src={toAbsoluteUrl("/media/svg/menu/close.svg")}
          />
        </Link>
        {/* end::Logo */}
      </div>
      {/* end::Brand */}
    </>
  );
}
