import * as requestFromServer from "./Crud";
import { visitorSlice, callTypes } from "./Slice";
import { NotificationManager } from "react-notifications";

const { actions } = visitorSlice;

export const getVisitorsList = (params) => (dispatch) => {
  requestFromServer.getVisitorsList(params).then((response) => {
    const item = response.data;
    dispatch(actions.fetchedVisitorsList(item));
  });
};

export const createVisitor = (item, updateList) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createVisitor(item)
    .then((response) => {
      const visitor = response.data;
      NotificationManager.success("New visitor added successfully!", "success");
      dispatch(actions.itemCreated({ item, updateList }));
      return visitor.id;
    })
    .catch((error) => {
      // const errMsg = error.response && error.response.data && error.response.data.errors ? error.response.data.errMsgerrors[0].msg : error.message ;
      // console.log(error.response.data.errMsgerrors[0].msg)
      //console.log(error);
      //   NotificationManager.error(errMsg, 'Error');
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createBookingVisitor = (item, updateList) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createBookingVisitor(item)
    .then((response) => {
      const visitor = response.data;
      NotificationManager.success(
        "New visitor booking added successfully!",
        "success"
      );
      dispatch(actions.itemCreated({ item, updateList }));
      return visitor.id;
    })
    .catch((error) => {
      // const errMsg = error.response && error.response.data && error.response.data.errors ? error.response.data.errMsgerrors[0].msg : error.message ;
      // console.log(error.response.data.errMsgerrors[0].msg)
      //console.log(error);
      //   NotificationManager.error(errMsg, 'Error');
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createMultiDateBookingVisitor = (item) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createMultiDateBookingVisitor(item)
    .then((response) => {
      const visitor = response.data;
      NotificationManager.success(
        "New visitor booking added successfully!",
        "success"
      );
      dispatch(actions.multiDateBookingCreated({ visitor }));
      return visitor;
    })
    .catch((error) => {
      // const errMsg = error.response && error.response.data && error.response.data.errors ? error.response.data.errMsgerrors[0].msg : error.message ;
      // console.log(error.response.data.errMsgerrors[0].msg)
      //console.log(error);
      //   NotificationManager.error(errMsg, 'Error');
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateVisitor = (item) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateVisitor(item)
    .then(() => {
      NotificationManager.success(
        "Visitor Details Updated successfully!",
        "success"
      );
      dispatch(actions.updateVisitorData({ item }));
      return true;
    })
    .catch((error) => {
      // error.clientMessage = "Can't update item";
      const errMsg =
        error.response && error.response.data && error.response.data.errors
          ? error.response.data.errors[0].msg
          : error.message;
      NotificationManager.error(errMsg, "Error");
      dispatch(actions.catchError({ errMsg, callType: callTypes.action }));
      return false;
    });
};

export const updateBookingVisitor = (item) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateBookingVisitor(item)
    .then(() => {
      NotificationManager.success(
        "Visitor Details Updated successfully!",
        "success"
      );
      dispatch(actions.updateBookingVisitorData({ item }));
      return true;
    })
    .catch((error) => {
      // error.clientMessage = "Can't update item";
      const errMsg =
        error.response && error.response.data && error.response.data.errors
          ? error.response.data.errors[0].msg
          : error.message;
      NotificationManager.error(errMsg, "Error");
      dispatch(actions.catchError({ errMsg, callType: callTypes.action }));
      return false;
    });
};

export const updateFavourite = (params) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .favouriteAction(params)
    .then(() => {
      dispatch(actions.favouriteActionDone({ params }));
      return true;
    })
    .catch((error) => {
      // error.clientMessage = "Can't update item";
      const errMsg =
        error.response && error.response.data && error.response.data.errors
          ? error.response.data.errors[0].msg
          : error.message;
      NotificationManager.error(errMsg, "Error");
      dispatch(
        actions.catchError({
          errMsg,
          callType: callTypes.action,
        })
      );
      return false;
    });
};

export const deleteItem = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteItem(id)
    .then((response) => {
      dispatch(actions.itemDeleted({ id }));
      NotificationManager.success("Visitor deleted successfully!", "success");
    })
    .catch((error) => {
      const errMsg =
        error.response && error.response.data && error.response.data.errors
          ? error.response.data.errors[0].msg
          : error.message;
      NotificationManager.error(errMsg, "Error");
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const setEditItem = (item) => (dispatch) => {
  dispatch(actions.setEditItem({ item }));
};

export const ResendReqVisitor = (params) => () => {
  return requestFromServer.ResendReqVisitor(params).then((res) => {
    if (res?.data) {
      NotificationManager.success(
        "Invitation Request Sent Successfully",
        "Success"
      );
    }
  });
};
