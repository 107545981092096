import { Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { useDispatch, useSelector } from "react-redux";
import * as eventsAction from "../_redux/Actions";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { TimePickerField } from "../../../../../_metronic/_partials/controls";
import CustomDatePicker from "../../../../components/CustomDatePicker/CustomDatePicker";
import "./BulletinStepPublish.scss";
import * as Yup from "yup";
import helperFunc from "../../../../../_metronic/_helpers/helperFunc";
import {
    createBulletin,
} from "../_redux/Actions";
import CreatableSelect from 'react-select/creatable';

const initialValues = {
    app_notification: "",
    email_notification: "",
    my_bulletin_info: "",
    exclulde_bulletin: "",
    disable_comment: "",
    is_featured: ""
};

const BulletinStepPublish = (props) => {
    const { selectType, handleNext, onHide } = props;
    const [formInitialValues, setFormInitialValues] = useState(initialValues);
    const { bulletin } = useSelector((state) => state.bulletin);
    const [appNotification, setAppNotification] = useState(false);
    const [emailNotification, setEmailNotification] = useState(false);
    const [isFeature, setIsFeatured] = useState(false);
    const [excludeBulletin, setExcludeBulletin] = useState(false);
    const [disableComment, setDisableComment] = useState(false);
    const [addBeacon, setAddBeacon] = useState(false);
    const [myBulletinInfo, setMyBulletinInfo] = useState(false);
    const [beaconSpace, setBeaconSpace] = useState(false);
    const [beacons, setbeacons] = useState([]);


    const handleClose = () => {
        if (onHide) {
            onHide();
        }
    };
    const getBeaconList = async () => {
        console.log("test");

        dispatch(eventsAction.getBeacons())
            .then((res) => {
                console.log(res, "beaons");
                res.forEach((element) => {
                    setbeacons((prev) => [
                        ...prev,
                        {
                            value: element.id,
                            label: element.title,
                        },
                    ]);
                });
                //  setLoading(false);
            })
            .catch((err) => {
                throw err;
            });

    };

    const handleChangeBeacon = (id) => {

        setBeaconSpace(id.value);
    };

    const dispatch = useDispatch();
    console.log(bulletin, "eventlform3");
    var format = "hh:mm:ss";


    const validations = Yup.object().shape({
    });

    const [loading, setLoading] = useState(true);


    return (
        <div className="d-flex justify-content-center event-step2-main w-100">
            <Formik
                initialValues={{
                    app_notification: bulletin.stepPublish.app_notification || false,
                    email_notification: bulletin.stepPublish.email_notification || false,
                    my_bulletin_info: bulletin.stepPublish.my_bulletin_info || false,
                    disable_comment: bulletin.stepPublish.disable_comment || false,
                    is_featured: bulletin.stepPublish.is_featured || false,
                    exclulde_bulletin: bulletin.stepPublish.exclulde_bulletin || false

                }}

                onSubmit={(values, { setSubmitting }) => {

                    setLoading(true);

                    if (loading) {
                        const formData = new FormData();
                        formData.append("title", bulletin?.stepOne?.title);
                        formData.append("content", bulletin?.stepOne?.content);
                        if (bulletin?.stepOne?.image) {
                            formData.append("image", bulletin?.stepOne?.image?.file, bulletin?.stepOne?.image?.path);
                        }
                        if (bulletin?.stepOne?.cover_image) {
                            formData.append("cover_image", bulletin?.stepOne?.cover_image?.file, bulletin?.stepOne?.cover_image?.path);
                        }
                        if (bulletin?.stepOne?.document) {
                            formData.append("document", bulletin?.stepOne?.document?.file, bulletin?.stepOne?.document?.path);
                        }

                        formData.append("category_id", bulletin?.stepOne?.category_id);
                        formData.append("is_visiiable", bulletin?.stepTwo?.is_visiiable);
                        formData.append("community_id", bulletin?.stepTwo?.community_id);
                        formData.append("floor_id", bulletin?.stepTwo?.floor_id);
                        formData.append("include_exclude_users", bulletin?.stepTwo?.include_exclude_users);
                        formData.append("start_date", bulletin?.stepThree?.start_date);
                        formData.append("start_time", bulletin?.stepThree?.start_time);
                        formData.append("end_date", bulletin?.stepThree?.end_date);
                        formData.append("end_time", bulletin?.stepThree?.end_time);
                        formData.append("app_notification", appNotification);
                        formData.append("email_notification", emailNotification);
                        formData.append("my_bulletin_info", myBulletinInfo);
                        formData.append("exclulde_bulletin", excludeBulletin);
                        formData.append("disable_comment", disableComment);
                        formData.append("is_featured", isFeature);
                        formData.append("beacon_space_id", beaconSpace);




                        console.log(formData, "submit1")

                        dispatch(createBulletin(formData)).then((res) => {
                            handleClose();
                            window.location.reload();
                        });
                    }

                }}
            >
                {({
                    values,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                    errors,
                    touched,
                    handleBlur,
                }) => (
                    <Form className="w-100">
                        <div className="row" style={{ minHeight: "600px" }}>
                            <div className="col-lg-12 event-select-time rounded p-5">
                                <span className="font-weight-bolder d-flex justify-content-start ">
                                    4.Distribution
                                </span>
                                <div className="teams-main-class">
                                    <div className="d-flex justify-content-between  mt-5">
                                        <span className="mt-1 link-generate">
                                            App Notification
                                        </span>

                                        <div className="button-cursor custom-control custom-switch align-self-sm-center">
                                            <input
                                                name={`app_notification`}
                                                type="checkbox"
                                                id={"app_notification"}
                                                className="custom-control-input"
                                                checked={appNotification}
                                                onChange={() => {
                                                    setAppNotification(!appNotification)
                                                }}
                                            //onChange={setAppNotification(!appNotification)}
                                            />
                                            <label
                                                title=""
                                                htmlFor={"app_notification"}
                                                className="custom-control-label"
                                            ></label>
                                        </div>
                                    </div>
                                </div>

                                <div className="teams-main-class">
                                    <div className="d-flex justify-content-between  mt-5">
                                        <span className="mt-1 link-generate">
                                            Email Notification
                                        </span>

                                        <div className="button-cursor custom-control custom-switch align-self-sm-center">
                                            <input
                                                name={`email_notification`}
                                                type="checkbox"
                                                id={"email_notification"}
                                                className="custom-control-input"
                                                checked={emailNotification}
                                                onChange={() => {
                                                    setEmailNotification(!emailNotification)
                                                }}
                                            //onChange={setEmailNotification(emailNotification)}
                                            />
                                            <label
                                                title=""
                                                htmlFor={"email_notification"}
                                                className="custom-control-label"
                                            ></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="teams-main-class">
                                    <div className="d-flex justify-content-between  mt-5">
                                        <span className="mt-1 link-generate">
                                            Add To My Building Info
                                        </span>

                                        <div className="button-cursor custom-control custom-switch align-self-sm-center">
                                            <input
                                                name={`my_bulletin_info`}
                                                type="checkbox"
                                                id={"my_bulletin_info"}
                                                className="custom-control-input"
                                                checked={myBulletinInfo}
                                                onChange={() => {
                                                    setMyBulletinInfo(!myBulletinInfo)
                                                }}
                                            //onChange={setEmailNotification(emailNotification)}
                                            />
                                            <label
                                                title=""
                                                htmlFor={"my_bulletin_info"}
                                                className="custom-control-label"
                                            ></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="teams-main-class">
                                    <div className="d-flex justify-content-between  mt-5">
                                        <span className="mt-1 link-generate">
                                            Exclude From Bulletin Section
                                        </span>

                                        <div className="button-cursor custom-control custom-switch align-self-sm-center">
                                            <input
                                                name={`exclude_bulletin`}
                                                type="checkbox"
                                                id={"exclude_bulletin"}
                                                className="custom-control-input"
                                                checked={excludeBulletin}
                                                onChange={() => {
                                                    setExcludeBulletin(!excludeBulletin)
                                                }}
                                            //onChange={setEmailNotification(emailNotification)}
                                            />
                                            <label
                                                title=""
                                                htmlFor={"exclude_bulletin"}
                                                className="custom-control-label"
                                            ></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="teams-main-class">
                                    <div className="d-flex justify-content-between  mt-5">
                                        <span className="mt-1 link-generate">
                                            Add To Feature
                                        </span>

                                        <div className="button-cursor custom-control custom-switch align-self-sm-center">
                                            <input
                                                name={`is_feature`}
                                                type="checkbox"
                                                id={"is_feature"}
                                                className="custom-control-input"
                                                checked={isFeature}
                                                onChange={() => {
                                                    setIsFeatured(!isFeature)
                                                }}
                                            //onChange={setEmailNotification(emailNotification)}
                                            />
                                            <label
                                                title=""
                                                htmlFor={"is_feature"}
                                                className="custom-control-label"
                                            ></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="teams-main-class">
                                    <div className="d-flex justify-content-between  mt-5">
                                        <span className="mt-1 link-generate">
                                            Disable Comment
                                        </span>

                                        <div className="button-cursor custom-control custom-switch align-self-sm-center">
                                            <input
                                                name={`disable_comment`}
                                                type="checkbox"
                                                id={"disable_comment"}
                                                className="custom-control-input"
                                                checked={disableComment}
                                                onChange={() => {
                                                    setDisableComment(!disableComment)
                                                }}
                                            //onChange={setEmailNotification(emailNotification)}
                                            />
                                            <label
                                                title=""
                                                htmlFor={"disable_comment"}
                                                className="custom-control-label"
                                            ></label>
                                        </div>
                                    </div>
                                </div>

                                <div className="teams-main-class">
                                    <div className="d-flex justify-content-between  mt-5">
                                        <span className="mt-1 link-generate">
                                            Add To Beacon
                                        </span>

                                        <div className="button-cursor custom-control custom-switch align-self-sm-center">
                                            <input
                                                name={`add_beacon`}
                                                type="checkbox"
                                                id={"add_beacon"}
                                                className="custom-control-input"
                                                checked={addBeacon}
                                                onChange={() => {
                                                    setAddBeacon(!addBeacon)
                                                    getBeaconList()
                                                }}
                                            //onChange={setEmailNotification(emailNotification)}
                                            />
                                            <label
                                                title=""
                                                htmlFor={"add_beacon"}
                                                className="custom-control-label"
                                            ></label>
                                        </div>
                                    </div>
                                </div>
                                {addBeacon
                                    ?
                                    <div className="mt-5">
                                        <CreatableSelect
                                            placeholder="Select Beacon"
                                            isClearable
                                            name="beacon"
                                            storeEntityName="beacon"
                                            onChange={e => { handleChangeBeacon(e); }}
                                            options={beacons}
                                        />
                                    </div>
                                    :
                                    <></>}

                            </div>

                            <div className="col-lg-12 px-5">
                                <button
                                    id="kt_login_signin_submit"
                                    type="submit"
                                    className={`btn btn-primary d-flex justify-content-between align-items-center font-weight-bolder mt-5 w-100`}

                                    disabled={isSubmitting}
                                >
                                    <span />
                                    <span>Publish</span>
                                    <span>
                                        <SVG
                                            src={toAbsoluteUrl(
                                                "/media/svg/icons/akar-icons_arrow-right.svg"
                                            )}
                                        />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div >
    );
};

export default BulletinStepPublish;
