import React, { useState } from "react"
import { Form, FormCheck } from "react-bootstrap"
import Header from "./Header"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"
import { Formik, Field } from "formik"
import { Input } from "../../../../_metronic/_partials/controls"
import * as Yup from "yup"
import * as receptionistAction from "./_redux/Actions"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"

const frmInitialValues = {
  reg: "",
  make: "",
  model: "",
  color: "",
  ev: false,
  is_bike: false,
}
export default function VisitorSignInVehicleDetails(props) {
  const dispatch = useDispatch()
  const receptionistStore = useSelector((state) => state.visitorSignIn)

  useEffect(() => {
    if (receptionistStore.newAdded && receptionistStore.lastError == null) {
      handleAddNewVehicle()
    }
  }, [receptionistStore])

  const { bookingData, handleAddNewVehicle, setCurrentStep } = props
  const [visitorVehicleDetails, setVisitorVehicleDetails] = useState(frmInitialValues)

  const stringValidateRegex = /^([a-zA-Z0-9]+|[a-zA-Z0-9]+\s{1}[a-zA-Z0-9]{1,}|[a-zA-Z0-9]+\s{1}[a-zA-Z0-9]{3,}\s{1}[a-zA-Z0-9]{1,})/

  // Validation schem
  const LevelsEditSchema = Yup.object().shape({
    reg: Yup.string()
      .matches(stringValidateRegex, "Vehicle reg is not valid.")
      .required("Vehicle reg is required."),
    make: Yup.string()
      .matches(stringValidateRegex, "Vehicle make is not valid.")
      .required("Vehicle make is required."),
    model: Yup.string()
      .matches(stringValidateRegex, "Vehicle Model is not valid.")
      .required("Vehicle Model is required."),
    color: Yup.string()
      .matches(stringValidateRegex, "Vehicle color is not valid.")
      .required("Vehicle color is required."),
  })
  return (
    <>
      <div className="text-vehicle-details-visitor">
        <h4 className="vehicle-details-body-text">
          Your host <b>{bookingData?.host[0]?.full_name}</b> will be with you shortly
        </h4>
        <h4 className="vehicle-details-body-text">
          We need to collect a few details about your vehicle
        </h4>
      </div>
      <Formik
        enableReinitialize={true}
        initialValues={visitorVehicleDetails}
        validationSchema={LevelsEditSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false)
          const visitor_sign_in_vehicle_details = {
            reg: values.reg,
            make: values.make,
            color: values.color,
            ev: values.ev,
            is_bike: values.is_bike,
            model: values.model,
            user_id: bookingData?.guests[0]?.id,
          }
          dispatch(receptionistAction.addNewVehicle(visitor_sign_in_vehicle_details))
        }}
      >
        {({ values, handleChange, handleSubmit, setFieldValue }) => (
          <Form className="form form-label-right" onSubmit={handleSubmit}>
            <div className="visitor-vehicle-details-form-div d-flex">
              <div className="visitor-vehicle-field-div w-50">
                <Field
                  component={Input}
                  name="reg"
                  type="text"
                  placeholder="Vehicl Reg"
                  value={values.reg}
                  className="visitor-signin-vehicle-field"
                />
                <Field
                  component={Input}
                  name="make"
                  type="text"
                  placeholder="Make"
                  value={values.make}
                  className="visitor-signin-vehicle-field"
                />
                <Field
                  component={Input}
                  name="model"
                  type="text"
                  placeholder="Model"
                  value={values.model}
                  className="visitor-signin-vehicle-field"
                />
                <Field
                  component={Input}
                  name="color"
                  type="text"
                  placeholder="Colour"
                  value={values.color}
                  onChange={handleChange}
                  className="visitor-signin-vehicle-field"
                />
              </div>
              <div className="w-50">
                <div className="visitor-vehicle-checkbox-div">
                  <p className="pb-0">Electric Vehicle?</p>
                  <FormCheck
                    className="button-cursor"
                    type="switch"
                    name="ev"
                    id="ev"
                    label=""
                    // checked={values.is_bike}
                    onChange={() => setFieldValue("ev", !values.ev)}
                  />
                </div>
                <div className="visitor-vehicle-checkbox-div">
                  <p className="pb-0">Motorbike?</p>
                  <FormCheck
                    className="button-cursor"
                    type="switch"
                    name="is_bike"
                    id="is_bike"
                    label=""
                    // checked={values.is_bike}
                    onChange={() => setFieldValue("is_bike", !values.is_bike)}
                  />
                </div>
              </div>
            </div>

            <div className="rounded-circle mt-5 d-flex justify-content-center">
              <button
                className="visitor-signin-btn vehicle-details-visitor-btn text-light back-button mr-3"
                type="button"
                onClick={() => {
                  setCurrentStep("select_vehicle")
                }}
              >
                Back{" "}
              </button>
              <button
                className="visitor-signin-btn vehicle-details-visitor-btn text-light"
                type="submit"
                // onClick={handleAddNewVehicle}
              >
                VISITOR SIGN IN{" "}
                <SVG src={toAbsoluteUrl("media/svg/icons/akar-icons_arrow-right.svg")} />
              </button>
            </div>
            <div className="rounded-circle mt-5 d-flex justify-content-center">
              <button
                className="visitor-signin-btn vehicle-details-visitor-btn text-light back-button"
                type="button"
                onClick={props?.handlePopUpClose}
              >
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}
