import React, { useState } from "react";
import SVG from "react-inlinesvg";
//components
import EventForm from "../../../../../app/modules/Booking/Event/EventForm";
// constanst & helpers
import { toAbsoluteUrl } from "../../../../_helpers";
//css
import "./AddNewEvent.scss";

const modalInitialValues = {
  name: "addEvent",
  title: "Book Event",
};
export const AddNewEvent = (props) => {
  const [show, setShow] = useState(false);

  const [modalProps, setModalProps] = useState(modalInitialValues);
  const [id, setId] = useState();

  const handleClose = () => {
    setShow(false);
    setId(undefined);
  };
  const handleShow = () => {
    setShow(true);
    props.handleParentClose();
  };

  return (
    <>
      <div
        className="button-cursor pb-5 event-main"
        onClick={() => handleShow()}
      >
        <div className="d-inline-flex pl-5">
          <span className="svg-icon h-100">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Booking/event.svg")}
            ></SVG>
          </span>
        </div>
        <div className="d-inline-flex pl-5 pt-3">
          <h6>BOOK EVENT</h6>
        </div>
      </div>
      <EventForm show={show} onHide={handleClose} modalProps={modalProps} />
    </>
  );
};
