import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Select2 } from "select2-react-component";
import axios from "axios";
import "./AddHost.scss";
import { useSelector } from "react-redux";

const AddHost = ({ show, handleClose, users, setHostDetails }) => {
  const [selectUser, setSelectUser] = useState("");
  const { events } = useSelector((state) => state.events);
  const [daypass, setDaypass] = useState(false);
  const [carpark, setCarpark] = useState(false);

  const handleSelectHost = () => {
    if (selectUser !== "") {
      const filtered = users.filter(
        (one) => parseInt(one.value, 10) === parseInt(selectUser, 10)
      );
      setHostDetails({
        user_id: filtered[0].value,
        full_name: filtered[0].label,
        email: filtered[0].email,
        day_pass: daypass,
        car_park: carpark,
        url_image: filtered[0].url_image,
      });
      handleClose();
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="modal add-invitee-main"
      backdrop="static"
    >
      <Modal.Header className="d-flex justify-content-between" closeButton>
        <Modal.Title>
          <span>ADD HOST</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-host-main">
        <Select2
          placeholder="Select Host"
          data={users}
          value={selectUser}
          update={(value) => {
            setSelectUser(value);
          }}
          className="mr-3 mt-4"
        ></Select2>
        {events?.stepOne?.event_type === "internal" && (
          <>
            <div className="d-flex justify-content-between border-class mt-2">
              <span className="mt-1 link-generate">Add Day Pass</span>
              <div className="button-cursor custom-control custom-switch align-self-sm-center">
                <input
                  name={`add_daypass`}
                  type="checkbox"
                  id={"add_daypass"}
                  className="custom-control-input"
                  checked={daypass}
                  onChange={() => {
                    setDaypass(!daypass);
                  }}
                />
                <label
                  title=""
                  htmlFor={"add_daypass"}
                  className="custom-control-label"
                ></label>
              </div>
            </div>
            <div className="d-flex justify-content-between border-class mt-2">
              <span className="mt-1 link-generate">
                Add Vehicle Parking Space?
              </span>
              <div className="button-cursor custom-control custom-switch align-self-sm-center">
                <input
                  name={`add_vehicle`}
                  type="checkbox"
                  id={"add_vehicle"}
                  className="custom-control-input"
                  checked={carpark}
                  onChange={() => {
                    setCarpark(!carpark);
                  }}
                />
                <label
                  title=""
                  htmlFor={"add_vehicle"}
                  className="custom-control-label"
                ></label>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {" "}
        <Button
          className="save-button"
          onClick={() => {
            handleSelectHost();
          }}
        >
          DONE
        </Button>
        <Button className="cancle-button" onClick={handleClose}>
          CANCEL
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddHost;
