import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Field } from "formik";
import SiteSelectInput from "./SiteSelectInput";
import FloorSelectInput from "./FloorSelectInput";
import ModalImage from "react-modal-image";

const SiteFloorSelectInput = (props) => {
  const [editMode, setEditMode] = useState(null);
  const [selectedSiteId, setSelectedSiteId] = useState(null);

  const handleEditMode = () => {
    setEditMode(true);
  };
  const searchDataStore = useSelector((state) => state.searchData);
  const { selectedSite, selectedFloor } = searchDataStore;

  useEffect(() => {
    if (searchDataStore?.selectedSite) {
      setSelectedSiteId(searchDataStore?.selectedSite?.id);
    }
  }, [searchDataStore]);

  const handleEditModeClose = () => {
    setEditMode(false);
  };
  const handleOnFloorChange = () => {
    setEditMode(false);
  };

  const headerSection = () => {
    // props.setSelectedDate({
    //   site: selectedSite?.title,
    //   floor: selectedFloor?.title,
    // });
    return (
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          {!selectedSite || searchDataStore?.siteId == null ? (
            <span className="ml-1 font-weight-bold selected-site-label">
              Select the site and floor
            </span>
          ) : (
            ""
          )}
          {/* <img
              src={selectedSite?.url_image}
              alt="display"
              className="display-image-height"
            /> */}
          {selectedSite?.url_image && searchDataStore?.siteId != null ? (
            <ModalImage
              small={selectedSite.url_image}
              large={selectedSite.url_image}
              className="display-image-height"
              alt="display"
            />
          ) : (
            ""
          )}
          {selectedSite && searchDataStore?.siteId != null ? (
            <span className="ml-1 font-weight-bold selected-site-label">
              {selectedSite?.title},&nbsp; {selectedFloor?.title}
            </span>
          ) : (
            ""
          )}
        </div>
        {/* {props.work_station && (
          <div className="d-flex">
            <img
              src="/media/svg/icons/workstation.svg"
              alt="display"
              className="mr-3"
            />
            {props.work_station} available
          </div>
        )} */}
        <div className="button-cursor edit-icon" onClick={handleEditMode}>
          <img src="/media/svg/icons/Booking/edit-sign.svg" alt="display" />
        </div>
      </div>
    );
  };
  const editForm = () => {
    return (
      <div id="edit-site-floor-section" className="p5">
        <div className="">
          <Field
            name="site"
            component={SiteSelectInput}
            placeholder="Select Site"
            source={`sites`}
            className={"form-control"}
            handleEdit={setEditMode}
          />
        </div>
        <div className="">
          <Field
            name="floor"
            component={FloorSelectInput}
            placeholder="Select Floor"
            source={`floors?site_id=${selectedSiteId}`}
            disabled={selectedSite ? "" : "disabled"}
            handleOnChange={(value) => {
              handleOnFloorChange(value);
            }}
            storeEntityName="floors"
          />
        </div>
        <div></div>
        <div
          className="footer-button-section d-flex justify-content-end button-cursor"
          onClick={handleEditModeClose}
        >
          <span className="text-uppercase cancel-button">cancel</span>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="primary-background">
        {!editMode && headerSection()}
        {editMode && editForm()
        // searchDataStore?.resetEditMode ?
        // : ""
        }
      </div>
    </>
  );
};

export default SiteFloorSelectInput;
