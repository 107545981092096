import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import { Link, useParams } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import ReactGA from "react-ga";
import axios from "axios";
import { values } from "lodash";
const TRACKING_ID = "UA-233080853-1"; // OUR_TRACKING_ID

ReactGA.initialize(TRACKING_ID);

ReactGA.initialize(TRACKING_ID);
// ?user_id=157&action=login&timestamp=1667215992&signature=b600f24ffec54cce87c3be069029a46fe34cadcee1d036e41de46104e93ae282
function Login(props) {
  const [email, setEmail] = useState("");
  // const initialValues = {
  //   username: "",
  //   password: "",
  //   // username: "vivien.vandervort@example.net",
  //   // password: "12345678",
  //   email: email || "",
  // };
  const { intl } = props;
  const [form, setForm] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const params2 = new URLSearchParams(search);
  const token = params.get("token");
  const refreshToken = params2.get("refresh_token");

  const [apiStatus, setApiStatus] = useState(undefined);

  // console.log(apiStatus?.status, "Status");

  // console.log(refreshToken, "refresh token");

  // console.log(token, "token");

  useEffect(() => {
    // console.log(refreshToken, "refresh token");
    // console.log(token, "token");
  }, [token, refreshToken]);

  const approveUser = async (token) => {
    const API_URL = `${process.env.REACT_APP_API_URL}/api/user-approve?token=${token}`;

    const response = await axios.get(API_URL);
  };

  if (token) {
    localStorage.setItem("ms_teams_access_token", `${token}`);
    approveUser(token);
  }

  if (refreshToken) {
    localStorage.setItem("ms_teams_refresh_token", `${refreshToken}`);
  }

  // const { user_id, action, timestamp, signature } = useParams()
  // console.log(user_id, "user_id", useParams, props.location.search)
  let LoginSchema = null;
  if (submit == true) {
    LoginSchema = Yup.object().shape({
      username: Yup.string().required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_USERNAME",
        })
      ),
      password: Yup.string()
        .min(8, "Password should atleast contain 8 characters.")
        .max(50, "Password should not be more than 50 characters.")
        .required(
          intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_PASSWORD",
          })
        ),
      email: Yup.string()
        .email("Please enter a vaild email address.")
        .max(50, "Maximum 50 symbols")
        .required(
          intl.formatMessage(
            {
              id: "AUTH.VALIDATION.REQUIRED_FIELD",
            },
            { name: "Email" }
          )
        ),
    });
  }

  if (apiStatus?.status === "sso") {
    window.open(
      `${
        process.env.REACT_APP_API_URL
      }/api/saml2/sso?action=login&email=${email}&mobile=${false}`
    );
    setApiStatus(undefined);
  }

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    if (props?.location?.search) {
      // props.location.search
      const params = {}; //   action: action,
      //   signature: signature,
      //   timestamp: timestamp,
      //   user_id: user_id,
      const urlParams = new URLSearchParams(props?.location?.search);
      for (let pair of urlParams.entries()) {
        params[pair[0]] = pair[1];
      }
      login(params)
        .then(({ data }) => {
          disableLoading();
          setErrorMsg(false);
          props.login(data);
        })
        .catch((error) => {
          // console.log("error.response", error.response);
          setErrorMsg(
            (error.response?.data &&
              error.response?.data?.errors &&
              error.response?.data?.errors[0]?.msg) ||
              error.response?.data?.error ||
              "Invalid Login Details! Kindly contact to Administrator"
          );
        })
        .finally(() => {
          disableLoading();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.location?.search]);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const signInSSO = async () => {
    window.open(`${process.env.REACT_APP_API_URL}/api/saml2/sso?action=login`);
    // window.open("", "", "width=200,height=100");
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues: { email: email || "", password: "" },
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setEmail(values?.email);
      if (apiStatus === undefined) {
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/api/saml2/check-email?email=${values?.email}`
          )
          .then((res) => setApiStatus(res?.data));
      } else {
        setTimeout(() => {
          const params = {
            email: email,
            password: values.password,
          };
          login(params)
            .then(({ data }) => {
              disableLoading();
              setErrorMsg(false);
              props.login(data);
            })
            .catch((error) => {
              // console.log("error.response", error.response);
              setErrorMsg(
                (error.response?.data &&
                  error.response?.data?.errors &&
                  error.response?.data?.errors[0]?.msg) ||
                  error.response?.data?.error ||
                  "Invalid Login Details! Kindly contact to Administrator"
              );
              setStatus(
                intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_LOGIN",
                })
              );
            })
            .finally(() => {
              disableLoading();
              setSubmitting(false);
            });
        }, 1000);
      }
      // if (submit == true) {
      //   enableLoading();
      //   setErrorMsg(false);
      //   setTimeout(() => {
      //     const params = {
      //       email: values.username,
      //       password: values.password,
      //     };
      //     login(params)
      //       .then(({ data }) => {
      //         disableLoading();
      //         setErrorMsg(false);
      //         props.login(data);
      //       })
      //       .catch((error) => {
      //         console.log("error.response", error.response);
      //         setErrorMsg(
      //           (error.response?.data &&
      //             error.response?.data?.errors &&
      //             error.response?.data?.errors[0]?.msg) ||
      //             error.response?.data?.error ||
      //             "Invalid Login Details! Kindly contact to Administrator"
      //         );
      //         setStatus(
      //           intl.formatMessage({
      //             id: "AUTH.VALIDATION.INVALID_LOGIN",
      //           })
      //         );
      //       })
      //       .finally(() => {
      //         disableLoading();
      //         setSubmitting(false);
      //       });
      //   }, 1000);
      // } else {
      //   formik.resetForm();
      // }
    },
  });

  const openForm = () => {
    setForm(true);
    formik.resetForm();
  };

  const submitForm = () => {
    // axios.get(
    //   `https://app.bookify.space/api/saml2/check-email?email=${}`
    // );
  };

  return (
    <div className="login-form" id="kt_login_signin_form">
      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
        id="kt_login_singin_form"
      >
        {/* begin::Head */}
        <div className="pb-5">
          <h3 className="font-weight-bolder text-center text-dark font-size-h2 font-size-h1-lg">
            <FormattedMessage id="AUTH.LOGIN.TITLE" />
          </h3>
        </div>
        {/* end::Head */}

        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{errorMsg}</div>
          </div>
        ) : null}

        <div className="support-login">
          <div className="form-group">
            <input
              placeholder="Email Address"
              type="text"
              className={`form-control  h-auto px-6 rounded-pill ${getInputClasses(
                "email"
              )}`}
              name="email"
              {...formik.getFieldProps("email")}
              disabled={apiStatus?.status}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.email}</div>
              </div>
            ) : null}
          </div>
          {/* normal login */}
          {apiStatus?.status === "normal" && (
            <div className="form-group mb-0">
              <input
                placeholder="Password"
                type="password"
                className={`form-control  h-auto px-6 rounded-pill ${getInputClasses(
                  "password"
                )}`}
                name="password"
                {...formik.getFieldProps("password")}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.password}</div>
                </div>
              ) : null}
            </div>
          )}
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={!formik?.values?.email}
            // onClick={() => submitForm()}
            className={`btn btn-primary d-flex justify-content-center align-items-center font-weight-bolder my-3 w-100`}
          >
            <span />
            <span>
              {/* <FormattedMessage id="AUTH.LOGIN.BUTTON" /> */}
              LOGIN
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </span>
            {/* <span>
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/akar-icons_arrow-right.svg"
                )}
              />
            </span> */}
          </button>
          {apiStatus?.status === "normal" && (
            <div className="form-group d-flex">
              <Link
                to="/auth/forgot-password"
                className="d-flex justify-content-end my-3 btn-link w-100"
                id="kt_login_forgot"
              >
                <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
              </Link>
            </div>
          )}
        </div>

        {/* {form ? (
          <div className="support-login">
            <div className="form-group">
              <input
                placeholder="Username"
                type="text"
                className={`form-control  h-auto px-6 rounded-pill ${getInputClasses(
                  "username"
                )}`}
                name="username"
                {...formik.getFieldProps("username")}
              />
              {formik.touched.username && formik.errors.username ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.username}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group mb-0">
              <input
                placeholder="Password"
                type="password"
                className={`form-control  h-auto px-6 rounded-pill ${getInputClasses(
                  "password"
                )}`}
                name="password"
                {...formik.getFieldProps("password")}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.password}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex">
              <Link
                to="/auth/forgot-password"
                className="d-flex justify-content-end my-3 btn-link w-100"
                id="kt_login_forgot"
              >
                <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
              </Link>
            </div>
          </div>
        ) : (
          <div></div>
        )} */}
        {/* <div className="form-group d-flex flex-wrap position-relative justify-content-between align-items-center">
          {form == true ? (
            <div></div>
          ) : (
            <button
              type="button"
              onClick={() => signInSSO()}
              className={`btn  btn-primary  font-weight-bolder my-3 px-8 w-100`}
            >
              <span className={`m-r-25`}>
                <FormattedMessage id="AUTH.OFICE_LOGIN.BUTTON" />
              </span>
            </button>
          )}
          {form == true ? (
            submit == true ? (
              <button
                id="kt_login_signin_submit"
                type="submit"
                disabled={formik.isSubmitting}
                onClick={() => submitForm()}
                className={`btn btn-primary d-flex justify-content-between align-items-center font-weight-bolder my-3 w-100`}
              >
                <span />
                <span>
                  <FormattedMessage id="AUTH.LOGIN.BUTTON" />
                  {loading && (
                    <span className="ml-3 spinner spinner-white"></span>
                  )}
                </span>
                <span>
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/akar-icons_arrow-right.svg"
                    )}
                  />
                </span>
              </button>
            ) : (
              <button
                id="kt_login_signin_submit"
                type="submit"
                onClick={() => submitForm()}
                className={`btn  d-flex justify-content-between align-items-center font-weight-bolder my-3 w-100`}
              >
                <span />
                <span>
                  <FormattedMessage id="AUTH.LOGIN.BUTTON" />
                  {loading && (
                    <span className="ml-3 spinner spinner-white"></span>
                  )}
                </span>
                <span>
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/akar-icons_arrow-right.svg"
                    )}
                  />
                </span>
              </button>
            )
          ) : (
            <button
              id="kt_login_signin_submit"
              disabled={formik.isSubmitting}
              onClick={() => openForm()}
              className={`btn  d-flex justify-content-between align-items-center font-weight-bolder my-3 w-100`}
            >
              <span />
              <span>
                <FormattedMessage id="AUTH.LOGIN.BUTTON" />
                {loading && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </span>
              <span>
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/akar-icons_arrow-right.svg"
                  )}
                />
              </span>
            </button>
          )}
        </div> */}
        {/* <div className="d-flex flex-wrap w-100 justify-content-center">
          <span className="d-flex text-gray">Don't have an account?</span>
          <Link to="/auth/registration" className="btn-link d-flex ml-1">
            <FormattedMessage id="AUTH.GENERAL.SIGNUP" />.
          </Link>
        </div> */}
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
