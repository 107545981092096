import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  siteForEdit: {},
  floorForEdit: {},
  lastError: null,
  refreshList: false,
  getSiteSettingDetails: [],
  getGlobalSettingDetails: [],
  getFloorDetails: [],
  getSiteListing: [],
}
export const callTypes = {
  list: "list",
  action: "action",
}
export const Slice = createSlice({
  name: "users",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
    },
    startCall: (state, action) => {
      state.error = null
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    // singleItemFetched
    singleItemFetched: (state, action) => {
      state.actionsLoading = false
      state.siteForEdit = action.payload.siteForEdit
      state.error = null
    },
    singleFloorItemFetched: (state, action) => {
      state.actionsLoading = false
      state.floorForEdit = action.payload.floorForEdit
      state.error = null
    },
    // listFetched
    listFetched: (state, action) => {
      const { totalCount, entities } = action.payload
      state.listLoading = false
      state.error = null
      state.entities = entities
      state.totalCount = totalCount
      state.refreshList = false
    },
    // itemCreated
    itemCreated: (state, action) => {
      state.actionsLoading = false
      state.error = null
      state.entities = []
      state.refreshList = true
    },
    // itemCreated
    floorItemCreated: (state, action) => {
      const { item } = action.payload
      const entities = state.entities
      state.entities = entities.map((site) => {
        if (site.id === item.site_id) {
          site.floor_count = parseInt(site.floor_count) + 1
          site.floor.push(item)
        }
        return site
      })
      state.actionsLoading = false
      state.error = null
    },
    // itemUpdated
    itemUpdated: (state, action) => {
      state.error = null
      state.actionsLoading = false
      state.entities = []
      state.refreshList = true
    },
    // itemDeleted
    itemDeleted: (state, action) => {
      state.error = null
      state.actionsLoading = false
      state.entities = state.entities.filter((el) => el.id !== action.payload.id)
    },
    userDeleted: (state, action) => {
      state.error = null
      state.actionsLoading = false
      // state.entities = state.entities.filter((el) => el.id !== action.payload.id)
    },
    // itemsDeleted
    itemsDeleted: (state, action) => {
      state.error = null
      state.actionsLoading = false
      state.entities = state.entities.filter((el) => !action.payload.ids.includes(el.id))
    },
    // itemsDeleted
    floorItemDeleted: (state, action) => {
      state.error = null
      state.actionsLoading = false
      state.entities = state.entities.filter((el) => !action.payload.ids.includes(el.id))
    },
    //site settings details
    fetchSiteSettingDetails: (state, action) => {
      state.getSiteSettingDetails = action?.payload
    },
    fetchUpdateSiteSetting: (state, action) => {
      state.getSiteSettingDetails = action?.payload
    },
    fetchSiteFromAccount: (state, action) => {
      state.getSiteListing = action?.payload
    },

    fetchGlobalSettingDetails: (state, action) => {
      state.getGlobalSettingDetails = action?.payload
    },
    fetchUpdateGlobalSettingDetails: (state, action) => {
      state.getGlobalSettingDetails = action?.payload
    },
    fetchFloorSettingDetails: (state, action) => {
      state.getFloorDetails = action?.payload
    },
    fetchUpdateFloorSettingDetails: (state, action) => {
      state.getFloorDetails = action?.payload
    },
  },
})
