import * as requestFromServer from "./Crud"
import { Slice, callTypes } from "./Slice"
import { NotificationManager } from "react-notifications"

const { actions } = Slice

export const createItem = (item) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }))
  return requestFromServer
    .createItem(item)
    .then((response) => {
      // console.log("resp", response)
      const data = response.data
      NotificationManager.success("Data saved successfully!", "success")
      dispatch(actions.itemCreated({ item: data }))
      return true
    })
    .catch((error) => {
      const errMsg =
        error.response && error.response?.data && error.response.data.message
          ? error.response.data.message
          : error.message
      NotificationManager.error(errMsg, "Error")
      dispatch(actions.catchError({ error, callType: callTypes.action }))
    })
}

export const updateItem = (item) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }))
  return requestFromServer
    .updateItem(item)
    .then(() => {
      NotificationManager.success("Vehicle Details Updated successfully!", "success")
      dispatch(actions.itemUpdated({ item }))
      return true
    })
    .catch((error) => {
      // error.clientMessage = "Can't update item";
      console.log(error.response.data?.errors)
      const errMsg =
        error?.response &&
          error.response?.data &&
          error.response.data?.errors &&
          error.response.data.errors[0]?.msg
          ? error.response.data.errors[0].msg
          : error?.message
      NotificationManager.error(errMsg, "Error")
      dispatch(actions.catchError({ errMsg, callType: callTypes.action }))
      return false
    })
}

export const deleteItem = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }))
  return requestFromServer
    .deleteItem(id)
    .then((response) => {
      dispatch(actions.itemDeleted({ id }))
      NotificationManager.success("Vehicle deleted successfully!", "success")
    })
    .catch((error) => {
      const errMsg =
        error.response && error.response.data && error.response.data.errors
          ? error.response.data.errors[0].msg
          : error.message
      NotificationManager.error(errMsg, "Error")
      dispatch(actions.catchError({ error, callType: callTypes.action }))
    })
}

export const clearError = (item) => (dispatch) => {
  dispatch(actions.clearError({}))
}

export const setCommonData = (item) => (dispatch) => {
  dispatch(actions.setCommonData(item))
}
