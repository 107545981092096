import axios from "axios"

export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/api/login`
export const REGISTER_URL = `${process.env.REACT_APP_API_URL}/api/accept`
export const REQUEST_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/api/forgot-password`
export const RESET_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/api/reset-password`
export const ME_URL = `${process.env.REACT_APP_API_URL}/auth/me`

export function login(params) {
  return axios.post(LOGIN_URL, params)
}

export function register(params) {
  const headers = {
    "Content-Type": "multipart/form-data",
  }
  return axios.post(REGISTER_URL, params, {
    headers: headers,
  })
}
export function requestPassword(params, type) {
  if (type === "reset") {
    return axios.post(RESET_PASSWORD_URL, params)
  }
  if (type === "request") {
    return axios.post(REQUEST_PASSWORD_URL + `/${params.token}`, params)
  }
}

export function getUserByToken() {
  return JSON.parse(localStorage.getItem("userData"))
  // Authorization head should be fulfilled in interceptor.
  // return axios.get(ME_URL);
}
