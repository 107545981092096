import { createSlice } from "@reduxjs/toolkit"
import helperFunc from "../../../../_metronic/_helpers/helperFunc"

const userDetails = helperFunc.getUserData()
const initialStaffUsersState = {
  listLoading: false,
  actionsLoading: false,
  searchData: {},
  staffUserForEdit: {},
  entityData: {},
  lastError: null,
  siteId: null,
  floorId: null,
  selectedSite: null,
  selectedFloor: null,
  resetEditMode: true,
  selectedFriendCircleId: null,
  selectedFriendCircleDetails: null,
}
export const callTypes = {
  list: "list",
  action: "action",
}
export const Slice = createSlice({
  name: "searchList",
  initialState: initialStaffUsersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
    },
    startCall: (state, action) => {
      state.error = null
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    // search list
    searchListFetched: (state, action) => {
      const { entities, source } = action.payload
      state.listLoading = false
      state.error = null
      state.searchData[source] = entities
      if (action.payload?.storeEntityName) {
        state.entityData[`${action.payload?.storeEntityName}`] = entities
      }
    },
    getSite: (state, action) => {
      const { siteId } = action.payload
      state.siteId = siteId
      state.selectedSite = state?.searchData?.sites?.find((item) => {
        if (item.id === Number(siteId)) {
          return item
        }
      })
      if (state.siteId && typeof state.selectedSite === "undefined") {
        state.selectedSite = userDetails?.site
      }
    },
    getFloor: (state, action) => {
      const { floorId } = action.payload
      state.floorId = floorId
      state.selectedFloor = state?.entityData?.floors?.find((item) => {
        if (item.id === Number(floorId)) {
          return item
        }
      })
      if (state.floorId && typeof state.selectedFloor === "undefined") {
        state.selectedFloor = userDetails?.floor[0]
      }
    },
    resetSiteAndFloor: (state, action) => {
      state.siteId = null
      state.floorId = null
      state.selectedSite = null
      state.selectedFloor = null
      state.resetEditMode = false
    },
    resetFriendCircleData: (state, action) => {
      state.selectedFriendCircleId = null
      state.selectedFriendCircleDetails = null
      state.resetEditMode = false
    },
    getFriendCircleList: (state, action) => {
      const { entities, source } = action.payload
      state.listLoading = false
      state.error = null
      state.searchData[source] = entities
    },
    getSelectedFriendCircle: (state, action) => {
      const { payload } = action
      state.selectedFriendCircleId = payload
      state.selectedFriendCircleDetails = state?.searchData["friend-circle/lsiting"]?.find(
        (item) => {
          if (item.id === payload) {
            return item
          }
        }
      )
    },
  },
})
