import React, { useEffect, useState } from "react";
import axios from "axios";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { useParams } from "react-router-dom";

import "./MeetingRoom.scss";

const MeetingRoomFloorPlan = () => {
  const [workstationCoordinates, setWorkStationCoordinates] = useState([]);
  const [floorData, setFloorData] = useState([]);
  const [xyzCirc, setXyzCirc] = useState([]);
  const [loading, setLoading] = useState(false);

  const { floor_id, space_id } = useParams();

  const BASE_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    axios.get(`${BASE_URL}/api/floors/${floor_id}`).then((res) => {
      setFloorData(res.data);
      if (res.data) {
        axios
          .get(`${BASE_URL}/api/spaces/${space_id}`)
          .then((res) => setWorkStationCoordinates(res.data));
      }
    });
  }, []);

  useEffect(() => {
    if (workstationCoordinates?.poly_coordinates?.length > 0) {
      return workstationCoordinates?.poly_coordinates.forEach((element) => {
        const parsed = element;
        if (parsed.circles.length > 0 && parsed.circles !== null) {
          parsed.circles.forEach((onecircle) => {
            setXyzCirc((prev) => [
              ...prev,
              {
                x: onecircle.x,
                y: onecircle.y,
                type: onecircle.type,
              },
            ]);
          });
        }
      });
    }
  }, [workstationCoordinates]);

  useEffect(() => {
    if (xyzCirc?.length > 0) {
      setLoading(true);
    }
    if (xyzCirc.length > 0) {
      setTimeout(() => {
        const assignedCircls = xyzCirc;
        const allCircles = document.querySelectorAll("svg circle");
        if (assignedCircls && assignedCircls.length > 0) {
          allCircles.forEach((element) => {
            assignedCircls.forEach((circle) => {
              if (
                element.attributes.cx.nodeValue === circle.x &&
                element.attributes.cy.nodeValue === circle.y &&
                element.localName === circle.type
              ) {
                element.classList.add("selected");
                setLoading(false);
              }
            });
          });
        }
      }, 2000);
    }
  }, [xyzCirc]);

  useEffect(() => {
    if (window.location.pathname.includes("/meetingroomfloor")) {
      document.querySelector("#root").style.height = "auto";
      document.querySelector("body").style.height = "auto";
      document.querySelector("#splash-screen").style.display = "none";
    }
  }, []);
  return (
    <>
      <div className="main_svg_wrapper_meeting_floor">
        {/* <TransformWrapper >
          <TransformComponent> */}
        <SVG src={toAbsoluteUrl(floorData?.url_image)}></SVG>
        {/* </TransformComponent>
        </TransformWrapper> */}
      </div>
    </>
  );
};

export default MeetingRoomFloorPlan;
