import React, { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import axios from "axios";
import { API_URL } from "./_redux/Crud";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Select2 } from "select2-react-component";
import SVG from "react-inlinesvg";
import { useSelector, useDispatch } from "react-redux";
import { Form, Formik } from "formik";
import moment from "moment";
import { Image } from "react-bootstrap";
import {
    Button,
    FormControl,
    InputLabel,
    Menu,
    MenuItem,
    MenuList,
    Select,
    TextField,
    Typography,
} from "@material-ui/core";

import "./VehicalReg.scss";
import { useParams } from "react-router-dom";
import * as actions from "../../Vehicle/_redux/Action";

const VisitorReceptionSignInStep1 = (
    props
) => {

    const [workstationCoordinates, setWorkStationCoordinates] = useState([]);
    const [xyzCirc, setXyzCirc] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const BASE_URL = process.env.REACT_APP_API_URL;

    const [selectedItemsPath, setSelectedItemsPath] = useState([]);



    const [hostData, sethostData] = useState([]);
    const [thankyou, setThankyou] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [bookingData, setBookingData] = useState();
    const [parkingData, setParkingData] = useState([]);
    const [siteData, setSiteData] = useState([]);
    const [siteListOption, setsiteListOption] = useState([]);
    const [file, setFile] = useState(undefined);
    const [selectedFile, setSelectedFile] = useState(undefined);
    const dispatch = useDispatch();

    const { booking_id } = useParams();

    const handleSubmit = () => {
    }
    let history = useHistory();

    const getUserList = async () => {
        const API_URL = `${process.env.REACT_APP_API_URL}/api/user?site_id=9`;

        const response = await axios.get(API_URL);
        let dataArray = [];

        if (response.data.users && response.data.users.length > 0) {
            dataArray = response.data.users.map((item) => {
                return {
                    value: item.id,
                    label: item.full_name,
                };
            });
            console.log(dataArray, "responses")
            setsiteListOption(dataArray);
            return siteListOption;
        }
    };

    useEffect(() => {
        axios.get(`${BASE_URL}/api/sites/9`).then((response) => {

            if (response) {
                setSiteData(response.data)

                // window.location.replace('https://portal.bookify.space/auth/login');
            }
        });
    }, [bookingData])

    useEffect(() => {
        getUserList();
    }, [])


    return (
        <>
            <div className="pad-12-reg">
                <img
                    className="img_header_reg"
                    src={toAbsoluteUrl("/media/svg/menu/logo-icon.svg")}
                    width="50px"
                    alt="Tick Icon"
                />
            </div>

            <div className="rect_header_reg">
                <img
                    className="img_logo_reg"
                    src={siteData?.account_setting?.kiosk_logo_url}
                    width="50px"
                    alt="Tick Icon"
                />
            </div>


            <div className="menu_reg">

            </div>



            <div className="content_paragraph_reg_step">
                Welcome to {siteData?.title} , {siteData?.address}
            </div>


            {thankyou ? <></> :
                <div className="pop_up_reg">
                    <Formik
                        className="width-full mb-form"
                        enableReinitialize={true}
                        initialValues={{
                            host_id: "",

                        }}

                        onSubmit={(values) => {
                            // debugger;
                            setSubmit(true);
                            console.log(values, "userDatas");


                            if (hostData) {
                                history.push({
                                    pathname: `/visitorReceptionSignInStep2`,
                                    state: { siteData: siteData, host_id: values.host_id },
                                });
                            }


                        }}
                    >
                        {({
                            handleSubmit,
                            values,
                            errors,
                            touched,
                            handleChange,
                            setFieldValue,
                        }) => (
                            <Form onSubmit={handleSubmit} className="width-full mb-form">

                                <div className="user-list">
                                    <span  >Who are you here to see</span>
                                </div>
                                <div className="form-group row">
                                    <div className="col-lg-12">
                                        <Select2
                                            name="user"
                                            placeholder="User"
                                            data={siteListOption}
                                            update={(value) => setFieldValue("host_id", value)}
                                            value={values.host_id}
                                        />
                                    </div>
                                </div>

                                <div    >
                                    {submit ?
                                        <button className="button-form-reg remove-click-reg" type="submit">
                                            <span className="ml-3 spinner spinner-white spinner-reg "></span>
                                        </button>
                                        :


                                        <button className="button-form-reg button-top-none" type="submit">
                                            <span className="text-sign-reg"> Next </span>
                                        </button>

                                    }
                                </div>

                            </Form>
                        )}
                    </Formik>

                </div >
            }



        </>
    );
};

export default VisitorReceptionSignInStep1;
