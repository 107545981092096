import React from "react"
import { useEffect } from "react"
import SVG from "react-inlinesvg"
import { useDispatch, useSelector } from "react-redux"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers"
import * as FCircleAction from "../../../FriendCircle/_redux/Actions"
import moment from "moment";

const TodayFriendList = (props) => {
  const { start_date } = props
  const dispatch = useDispatch()

  useEffect(() => {
    console.log(start_date, "today start")
    if (start_date) {
      let params = {
        starts_at: moment(start_date).format("YYYY-MM-DD"),
      }
      dispatch(FCircleAction.getDayPassListing(params))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start_date])

  const { currentState } = useSelector((state) => ({ currentState: state.friendCircle }))
  const { todayDayPassList: friendList } = currentState

  return (
    <>
      {friendList && friendList.length > 0 && (
        <>
          <div className="mt-5">
            <p className="friend-circle-text">Colleagues - Who's in today:</p>
          </div>
          {friendList.map((item, key) => (
            <div
              className="d-flex mt-5 today-friend-circle-wrapper align-items-center"
              key={`today-friend-${key}`}
            >
              <div className="">
                <span className="svg-icon">
                  <img src={item.url_image || ""} className="user-image" alt={item.full_name}></img>
                </span>
              </div>
              <div className="">
                <p className="user-name-text">
                  <b>{item?.full_name || ""}</b>
                </p>
              </div>
              <div className="d-flex friend-circle-image-wrap">
                <span className="svg-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/friend_circle_group.svg")}
                    className="friend-circle-image"
                  ></SVG>
                </span>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  )
}

export default TodayFriendList
