import React from "react"
import { Button, Form, FormCheck, Modal} from "react-bootstrap"
import SVG from "react-inlinesvg"
import { useState } from "react"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { Formik } from "formik"
import { toAbsoluteUrl } from "../../../_metronic/_helpers"

export default function AdjustFriendCircleParking(props) {
  const {
    selectedParkingUsers,
    isParkingColleagues,
    handleToggleParkingForColleaguesClose,
    handleShow,
    availableData
  } = props

  const [selectedFriendForParking, setSelectedFriendForParking] = useState([])
  const [parkingUserDetails, setParkingUserDetails] = useState([])

  const friendCircleStore = useSelector((state) => state.searchData.selectedFriendCircleDetails?.users)

  useEffect(() => {
    if (selectedParkingUsers) {
      setSelectedFriendForParking(selectedParkingUsers)
    }
    setParkingUserDetails(friendCircleStore?.map((item) => {
      if (selectedParkingUsers.includes(item?.id)) {
        return item
      }
    }))

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParkingUsers])

  // console.log(selectedFriendCircle);

  const handleSelectedFriends = (ele) => {
    if (!selectedFriendForParking.includes(ele?.id)) {
      setSelectedFriendForParking([...selectedFriendForParking, ele?.id])
    } else {
      setSelectedFriendForParking(selectedFriendForParking.filter((d) => d !== ele.id))
    }
  }

  const AdustFriendCircleParking = () => {
    handleToggleParkingForColleaguesClose()
    handleShow()
  }

  return (
    <>
      <Modal
        show={isParkingColleagues}
        onHide={AdustFriendCircleParking}
        className="modal-right edit-friend-circle-pop-up friend-circle-parking-pop-up"
      >
        <Modal.Header closeButton>
          <div className="friend-circle-parking-header-wrapper">
            <span className="svg-icon">
              <SVG
                src={toAbsoluteUrl("media/svg/icons/car.svg")}
                className="friend-circle-parking-car-logo"
              />
            </span>
            <b className="friend-circle-parking-title-text">ADD PARKING</b>
          </div>
          <div className="d-flex justify-content-end  align-items-center ">
            <span className="available-status-label">Available:</span>{" "}
            <span className="ml-2">
              <SVG
                src={toAbsoluteUrl("media/svg/icons/color-parking.svg")}
                className="friend-circle-availability-parking-image"
              />
            </span>
            <span className="available-counter-text">{availableData?.parking}</span>
            <span className="ml-2">
              <SVG
                src={toAbsoluteUrl("media/svg/icons/color-charging.svg")}
                className="friend-circle-availability-parking-image"
              />
            </span>
            <span className="available-counter-text">{availableData?.parking_ev}</span>
            <span className="ml-2">
              <SVG
                src={toAbsoluteUrl("media/svg/icons/color-bike.svg")}
                className="friend-circle-availability-parking-image"
              />
            </span>
            <span className="available-counter-text">{availableData?.motorbike}</span>
          </div>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <Formik
            initialValues={selectedParkingUsers}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false)
              if (props?.manageSelectedUsers) {
                props.manageSelectedUsers(selectedFriendForParking)
              }
              AdustFriendCircleParking()
            }}
          >
            {({ values, handleChange, handleSubmit, setFieldValue, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <div>
                  {parkingUserDetails?.map((item, index) => {
                    if (item !== undefined) {
                      return (
                        <div
                          className="d-flex align-items-center edit-friend-circle-wrapper"
                          key={index}
                        >
                          <div className="edit-friend-circle-friend-image">
                            <span className="svg-icon">
                              {item?.url_image ? (
                                <img
                                  src={item?.url_image || ""}
                                  alt={item?.full_name || ""}
                                  className="selected-friend-circle-user-img"
                                ></img>
                              ) : (
                                <SVG
                                  src={toAbsoluteUrl("media/svg/icons/default_user.svg")}
                                  className="selected-friend-circle-user-img"
                                ></SVG>
                              )}
                            </span>
                          </div>
                          <div>
                            <p className="edit-friend-circle-friend-name">{item?.full_name}</p>
                          </div>
                          <div className="edit-friend-circle-switch">
                            <FormCheck
                              type="switch"
                              className="button-cursor custom-switch-vehicle"
                              id={item?.id}
                              name={`parking_` + item?.id}
                              label=""
                              // checked={(values[`user_${e.id}`] && values[`user_${e.id}`] === e.id ) ? true : false}
                              checked={selectedFriendForParking.includes(item?.id) ? true : false}
                              onChange={() => handleSelectedFriends(item)}
                            />
                          </div>
                        </div>
                      )
                    }
                  })}
                </div>
                <Modal.Footer>
                  <Button
                    className="w-100 font-weight-bolder custom-button"
                    variant="primary"
                    type="submit"
                  >
                    Update
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  )
}
