import * as requestFromServer from "./Crud";
import { Slice, callTypes } from "./Slice";
import { NotificationManager } from "react-notifications";

const { actions } = Slice;

export const getVisitorVehicleList = (src, queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .getVisitorVehicleList(src, queryParams)
    .then((response) => {
      let entities;
      if (src.returnData) {
        entities = response.data?.[src?.returnData];
      } else {
        entities = response.data;
      }
      dispatch(actions.getVisitorVehicleList({ entities, src }));
    })
    .catch((error) => {
      const errMsg =
        error.response && error.response.data && error.response.data.errors
          ? error.response.data.errors[0].msg
          : error.message;
      NotificationManager.error(errMsg, "Error");
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const addNewVehicle = (item) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .addNewVehicle(item)
    .then((response) => {
      const data = response.data;
      NotificationManager.success("Data saved successfully!", "success");
      dispatch(actions.addNewVehicle(response));
      return true;
    })
    .catch((error) => {
      const errMsg =
        error.response && error.response?.data && error.response.data.message
          ? error.response.data.message
          : error.message;
      NotificationManager.error(errMsg, "Error");
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const createKiosk = (receptionist_id, visitor_id, booking_id) => (
  dispatch
) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createKiosk(receptionist_id, visitor_id, booking_id)
    .then((response) => {
      // NotificationManager.success("Data saved successfully!", "Success");
      NotificationManager.success(
        "Visitor has been sent to kiosk app.",
        "Success"
      );
      dispatch(actions.createKiosk({ response }));
      return true;
    })
    .catch((error) => {
      const errMsg = error.response.data.message;
      NotificationManager.error(errMsg, "Error");
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const cancelKiosk = (receptionist_id, visitor_id, booking_id) => (
  dispatch
) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .cancelKiosk(receptionist_id, visitor_id, booking_id)
    .then((response) => {
      NotificationManager.success(
        "Cancel sign in request has been sent to kiosk app.",
        "Success"
      );
      return true;
    })
    .catch((error) => {
      const errMsg = error.response.data.message;
      NotificationManager.error(errMsg, "Error");
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const visitorIn = (inputs) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .visitorIn(inputs)
    .then((response) => {
      // NotificationManager.success("Data saved successfully!", "Success");
      dispatch(actions.visitorIn({ response }));
      return true;
    })
    .catch((error) => {
      const errMsg = error.response.data.message;
      NotificationManager.error(errMsg, "Error");
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const VisitorVehicleAction = (data) => (dispatch) => {
  dispatch(actions.newVehicleDetails(data));
};
