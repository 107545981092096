import { useFormikContext } from "formik"
import React, { useState } from "react"
import { FormCheck } from "react-bootstrap"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers"
import SVG from "react-inlinesvg"
import { useSelector } from "react-redux"
import moment from "moment"
import { useEffect } from "react"

const DayWiseParkingOptions = (props) => {
  const { setFieldValue } = useFormikContext()
  const { handleSelectedParkingDates, value } = props
  const [toggleAllDayParking, setToggleAllDayParking] = useState(false)
  const [selectedDateForParking, setSelectedDateForParking] = useState([])

  const datePickerStore = useSelector((state) => state.customDatePicker?.multiDateSelectedData)
  // console.log(datePickerStore?.multiDateSelectedData)
  const handleParkingSpace = (date) => {
    if (!selectedDateForParking.includes(date)) {
      setSelectedDateForParking([...selectedDateForParking, date])
    } else {
      setSelectedDateForParking(selectedDateForParking.filter((dates) => dates !== date))
    }
  }

  useEffect(() => {
    let dates = []
    if (toggleAllDayParking) {
      for (let key in datePickerStore) {
        dates.push(key)
      }
    } else {
      dates = selectedDateForParking
    }
    handleSelectedParkingDates(dates)
  }, [toggleAllDayParking, selectedDateForParking])
  const handleToggleAllDayParkingClick = (value) => {
    setToggleAllDayParking(value)
  }

  const parkingRowHTML = (key, date, item) => {
    return (
      <div
        key={key}
        className="d-flex day-wise-parking-selection-wrapper justify-content-between align-items-center mb-2"
      >
        <div className="d-flex day-wise-parking-selection-common-margin day-wise-parking-start-section">
          <span className="svg-icon">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/ticket.svg")}
              className="selected-group-circle-image"
            ></SVG>
          </span>
          <b className="day-wise-parking-selected-date">{moment.utc(date).add(1, 'days').format("MMM DD")}</b>
        </div>
        <div className="d-flex day-wise-parking-selection-common-margin  day-wise-parking-middle-section">
          <span className="svg-icon">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/ev.svg")}
              className="selected-group-circle-image"
            ></SVG>
            <span className="spn-numbers">{item?.available?.parking_ev}</span>
          </span>
          <span className="svg-icon middle-icn">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/parking-2.svg")}
              className="selected-group-circle-image"
            ></SVG>
            <span className="spn-numbers">{item?.available?.parking}</span>
          </span>
          <span className="svg-icon">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/color-bike.svg")}
              className="selected-group-circle-image"
            ></SVG>
            <span className="spn-numbers">{item?.available?.motorbike}</span>
          </span>
        </div>
        <div
          className="button-cursor"
          onClick={(e) => {
            handleParkingSpace(date)
          }}
        >
          {selectedDateForParking.includes(date) ? (
            <img
              src="/media/work-circle/tickIcon.svg"
              width="40px"
              alt="Tick Icon"
              className="mr-3 mt-2 mb-2"
            />
          ) : (
            <div className="day-wise-parking-selection-common-margin day-wise-parking-end-section">
              <p className="day-wise-parking-add-symbol">+</p>
            </div>
          )}
        </div>
      </div>
    )
  }

  const getParkingContent = () => {
    let content = []

    for (let key in datePickerStore) {
      const item = datePickerStore[key]
      content.push(parkingRowHTML(key, key, item))
    }
    return content
  }

  return (
    <>
      <div className="space-1"></div>
      <div className="mt-3 select-time rounded">
        <div className="mt-3 p-3">
          <b>Add Vehicle</b>
        </div>
        <div className="d-flex justify-content-between">
          <div className="p-5">Add Parking Space For All Dates</div>
          <div className=" p-5 pb-4 p-07">
            <FormCheck
              type="switch"
              id="all_day_parking"
              name="all_day_parking"
              label=""
              className="button-cursor"
              checked={value.all_day_parking}
              onChange={() => setFieldValue("all_day_parking", !value.all_day_parking)}
              onClick={() => {
                if (value.all_day_parking) {
                  setFieldValue("all_day_parking", "")
                }

                handleToggleAllDayParkingClick(!toggleAllDayParking)
              }}
            />
            {/* {
              toggleAllDayParking &&
            } */}
          </div>
        </div>
        {!toggleAllDayParking && (
          <div className="day-wise-parking-selection p-3">
            <b>Select dates to assign a Parking Space to:</b>
            {getParkingContent()}
          </div>
        )}
      </div>
    </>
  )
}

export default DayWiseParkingOptions
