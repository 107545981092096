import React from "react"
import { toAbsoluteUrl } from "../../_metronic/_helpers"
import SVG from "react-inlinesvg"
import { Modal } from "react-bootstrap"
import ModalImage from "react-modal-image"

const CalendarUserListComponent = (props) => {
  return (
    <Modal
      show={props?.show}
      className="calendar-booking-craete-popup"
      centered
    >
      <Modal.Header>
        <Modal.Title>
          <h3 className="m-0">User List</h3>
          <button
            type="button"
            onClick={() => props?.setHidePopup(false)}
            className="btn btn-close-poup p-0"
          >
            <SVG src={toAbsoluteUrl("/media/svg/icons/close.svg")} />
          </button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div className="align-items-center d-flex full-width-block justify-content-start w-100 mb-5">
          <ul className="list-group w-100">
            {props?.bookingUserData?.length > 0 &&
              props?.bookingUserData?.map((item, ind) => (
                <li
                  key={`index-ind${ind}`}
                  className="vehicle-item list-group-item mt-4 d-flex"
                >
                  {item?.booked_for && (
                    <div className="align-items-center d-flex title mr-auto min-h-50px">
                      {/* <img
                          title={item?.booked_for?.full_name || ""}
                          src={item.booked_for?.url_image}
                          alt={item?.booked_for?.full_name || ""}
                          className="calendar-user-list-image"
                        /> */}
                      {item.booked_for?.url_image ? (
                        <ModalImage
                          small={item.booked_for?.url_image}
                          large={item.booked_for?.url_image}
                          alt={item?.booked_for?.full_name || ""}
                          className="calendar-user-list-image"
                        />
                      ) : (
                        <ModalImage
                          small={"/media/users/sample_user.svg"}
                          large={"/media/users/sample_user.svg"}
                          alt={item?.booked_for?.full_name || ""}
                          className="calendar-user-list-image"
                        />
                      )}
                      <div className="d-flex pl-5 vehicle-details-main">
                        {item?.booked_for?.full_name}
                      </div>
                    </div>
                  )}
                  {!item?.booked_for && (
                    <div className="align-items-center d-flex title mr-auto min-h-50px">
                      {item?.url_image ? (
                        <ModalImage
                          small={item.url_image}
                          large={item.url_image}
                          alt={item?.full_name || ""}
                          className="calendar-user-list-image"
                        />
                      ) : (
                        <ModalImage
                          small={"/media/users/sample_user.svg"}
                          large={"/media/users/sample_user.svg"}
                          alt={item?.full_name || ""}
                          className="calendar-user-list-image"
                        />
                      )}
                      <div className="d-flex pl-5 vehicle-details-main">
                        {item?.full_name}
                      </div>
                    </div>
                  )}
                </li>
              ))}
          </ul>
        </div>
      </Modal.Body>
    </Modal>
  )
}
export default CalendarUserListComponent
