import React, { useState, useEffect } from "react";
import { Button, FormCheck, Modal } from "react-bootstrap";
import { Tabs, Tab } from "@material-ui/core";
import CustomMultiDatePicker from "./CustomMultiDatePicker";
import { useDispatch, useSelector } from "react-redux";
import * as datePickerAction from "../_redux/Actions";
import moment from "moment";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export default function MultiDatePickerPopUp(props) {
  const {
    isMultipleDatePicker,
    handleMultiDatePickerPopUpClose,
    handleMultipleSelectedDates,
    bookingType,
  } = props;
  const [toggleFriendCircleCheckBox, setToggleFriendCircleCheckBox] = useState(
    false
  );
  const [friendCircleList, setFriendCircleList] = useState([]);
  const [disableList, setDisableList] = useState([]);
  const [bookedDates, setBookedDates] = useState([]);
  const [selectedFriend, setSelectedFriend] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [monthDate, setMonthDate] = useState(moment());

  const dispatch = useDispatch();
  const customDatePickerStore = useSelector((state) => state.customDatePicker);
  const [value, setValue] = useState(1);

  useEffect(() => {
    if (isMultipleDatePicker && bookingType === "dayPass") {
      let month_start_end_date = {
        starts_at: monthDate.startOf("month").format("YYYY-MM-DD"),
        ends_at: monthDate.endOf("month").format("YYYY-MM-DD"),
      };

      let disabled_date_query_params = {
        date: monthDate.startOf("month").format("YYYY-MM-DD"),
        floor_id: props?.floor_id,
      };
      if (monthDate && moment.isMoment(monthDate)) {
        month_start_end_date["starts_at"] = monthDate
          .startOf("month")
          .format("YYYY-MM-DD");
        month_start_end_date["ends_at"] = monthDate
          .endOf("month")
          .format("YYYY-MM-DD");
        disabled_date_query_params["date"] = monthDate
          .startOf("month")
          .format("YYYY-MM-DD");

        dispatch(datePickerAction.friendDayPassList(month_start_end_date));
        dispatch(datePickerAction.disableDateList(disabled_date_query_params));
      } else {
        dispatch(datePickerAction.friendDayPassList(month_start_end_date));
        dispatch(datePickerAction.disableDateList(disabled_date_query_params));
      }
    } else if (bookingType !== "dayPass") {
      dispatch(datePickerAction.clearDisableDateList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMultipleDatePicker, monthDate]);

  useEffect(() => {
    setFriendCircleList(customDatePickerStore?.friendDayPassList);

    // if (customDatePickerStore.friendDayPassList) {
    // 	let dates = []
    // 	let entries = customDatePickerStore?.friendDayPassList
    // 	entries.map((item) => {
    // 		let booking_dates = item.dates
    // 		booking_dates.forEach(date => {
    // 			dates.push({ date: date, color: `${item?.color}` })
    // 		})
    // 		return item
    // 	})
    // 	setBookedDates(dates)
    // }
  }, [customDatePickerStore.friendDayPassList]);

  useEffect(() => {
    setDisableList(customDatePickerStore?.disableDateList);
  }, [customDatePickerStore.disableDateList]);

  useEffect(() => {
    if (customDatePickerStore?.multiDateSelectedData) {
      let dates = [];
      let multiDates = customDatePickerStore?.multiDateSelectedData;
      for (const key in multiDates) {
        if (Object.hasOwnProperty.call(multiDates, key)) {
          dates.push(new Date(key));
        }
      }
      setSelectedDates(dates);
    } else {
      setSelectedDates([]);
      setBookedDates([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customDatePickerStore?.multiDateSelectedData]);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  const handleFriendCircleList = () => {
    setToggleFriendCircleCheckBox(!toggleFriendCircleCheckBox);
    setSelectedFriend([]);
  };
  const handleFriendCircle = (item) => {
    if (!selectedFriend.includes(item?.user_id)) {
      setSelectedFriend([...selectedFriend, item.user_id]);
    } else {
      setSelectedFriend(selectedFriend.filter((d) => d !== item.user_id));
    }
  };
  useEffect(() => {
    if (selectedFriend && friendCircleList) {
      let dates = [];
      let entries = [];

      entries = friendCircleList?.filter((item) => {
        if (selectedFriend.includes(item.user_id)) {
          return item;
        }
        return false;
      });

      entries.map((item) => {
        let booking_dates = item.dates;
        booking_dates.forEach((date) => {
          dates.push({ date: date, color: `${item?.color}` });
        });
        return item;
      });
      setBookedDates(dates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFriend]);
  const handleMultiDatePickerMenu = () => {
    if (selectedDates) {

      handleMultiDatePickerPopUpClose();
      handleMultipleSelectedDates(selectedDates);
    }
  };
  return (
    <>
      <Modal
        show={isMultipleDatePicker}
        className="multi-date-picker-modal"
        onHide={handleMultiDatePickerPopUpClose}
        centered
      >
        <Modal.Header closeButton>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="secondary"
          >
            {/* <Tab label={bookingType === "dayPass" ? "SINGLE DAY" : "SINGLE"} /> */}
            <Tab
              label={
                bookingType === "dayPass" ? "MULTIPLE DAY PASSES" : "MULTIPLE"
              }
            />
          </Tabs>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="">
            <div className="d-flex justify-content-center">
              <CustomMultiDatePicker
                value={value}
                bookedDates={bookedDates}
                disableList={disableList}
                handleSelectedDates={(dates) => {
                  console.log(dates, "consoledate")
                  setSelectedDates(dates);
                }}
                handleMonthChange={(date) => {
                  setMonthDate(moment(date, "DD/MM/YYYY"));
                }}
                selectedDates={selectedDates}
              />
            </div>
            {bookingType === "dayPass" && (
              <>
                <div className="space-1"></div>
                <div className="d-flex justify-content-between align-items-center multi-date-picker-friend-list-toggle-div">
                  <p className="mt-4">Find A Colleague</p>
                  <div>
                    <FormCheck
                      type="switch"
                      id="find-friend"
                      label=""
                      className="button-cursor"
                      onChange={handleFriendCircleList}
                    />
                  </div>
                </div>
                <div className="multi-date-picker-friend-list">
                  {toggleFriendCircleCheckBox &&
                    friendCircleList?.map((item, index) => {
                      return (
                        <div
                          className="d-flex align-items-center edit-friend-circle-wrapper"
                          key={index}
                        >
                          <div className="edit-friend-circle-friend-image">
                            <span className="svg-icon">
                              {item?.url_image ? (
                                <img
                                  src={item?.url_image || ""}
                                  alt={item?.full_name || ""}
                                  className="selected-friend-circle-user-img"
                                  style={{ border: `5px solid ${item.color}` }}
                                ></img>
                              ) : (
                                <SVG
                                  src={toAbsoluteUrl(
                                    "media/svg/icons/default_user.svg"
                                  )}
                                  className="selected-friend-circle-user-img"
                                  style={{
                                    border: `5px solid ${item.color}`,
                                    borderRadius: `50%`,
                                  }}
                                ></SVG>
                              )}
                            </span>
                          </div>
                          <div>
                            <p className="edit-friend-circle-friend-name">
                              {item?.full_name}
                            </p>
                          </div>
                          <div className="edit-friend-circle-switch">
                            <FormCheck
                              type="switch"
                              className="button-cursor custom-switch-vehicle"
                              id={item?.user_id}
                              name={`user_` + item?.user_id}
                              label=""
                              // checked={(values[`user_${e.id}`] && values[`user_${e.id}`] === e.id ) ? true : false}
                              // checked={selectedFriendCircle.includes(item?.id) ? true : false}
                              onChange={() => handleFriendCircle(item)}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="w-100 font-weight-bolder custom-button"
            variant="primary"
            type="button"
            onClick={handleMultiDatePickerMenu}
          >
            SAVE
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
