import * as requestFromServer from "./Crud";
import { meetingSlice, callTypes } from "./Slice";
import { NotificationManager } from "react-notifications";

const { actions } = meetingSlice;

export const createMeeting = (item) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  console.log(item, "Action");
  // return requestFromServer
  // .createMeeting(item)
  // .then(
  //     (response)
  //     => {
  //     const visitor = response.data;
  //     NotificationManager.success(
  //         "success",
  //         "New visitor added successfully!"
  //     );
  dispatch(actions.itemCreated({ item }));
  // return visitor.id;
  // })
  // .catch((error) => {
  // const errMsg = error.response && error.response.data && error.response.data.errors ? error.response.data.errMsgerrors[0].msg : error.message ;
  // console.log(error.response.data.errMsgerrors[0].msg)
  //console.log(error);
  //   NotificationManager.error(errMsg, 'Error');
  // dispatch(actions.catchError({ error, callType: callTypes.action }));
  // });
};
export const setEditItem = (item) => (dispatch) => {
  dispatch(actions.setEditItem({ item }));
};

export const setCreateMeetingdata = (item) => (dispatch) => {
  dispatch(actions.createMeetingData(item));
};
export const clearData = () => (dispatch) => {
  dispatch(actions.clearData());
};
export const meetingRoomList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .meetingRoomList(queryParams)
    .then((response) => {
      const data = response.data;
      dispatch(actions.meetingRoomList({ data }));
    })
    .catch((error) => {
      const errMsg =
        error.response && error.response.data && error.response.data.errors
          ? error.response.data.errors[0].msg
          : error.message;
      NotificationManager.error(errMsg, "Error");
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const editMeetingRoom = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .editMeetingRoom(queryParams)
    .then((response) => {
      NotificationManager.success(
        "Meeting Room Edited Successfully!",
        "success"
      );
      const data = response.data;
      dispatch(actions.editMeetingRoom({ queryParams }));
      return response;
    })
    .catch((error) => {
      const errMsg =
        error.response && error.response.data && error.response.data.errors
          ? error.response.data.errors[0].msg
          : error.message;
      NotificationManager.error(errMsg, "Error");
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
