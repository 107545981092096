/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, BrowserRouter, Route, Switch } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { ContentRoute, Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { NotificationContainer } from "react-notifications";
import LiveFloorMobile from "./modules/LiveFloorMobile/Index";
import MeetingRoomFloorPlan from "./modules/Booking/Meeting/MeetingRoomFloorPlan";
import WorkstationFloorMobile from "./modules/Booking/DayPass/WorkstationFloorMobile";

import VehicalReg from "./modules/Booking/Visitor/VehicalReg";
import VehicalRegImage from "./modules/Booking/Visitor/VehicalRegImage";
import VehicalRegPolicy from "./modules/Booking/Visitor/VehicalRegPolicy";
import VisitorReceptionSignInStep1 from "./modules/Booking/Visitor/VisitorReceptionSignInStep1";
import VisitorReceptionSignInStep2 from "./modules/Booking/Visitor/VisitorReceptionSignInStep2";
export function Routes() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );
  return (
    <>
      <BrowserRouter>
        {window.location.pathname.includes("/livefloorplanmobile") ||
          window.location.pathname.includes("/meetingroomfloor") ||
          window.location.pathname.includes("/workstationFloor") ||
          window.location.pathname.includes("/vehicalReg") ||
          window.location.pathname.includes("/visitorReceptionSignInStep1") ||
          window.location.pathname.includes("/visitorReceptionSignInStep2") ||
          window.location.pathname.includes("/vehicalRegImage") ? (
          <>
            <Switch>
              <ContentRoute
                path="/livefloorplanmobile/:floor_id/:start_time/:space_id?"
                component={LiveFloorMobile}
              />
              <ContentRoute
                path="/meetingroomfloor/:floor_id/:space_id?"
                component={MeetingRoomFloorPlan}
              />
              <ContentRoute
                path="/workstationFloor/:floor_id/:starts_time/:ends_time?"
                component={WorkstationFloorMobile}
              />
              <ContentRoute
                path="/vehicalReg/:booking_id?"
                component={VehicalReg}
              />
              <ContentRoute
                path="/vehicalRegImage/:booking_id?"
                component={VehicalRegImage}
              />
              <ContentRoute
                path="/vehicalRegPolicy"
                component={VehicalRegPolicy}
              />
              <ContentRoute
                path="/visitorReceptionSignInStep1"
                component={VisitorReceptionSignInStep1}
              />
              <ContentRoute
                path="/visitorReceptionSignInStep2"
                component={VisitorReceptionSignInStep2}
              />
            </Switch>
          </>
        ) : (
          <>
            <NotificationContainer />

            {!isAuthorized ? (
              /*Render auth page when user at `/auth` and not authorized.*/
              <Route>
                <AuthPage />
              </Route>
            ) : (
              <Layout>
                <BasePage />
              </Layout>
            )}
            {/* <Redirect from="/auth" to="/" /> */}
            <Route path="/error" component={ErrorsPage} />
            <Route path="/logout" component={Logout} />
          </>
        )}
      </BrowserRouter>
    </>
  );
}
