import React, { useEffect, useState } from "react";
import { Formik, Field } from "formik";
import { Form, Modal, Button, FormCheck } from "react-bootstrap";
import { Input } from "../../../../../_metronic/_partials/controls";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../app/modules/Booking/Visitor/_redux/Action";
import * as Yup from "yup";
import CommonErrorShow from "./CommonErrorShow";
import helperFunc from "../../../../_helpers/helperFunc";

export default function AddNewVisitor({
  show,
  showAddClose,
  setAddedVisitor,
  item,
  updateList,
  user_id,
  listUpdate,
  setListUpdate,
}) {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  var visitorError = store?.visitors?.error ? [store.visitors.error] : [];
  const currentUser = helperFunc.getUserData();
  // Validation schema
  const phoneRegExp = /^((\+44?)|\+)?((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const [showEmailField, setShowEmailField] = useState(true);

  const LevelsEditSchema = Yup.object().shape({
    email_not_known: Yup.boolean(),
    email: Yup.string()
      .email("Invalid Email Address.")
      /* .required("Email is required.") */
      .when("email_not_known", {
        is: true,
        then: Yup.string().nullable(),
        otherwise: Yup.string().required("Email is required"),
      }),
    number: Yup.string()
      .nullable()
      .notRequired()
      .matches(phoneRegExp, "Phone number is not valid")
      /*  .typeError("Enter valid Number.") */
      .min(10, "Enter minimum 10 digits."),
    full_name: Yup.string()
      .matches(
        /^([a-zA-Z0-9]+|[a-zA-Z0-9]+\s{1}[a-zA-Z0-9]{1,}|[a-zA-Z0-9]+\s{1}[a-zA-Z0-9]{3,}\s{1}[a-zA-Z0-9]{1,})/,
        "Full name is not valid."
      )
      .required("Full name is required."),
  });

  const [frmInitialValues, setFrmInitialValues] = useState(undefined);

  useEffect(() => {
    if (item) {
      setFrmInitialValues(item);
    } else {
      setFrmInitialValues({
        email: "",
        full_name: "",
        number: "",
        type: "visitor",
        company: "",
        email_not_known: false,
      });
    }
  }, [item]);

  return (
    <Modal
      show={show}
      onHide={showAddClose}
      className="add-vehicle-modal"
      centered
    >
      <Modal.Header>
        {item && item.id ? (
          <Modal.Title>UPDATE VISITOR DETAILS</Modal.Title>
        ) : (
          <Modal.Title>ADD NEW VISITOR</Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body className="pt-0">
        <Formik
          initialValues={frmInitialValues}
          validationSchema={LevelsEditSchema}
          enableReinitialize={true}
          onSubmit={(values, actionsFormik) => {
            const visitor = {
              email: values.email,
              full_name: values.full_name,
              number: values.number,
              company: values.company,
              host_id: user_id
                ? user_id
                : JSON.parse(localStorage.getItem("userData")).id,
            };

            if (item) {
              visitor.guest_id = item.id;

              dispatch(actions.updateVisitor(visitor)).then((result) => {
                if (result !== undefined) {
                  if (updateList) {
                    updateList("update");
                  }
                  setListUpdate(listUpdate + 1);
                  console.log("action rest");
                  actionsFormik.resetForm({
                    values: {
                      // the type of `values` inferred to be Blog
                      email: "",
                      full_name: "",
                      number: "",
                      company: "",
                      email_not_known: false,
                    },
                    // you can also set the other form states here
                  });
                  showAddClose();
                  if (!updateList) {
                    setAddedVisitor(result);
                  }
                }
              });
            } else {
              dispatch(actions.createVisitor(visitor, updateList)).then(
                (result) => {
                  if (result !== undefined) {
                    if (updateList) {
                      updateList("update");
                    }
                    actionsFormik.resetForm();
                    /* setFrmInitialValues({
                    email: "",
                    full_name: "",
                    number: "",
                    company: "",
                    host_id: currentUser.id,
                  }) */
                  }
                  showAddClose();
                  // setAddedVisitor(result)
                }
              );
            }
          }}
        >
          {({ values, handleSubmit, setFieldValue }) => (
            <Form
              className="form form-label-right add-new-visitor"
              onSubmit={handleSubmit}
            >
              {!values?.email_not_known && (
                <div className="custom-form-group">
                  <img alt="sms" src="/media/svg/icons/Input/sms.svg" />
                  <Field
                    component={Input}
                    name="email"
                    value={values.email}
                    placeholder="Email"
                  />
                </div>
              )}
              <div className="align-items-center custom-form-group d-flex justify-content-between my-5">
                <div>Email not known*</div>
                <div>
                  <FormCheck
                    className="button-cursor"
                    type="switch"
                    id="custom-switch-vehicle-three"
                    label=""
                    name="email_not_known"
                    checked={values.email_not_known}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setShowEmailField(false);
                        setFieldValue("email_not_known", true);
                      } else {
                        setShowEmailField(true);
                        setFieldValue("email_not_known", false);
                      }
                    }}
                  />
                </div>
              </div>
              <div className="custom-form-group">
                <img alt="person" src="/media/svg/icons/Input/person.svg" />
                <Field
                  component={Input}
                  name="full_name"
                  placeholder="Full Name"
                  value={values.full_name}
                />
              </div>
              <div className="custom-form-group">
                <img
                  alt="briefcase"
                  src="/media/svg/icons/Input/briefcase.svg"
                />
                <Field
                  component={Input}
                  name="company"
                  placeholder="Company"
                  value={values.company}
                />
              </div>
              <div className="custom-form-group">
                <img alt="phone" src="/media/svg/icons/Input/phone.svg" />
                <Field
                  component={Input}
                  name="number"
                  placeholder="Number"
                  value={values.number}
                />
              </div>
              <CommonErrorShow error={visitorError} />
              <Modal.Footer className="border-0  pt-0 mt-0">
                <Button
                  className="w-100 vehicle-button-border"
                  variant=""
                  type="submit"
                >
                  Save
                </Button>
                <Button
                  className="w-100 pb-0 text-primary"
                  onClick={showAddClose}
                  variant=""
                >
                  Cancel
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
