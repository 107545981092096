import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  listLoading: false,
  actionsLoading: false,
  isProfileHeader: true,
  totalCount: 0,
  entities: [],
  accountForEdit: {},
  lastError: null,
  getUserList: [],
  userById: [],
  getVisitorList: [],
  getVehiclesList: [],
  getAccountSettings: [],
  refreshList: false,

}
export const callTypes = {
  list: "list",
  action: "action",
}
export const Slice = createSlice({
  name: "accounts",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
    },
    startCall: (state, action) => {
      state.error = null
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    // singleItemFetched
    setIsProfileHeader: (state, action) => {
      state.isProfileHeader = action.payload
    },
    // singleItemFetched
    singleItemFetched: (state, action) => {
      state.actionsLoading = false
      state.accountForEdit = action.payload.accountForEdit
      state.error = null
    },
    // listFetched
    listFetched: (state, action) => {
      const { totalCount, entities } = action.payload
      state.listLoading = false
      state.error = null
      state.entities = entities
      state.totalCount = totalCount
      state.refreshList = false

    },
    // itemCreated
    itemCreated: (state, action) => {
      state.actionsLoading = false
      state.error = null
      // state.entities.push(action.payload.item);
      state.entities = []
    },
    // itemUpdated
    itemUpdated: (state, action) => {
      state.error = null
      state.actionsLoading = false
      // state.entities = state.entities.map(entity => {
      //   if (entity._id === action.payload.item._id) {
      //     return action.payload.item;
      //   }
      //   return entity;
      // });
      state.entities = []
    },
    // itemDeleted
    itemDeleted: (state, action) => {
      state.error = null
      state.actionsLoading = false
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      )
    },
    // itemsDeleted
    itemsDeleted: (state, action) => {
      state.error = null
      state.actionsLoading = false
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      )
    },
    // itemStatusUpdated
    itemStatusUpdated: (state, action) => {
      state.actionsLoading = false
      state.error = null
      const { ids, status } = action.payload
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status
        }
        return entity
      })
    },
    fetchUsersList: (state, action) => {
      state.getUserList = action?.payload
    },
    fetchUserById: (state, action) => {
      state.listLoading = false
      state.error = null
      state.userById = action?.payload
    },
    fetchGetVisitorList: (state, action) => {
      state.getVisitorList = action?.payload
    },
    fetchedVehiclesList: (state, action) => {
      state.getVehiclesList = action.payload
    },
    getAccountSettings: (state, action) => {
      state.getAccountSettings = action.payload
    },
    deleteVisitor: (state, action) => {
      // state.getVisitorList = state.getVisitorList?.filter((el) => el?.id !== action.payload?.id)
    },
    deleteVehicle: (state, action) => { },
    updateGeneralInfo: (state, action) => {
      // state.usersList = action.payload
    },
    createUser: (state, action) => {
      // state.usersList = action.payload
    },
    createSubAccount: (state, action) => {
      // state.usersList = action.payload
    },
  },
})
