import * as requestFromServer from "./Crud"
import { Slice, callTypes } from "./Slice"
import { NotificationManager } from "react-notifications"

const { actions } = Slice

export const getSearchList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }))
  const storeEntityName = queryParams?.storeEntityName
  // if(queryParams?.storeEntityName) {
  //   delete queryParams.storeEntityName;
  // }
  // console.log(storeEntityName);
  return requestFromServer
    .getSearchList(queryParams)
    .then((response) => {
      let entities
      if (queryParams.returnData) {
        if (response.data?.[queryParams?.returnData]) {
          entities = response.data?.[queryParams?.returnData]
        } else {
          entities = response?.data
        }
      } else {
        entities = response.data
      }
      const source = queryParams.source
      dispatch(actions.searchListFetched({ entities, source, storeEntityName }))
    })
    .catch((error) => {
      const errMsg =
        error.response && error.response.data && error.response.data.errors
          ? error.response.data.errors[0].msg
          : error.message
      NotificationManager.error(errMsg, "Error")
      dispatch(actions.catchError({ error, callType: callTypes.list }))
    })
}
export const getGameList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }))
  return requestFromServer
    .getSearchList(queryParams)
    .then((response) => {
      const entities = response.data.list
      const source = queryParams.source
      dispatch(actions.searchListFetched({ entities, source }))
    })
    .catch((error) => {
      const errMsg =
        error.response && error.response.data && error.response.data.errors
          ? error.response.data.errors[0].msg
          : error.message
      NotificationManager.error(errMsg, "Error")
      dispatch(actions.catchError({ error, callType: callTypes.list }))
    })
}
export const getFriendCircleList = (src, queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }))
  return requestFromServer
    .getFriendCircleList(src, queryParams)
    .then((response) => {
      const entities = response.data
      const source = src
      dispatch(actions.getFriendCircleList({ entities, source }))
    })
    .catch((error) => {
      const errMsg =
        error.response && error.response.data && error.response.data.errors
          ? error.response.data.errors[0].msg
          : error.message
      NotificationManager.error(errMsg, "Error")
      dispatch(actions.catchError({ error, callType: callTypes.list }))
    })
}
export const getInfiniteScrollData = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }))
  return requestFromServer
    .getInfiniteScrollList(queryParams)
    .then((response) => {
      // dispatch(actions.getFriendCircleList(response?.data))
      return response?.data
    })
    .catch((error) => {
      const errMsg =
        error.response && error.response.data && error.response.data.errors
          ? error.response.data.errors[0].msg
          : error.message
      NotificationManager.error(errMsg, "Error")
      dispatch(actions.catchError({ error, callType: callTypes.list }))
    })
}

export const getSite = (siteId) => (dispatch) => {
  dispatch(actions.getSite(siteId))
}
export const getFloor = (floorId) => (dispatch) => {
  dispatch(actions.getFloor(floorId))
}
export const resetSiteAndFloor = () => (dispatch) => {
  dispatch(actions.resetSiteAndFloor())
}
export const resetFriendCircleData = () => (dispatch) => {
  dispatch(actions.resetFriendCircleData())
}
export const getSelectedFriendCircle = (selectedFriendCircleId) => (dispatch) => {
  dispatch(actions.getSelectedFriendCircle(selectedFriendCircleId))
}
