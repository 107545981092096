import { CircularProgress, TextField, Typography } from "@material-ui/core";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../_redux/Actions";
import { Select2 } from "select2-react-component";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { TimePickerField } from "../../../../../_metronic/_partials/controls";
import CustomDatePicker from "../../../../components/CustomDatePicker/CustomDatePicker";
import SearchMultiSelectInput from "../../../../components/SelectComponents/SearchMultiSelectInput";
import "./BulletinStep2.scss";
import * as Yup from "yup";
import helperFunc from "../../../../../_metronic/_helpers/helperFunc";

const initialValues = {
  color: "",
  duration: "",
  ev: "",
  make: "",
  reg: "",
  site_id: "",
  starts_at: "",
  user_id: "",
  vehicle_id: "",
  parking_space: false,
  day_pass: false,
  electric_vehicle: false,
};

const BulletinStep3 = (props) => {
  const { selectType, handleNext } = props;
  const [formInitialValues, setFormInitialValues] = useState(initialValues);
  const [nightTime, setNightTime] = useState();
  const [unassignedList, setUnassignedList] = useState([]);
  const [selectUnassign, setSelectUnassign] = useState("");
  const { bulletin } = useSelector((state) => state.bulletin);
  const [limitedTime, setLimitedTime] = useState(false);
  const dispatch = useDispatch();
  console.log(bulletin, "eventlform3");
  var format = "hh:mm:ss";
  var time = moment(nightTime, format);
  var afterTime = moment("18:01:00", format);
  var beforeTime = moment("07:00:00", format).add(1, "day");
  var timeBetween = time.isBetween(afterTime, beforeTime);

  const validations = Yup.object().shape({
    created_at: Yup.string().required("Event Date is required"),
    starts_at: Yup.string().required("Start time is required"),
    ends_at: Yup.string().required("End time is required"),
    city: Yup.string().required("City is required."),
    address_1: Yup.string().required("Initial Address is required."),
  });

  const [loading, setLoading] = useState(true);
  const [siteFloors, setSiteFloors] = useState([]);
  const [selectFloorName, setSelectFloorName] = useState({
    name: "",
    id: "",
  });
  const [selectSiteName, setSelectSiteName] = useState({
    name: "",
    id: "",
  });
  const [Site, setSite] = useState([]);
  const siteId = helperFunc.getUserData();

  useEffect(() => {
    if (bulletin?.stepOne?.event_type === "internal") {
      setLoading(true);
      const params = siteId?.site_id;
      dispatch(actions.getAllEventFloor(params))
        .then((res) => {
          res.forEach((element) => {
            setSiteFloors((prev) => [
              ...prev,
              {
                value: element.id,
                label: element.title,
              },
            ]);
          });
          setLoading(false);
        })
        .catch((err) => {
          throw err;
        });
    }
  }, [bulletin?.stepOne?.event_type]);

  const handleChangeFloor = (id) => {
    const filtered = siteFloors.filter((one) => one.value === id);
    setSelectFloorName({ name: filtered[0].label, id: id });
  };

  const handleChangeSite = (id) => {

    const filtered = Site.filter((one) => one.value === id);
    setSelectSiteName({ name: filtered[0].label, id: id });
  };

  useEffect(() => {
    if (selectFloorName.id !== "") {
      setLoading(true);
      const params = selectFloorName.id;
      dispatch(actions.getAllEventSpace(params)).then((res) => {
        console.log(res, "selectFloorName");
        return res?.forEach((element) => {
          setSite((prev) => [
            ...prev,
            {
              value: element.id,
              label: element.title,
            },
          ]);
          setLoading(false);
        });
      });
    }
  }, [selectFloorName.id]);

  useEffect(() => {
    console.log(siteFloors, "siteFloors");
  }, [siteFloors]);

  return (
    <div className="d-flex justify-content-center event-step2-main w-100">
      <Formik
        initialValues={{
          starts_at_date: bulletin.stepTwo.starts_at_date || "",
          ends_at_date: bulletin.stepTwo.ends_at_date || "",
          starts_at: bulletin.stepTwo.starts_at || moment().format(),
          ends_at: bulletin.stepTwo.ends_at || "",
          map_url: bulletin.stepTwo.map_url || "",
          city: bulletin.stepTwo.city || "",
          address_1: bulletin.stepTwo.address_1 || "",
          address_2: bulletin.stepTwo.address_2 || "",
          postcode: bulletin.stepTwo.postcode || "",
        }}
        validationSchema={
          bulletin?.stepOne?.event_type === "external" ? validations : ""
        }
        onSubmit={(values, { setSubmitting }) => {
          let formData;
          formData = {
            start_date: values.created_at,
            start_time: values.starts_at,
            end_date: values.ends_at,
            end_time: values.ends_at,

          };
          console.log(formData, "formData");
          dispatch(actions.handleChangeStepThree(formData));
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          errors,
          touched,
          handleBlur,
        }) => (
          <Form className="w-100">
            <div className="row" style={{ minHeight: "700px" }}>
              <div className="col-lg-12 event-select-time rounded p-5">
                <span className="font-weight-bolder d-flex justify-content-start mb-3">
                  3.Schedule
                </span>
                <CustomDatePicker
                  className="bookify-date-picker mt-3"
                  name="created_at"
                  type="event"
                  // onBlur={handleBlur}
                  onDateSet={(date) => {
                    setFieldValue("created_at", date);
                  }}
                />
                {errors.created_at && touched.created_at && (
                  <p className="text-danger">{errors.created_at}</p>
                )}

                <div className="event-time-picker mt-3">
                  <div className="time-picker-main">
                    {timeBetween && (
                      <>
                        <div className="night-div-meeting w-100 d-flex justify-content-around">
                          <div className="d-inline-flex ">
                            <span className="svg-icon night-moon-span m-2">
                              <img
                                src="/media/svg/icons/moon-night.png"
                                alt="nightMoon"
                                className="nightMoon"
                              />
                            </span>
                          </div>
                          <div className="d-inline-flex ">
                            <h6 className="mt-3 ml-2 mr-2">
                              Opps!{" "}
                              <b>
                                It appears the selected time is outside of
                                regular working hours
                              </b>
                            </h6>
                          </div>
                        </div>
                      </>
                    )}
                    {/* <label>Select Time:</label> */}
                    <div className="d-flex justify-content-between">
                      <div
                        className={
                          "time-picker-inner w-100 " +
                          // (startTime ? " time-picker-show" : "") +
                          (timeBetween ? " moonlight-input" : "")
                        }
                      >
                        {timeBetween ? (
                          <img
                            src="/media/svg/icons/warning-red.png"
                            alt="nightMoon"
                            className="timepicker-moonlight-warning-icon"
                          />
                        ) : (
                          <SVG
                            className=""
                            src={toAbsoluteUrl("/media/svg/icons/clock.svg")}
                          />
                        )}

                        <TimePickerField
                          // onDateSet={(data) => {
                          //   setFieldValue("starts_at", data);
                          // }}
                          // date={moment(values.starts_at).format("hh:mm a")}
                          // minDate={moment().format("hh:mm a")}
                          maxDate={values.ends_at}
                          name="starts_at"
                          placeholder={"Start Time"}
                          date={values.startTime}
                        />
                      </div>


                    </div>
                  </div>
                </div>
                <div className="limited-main-class">

                  <div className="d-flex justify-content-between border-class">
                    <span className="mt-1 link-generate">
                      Limited time bulletin
                    </span>

                    <div className="button-cursor custom-control custom-switch align-self-sm-center">
                      <input
                        name={`limitedTime`}
                        type="checkbox"
                        id={"limitedTime"}
                        className="custom-control-input"
                        checked={limitedTime}
                        onChange={() => {
                          setLimitedTime(!limitedTime);
                        }}
                      />
                      <label
                        title=""
                        htmlFor={"limitedTime"}
                        className="custom-control-label"
                      ></label>
                    </div>
                  </div>

                </div>

                {limitedTime &&
                  (
                    <div className="remove-bulletin">
                      <span className="font-weight-bolder d-flex justify-content-start mb-3">
                        Remove bulletin
                      </span>
                      <CustomDatePicker
                        className="bookify-date-picker mt-3"
                        name="ends_at_date"
                        type="event"
                        // onBlur={handleBlur}
                        onDateSet={(date) => {
                          setFieldValue("ends_at_date", date);
                        }}
                      />

                      <div className="event-time-picker mt-3">
                        <div className="time-picker-main">

                          {/* <label>Select Time:</label> */}
                          <div className="d-flex justify-content-between">
                            <div
                              className={
                                "time-picker-inner w-100 mr-3" +
                                // (startTime ? " time-picker-show" : "") +
                                (timeBetween ? " moonlight-input" : "")
                              }
                            >

                              <SVG
                                className=""
                                src={toAbsoluteUrl("/media/svg/icons/clock.svg")}
                              />


                              <TimePickerField
                                // onDateSet={(data) => {
                                //   setFieldValue("starts_at", data);
                                // }}
                                // date={moment(values.starts_at).format("hh:mm a")}
                                // minDate={moment().format("hh:mm a")}

                                name="ends_at"
                                placeholder={"End Time"}
                                date={values.endTime}
                              />
                            </div>


                          </div>
                        </div>
                      </div>
                    </div>
                  )

                }





              </div>

              <div className="col-lg-12 px-5">
                <button
                  id="kt_login_signin_submit"
                  type="submit"
                  className={`btn btn-primary d-flex justify-content-between align-items-center font-weight-bolder mt-5 w-100`}
                  onClick={() => {
                    handleSubmit();

                    handleNext();

                  }}
                >
                  <span />
                  <span>NEXT</span>
                  <span>
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/akar-icons_arrow-right.svg"
                      )}
                    />
                  </span>
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div >
  );
};

export default BulletinStep3;
