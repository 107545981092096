import React, { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import axios from "axios";
import { API_URL } from "./_redux/Crud";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { useSelector, useDispatch } from "react-redux";
import { Form, Formik } from "formik";
import moment from "moment";
import {
    Button,
    FormControl,
    InputLabel,
    Menu,
    MenuItem,
    MenuList,
    Select,
    TextField,
    Typography,
} from "@material-ui/core";

import "./VehicalReg.scss";
import { useParams } from "react-router-dom";
import * as actions from "../../Vehicle/_redux/Action";

const VehicalReg = (
    props
) => {
    let history = useHistory();
    const bookingId = props?.location?.state?.booking_id;
    const [workstationCoordinates, setWorkStationCoordinates] = useState([]);
    const [xyzCirc, setXyzCirc] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const BASE_URL = process.env.REACT_APP_API_URL;

    const [selectedItemsPath, setSelectedItemsPath] = useState([]);



    const [floorData, setFloorData] = useState([]);
    const [thankyou, setThankyou] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [bookingData, setBookingData] = useState([]);
    const [parkingData, setParkingData] = useState([]);
    const [visitorData, setVisitorData] = useState([]);
    const dispatch = useDispatch();

    const { booking_id } = useParams();


    useEffect(() => {

        let bID = booking_id ? booking_id : bookingId;

        console.log(props?.location?.state, booking_id, bookingId, "booking_id_print")
        axios.get(`${BASE_URL}/api/booking/${bID}`).then((response) => {

            if (response.data) {
                setBookingData(response.data);
                setParkingData(response.data.parking[0]);
                setVisitorData(response.data.users[0]);
            }
        });
    }, []);




    return (
        <>
            <div className="pad-12-reg">
                <img
                    className="img_header_reg"
                    src={toAbsoluteUrl("/media/svg/menu/logo-icon.svg")}
                    width="50px"
                    alt="Tick Icon"
                />
            </div>

            <div className="rect_header_reg">
                <img
                    className="img_logo_reg"
                    src={bookingData?.account_setting?.kiosk_logo_url}
                    width="50px"
                    alt="Tick Icon"
                />
            </div>


            <div className="menu_reg">

            </div>
            {thankyou ?
                <div className="content_header_reg green_tick_reg">
                    <span>Thank your for confirming your Vehicle details</span>
                </div>
                :
                <div className="content_header_reg ">
                    <span>Vehicle Details</span>
                </div>
            }

            {thankyou ? <div className="content_paragraph_reg ">
                <img
                    className="img_reg"
                    src={toAbsoluteUrl("/media/vehicles/green_tick.png")}
                    width="50px"
                    alt="Tick Icon"
                />
            </div>
                :
                <div className="content_paragraph_reg">
                    {bookingData?.site?.id == 1 ?

                        <span>  Hi Lee, for your visit to No5. The Heights on  {moment(bookingData?.starts_at).format("DD/MM/YYYY")} at {moment(bookingData?.starts_at).format("HH:mm")} can you please provide vehicle details below</span>
                        :
                        <span>Hi {visitorData.first_name}, for your visit to {bookingData?.site?.title} on  {moment(bookingData?.starts_at).format("DD/MM/YYYY")} , {moment(bookingData?.starts_at).format("HH:mm")} please provide car reg details below before you arrive at site </span>}

                </div>
            }

            {thankyou ? <></> :
                <div className="pop_up_reg">
                    <Formik
                        className="width-full mb-form"
                        enableReinitialize={true}
                        initialValues={{
                            reg: bookingData?.vehicle?.reg ? bookingData?.vehicle?.reg : " ",
                            model: bookingData?.vehicle?.model ? bookingData?.vehicle?.model : " ",
                            color: bookingData?.vehicle?.color ? bookingData?.vehicle?.color : " ",
                            motorbike: false,
                            electric: false,
                            disability: parkingData.disability,
                           // visitor_image : bookingData?.users[0]?.url_image

                        }}

                        onSubmit={(values) => {
                            // debugger;
                            console.log(values, "values");
                            setSubmit(true);
                            const formData1 = new FormData();
                            formData1.append("reg", values.reg);
                            formData1.append("model", values.model);
                            formData1.append("color", values.color);
                            formData1.append("electric", values.electric);
                            formData1.append("disability", values.disability);
                            formData1.append("motorbike", values.motorbike);
                            formData1.append("booking_id", booking_id);
                            formData1.append("user_id", bookingData.user_id);
                            console.log(formData1, "formdata");
                            // history.push("/vehicalRegImage");
                            const formDataObject = {};
                            formData1.forEach((value, key) => {
                                formDataObject[key] = value;
                                });
                            history.push({
                                pathname: `/vehicalRegPolicy`,
                                state: { formData: formDataObject, bookingDataForm: bookingData },
                            });
                            //history.pushState({ formDataProp: formData1, bookingData: bookingData }, '', '/new-url');
                        }}
                    >
                        {({
                            handleSubmit,
                            values,
                            errors,
                            touched,
                            handleChange,
                            setFieldValue,
                        }) => (
                            <Form onSubmit={handleSubmit} className="width-full mb-form">
                                <img
                                    className="img_reg radius-50"
                                    src={visitorData.url_image}
                                    width="50px"
                                    alt="Tick Icon"
                                />
                                <TextField
                                    id=""
                                    label="Type your vehicle reg"
                                    variant="outlined"
                                    style={{
                                        mariginTop: "10px",
                                        width: "100%"
                                    }}
                                    name="reg"
                                    value={values?.reg}
                                    onChange={handleChange}
                                />

                                <TextField
                                    className="auto-width"
                                    id=""
                                    label="Model"
                                    variant="outlined"
                                    style={{
                                        mariginTop: "30px",
                                        float: "left"
                                    }}
                                    name="model"
                                    value={values?.model}
                                    onChange={handleChange}
                                />
                                <TextField
                                    className="auto-width"
                                    id=""
                                    label="Color"
                                    variant="outlined"
                                    style={{
                                        mariginTop: "30px",
                                        float: "right"
                                    }}
                                    name="color"
                                    value={values?.color}
                                    onChange={handleChange}
                                />
                                <div>
                                    <div className="switch-class float-left switch-class-reg" >
                                        <div className="align-items-center d-flex title mr-auto vehicle-info">
                                            <div className="vehicle-details-main ">
                                                <span className="vehicle-no">
                                                    Electric Vehicle
                                                </span>
                                            </div>
                                        </div>
                                        <div className="button-cursor custom-control custom-switch">
                                            <input
                                                name={`electric`}
                                                type="checkbox"
                                                id={"electric"}
                                                className="custom-control-input"
                                                checked={values?.electric}
                                                onChange={handleChange}
                                            />
                                            <label
                                                title=""
                                                htmlFor={"electric"}
                                                className="custom-control-label"
                                            ></label>
                                        </div>
                                    </div>
                                    <div className="switch-class float-right switch-class-reg">
                                        <div className="align-items-center d-flex title mr-auto vehicle-info">
                                            <div className="vehicle-details-main ">
                                                <span className="vehicle-no">
                                                    Motorbike
                                                </span>
                                            </div>
                                        </div>
                                        <div className="button-cursor custom-control custom-switch">
                                            <input
                                                name={`motorbike`}
                                                type="checkbox"
                                                id={"motorbike"}
                                                className="custom-control-input"
                                                checked={values?.motorbike}
                                                onChange={handleChange}
                                            />
                                            <label
                                                title=""
                                                htmlFor={"motorbike"}
                                                className="custom-control-label"
                                            ></label>
                                        </div>

                                    </div>

                                    <div className="switch-class float-left switch-class-reg" >
                                        <div className="align-items-center d-flex title mr-auto vehicle-info">
                                            <div className="vehicle-details-main ">
                                                <span className="vehicle-no">
                                                    Disability
                                                </span>
                                            </div>
                                        </div>
                                        <div className="button-cursor custom-control custom-switch">
                                            <input
                                                name={`disability`}
                                                type="checkbox"
                                                id={"disability"}
                                                className="custom-control-input"
                                                checked={values?.disability}
                                                onChange={handleChange}
                                            />
                                            <label
                                                title=""
                                                htmlFor={"disability"}
                                                className="custom-control-label"
                                            ></label>
                                        </div>
                                    </div>

                                    <div className="switch-class float-right switch-class-reg">


                                    </div>

                                </div>
                                <div    >
                                    {submit ?
                                        <button className="button-form-reg remove-click-reg" type="submit">
                                            <span className="ml-3 spinner spinner-white spinner-reg "></span>
                                        </button>
                                        :
                                        //<button className="button-form-reg " type="submit">


                                        <button className="button-form-reg " type="submit">
                                            <span className="text-sign-reg"> Next </span>
                                        </button>

                                    }
                                </div>

                            </Form>
                        )}
                    </Formik>

                </div >
            }



        </>
    );
};

export default VehicalReg;
