import React from "react"
import { Button } from "react-bootstrap"
import SVG from "react-inlinesvg"
import { Modal } from "react-bootstrap"
import { useSelector } from "react-redux"
import moment from "moment"
import { useHistory } from "react-router-dom"

export default function NightMoonPopUp({ warningMenu, WarninghandleMenuClose, WarningMenuProceed, warningPopUpTimeStart, warningPopUpTimeEnd, rediractionValue }) {
    const history = useHistory()
    const datePickerStore = useSelector((state) => state.customDatePicker);
    const WarningProcced = () => {
        if (rediractionValue === "rooms") {
            WarningMenuProceed();
            history.push("/calendar/room-availability");
        }
        else {
            WarningMenuProceed();
            history.push("/calendar/list");
        }

    }
    return (
        // common - success - popup
        <Modal className="night-moon-pop-up" show={warningMenu} centered>
            <div className=" d-flex justify-content-center">
                <span className="night-pop-up-image mt-4 mb-4">
                    <img src="/media/svg/icons/moon-night.png" alt="nightMoon" className="nightMoon" />
                </span>
            </div>


            <Modal.Body className="pt-0 pb-0 mb-0">
                <div className="success-modal-div w-100">
                    <div className="d-inline-flex ml-3">
                        <h6 className="text-white mt-2 mb-5"><b>It appears your selected booking is late at night.Are you sure to proceed?</b></h6>
                    </div>
                    <div className="night-div-meeting w-100 d-flex justify-content-around">
                        <div className="d-inline-flex ">
                            <span className="svg-icon pop-up-night-pop-up-image m-2">
                                <img src="/media/svg/icons/warning-red.png" alt="warning" className="warning-moonlight-pop-up " />
                            </span>
                        </div>
                        <div className="d-inline-flex ">
                            <h6 className="mt-3 ml-2 mr-2">Date:<b>{moment(datePickerStore?.date).format("dddd[,]Do MMM")} {warningPopUpTimeStart} - {warningPopUpTimeEnd}</b>
                            </h6>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="mt-0 pt-0">
                <Button
                    className="w-100 font-weight-bolder bg-white"
                    onClick={WarningProcced}
                >
                    Yes
                </Button>
                <Button
                    className="w-100 font-weight-bolder custom-button"
                    onClick={WarninghandleMenuClose}
                >
                    Change Time
                </Button>
            </Modal.Footer>
        </Modal >
    )
}
