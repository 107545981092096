import React, { useState, useEffect } from "react";
import { Formik, Field } from "formik";
import { Button, Form, FormCheck, Modal } from "react-bootstrap";
import SearchSelectInput from "../../../../../app/components/SelectComponents/SearchSelectInput";
import { useDispatch, useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_helpers";
import moment from "moment";
import SiteFloorSelectInput from "../../../../../app/components/SelectComponents/SiteFloorSelectInput";
import CustomDatePicker from "../../../../../app/components/CustomDatePicker/CustomDatePicker";
import helperFunc from "../../../../_helpers/helperFunc";
import * as action from "../../../../../app/components/SelectComponents/_redux/Actions";
import * as meetingAction from "../../../../../app/modules/Booking/Meeting/_redux/Actions";
import { NotificationManager } from "react-notifications";
import { useHistory } from "react-router-dom";
import { TimePickerField } from "../../../../_partials/controls";
import EditMeetingRoom from "../../../../../app/modules/Booking/Meeting/EditMeetingRoom";

const initialValues = {
  site_id: "",
  floor_id: "",
  start_at: "",
  meeting_host: false,
  host_id: "",
  start_time: undefined,
  end_time: undefined,
  selector: "",
  host: undefined,
};
const ActionModalMeeting = (props) => {
  const userDetails = helperFunc.getUserData();

  const {
    show,
    onHide,
    WarningandleMenuOpen,
    proceedWarningMenu,
    getWarningPopUpTime,
    WarninghandleMenuClose,
    id,
  } = props;

  let history = useHistory();

  const datepickerStore = useSelector((state) => state.customDatePicker);
  const searchDataStore = useSelector((state) => state.searchData);
  const meetingStore = useSelector((state) => state.meetingRooms);

  const dispatch = useDispatch();

  const initialAvailableState = {
    day_passes: 0,
  };

  const [manageDatePickerDate, setManageDatePickerDate] = useState();
  const [, setAvailableData] = useState(initialAvailableState);

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [toggleOwnerMenu, setToggleOwnerMenu] = useState(false);
  const [selectedSiteId, setSelectedSiteId] = useState("");
  const [, setUserId] = useState(helperFunc.getUserId());
  const [nightTime, setNightTime] = useState();
  const [occupancyShow, setOccupancyShow] = useState(true);
  const [formData, setFormData] = useState();
  const [buttonClick, setButtonClick] = useState("");
  const [isMeetingEdit, setIsMeetingEdit] = useState(false);
  const [frmInitialValues, setFrmInitialValues] = useState(initialValues);

  const [meetingData, setMeetingData] = useState([]);
  const [hostData, setHostData] = useState({
    host_name: "",
    host_id: "",
  });

  console.log(meetingData, "meetingData");

  // const [timeBetween, setTimeBetween] = useState(false);
  var format = "hh:mm:ss";

  useEffect(() => {
    if (userDetails?.site_id) {
      dispatch(action.getSite({ siteId: userDetails.site_id }));
    }
    if (userDetails?.floor_id) {
      dispatch(action.getFloor({ floorId: userDetails.floor_id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  // var time = moment() gives you current time. no format required.
  // useEffect(() => {
  var time = moment(nightTime, format);
  var afterTime = moment("18:01:00", format);
  var beforeTime = moment("07:00:00", format).add(1, "day");
  var timeBetween = time.isBetween(afterTime, beforeTime);
  // setTimeBetween(manageTimeBetween);
  // }, [<TimePickerField />])

  useEffect(() => {
    let bookingStatus = datepickerStore?.available;
    if (bookingStatus) {
      setManageDatePickerDate(datepickerStore.date);
      setAvailableData(bookingStatus);
    } else {
      setAvailableData(initialAvailableState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datepickerStore]);

  const handleToggleOwnerClick = () => {
    setToggleOwnerMenu(!toggleOwnerMenu);
    if (!toggleOwnerMenu) {
      setUserId(helperFunc.getUserId());
    }
  };

  useEffect(() => {
    setStartTime(null);
    setEndTime(null);
  }, [show]);

  useEffect(() => {
    if (searchDataStore?.selectedSite) {
      setSelectedSiteId(searchDataStore?.selectedSite?.id);
    }
  }, [searchDataStore]);

  const handleClose = () => {
    if (onHide) {
      onHide();
    }
    dispatch(action.resetSiteAndFloor());
    dispatch(meetingAction.setEditItem(undefined));
  };
  useEffect(() => {
    if (meetingStore.meetingEntities.length !== 0) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingStore.meetingEntities.length]);

  const applyTimeFilter = (data, type, setFieldValue) => {
    var timePickerTime = moment(data);
    console.log(data);
    if (type === "startTime") {
      let startTime = moment(timePickerTime);
      // let endTime = timePickerTime.add(60, "minutes");
      setStartTime(startTime);
      //setEndTime(moment(endTime))
      setFieldValue("startTime", startTime);
      //setFieldValue("endTime", endTime)
    }

    if (type === "endTime") {
      let endTime = moment(timePickerTime);
      // let startTime = timePickerTime.subtract(60, "minutes");
      setEndTime(endTime);
      // setStartTime(moment(startTime))
      setFieldValue("endTime", endTime);
      // setFieldValue("startTime", startTime)
    }
  };
  useEffect(() => {
    if (meetingStore?.meetingEditItem) {
      // data = { ...data, ...meetingStore?.meetingEditItem };
      setOccupancyShow(false);
    }
  }, [meetingStore]);

  const manageNavigation = (add_meeting_booking) => {
    // setMeetingData([add_meeting_booking]);
    if (timeBetween) {
      WarningandleMenuOpen();
      getWarningPopUpTime(
        moment(add_meeting_booking?.start_time).format("hh:mm a"),
        moment(add_meeting_booking?.end_time).format("hh:mm a")
      );
      // setTimeBetween(null);
    } else {
      if (buttonClick === "calender") {
        handleClose();
        dispatch(meetingAction.createMeeting(add_meeting_booking));
        history.push("/calendar/list");
      } else {
        dispatch(meetingAction.createMeeting(add_meeting_booking));
        dispatch(meetingAction.setCreateMeetingdata(add_meeting_booking));
        history.push("/calendar/room-availability");
        handleClose();
      }
    }
  };
  useEffect(() => {
    if (proceedWarningMenu) {
      console.log(proceedWarningMenu);
      handleClose();
      WarninghandleMenuClose();
      dispatch(meetingAction.createMeeting(formData));
      // history.push("/calendar/list");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proceedWarningMenu]);
  useEffect(() => {
    if (id) {
      setIsMeetingEdit(true);
    } else {
      setIsMeetingEdit(false);
    }
  }, [id]);
  const handleMeetingClose = () => {
    setIsMeetingEdit(false);
  };
  return (
    <>
      {id ? (
        <EditMeetingRoom
          isMeetingEdit={isMeetingEdit}
          handleMeetingClose={handleMeetingClose}
          id={id}
        />
      ) : (
        <>
          <div>
            <Modal show={show} onHide={handleClose} className="modal-right">
              <Modal.Header closeButton style={{ paddingBottom: "0px" }}>
                <Modal.Title>Book Meeting Room</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Formik
                  enableReinitialize={true}
                  initialValues={frmInitialValues}
                  onSubmit={(values) => {
                    const add_meeting_booking = {
                      site_id: searchDataStore?.siteId,
                      floor_id: searchDataStore?.floorId,
                      starts_at: manageDatePickerDate,
                      meeting_host_id: values?.host_id,
                      start_time: values?.start_time,
                      end_time: values?.end_time,
                      room_capacity: values.selector,
                      host: hostData,
                    };
                    manageNavigation(add_meeting_booking);
                    setFormData(add_meeting_booking);
                  }}
                >
                  {({
                    values,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                  }) => (
                    <Form
                      className="form form-label-right"
                      onSubmit={handleSubmit}
                    >
                      {/* <SiteFloorSelectInput /> */}
                      <div className="space-1"></div>
                      <div className="select-time">
                        <div className="title-text select-meeting font-weight-bolder mb-2">
                          SELECT TIME
                        </div>
                        <div className="content-inner">
                          <CustomDatePicker
                            className="bookify-date-picker"
                            name="date"
                            type="meetingRoom"
                            occupancyShow={occupancyShow}
                          />

                          <div className="mt-5">
                            <div className="d-flex justify-content-between pl-3 pr-1 mb-5">
                              <div className="py-1">
                                The meeting host is someone else
                              </div>
                              <div
                                className="py-1 d-flex align-align-items-center justify-content-end"
                                onClick={() => {
                                  if (searchDataStore?.siteId == null) {
                                    NotificationManager.error(
                                      "Please select Site.",
                                      "Error"
                                    );
                                  }
                                }}
                              >
                                <FormCheck
                                  type="switch"
                                  name="meeting_host"
                                  id="meeting_host"
                                  label=""
                                  className="button-cursor"
                                  checked={values.meeting_host}
                                  onChange={() =>
                                    setFieldValue(
                                      "meeting_host",
                                      !values.meeting_host
                                    )
                                  }
                                  onClick={() => {
                                    handleToggleOwnerClick();
                                  }}
                                  disabled={searchDataStore?.siteId == null}
                                />
                              </div>
                            </div>

                            {toggleOwnerMenu ? (
                              <div className="space-1">
                                <div className="">
                                  <Field
                                    name="meeting_host_id"
                                    id="meeting_host_id"
                                    component={SearchSelectInput}
                                    placeholder="Select Host"
                                    source={`user?site_id=${selectedSiteId}`}
                                    returnData="users"
                                    onChange={handleChange}
                                    value={values.meeting_host_id}
                                    onSelect={(item) => {
                                      if (item?.id) {
                                        setUserId(item.id);
                                        setHostData({
                                          host_id: item?.id,
                                          host_name: item?.full_name,
                                        });
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            ) : null}
                          </div>

                          <div className="time-picker-main">
                            {timeBetween && (
                              <>
                                <div className="night-div-meeting w-100 d-flex justify-content-around">
                                  <div className="d-inline-flex ">
                                    <span className="svg-icon night-moon-span m-2">
                                      <img
                                        src="/media/svg/icons/moon-night.png"
                                        alt="nightMoon"
                                        className="nightMoon"
                                      />
                                    </span>
                                  </div>
                                  <div className="d-inline-flex ">
                                    <h6 className="mt-3 ml-2 mr-2">
                                      Opps!{" "}
                                      <b>
                                        It appears the selected time is outside
                                        of regular working hours
                                      </b>
                                    </h6>
                                  </div>
                                </div>
                              </>
                            )}
                            <label>Select Time:</label>
                            <div className="d-flex justify-content-between">
                              <div
                                className={
                                  "time-picker-inner w-100 mr-3" +
                                  (values?.start_time
                                    ? " time-picker-show"
                                    : "") +
                                  (timeBetween ? " moonlight-input" : "")
                                }
                              >
                                {timeBetween ? (
                                  <img
                                    src="/media/svg/icons/warning-red.png"
                                    alt="nightMoon"
                                    className="timepicker-moonlight-warning-icon"
                                  />
                                ) : (
                                  <SVG
                                    className=""
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/clock.svg"
                                    )}
                                  />
                                )}

                                <TimePickerField
                                  onDateSet={(data) => {
                                    applyTimeFilter(
                                      data,
                                      "startTime",
                                      setFieldValue
                                    );
                                    setNightTime(moment(data));
                                  }}
                                  stepping={15}
                                  name="start_time"
                                  placeholder={"Start Time"}
                                  value={values?.end_time}
                                />
                              </div>

                              <div
                                className={
                                  "time-picker-inner w-100" +
                                  (values?.end_time
                                    ? " time-picker-show"
                                    : "") +
                                  (timeBetween ? " moonlight-input" : "")
                                }
                              >
                                {timeBetween ? (
                                  <img
                                    src="/media/svg/icons/warning-red.png"
                                    alt="nightMoon"
                                    className="timepicker-moonlight-warning-icon"
                                  />
                                ) : (
                                  <SVG
                                    className=""
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/clock.svg"
                                    )}
                                  />
                                )}
                                {values?.start_time ? (
                                  <TimePickerField
                                    onDateSet={(data) =>
                                      applyTimeFilter(
                                        data,
                                        "endTime",
                                        setFieldValue,
                                        values
                                      )
                                    }
                                    //minDate={values?.startTime}
                                    stepping={15}
                                    minDate={moment().format("hh:mm a")}
                                    name="end_time"
                                    placeholder={"End Time"}
                                  //date={values.endTime}
                                  />
                                ) : (
                                  <TimePickerField
                                    onDateSet={(data) =>
                                      applyTimeFilter(
                                        data,
                                        "endTime",
                                        setFieldValue
                                      )
                                    }
                                    // stepping={15}
                                    name="end_time"
                                    placeholder={"End Time"}
                                  />
                                )}
                                {/* <TimePickerField
                                  // onDateSet={(data) =>
                                  //   applyTimeFilter(
                                  //     data,
                                  //     "endTime",
                                  //     setFieldValue
                                  //   )
                                  // }
                                  // stepping={15}
                                  name="end_time"
                                  placeholder={"End Time"}
                                /> */}
                              </div>
                            </div>
                          </div>

                          <div className="space-15 meeting-capacity">
                            <label className="d-block mb-5 pb-1">
                              Select meeting room capacity:
                            </label>
                            <div
                              className=" selector d-flex justify-content-between"
                              role="group"
                              aria-labelledby="my-radio-group"
                            >
                              <div className="w-100 selecotr-item">
                                <Field
                                  type="radio"
                                  id="radio1"
                                  name="selector"
                                  value="4-6"
                                  className="selector-item_radio"
                                />
                                <label
                                  htmlFor="radio1"
                                  className="selector-item_label"
                                >
                                  <SVG
                                    className=""
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/man-gray.svg"
                                    )}
                                  />
                                  <span>4-6</span>
                                </label>
                              </div>

                              <div className="w-100 mx-5 selecotr-item">
                                <Field
                                  type="radio"
                                  id="radio2"
                                  name="selector"
                                  value="8-10"
                                  className="selector-item_radio"
                                />
                                <label
                                  htmlFor="radio2"
                                  className="selector-item_label"
                                >
                                  <SVG
                                    className=""
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/man-gray.svg"
                                    )}
                                  />
                                  <span>8-10</span>
                                </label>
                              </div>

                              <div className="w-100 selecotr-item">
                                <Field
                                  type="radio"
                                  id="radio3"
                                  name="selector"
                                  value="12+"
                                  className="selector-item_radio"
                                />
                                <label
                                  htmlFor="radio3"
                                  className="selector-item_label"
                                >
                                  <SVG
                                    className=""
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/man-gray.svg"
                                    )}
                                  />
                                  <span>12+</span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="space-15 ">
                            <div className="avl-calendar">
                              <button
                                className="avl-calendar-btn mb-5"
                                type="submit"
                                onClick={() => {
                                  setButtonClick("calender");
                                  props.rediractionLink("calender");
                                }}
                              >
                                <div className="avl-calendar-icon">
                                  <img
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/calendar-pink.svg"
                                    )}
                                    alt="calender-pink"
                                  />
                                </div>
                                <span className="cal-label">
                                  MEETING ROOM AVAILABILITY
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* </div> */}
                      <div className="space-15">
                        <Button
                          className="w-100 custom-button"
                          variant="primary"
                          type="submit"
                          onClick={() => {
                            setButtonClick("rooms");
                            props.rediractionLink("rooms");
                          }}
                        // disabled={availableData?.day_passes && !isSubmitting ? "" : "disabled"}
                        >
                          CHECK AVAILABLE MEETING ROOMS
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Modal.Body>
            </Modal>
          </div>
        </>
      )}
    </>
  );
};

export default ActionModalMeeting;
