import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  listLoading: false,
  actionsLoading: false,
  vehicleEntities: [],
  lastError: null,
  commonData: {},
  vehicleUpdated: false,
  newAdded: false,
}
export const callTypes = {
  list: "list",
  action: "action",
}
export const Slice = createSlice({
  name: "vehicle",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.lastError = true
      state.error = action?.payload?.error?.response?.data?.errors
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
    },
    clearError: (state, action) => {
      state.error = null
    },
    startCall: (state, action) => {
      state.error = null
      state.newAdded = false
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    // itemCreated
    itemCreated: (state, action) => {
      state.actionsLoading = false
      state.error = null
      state.newAdded = true
      state.lastError = null
      state.vehicleEntities.push(action.payload.item)
    },
    itemUpdated: (state, action) => {
      state.error = null
      state.actionsLoading = false
      state.entities = []
      state.vehicleUpdated = true
    },
    itemDeleted: (state, action) => {
      state.error = null
      state.actionsLoading = false
      state.vehicleEntities = state.vehicleEntities?.filter((el) => el?.id !== action.payload?.id)
    },
    // itemCreated
    setCommonData: (state, action) => {
      const item = action.payload
      state.commonData = { ...state.commonData, ...item }
    },
    // singleItemFetched
    singleItemFetched: (state, action) => {
      state.actionsLoading = false
      state.vehicleForEdit = action.payload.vehicleForEdit
      state.error = null
    },
  },
})
