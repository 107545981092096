import {createSlice} from "@reduxjs/toolkit";

const initialStaffUsersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  staffUserForEdit: {},
  logs:{
    entities:[],
    totalCount: 0,
  },
  queries:{
    entities:[],
    totalCount: 0,
  },
  contacts:{
    entities:[],
    totalCount: 0,
  },
  contests:{
    entities:[],
    totalCount: 0,
  },
  tournamnets:{
    entities:[],
    totalCount: 0,
  },
  transactions:{
    entities:[],
    totalCount: 0,
  },
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};
export const staffUsersSlice = createSlice({
  
  name: "staffUsers",
  initialState: initialStaffUsersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    emptyList: (state, action) => {
      state.error = null;
      state.entities = [];
      state.totalCount = 0;
    },
    // getstaffUserById
    staffUserFetched: (state, action) => {
      state.actionsLoading = false;
      state.staffUserForEdit = action.payload.staffUserForEdit;
      state.error = null;
    },
    // findstaffUsers
    staffUsersFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // findstaffUsers
    userLogFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.logs.entities = entities;
      state.logs.totalCount = totalCount;
    },
    // findstaffUsers
    userQueriesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.queries.entities = entities;
      state.queries.totalCount = totalCount;
    },
    userContactsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.contacts.entities = entities;
      state.contacts.totalCount = totalCount;
    },
    // findstaffUsers
    userContestsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.contests.entities = entities;
      state.contests.totalCount = totalCount;
    },
    // findstaffUsers
    userTournamnetsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.tournamnets.entities = entities;
      state.tournamnets.totalCount = totalCount;
    },
    // findstaffUsers
    userTransactionsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.transactions.entities = entities;
      state.transactions.totalCount = totalCount;
    },
    // createstaffUser
    staffUserCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities = [];
      // state.entities.push(action.payload.staffUser);
    },
    // updatestaffUser
    staffUserUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = [];
      // state.entities = state.entities.map(entity => {
      //   if (entity._id === action.payload.staffUser._id) {
      //     return action.payload.staffUser;
      //   }
      //   return entity;
      // });
    },
    // deletestaffUser
    staffUserDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deletestaffUsers
    staffUsersDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // staffUsersUpdateState
    staffUsersStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
