import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import helperFunc from "../../../../_helpers/helperFunc";
import { AddDayPass } from "./AddDayPass";
import { AddMeetingRoom } from "./AddMeetingRoom";
import { AddNewEvent } from "./AddNewEvent";
import { AddNewBulletin } from "./AddNewBulletin";
import { AddParking } from "./AddParking";
import { AddVisitor } from "./AddVisitor";
import * as bookingActions from "../../../../../app/modules/Booking/_redux/Action";
import { useDispatch } from "react-redux";

export const ActionButtonPopUp = () => {
  const dispatch = useDispatch();
  const handleCloseBookingOptionModal = () => {
    const closeBtn = document.querySelector("#addBookingModal .modal-close");
    closeBtn.click();
  };

  const location = useLocation();

  let hideElement = false;
  if (location.pathname.indexOf("/settings") >= 0) {
    hideElement = true;
  }
  if (location.pathname.indexOf("/availability-scheduler") >= 0) {
    hideElement = true;
  }
  if (location.pathname.indexOf("/feedback/list") >= 0) {
    hideElement = true;
  }

  if (
    !helperFunc.isMenuShow("addDayPassBooking") &&
    !helperFunc.isMenuShow("addMeetingRoomBooking") &&
    !helperFunc.isMenuShow("addParkingBooking") &&
    !helperFunc.isMenuShow("addVisitorBooking") &&
    !helperFunc.isMenuShow("addEventBooking")
  ) {
    hideElement = true;
  }

  console.log(helperFunc.getUserData().is_event, "userdata");

  const [defaultAccount, setDefaultAccount] = useState([]);
  useEffect(() => {

    dispatch(bookingActions.defaultAccountDetails()).then((res) => {
      setDefaultAccount(res);

    });
  }, []);

  return (
    <>
      {!hideElement && (
        <div
          id=""
          className="add-floating-btn"
          data-toggle="modal"
          data-target="#addBookingModal"
        >
          <span className="add-booking-btn">
            <i className="fa fa-plus"></i>
          </span>
        </div>
      )}

      <div
        className="modal booking-options-modal"
        id="addBookingModal"
        role="dialog"
        aria-labelledby="addBookingModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog position-absolute right-0 bottom-0"
          style={{ width: "375px" }}
          role="document"
        >
          <div className="modal-content">
            <div
              className="modal-header padding-0"
              style={{ borderBottom: "none" }}
            >
              <h5 className="modal-title " id="addBookingModalLabel"></h5>
              <i
                className="modal-close padding-right-20 "
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </i>
            </div>

            {helperFunc.getUserData().is_add_booking && (
              <div>
                {helperFunc.isPermisstionShow("addDayPassBooking") && (
                  <AddDayPass
                    handleParentClose={() => {
                      handleCloseBookingOptionModal();
                    }}
                  />
                )}
                {helperFunc.isPermisstionShow("addParkingBooking") && (
                  <AddParking
                    handleParentClose={() => {
                      handleCloseBookingOptionModal();
                    }}
                  />
                )}
                {helperFunc.isPermisstionShow("addMeetingRoomBooking") && (
                  <AddMeetingRoom
                    handleParentClose={() => {
                      handleCloseBookingOptionModal();
                    }}
                  />
                )}
                {helperFunc.isPermisstionShow("addVisitorBooking") && (
                  <AddVisitor
                    handleParentClose={() => {
                      handleCloseBookingOptionModal();
                    }}
                  />
                )}

                {defaultAccount?.is_event_selected_add && (
                  < AddNewEvent
                    handleParentClose={() => {
                      handleCloseBookingOptionModal();
                    }}
                  />
                )}


                {defaultAccount?.is_bulletin_selected_add && (
                  <AddNewBulletin
                    handleParentClose={() => {
                      handleCloseBookingOptionModal();
                    }}
                  />
                )}
                {/* new setup */}
              </div>)}
          </div>
        </div>
      </div>
    </>
  );
};
