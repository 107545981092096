import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  lastError: null,
  error: null,
  vehicleList: undefined,
  newAdded: false,
  signIn: false,
  vehicleEntities: undefined,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const Slice = createSlice({
  name: "visitorSignIn",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.lastError = true;
      state.error = action.payload.error.response.data.errors;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    clearError: (state, action) => {
      state.error = null;
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    createKiosk: (state, action) => {},
    getVisitorVehicleList: (state, action) => {
      state.vehicleList = undefined;
      state.vehicleList = action.payload.entities.vehicle;
    },
    addNewVehicle: (state, action) => {
      state.newAdded = true;
      state.vehicleList.push(action?.payload?.data);
    },
    visitorIn: (state, action) => {
      state.signIn = true;
    },
    newVehicleDetails: (state, action) => {
      state.vehicleEntities = action.payload;
    },
  },
});
