import axios from "axios"
import helperFunc from "../../../../_metronic/_helpers/helperFunc"

export const API_URL = `${process.env.REACT_APP_API_URL}`

export function getUserById(params) {
  return axios.get(`${API_URL}/api/user/by_email?email=${params}`)
}

export function getVehiclesList(userID) {
  return axios.get(`${API_URL}/api/vehicle/user/list?user_id=${userID}`)
}

export function getNotificationsList() {
  return axios.get(`${API_URL}/api/user/notification-setting`)
}


export function getSiteByID(id) {
  return axios.get(`${API_URL}/api/sites/${id}`)
}

export function friendRequestResponse(params) {
  return axios.post(`${API_URL}/api/friend/respond-request`, params)
}

export function updateUserNotificationSettings(params) {
  return axios.post(`${API_URL}/api/user/notification-setting`, params)
}

export function getWorkCircleList(params) {
  return axios.post(`${API_URL}/api/friend-circle/lsiting`, params)
}

export function getWorkCircleIDsList(params) {
  return axios.post(`${API_URL}/api/friend-circle/only-ids`, params)
}

export function getUsersList(params) {
  return axios.get(
    `${API_URL}/api/user/listing?site_id=${params.site_id}&perPage=${params.perPage}&page=${params.page}`
  )
}

export function getEditCircleList(params) {
  return axios.get(
    `${API_URL}/api/user/listing?site_id=${params.site_id}&perPage=${params.perPage}&page=${params.page}&work_circle_id=${params.work_circle_id}`
  )
}

export function createWorkCircle(params) {
  return axios.post(`${API_URL}/api/friend-circle/create`, params)
}

export function getWorkCircleDetails(id) {
  return axios.get(`${API_URL}/api/friend-circle/${id}/detail`)
}

export function getCalendarSettingsList() {
  return axios.get(`${API_URL}/api/user/calender-settings`)
}

export function updateCalendarSettings(params) {
  return axios.post(`${API_URL}/api/user/calender-settings`, params)
}

export function searchUser(params) {
  return axios.get(`${API_URL}/api/user/search?site_id=${params.site_id}&search=${params.search}`)
}

export function updateWorkCircleDetails(params) {
  return axios.post(`${API_URL}/api/friend-circle/edit`, params)
}

export function getAllFriendsWithRequests() {
  const currentUser = helperFunc.getUserData()
  return axios.get(`${API_URL}/api/friend/request-listing?user_id=${currentUser.id}`)
}

export function addFriendRequest(params) {
  return axios.post(`${API_URL}/api/friend/send-request`, params)
}

export function addFriendToWorkCircle(params) {
  return axios.post(`${API_URL}/api/friend-circle/add-user`, params)
}

// DELETE => delete work Circle
export function deleteWorkCircle(id) {
  return axios.post(`${API_URL}/api/friend-circle/delete`, { friend_circle_id: id })
}
