import axios from "axios";

export const API_URL = `${process.env.REACT_APP_API_URL}/api`;

// CREATE =>  POST: add a new StaffUser to the server
export function createItem(item) {
  return axios.post(`${API_URL}/create`, item);
}

// READ
export function getBookingList(urlString) {
  return axios.get(`${API_URL}/booking-listing?${urlString}`);
}

// READ
export function getList(queryParams) {
  return axios.post(`${API_URL}/list`, queryParams);
}

export function getItemById(id) {
  return axios.post(`${API_URL}/get/${id}`);
}
export function deleteItem(id, type) {
  console.log(type, "TYpe");
  if (type === "parking") {
    return axios.delete(`${API_URL}/booking/${id}?type=${type}`);
  } else {
    return axios.delete(`${API_URL}/booking/${id}`);
  }
}

// UPDATE Status
export function updateItemsStatus(ids, status) {
  return axios.post(`${API_URL}/updateStatusForitems`, {
    ids,
    status,
  });
}
