import * as requestFromServer from "./Crud"
import { Slice, callTypes } from "./Slice"
import { NotificationManager } from "react-notifications"

const { actions } = Slice

export const createItem = (item) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }))
  return requestFromServer
    .createItem(item)
    .then((response) => {
      NotificationManager.success("Data saved successfully!", "Success");
      dispatch(actions.createParkingData({ item }))
      return response;
    })
    .catch((error) => {
      const errMsg = error.response.data.message
      NotificationManager.error(errMsg, "Error")
      dispatch(actions.catchError({ error, callType: callTypes.action }))
      return error;
    })
}

export const updateItem = (id, item) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }))
  return requestFromServer
    .updateItem(id, item)
    .then((response) => {
      NotificationManager.success("Data updated successfully!", "Success");
      dispatch(actions.updateParkingData({ item }))
      return response;
    })
    .catch((error) => {
      const errMsg = error?.response?.data?.message
      NotificationManager.error(errMsg, "Error")
      dispatch(actions.catchError({ error, callType: callTypes.action }))
    })
}
export const setEditItem = (item) => (dispatch) => {
  dispatch(actions.setEditItem({ item }))
}
export const clearData = () => (dispatch) => {
  dispatch(actions.clearData());
};