import React, { useEffect } from "react";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import * as actions from "./_redux/Actions";
import { FieldFeedbackLabel } from "../../../_metronic/_partials/controls/forms/FieldFeedbackLabel";
// import { Select } from "../../../_metronic/_partials/controls";
import { Multiselect } from "multiselect-react-dropdown";
// import {useField, useFormikContext} from "formik";
const getFieldCSSClasses = (touched, errors) => {
  const classes = [
    "align-items-center d-flex form-control multi-select-box py-0",
  ];
  if (touched && errors) {
    classes.push("is-invalid-select");
  }

  if (touched && !errors) {
    classes.push("is-valid-select");
  }

  return classes.join(" ");
};

const SearchMultiSelectInput = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  children,
  filter,
  site_id,
  ...props
}) => {
  // const { setFieldValue } = useFormikContext();
  const dispatch = useDispatch();
  useEffect(() => {
    if (props?.source && !props?.customOptions)
      dispatch(
        actions.getSearchList({
          source: props.source,
          filter: filter,
          site_id: site_id,
        })
      );
  }, [filter, dispatch, props.source]);

  const { currentState } = useSelector(
    (state) => ({ currentState: state.searchData }),
    shallowEqual
  );
  const { searchData } = currentState;

  // console.log(site_id, "setSiteId");

  // const onChangeHandle = (value) => {
  //     if (props.onSelect) {
  //         const item = searchData[props.source].find(item => item._id === value);
  //         props.onSelect(item);
  //     }
  // };
  // const getValues =(value, data) => {
  //     if(data && data.length > 0 && value) {
  //         return data.filter(item => value.include(item._id));
  //     }
  //     return [];
  // }
  const setValue = (value) => {
    setFieldValue(field.name, value);
    if (props.onChange) {
      // const item = searchData[props.source].find(item => item._id === value);
      props.onChange(value);
    }
  };

  return (
    <>
      <div
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
      >
        {label && (
          <label className={props.isRequired ? "required" : ""}>
            Select {label}
          </label>
        )}
        <Multiselect
          className="form-control"
          // className={getFieldCSSClasses(touched[field.name], errors[field.name])}
          closeOnSelect={false}
          showCheckbox={true}
          options={
            props?.customOptions
              ? props.customOptions
              : searchData[props.source] || searchData[props.source]
          }
          selectedValues={props?.defaultVals ? props?.defaultVals : field.value}
          onSelect={(value) => setValue(value)}
          onRemove={(value) => setValue(value)}
          {...field}
          {...props}
        />
        {withFeedbackLabel && (
          <FieldFeedbackLabel
            error={errors[field.name]}
            touched={touched[field.name]}
            label={label}
            customFeedbackLabel={customFeedbackLabel}
          />
        )}
      </div>
    </>
  );
};
export default SearchMultiSelectInput;
