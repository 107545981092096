import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import moment from "moment";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

const messages = {
  createMsg: "You have successfully made a booking!",
  updateMsg: "You have successfully update a booking!",
}

export default function AddVisitorSuccess({ show, showAddClose, status }) {
  const store = useSelector((state) => state);
  const [msgTxt, setMsgTxt] = useState(messages.createMsg);

  useEffect(() => {
    if (status === "update") {
      setMsgTxt(messages.updateMsg);
    } else {
      setMsgTxt(messages.createMsg);
    }
  }, [status]);

  return (
    <Modal show={show} className="common-success-popup" centered>
      <div className=" d-flex justify-content-center">
        <SVG
          className=" button-cursor mr-5"
          src={toAbsoluteUrl("/media/svg/icons/visitor-booking.svg")}
        />
      </div>

      <div className="d-flex justify-content-center mt-2">
        <p className="h6">{msgTxt}</p>
      </div>
      <Modal.Body className="pt-0">
        <div className="success-modal-div w-100">
          <div className="success-popup-heading-logo d-inline-flex bg-white">
            <span className="svg-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/calendar.svg")}></SVG>
            </span>
          </div>
          <div className="d-inline-flex ml-3">
            <h6 className="">
              Date:
              <b>
                {moment(store.visitors?.visitorEntities[0]?.starts_at).format(
                  "dddd [,] Do MMM"
                )}
              </b>
            </h6>

          </div>
        </div>

        <div className="success-modal-div w-100 mt-4">
          <div className="success-popup-heading-logo d-inline-flex bg-white">
            <span className="svg-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/task.svg")}></SVG>
            </span>
          </div>
          <div className="d-inline-flex ml-3">
            {/* <h6>Time <b>{store.visitors?.visitorEntities[0]?.duration}</b></h6>
            <h6>{store.visitors?.visitorEntities[0]?.full_name}</h6>
            Host: <b>{store.auth.user.full_name}</b> */}
            <div className="d-flex flex-column bd-highlight">
              <h6>Visitor :<b>{store.visitors?.visitorEntities[0]?.visitors[0]?.full_name}</b></h6>
              <h6>Time: <b>{store.visitors?.visitorEntities[0]?.duration}</b></h6>
              <h6>Host: <b>{store.auth.user.full_name}</b></h6>
            </div>
          </div>
        </div>

        <div className="success-modal-div w-100  mt-4">
          <div className="success-popup-heading-logo bg-white d-inline-flex">
            <span className="svg-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Booking/motorcycle.svg")}></SVG>
            </span>
          </div>
          <div className="d-inline-flex ml-3">
            <h6 className="">
              Vehicle : Motorbike
            </h6>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="">
        <Button
          className="w-100 font-weight-bolder custom-button"
          onClick={showAddClose}
        >
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
