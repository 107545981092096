import { createSlice } from "@reduxjs/toolkit"

const initialStaffUsersState = {
  loading: false,
  lastError: null,
  available: null,
  total: null,
  friendDayPassList: undefined,
  disableDateList: undefined,
  multiDateSelectedData: undefined,
}
export const callTypes = {
  list: "list",
  action: "action",
}
export const Slice = createSlice({
  name: "searchList",
  initialState: initialStaffUsersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`
      state.loading = false
      state.available = null
    },
    startCall: (state, action) => {
      state.error = null
      state.lastError = null
      state.loading = true
    },
    // search Availability
    BookingDateAvailabilityFetched: (state, action) => {
      // console.log(state, action);

      const { available, total, parameters } = action.payload
      // console.log(parameters);
      state.loading = false
      state.error = null
      state.available = available
      state.total = total
      state.date = parameters?.starts_at
    },
    friendDayPassList: (state, action) => {
      state.loading = false
      state.error = null
      state.error = null
      state.friendDayPassList = action.payload
    },
    disableDateList: (state, action) => {
      state.loading = false
      state.error = null
      state.error = null
      state.disableDateList = action.payload
    },
    multipleDateSelection: (state, action) => {
      state.loading = false
      state.error = null
      state.error = null
      state.multiDateSelectedData = action.payload
    },
    clearMultipleDateSelection: (state, action) => {
      state.loading = false
      state.error = null
      state.error = null
      state.multiDateSelectedData = undefined
    },
    clearDisableDateList: (state, action) => {
      state.loading = false
      state.error = null
      state.error = null
      state.disableDateList = undefined
    },
  },
})
