import React, { useState } from "react";
import { useSubheader } from "../../_metronic/layout";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
  Select,
  Textarea,
  DatePickerField,
  TimePickerField,
} from "../../_metronic/_partials/controls";
// Validation schema
const CustomerEditSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Firstname is required"),
  lastName: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Lastname is required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  userName: Yup.string().required("Username is required"),
  dateOfBbirth: Yup.mixed()
    .nullable(false)
    .required("Date of Birth is required"),
  ipAddress: Yup.string().required("IP Address is required"),
});
const customer = {
  firstName: "jaydeep",
  lastName: "Chandegra",
  email: "jdchansw@gm.com",
  userName: "username",
  dateOfBbirth: "",
  ipAddress: "126.126.2.1",
  isActive: "true",
  Gender: "Male",
  type: "1",
  isActive2: false,
};
export const MyPage = (props) => {
  const suhbeader = useSubheader();

  suhbeader.setTitle("My Custom Form");

  const onHide = (data) => {
    console.log(data);
  };
  const changeSelectE = (data) => {
    console.log(data);
  };
  const saveCustomer = (customer) => {
    console.log(customer);
  };
  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card card-custom gutter-b example example-compact">
            <div className="card-header">
              <h3 className="card-title">Demo Form</h3>
            </div>
            <div className="card-body">
              <Formik
                enableReinitialize={true}
                initialValues={customer}
                validationSchema={CustomerEditSchema}
                onSubmit={(values) => {
                  saveCustomer(values);
                }}
              >
                {({ handleSubmit }) => (
                  <>
                    <Form className="form form-label-right">
                      <div className="form-group row">
                        {/* First Name */}
                        <div className="col-lg-4">
                          <Field
                            name="firstName"
                            component={Input}
                            placeholder="First Name"
                            label="First Name"
                          />
                        </div>
                        {/* Last Name */}
                        <div className="col-lg-4">
                          <Field
                            name="lastName"
                            component={Input}
                            placeholder="Last Name"
                            label="Last Name"
                          />
                        </div>
                        {/* Login */}
                        <div className="col-lg-4">
                          <Field
                            name="userName"
                            component={Input}
                            placeholder="Login"
                            label="Login"
                          />
                        </div>
                      </div>
                      {/* Email */}
                      <div className="form-group row">
                        <div className="col-lg-4">
                          <Field
                            type="email"
                            name="email"
                            component={Input}
                            placeholder="Email"
                            label="Email"
                          />
                        </div>
                        {/* Date of birth */}
                        <div className="col-lg-4">
                          <DatePickerField
                            name="dateOfBbirth"
                            label="Date of Birth"
                          />
                        </div>
                        {/* Date of birth */}
                        <div className="col-lg-4">
                          <TimePickerField name="time" label="Time" />
                          {/* <Field type="time" name="time2" className="form-control"  /> */}
                        </div>
                        <div className="col-lg-4">
                          <Field
                            name="phone"
                            component={Input}
                            placeholder="phone"
                            label="phone"
                            type="number"
                            customFeedbackLabel="We'll never share customer phone with anyone else"
                          />
                          {/* <label className="col-form-label">Phone</label> */}
                          {/* <Field type="number" name="phone" label="phone" placeholder="phone" className="form-control" /> */}
                        </div>
                        {/* IP Address */}
                        <div className="col-lg-4">
                          <Field
                            name="ipAddress"
                            component={Input}
                            placeholder="IP Address"
                            label="IP Address"
                            customFeedbackLabel="We'll never share customer IP Address with anyone else"
                          />
                        </div>
                        <div className="col-lg-4">
                          <Field
                            name="address"
                            placeholder="Address"
                            component={Textarea}
                            label="Address"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        {/* Gender */}
                        <div className="col-lg-4">
                          <Select
                            onChange={changeSelectE}
                            name="Gender"
                            label="Gender"
                            className="form-control"
                          >
                            <option value="Female">Female</option>
                            <option value="Male">Male</option>
                          </Select>
                        </div>
                        {/* Type */}
                        <div className="col-lg-4">
                          <Select
                            name="type"
                            label="Type"
                            className="form-control"
                          >
                            <option value="0">Business</option>
                            <option value="1">Individual</option>
                          </Select>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-4">
                          <div
                            role="group"
                            aria-labelledby="my-radio-group"
                            className="radio-inline"
                          >
                            <label className="radio">
                              <Field
                                type="radio"
                                name="isActive"
                                value={"true"}
                              />
                              <span></span>Active
                            </label>
                            <label className="radio">
                              <Field
                                type="radio"
                                name="isActive"
                                value={"false"}
                              />
                              <span></span>InActive
                            </label>
                          </div>
                        </div>

                        <label className="col-3 col-form-label">
                          Is Active 2
                        </label>
                        <div className="col-1">
                          <span className="switch switch-icon">
                            <label>
                              <Field type="checkbox" name="isActive2" />
                              {/* <input type="checkbox" checked="checked" name="select" /> */}
                              <span></span>
                            </label>
                          </span>
                        </div>
                      </div>
                      <div className="col-3">
                        <label>Square Style</label>
                        <div className="checkbox-inline">
                          <label className="checkbox checkbox-square">
                            <Field
                              type="checkbox"
                              value="a"
                              name="Checkboxes13_1"
                            />
                            <span></span>
                            Option 1
                          </label>
                          <label className="checkbox checkbox-square">
                            <Field
                              type="checkbox"
                              value="b"
                              name="Checkboxes13_1"
                            />
                            <span></span>
                            Option 2
                          </label>
                          <label className="checkbox checkbox-square">
                            <Field
                              type="checkbox"
                              value="c"
                              name="Checkboxes13_1"
                            />
                            <span></span>
                            Option 3
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <Field
                          name="file"
                          component={Input}
                          placeholder="file"
                          file="file"
                          type="file"
                          customFeedbackLabel="We'll never share customer file with anyone else"
                        />
                      </div>
                    </Form>

                    <button
                      type="button"
                      onClick={onHide}
                      className="btn btn-light btn-elevate"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      onClick={() => handleSubmit()}
                      className="btn btn-primary btn-elevate"
                    >
                      Save
                    </button>
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
