import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { staffUsersSlice } from "../app/modules/StaffUser/_redux/staffUsersSlice";
import { Slice as DashboardsSlice } from "../app/pages/_redux/Slice";

import { Slice as CalendarSlice } from "../app/modules/Calendar/_redux/Slice";
import { Slice as languageSlice } from "../app/modules/Languages/_redux/Slice";

import { Slice as RolesSlice } from "../app/modules/Roles/_redux/Slice";
import { Slice as AccountsSlice } from "../app/modules/Accounts/_redux/Slice";
import { Slice as IntegrationSlice } from "../app/modules/Accounts/Integrations/_redux/Slice";
import { Slice as SiteSlice } from "../app/modules/Accounts/Sites/_redux/Slice";
import { Slice as UserSlice } from "../app/modules/Accounts/Users/_redux/Slice";
import { Slice as spaceTypeSlice } from "../app/modules/Accounts/SpaceTypes/_redux/Slice";
import { Slice as SpacesSlice } from "../app/modules/Accounts/Spaces/_redux/Slice";
import { Slice as schedulerAvailabilitySlice } from "../app/modules/RoomAvailability/_redux/Slice";

import { Slice as searchSlice } from "../app/components/SelectComponents/_redux/Slice";
import { Slice as customDatePickerSlice } from "../app/components/CustomDatePicker/_redux/Slice";
import { Slice as uploadSlice } from "../app/components/upload//_redux/Slice";
import { Slice as parkingSlice } from "../app/modules/Booking/Parking/_redux/Slice";
import { Slice as vehiclesSlice } from "../app/modules/Vehicle/_redux/Slice";
import { Slice as settingSlice } from "../app/modules/Settings/_redux/Slice";
import { meetingSlice } from "../app/modules/Booking/Meeting/_redux/Slice";
import { visitorSlice } from "../app/modules/Booking/Visitor/_redux/Slice";
import { Slice as dayPassSlice } from "../app/modules/Booking/DayPass/_redux/Slice";
import { Slice as bookingSlice } from "../app/modules/Booking/_redux/Slice";

import { Slice as reportingSlice } from "../app/modules/Reporting/_redux/Slice";
import { Slice as visitorSignInSlice } from "../app/modules/Reception/VisitorSignIn/_redux/Slice";
import { Slice as FriendCircleSlice } from "../app/modules/FriendCircle/_redux/Slice";
import { Slice as FeedbackSlice } from "../app/modules/Feedback/_redux/Slice";
import { Slice as EventSlice } from "../app/modules/Booking/Event/_redux/Slice";
import { Slice as inviteeSlice } from "../app/components/InviteeComponent/_redux/Slice";
import { Slice as socialWallSlice } from "../app/modules/SocialWall/_redux/Slice";
import { Slice as bulletin } from "../app/modules/Booking/Bulletin/_redux/Slice";
import { Slice as subAccount } from "../app/modules/Accounts/SubAccount/_redux/Slice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  dashboards: DashboardsSlice.reducer,
  calendar: CalendarSlice.reducer,
  staffUsers: staffUsersSlice.reducer,

  languages: languageSlice.reducer,
  booking: bookingSlice.reducer,
  schedulerAvailability: schedulerAvailabilitySlice.reducer,

  accounts: AccountsSlice.reducer,
  Integrations: IntegrationSlice.reducer,
  sites: SiteSlice.reducer,
  users: UserSlice.reducer,
  spaces: SpacesSlice.reducer,
  spaceTypes: spaceTypeSlice.reducer,
  roles: RolesSlice.reducer,

  searchData: searchSlice.reducer,
  upload: uploadSlice.reducer,

  vehicles: vehiclesSlice.reducer,
  visitors: visitorSlice.reducer,
  dayPass: dayPassSlice.reducer,
  parkingPass: parkingSlice.reducer,
  meetingRooms: meetingSlice.reducer,
  customDatePicker: customDatePickerSlice.reducer,

  /* reducers created by Irfan */
  settings: settingSlice.reducer,
  /* reducers created by Irfan S */
  reporting: reportingSlice.reducer,
  visitorSignIn: visitorSignInSlice.reducer,
  friendCircle: FriendCircleSlice.reducer,
  feedBack: FeedbackSlice.reducer,
  events: EventSlice.reducer,
  bulletin: bulletin.reducer,
  invitee: inviteeSlice.reducer,
  socialWall: socialWallSlice.reducer,
  subAccount: subAccount.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
