import React, { useEffect, useState } from "react";
import * as visitorSignInAction from "../_redux/Actions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { useFormikContext } from "formik";
import { Fragment } from "react";

const VisitorVehicleSelect = ({
  form: { touched, errors },
  customFeedbackLabel,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const [options, setOptions] = useState([]);
  const [selectedId, setSelectedId] = useState(undefined);

  const { source, field, handleSelectedVehicle } = props;
  const { currentState } = useSelector(
    (state) => ({ currentState: state.visitorSignIn?.vehicleEntities }),
    shallowEqual
  );

  useEffect(() => {
    if (currentState?.guest?.vehicles) {
      setOptions(currentState?.guest?.vehicles);
    }
  }, [currentState]);
  const onChangeHandle = (item) => {
    // props.onChange(item.id);
    if (handleSelectedVehicle) {
      handleSelectedVehicle(item.id);
    }
    setSelectedId(item.id);
    setFieldValue(field.name, item.id);
  };

  return (
    options && (
      <Fragment>
        <div
          role="group"
          aria-labelledby="floor-radio-group"
          className={`custom-card-radio-group d-flex overflow-x-auto vehicle-details-wrap ${
            errors[field.name] ? "is-invalid" : ""
          }`}
        >
          {options.map((item, key) => {
            return (
              <div
                key={key}
                className={`item ${item.id === selectedId ? "selected" : ""}`}
              >
                <div
                  className="visitor-car-data-slider-wrap d-flex"
                  onClick={() => {
                    onChangeHandle(item);
                  }}
                >
                  <div className="image-wrap">
                    <SVG src={toAbsoluteUrl("media/svg/icons/Frame812.svg")} />
                  </div>
                  <div className="details-wrap d-flex">
                    <div className="select-vehicle-details-div">
                      <h6 className="vehicle-component-heading">Vehicle Reg</h6>
                      <h4 className="vehicle-details">
                        <b title={item?.reg} className="toolTipHover">
                          {item?.reg}
                        </b>
                      </h4>
                    </div>
                    <div className="select-vehicle-details-div">
                      <h6 className="vehicle-component-heading">Make</h6>
                      <h4 className="vehicle-details">
                        <b title={item?.make}>{item?.make}</b>
                      </h4>
                    </div>
                    <div className="select-vehicle-details-div">
                      <h6 className="vehicle-component-heading">Color</h6>
                      <h4 className="vehicle-details">
                        <b title={item?.color}>{item?.color}</b>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {errors[field.name] && (
          <div className="invalid-feedback">{errors[field.name]}</div>
        )}
      </Fragment>
    )
  );
};

export default VisitorVehicleSelect;
