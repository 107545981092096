import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import MobileStepper from "@material-ui/core/MobileStepper/MobileStepper";
import Button from "@material-ui/core/Button/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import SVG from "react-inlinesvg";
//components
import BulletinStep1 from "./StepperComponents/BulletinStep1";
import BulletinStep2 from "./StepperComponents/BulletinStep2";
import BulletinStep22 from "./StepperComponents/BulletinStep22";
import BulletinStep3 from "./StepperComponents/BulletinStep3";
import BulletinStepPublish from "./StepperComponents/BulletinStepPublish";
import BulletinStep4 from "./StepperComponents/BulletinStep4";
//css
import "./BulletinForm.scss";

const BulletinForm = (props) => {
    const [activeStep, setActiveStep] = useState(0);
    const [selectType, setSelectType] = useState("external");

    const { show, modalProps, onHide } = props;
    const handleClose = () => {
        if (onHide) {
            onHide();
        }
    };
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                className="modal-right"
                backdrop="static"
            >
                <Modal.Header closeButton className="bulletin-header-pop-up">
                    <Modal.Title>
                        <b>{modalProps?.title.toUpperCase()}</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="event-form-main bulletin-modal-popup ">
                    <MobileStepper
                        variant="dots"
                        steps={4}
                        position="static"
                        activeStep={activeStep}
                        sx={{ maxWidth: 400, flexGrow: 1 }}
                        nextButton={
                            <Button size="small" onClick={handleNext} disabled={true}>
                                {/* {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )} */}
                            </Button>
                        }
                        backButton={
                            <Button
                                size="small"
                                onClick={handleBack}
                                disabled={activeStep === 0}
                            >
                                <KeyboardArrowLeft />
                            </Button>
                        }
                    />
                    {activeStep === 0 && (
                        <BulletinStep1
                            setActiveStep={setActiveStep}
                            selectType={selectType}
                            setSelectType={setSelectType}
                            handleNext={handleNext}
                        />
                    )}
                    {activeStep === 1 && (
                        <BulletinStep22
                            handleNext={handleNext}
                            selectType={selectType}
                            setSelectType={setSelectType}
                        />
                    )}
                    {activeStep === 2 && <BulletinStep3 handleNext={handleNext} />}
                    {activeStep === 3 && (
                        <BulletinStepPublish
                            onHide={handleClose}
                            setActiveStep={setActiveStep}
                            selectType={selectType}
                        />
                    )}

                    {/* <button
              id="kt_login_signin_submit"
              type="submit"
              className={`btn btn-primary d-flex justify-content-between align-items-center font-weight-bolder my-3 w-100`}
              onClick={handleNext}
            >
              <span />
              <span>{activeStep === 3 ? "PUBLISH EVENT" : "NEXT"}</span>
              <span>
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/akar-icons_arrow-right.svg"
                  )}
                />
              </span>
            </button> */}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default BulletinForm;
