import {
  TextareaAutosize,
  TextField,
} from "@material-ui/core";
import { Form, Formik, Field } from "formik";
import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { useDispatch, useSelector } from "react-redux";
//constants & helpers
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import * as eventsAction from "../_redux/Actions";
import * as Yup from "yup";
import { Select2 } from "select2-react-component";
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import {
  createBulletin,
} from "../_redux/Actions";
import CKEditor from "../../../../components/Ckeditor";
import axios from "axios";
import SearchMultiSelectInput from "../../../../components/SelectComponents/SearchMultiSelectInput"

// css
import "./BulletinStep1.scss";
import { async } from "react-tempusdominus-bootstrap";

const BulletinStep1 = (props) => {

  const { handleNext } = props;
  const [file, setFile] = useState(undefined);
  const [doc, setDoc] = useState(undefined);
  const [image, setImage] = useState(undefined);
  const [selectedFile, setSelectedFile] = useState(undefined);
  const [selectedImage, setSelectedImage] = useState(undefined);
  const [selectedDocument, setSelectedDocument] = useState(undefined);
  const { bulletin } = useSelector((state) => state.bulletin);
  const [isSubmit, setIsSubmit] = useState(0);
  const [codeValue, setCodeValue] = useState(bulletin?.stepOne.content || "");
  const handleCallbackContent = (childData) => {
    setCodeValue(childData)
  }

  const [category, setCategory] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState([]);
  const [newCategory, setNewCategory] = useState([]);
  const [selectCategoryName, setSelectCategoryName] = useState({
    name: "",
    id: "",
  });

  const handleChangeCategory = async (e) => {
    console.log(e, "category1");
    const cate = [];
    e.map((option) => cate.push(option.value));
    setSelectedCategory(cate);

  };
  const getImageUrl = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile({
        file: event.target.files[0],
        path: event.target.value,
      });
      let img = event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(img);

      reader.onloadend = function (e) {
        setFile(reader.result);
      };
    }
  };

  const getImageUrls = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedImage({
        file: event.target.files[0],
        path: event.target.value,
      });
      let img = event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(img);

      reader.onloadend = function (e) {
        setImage(reader.result);
      };
    }
  };


  const getDocUrl = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedDocument({
        file: event.target.files[0],
        path: event.target.value,
      });
      let img = event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(img);

      reader.onloadend = function (e) {
        setDoc(reader.result);
      };
    }
  };


  const getCategoryList = () => {

    const params = null;

    dispatch(eventsAction.getCategoryList(params))
      .then((res) => {

        res.forEach((element) => {
          setCategory((prev) => [
            ...prev,
            {
              value: element.id,
              label: element.value,
            },
          ]);
        });
        //  setLoading(false);
      })
      .catch((err) => {
        throw err;
      });

  };
  useEffect(() => {
    getCategoryList()
  }, []);

  const BASE_URL = process.env.REACT_APP_API_URL;
  const createNewCategory = (e) => {
    console.log(e, "testss");
    // if (e) {
    //   e.map((option) => {
    //     if (option.__isNew__ == true) {
    //       console.log(option, "evlaues")
    //       let params = { "name": option.value };
    //       setNewCategory(option.value);
    //       axios.post(`${BASE_URL}/api/bulletin/category/create`, params).then((response) => {
    //         // console.log(response.data, "responses")
    //         // let categoryList = response.data?.map((item) => {
    //         //   return {
    //         //     value: item.id,
    //         //     label: item.value,
    //         //     __isNew__: false
    //         //   };
    //         // });
    //         // setCategory(categoryList);
    //       });
    //     }
    //   }
    //   );
    // }


  }



  const dispatch = useDispatch();

  const validations = Yup.object().shape({
    title: Yup.string().required("Title is required.")
  });

  return (
    <div className="d-flex justify-content-center event-step1-main">
      <div className="form-group row w-100 bulletin-form">
        <Formik
          initialValues={{
            title: bulletin?.stepOne.title || "",
            content: bulletin?.stepOne.content || "",
            video_url: bulletin?.stepOne.video_url || "",
            cover_image: bulletin?.stepOne.cover_image || selectedFile,
            image: bulletin?.stepOne.image || selectedImage,
            document: bulletin?.stepOne.document || selectedDocument,
            category_id: bulletin?.stepOne.category_id || [],
          }}
          validationSchema={validations}
          onSubmit={(values, { setSubmitting }) => {
            const formData = {
              title: values.title,
              content: codeValue,
              video_url: values.video_url,
              cover_image: selectedFile,
              image: selectedImage,
              document: selectedDocument,
              category_id: selectedCategory
            };
            dispatch(eventsAction.handleChangeStepOne(formData));
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            errors,
            touched,
            handleBlur,
          }) => (
            <Form className="w-100">
              <div className="col-lg-12">

              </div>

              <div className="col-lg-12 mt-3 d-flex justify-content-center event-details w-100">
                <>
                  <div
                    className={`square-image-display ${file ? "align-items-baseline" : "align-items-center"
                      } `}
                  >
                    <label
                      className="align-items-center d-flex flex-column flex-wrap justify-content-center min-h-120px"
                      data-action="change"
                      title="Upload Image"
                    >
                      {!file && !values.cover_image && (
                        <>
                          <Image
                            src={toAbsoluteUrl("/media/event/camera.svg")}
                            style={{ width: "50px" }}
                            alt="progile-image"
                          />
                          <span>Add Cover Photo...</span>
                        </>
                      )}
                      {values.cover_image && file === undefined && (
                        <Image
                          src={URL.createObjectURL(values.cover_image.file)}
                          style={{
                            width: "100%",
                            height: "18vh",
                            backgroundSize: "cover",
                          }}
                          alt="progile-image"
                        />
                      )}
                      {file && (
                        <Image
                          src={file}
                          style={{
                            width: "100%",
                            height: "18vh",
                            backgroundSize: "cover",
                          }}
                          alt="progile-image"
                        />
                      )}
                      {/* <i className="fa fa-pen icon-sm text-muted"></i> */}
                      <input
                        type="file"
                        name="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={getImageUrl}
                      //{getImageUrl}=
                      />

                    </label>

                  </div>

                  {selectedFile == undefined && isSubmit != 0 && (
                    <p className="text-danger mb-0">Cover Image is requried .</p>
                  )}
                  <TextField
                    id="outlined-basic"
                    label="Title"
                    variant="outlined"
                    className=""
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.title && touched.title && (
                    <p className="text-danger mb-0">{errors.title}</p>
                  )}

                  <CKEditor
                    parentToChild={codeValue}
                    parentCallback={handleCallbackContent}
                  />
                  {/* <TextareaAutosize
                    aria-label="event_details"
                    placeholder="Content"
                    className="event-text-aria"
                    style={{
                      width: "100%",
                      borderRadius: "5px",
                      height: "100px",
                      padding: "10px",
                    }}
                    value={values.content}
                    name="content"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  /> */}
                  {errors.content && touched.content && (
                    <p className="text-danger mb-0">{errors.content}</p>
                  )}

                  <div className=" full-width">
                    {/* <Select2
                      placeholder="Select Category"
                      data={category}
                      value={selectCategoryName}
                      update={(value) => {
                        handleChangeCategory(value);
                      }}
                      isMulti
                      className="mr-3"
                    ></Select2> */}

                    {/* <Field
                      name="category_id"
                      //disable={floorIDs?.length <= 0}
                      component={SearchMultiSelectInput}
                      placeholder="Select Category"
                      displayValue="value"
                      source={`bulletin/category/get`}
                      //defaultVals={latestReportingFilters?.space_obj}
                      isRequired={false}
                      //onChange={(item) => applyFilterValue("space_id", item)}
                      //onChange={handleChange}
                      onChange={e => { handleChangeCategory(e); }}
                    /> */}

                    <CreatableSelect
                      placeholder="Select Category"
                      isClearable
                      name="category"
                      // onChange={visitorListValue}
                      // onChange={e => { visitorListValue(e); setFieldValue('visitor', e) }}
                      onChange={e => { createNewCategory(e); handleChangeCategory(e); }}
                      //value={selectCategoryName}
                      storeEntityName="category"
                      options={category}
                      isMulti
                    // onInputChange={visitorListValueChange}
                    />

                  </div>
                  <TextField
                    id="outlined-basic"
                    label="Video Url"
                    variant="outlined"
                    className=""
                    name="video_url"
                    style={{
                      marginTop: "0px",
                    }}
                    value={values.video_url}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <div
                    className={`square-image-display ${file ? "align-items-baseline" : "align-items-center"
                      } `}
                  >
                    <label
                      className="align-items-center d-flex flex-column flex-wrap justify-content-center min-h-120px"
                      data-action="change"
                      title="Upload Image"
                    >
                      {!image && !values.image && (
                        <>
                          <Image
                            src={toAbsoluteUrl(
                              "/media/svg/icons/cloud-plus.svg"
                            )}
                            style={{ width: "50px" }}
                            alt="progile-image"
                          />
                          <span>Upload Image Gallery</span>
                        </>
                      )}
                      {values.image && image === undefined && (
                        <Image
                          src={URL.createObjectURL(values.image.file)}
                          style={{
                            width: "100%",
                            height: "18vh",
                            backgroundSize: "cover",
                          }}
                          alt="progile-image"
                        />
                      )}
                      {image && (
                        <Image
                          src={image}
                          style={{
                            width: "100%",
                            height: "18vh",
                            backgroundSize: "cover",
                          }}
                          alt="progile-image"
                        />
                      )}
                      {/* <i className="fa fa-pen icon-sm text-muted"></i> */}
                      <input
                        type="file"
                        name="image"
                        accept=".png, .jpg, .jpeg"
                        onChange={getImageUrls}
                        multiple
                      />
                    </label>
                  </div>


                  <div
                    className={`square-image-display ${file ? "align-items-baseline" : "align-items-center"
                      } `}
                  >
                    <label
                      className="align-items-center d-flex flex-column flex-wrap justify-content-center min-h-120px"
                      data-action="change"
                      title="Upload title"
                    >
                      {!doc && !values.document && (
                        <>
                          <Image
                            src={toAbsoluteUrl(
                              "/media/svg/icons/cloud-plus.svg"
                            )}
                            style={{ width: "50px" }}
                            alt="progile-image"
                          />
                          <span>Upload Documentation</span>
                        </>
                      )}
                      {values.document && selectedDocument === undefined && (
                        <p className="mb-0">{values.document.file.name}</p>
                      )}
                      {selectedDocument && (
                        <p className="mb-0">{selectedDocument.file.name}</p>
                      )}
                      {/* <i className="fa fa-pen icon-sm text-muted"></i> */}
                      <input
                        type="file"
                        name="file"
                        accept=".doc, .pdf, .docx"
                        onChange={getDocUrl}
                      />
                    </label>
                  </div>
                </>
              </div>

              <button
                id="kt_login_signin_submit"
                type="submit"
                className={`btn btn-primary d-flex justify-content-between align-items-center font-weight-bolder mt-5 w-100`}
                onClick={() => {
                  handleSubmit();
                  setIsSubmit(1);
                  console.log(selectedFile, "selectedFile")
                  if (
                    values.title !== "" &&
                    selectedFile !== undefined
                  ) {
                    handleNext();
                  }
                }}
              >
                <span />
                <span>NEXT</span>
                <span>
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/akar-icons_arrow-right.svg"
                    )}
                  />
                </span>
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div >
  );
};

export default BulletinStep1;
