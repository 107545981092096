import React from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import VectorImage from "../../_metronic/_assets/Stress-pana.svg"
import { Slice as DayPassSlice } from "../modules/Booking/DayPass/_redux/Slice"
import { Slice as ParkingPassSlice } from "../modules/Booking/Parking/_redux/Slice"
import { Slice as CalendarSlice } from "../modules/Calendar/_redux/Slice"

const { actions: CalendarActions } = CalendarSlice
const { actions: DayPassActions } = DayPassSlice
const { actions: ParkingPassActions } = ParkingPassSlice

const ErrorDialog = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const HandleRedirect = () => {
    dispatch(CalendarActions.clearError())
    dispatch(DayPassActions.clearError())
    dispatch(ParkingPassActions.clearError())
    history.push("/")
  }

  return (
    <div
      className="w-100 d-flex align-items-center justify-content-center flex-column"
      style={{ height: "80vh" }}
    >
      <img src={VectorImage} alt="Alert Icon" height="60%" width="60%" />
      <h2 className="text-danger h2 font-weight-bold mt-3">
        Something went wrong please check in a while
      </h2>
      <button onClick={HandleRedirect}>Redirect to dashboard</button>
    </div>
  )
}

export default ErrorDialog
