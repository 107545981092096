import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allUsers: [],
  selectedUsers: { users: [], visitors: [] },
};
export const callTypes = {
  list: "list",
  action: "action",
};
export const Slice = createSlice({
  name: "invitee",
  initialState: initialState,
  reducers: {
    getAllUsers: (state, action) => {
      state.allUsers = action.payload;
    },
    selectedUsers: (state, action) => {
      state.selectedUsers = action.payload;
    },
    removeSelectedUsers: (state, action) => {
      state.selectedUsers.users = state.selectedUsers?.users?.filter(
        (el) => el?.user_id !== action.payload
      );
    },
    removeSelectedVisitors: (state, action) => {
      state.selectedUsers.visitors = state.selectedUsers.visitors?.filter(
        (el) => el?.user_id !== action.payload
      );
    },
  },
});
