import { createSlice } from "@reduxjs/toolkit"
import moment from "moment"

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  cityForEdit: {},
  bookings: {
    list: [],
    counts: {},
  },
  availability: {},
  meetingRoom: {},
  roomsList: [],
  calendarFilters: {
    date: moment(),
    site_id: 1,
    floor_id: [],
    floor_obj: {},
    type: "all",
  },
  lastError: null,
  meetingRoomDate: null,
}
export const callTypes = {
  list: "list",
  action: "action",
}
export const Slice = createSlice({
  name: "calendar",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
    },
    clearError: (state, action) => {
      state.error = null
    },
    startCall: (state, action) => {
      state.error = null
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    // singleItemFetched
    singleItemFetched: (state, action) => {
      state.actionsLoading = false
      state.cityForEdit = action.payload.cityForEdit
      state.error = null
    },
    // listFetched
    listFetched: (state, action) => {
      const { data } = action.payload
      state.listLoading = false
      state.error = null
      state.availability = data
    },
    // listFetched
    meetingRoomListFetched: (state, action) => {
      const { data } = action.payload
      state.listLoading = false
      state.error = null
      state.meetingRoom = data
    },
    // listFetched
    OpenCaledanrlistFetched: (state, action) => {
      const { data } = action.payload
      state.listLoading = false
      state.error = null
      state.bookings.list = data.bookings
      state.bookings.counts = data.counts
    },
    // listFetched
    roomsFetched: (state, action) => {
      const { data } = action.payload
      state.listLoading = false
      state.error = null
      state.roomsList = data
    },
    // itemCreated
    bookingItemCreated: (state, action) => {
      state.actionsLoading = false
      state.error = null
      // state.entities.push(action.payload.item);
      state.availability = []
    },
    // itemCreated
    itemCreated: (state, action) => {
      state.actionsLoading = false
      state.error = null
      // state.entities.push(action.payload.item);
      state.entities = []
    },
    // itemUpdated
    itemUpdated: (state, action) => {
      state.error = null
      state.actionsLoading = false
      // state.entities = state.entities.map(entity => {
      //   if (entity._id === action.payload.item._id) {
      //     return action.payload.item;
      //   }
      //   return entity;
      // });
      state.entities = []
    },
    // itemDeleted
    itemDeleted: (state, action) => {
      state.error = null
      state.actionsLoading = false
      // state.entities = state.entities.filter(
      //   (el) => el.id !== action.payload.id
      // )
    },
    // itemsDeleted
    itemsDeleted: (state, action) => {
      state.error = null
      state.actionsLoading = false
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      )
    },
    // itemStatusUpdated
    itemStatusUpdated: (state, action) => {
      state.actionsLoading = false
      state.error = null
      const { ids, status } = action.payload
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status
        }
        return entity
      })
    },
    meetingRoomDate: (state, action) => {
      // console.log(action.payload.date);
      state.meetingRoomDate = action.payload.date
    },
    isActionLoading: (state, action) => {
      // console.log(action.payload.date);
      state.actionsLoading = action.payload
    },
    setCalItemFilter: (state, action) => {
      const data = action.payload
      state.calendarFilters = { ...state.calendarFilters, ...data }
    },
  },
})
