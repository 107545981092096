import React, { useEffect, useState, useCallback } from "react";
import SVG from "react-inlinesvg";
import axios from "axios";
import { useParams } from "react-router-dom";
//constants
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
//style
import "./styles.scss";
import { Typography } from "@material-ui/core";

const LiveFloorPlan = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const [spaces, setSpaces] = useState([]);
  const [vergeSpaces, setVergeSpaces] = useState([]);
  const [apiCordinatesCirc, setApiCordinatesCirc] = useState([]);
  const [xyzCirc, setXyzCirc] = useState([]);
  const [xyzPaths, setXyzPaths] = useState([]);
  const [updateState, setUpdateState] = useState(0);
  const [loading, setLoading] = useState(false);

  const { floor_id, start_time, space_id } = useParams();

  const getSpaces = useCallback(async () => {
    await axios
      .get(
        `${BASE_URL}/api/spaces/wayfinding/tablet-spaces?floor_id=${floor_id}&now=${start_time}`
      )
      .then((res) => {
        setSpaces(res.data);
        axios
          .get(`https://api.vergesense.com/spaces/?floor_id=${floor_id}`, {
            headers: {
              "vs-api-key": "5034f273d1e964ed5179ba652bcc9f9d",
            },
          })
          .then((vergeres) => {
            if (vergeres.data) {
              let tempArr = [];
              res.data.forEach((element) => {
                const filtered = vergeres.data.filter(
                  (one) => one.space_ref_id === element.space_ref_id
                );
                tempArr.push(filtered[0]);
              });
              setVergeSpaces(tempArr);
            }
          });
      });
  }, [vergeSpaces]);

  const [floorData, serFloorData] = useState([]);

  useEffect(() => {
    axios.get(`${BASE_URL}/api/floors/${floor_id}`).then((response) => {
      serFloorData(response.data);
      if (response.data) {
        getSpaces();
      }
    });
  }, []);

  useEffect(() => {
    if (spaces?.length > 0) {
      return spaces.forEach((element) => {
        if (element?.poly_coordinates !== null) {
          const parsed = JSON.parse(element.poly_coordinates);
          if (parsed[0].circles.length > 0 && parsed[0].circles !== null) {
            parsed[0].circles.forEach((onecircle) => {
              setXyzCirc((prev) => [
                ...prev,
                {
                  x: onecircle.x,
                  y: onecircle.y,
                  type: onecircle.type,
                  space: element.description,
                  space_ref_id: element.space_ref_id,
                },
              ]);
            });
          }
          if (parsed[0].paths.length > 0 && parsed[0].paths !== null) {
            parsed[0].paths.forEach((onepath) => {
              setXyzPaths((prev) => [
                ...prev,
                {
                  d: onepath.d,
                  type: onepath.type,
                  space: element.description,
                  space_ref_id: element.space_ref_id,
                },
              ]);
            });
          }
        }
      });
    }
  }, [spaces]);

  useEffect(() => {
    if (vergeSpaces?.length > 0) {
      return spaces?.forEach((space) => {
        let myVergSpace = vergeSpaces.filter(
          (one) => one?.space_ref_id === space?.space_ref_id
        );
        if (space.poly_coordinates !== null && space.space_ref_id !== null) {
          const parsed = JSON.parse(space.poly_coordinates);
          if (parsed[0].circles.length > 0 && parsed[0].circles !== null && myVergSpace[0]) {
            for (let i = 0; i < myVergSpace[0].people.count; i++) {
              const element = parsed[0].circles[i];
              if (
                element !== undefined &&
                element !== null &&
                element !== "" &&
                element.x &&
                element.y
              ) {
                setApiCordinatesCirc((prev) => [
                  ...prev,
                  {
                    x: element.x,
                    y: element.y,
                    type: element.type,
                    space: space.description,
                    space_ref_id: space.space_ref_id,
                  },
                ]);
              }
            }
          }
        }
      });
    }
  }, [vergeSpaces]);

  useEffect(() => {
    if (apiCordinatesCirc?.length > 0) {
      setLoading(true);
    }
    if (apiCordinatesCirc.length > 0 && updateState === 0) {
      setTimeout(() => {
        const savedCircles = apiCordinatesCirc;
        const allCircles = document.querySelectorAll("svg circle");

        if (savedCircles && savedCircles.length > 0) {
          allCircles.forEach((element) => {
            savedCircles.forEach((circle) => {
              if (
                element.attributes.cx.nodeValue === circle.x &&
                element.attributes.cy.nodeValue === circle.y &&
                element.localName === circle.type
              ) {
                element.classList.add("assignedAlready");
                setLoading(false);
              }
            });
          });
        }
        setUpdateState(1);
      }, 4000);
    }
  }, [apiCordinatesCirc]);

  useEffect(() => {
    if (xyzCirc?.length > 0) {
      setLoading(true);
    }
    if (xyzCirc.length > 0) {
      const assignedCircls = xyzCirc;
      const allCircles = document.querySelectorAll("svg circle");

      if (assignedCircls && assignedCircls.length > 0) {
        allCircles.forEach((element) => {
          assignedCircls.forEach((circle) => {
            if (
              element.attributes.cx.nodeValue === circle.x &&
              element.attributes.cy.nodeValue === circle.y &&
              element.localName === circle.type
            ) {
              element.classList.add("selected");
              setLoading(false);
            }
          });
        });
      }
    }
  }, [xyzCirc]);

  useEffect(() => {
    if (xyzPaths?.length > 0) {
      setLoading(true);
    }
    if (xyzPaths.length > 0) {
      setTimeout(() => {
        const assignedPaths = xyzPaths;
        const allPaths = document.querySelectorAll("svg path");

        if (assignedPaths && assignedPaths.length > 0) {
          allPaths.forEach((element) => {
            assignedPaths.forEach((path) => {
              if (
                element.attributes.d.nodeValue === path.d &&
                element.localName === path.type
              ) {
                element.classList.add("selected");
                setLoading(false);
              }
            });
          });
        }
      }, 2500);
    }
  }, [xyzPaths]);

  const [showPopover, setShowPopover] = useState("");
  const [popOverCordinates, setPopOverCordinates] = useState({
    top: 0,
    left: 0,
    spaceName: "",
    spaceId: "",
    currCount: undefined,
  });

  const updateCordinates = (x, y, spaceName, spaceId, currCount) => {
    setPopOverCordinates({ top: y, left: x, spaceName, spaceId, currCount });
  };

  const allMyCircles = document.querySelectorAll("svg circle.selected");
  const allMyPaths = document.querySelectorAll("svg path.selected");

  allMyCircles.forEach((element) => {
    element.onmouseover = (event) => {
      setShowPopover("showed");
      const singleCirc = xyzCirc.filter(
        (one) =>
          one.x === element.attributes.cx.nodeValue &&
          one.y === element.attributes.cy.nodeValue &&
          one.type === element.localName
      );
      let singleVergeSpace;
      if (singleCirc.length > 0) {
        singleVergeSpace = vergeSpaces.filter(
          (one) => one.space_ref_id === singleCirc[0].space_ref_id
        );
      }
      updateCordinates(
        event.clientX,
        event.clientY,
        singleCirc[0].space,
        singleCirc[0].space_ref_id,
        singleVergeSpace[0].people.count
      );
    };
    element.onmouseout = (event) => {
      setShowPopover("");
    };
  });

  allMyPaths.forEach((element) => {
    element.onmouseover = (event) => {
      setShowPopover("showed");
      const singleCirc = xyzPaths.filter(
        (one) =>
          one.d === element.attributes.d.nodeValue &&
          one.type === element.localName
      );
      let singleVergeSpace;
      if (singleCirc.length > 0) {
        singleVergeSpace = vergeSpaces.filter(
          (one) => one.space_ref_id === singleCirc[0].space_ref_id
        );
      }
      updateCordinates(
        event.clientX,
        event.clientY,
        singleCirc[0].space,
        singleCirc[0].space_ref_id,
        singleVergeSpace[0].people.count
      );
    };
    element.onmouseout = (event) => {
      setShowPopover("");
    };
  });

  useEffect(() => {
    if (window.location.pathname.includes("/livefloorplanmobile")) {
      document.querySelector("#root").style.height = "auto";
      document.querySelector("body").style.height = "auto";
      document.querySelector("#splash-screen").style.display = "none";
    }
  }, []);

  const getMeetingRoomById = (id) => {
    if (spaces.length > 0) {
      const filtered = spaces.filter((one) => one.id === parseInt(id));
      const filteredCordinates = JSON.parse(filtered[0].poly_coordinates);
      const assignedPaths = filteredCordinates[0].paths;
      const allPaths = document.querySelectorAll("svg path");

      if (assignedPaths && assignedPaths.length > 0) {
        allPaths.forEach((element) => {
          if (element.classList.contains("searchedMeetingRoom")) {
            element.classList.remove("searchedMeetingRoom");
          }
          assignedPaths.forEach((path) => {
            if (
              element.attributes.d.nodeValue === path.d &&
              element.localName === path.type
            ) {
              element.classList.add("searchedMeetingRoom");
              const filteredSpace = spaces.filter((one) => one.id == id);
              let singleVergeSpace;
              if (filteredSpace.length > 0) {
                singleVergeSpace = vergeSpaces.filter(
                  (one) => one.space_ref_id === filteredSpace[0].space_ref_id
                );
              }
              updateCordinates(
                element.getBoundingClientRect().x,
                element.getBoundingClientRect().y,
                filteredSpace[0].description,
                filteredSpace[0].space_ref_id,
                singleVergeSpace[0].people.count
              );
              setShowPopover("showed");
            }
          });
        });
      }

      const assignedCircls = filteredCordinates[0].circles;
      const allCircles = document.querySelectorAll("svg circle");

      if (assignedCircls && assignedCircls.length > 0) {
        allCircles.forEach((element) => {
          if (element.classList.contains("searchedMeetingRoom")) {
            element.classList.remove("searchedMeetingRoom");
          }
          assignedCircls.forEach((circle) => {
            if (
              element.attributes.cx.nodeValue === circle.x &&
              element.attributes.cy.nodeValue === circle.y &&
              element.localName === circle.type
            ) {
              element.classList.add("searchedMeetingRoom");
            }
          });
        });
      }
    }
  };

  useEffect(() => {
    if (space_id && spaces.length > 0 && vergeSpaces.length > 0) {
      setTimeout(() => {
        getMeetingRoomById(space_id);
      }, 2000);
    }
  }, [spaces, vergeSpaces]);

  window.onclick = (e) => {
    if (showPopover === "showed") {
      setShowPopover("");
    }
  };

  return (
    // <Card className="bg-blue-card w-100">
    //   <CardBody>
    <>
      <div className="main_svg_wrapper_live_floor">
        {/* <TransformWrapper pinch={true}>
          <TransformComponent> */}
        {/* {loading ? (
                <div className="circular_progress">
                  <div>

                  </div>
                  </div>
              ) : null} */}
        {/* <HeleonFloorOne /> */}
        {/* {floorData?.length > 0 && ( */}
        <SVG
          className="heleon_svg"
          src={toAbsoluteUrl(floorData?.url_image)}
        ></SVG>
        {/* )} */}
        {/* </TransformComponent>
        </TransformWrapper> */}
      </div>
      <div
        className={`${showPopover} custom_popover`}
        style={{
          top: popOverCordinates.top + 15,
          left: popOverCordinates.left + 15,
        }}
      >
        <Typography className="font-12">
          Space Name : <strong> {popOverCordinates.spaceName} </strong>
        </Typography>
        <Typography className="font-12">
          Space ID : <strong> {popOverCordinates.spaceId} </strong>
        </Typography>
        <Typography className="font-12">
          People Count : <strong> {popOverCordinates.currCount} </strong>
        </Typography>
      </div>
    </>
    //   </CardBody>
    // </Card>
  );
};

export default LiveFloorPlan;
