import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import AddInvitee from "./Invitees/AddInvitee";
import { Select2 } from "select2-react-component";
import { Col, Row } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
//components
import AddHost from "./Invitees/AddHost";
import AddWorkCircleModal from "../../../Settings/WorkCircles/AddWorkCircleModal";
//constants
import * as dayPassAction from "../../DayPass/_redux/Action";
import * as actions from "../_redux/Actions";
import helperFunc from "../../../../../_metronic/_helpers/helperFunc";
//css
import "./EventStep3.scss";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { Button, CircularProgress, Typography } from "@material-ui/core";

const EventStep3 = ({ handleNext }) => {
  const [addInvitee, setAddInvitee] = useState(false);
  const [addHost, setAddHost] = useState(false);
  const [workCircle, setWorkCircle] = useState(false);
  const [workCirclesListPair, setWorkCirclesListPair] = useState({});
  const [isShowAddFriendCircle, setIsShowAddFriendCircle] = useState(false);
  const [updateWorkCircleList, setUpdateWorkCircleList] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [hostDetails, setHostDetails] = useState({
    full_name: "",
    email: "",
    day_pass: false,
    car_park: false,
    user_id: "",
    url_image: "",
  });
  const [generateLink, setGenerateLink] = useState(false);
  const [openEvent, setOpenEvent] = useState(false);

  const [teamsLink, setTeamsLink] = useState("");
  const dispatch = useDispatch();
  const [userId, setUserId] = useState(helperFunc.getUserId());
  const { events } = useSelector((state) => state.events);
  const [users, SetUsers] = useState([]);
  const [visitors, SetVisitors] = useState([]);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedVisitors, setSelectedVisitors] = useState([]);
  const [workCircleUsers, setWorkCircleUsers] = useState([]);
  const [selectedWorkCircle, setSelectedWorkCircle] = useState("");
  const [addUserVisitors, setAddUserVisitors] = useState(false);

  const handleAddInvitee = () => {
    setAddInvitee(!addInvitee);
    // setAddUserVisitors(!addUserVisitors);
  };

  useEffect(() => {
    if (addInvitee === false) {
      setSelectedUsers([]);
      setSelectedVisitors([]);
    }
  }, [addInvitee]);

  useEffect(() => {
    if (workCircle === false) {
      setWorkCircleUsers([]);
      setSelectedWorkCircle("");
    }
  }, [workCircle]);

  useEffect(() => {
    if (
      events?.stepThree?.invitees?.[0]?.users.length > 0 ||
      events?.stepThree?.invitees?.[0]?.visitors.length > 0
    ) {
      setAddInvitee(true);
      setSelectedUsers(events?.stepThree?.invitees[0].users);
      setSelectedVisitors(events?.stepThree?.invitees[0].visitors);
    }
    if (events?.stepThree?.invitees?.[0]?.workCircleUsers.length > 0) {
      setWorkCircle(true);
      setWorkCircleUsers(events?.stepThree?.invitees[0].workCircleUsers);
    }
    if (events?.stepThree?.openEvent === true) {
      setOpenEvent(events?.stepThree?.openEvent);
    }
  }, []);

  const handleAddHost = () => {
    setAddHost(!addHost);
  };

  const handleWorkCircle = () => {
    setWorkCircle(!workCircle);
  };

  const { workCirclesList } = useSelector(
    (state) => ({ workCirclesList: state.dayPass.workCircleList }),
    shallowEqual
  );

  const localstorageUserData = helperFunc.getUserData();

  useEffect(() => {
    if (workCirclesList.length) {
      let workCirclesListPair = workCirclesList?.map((item) => {
        return {
          value: item.id,
          label: item.title,
        };
      });
      setWorkCirclesListPair(workCirclesListPair);
    }
  }, [workCirclesList, updateWorkCircleList]);

  const handleAddFriendCircleShow = () => {
    setIsShowAddFriendCircle(!isShowAddFriendCircle);
    // onHide()
    // setToggleFriendCircle(false)
  };
  const handleAddFriendCircleClose = () => {
    setIsShowAddFriendCircle(!isShowAddFriendCircle);
  };

  const updateList = (data) => {
    if (data === "update") {
      setUpdateWorkCircleList(true);
      /*  setSelectedVisitor(undefined)
      setShowAddNewVisitor(false)
      setCount(count + 1) */
    }
  };

  useEffect(() => {
    if (events?.stepOne?.event_type === "virtual") {
      setGenerateLink(!generateLink);
    }
  }, []);

  useEffect(() => {
    const params = {
      user_id: userId,
    };
    dispatch(dayPassAction.getWorkCircleList(params));
    setUpdateWorkCircleList(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateWorkCircleList]);

  const siteId = helperFunc.getUserData();

  useEffect(() => {
    const params = siteId?.site_id;
    dispatch(actions.getAllEventHost(params)).then((res) => {
      return Object.entries(res)?.forEach((element) => {
        if (element[0] === "guests") {
          return element[1]?.forEach((single) => {
            SetVisitors((prev) => [
              ...prev,
              {
                value: single.id,
                label: single.full_name,
                email: single.email,
                url_image: single.url_image,
              },
            ]);
          });
        }
        if (element[0] === "users") {
          return element[1]?.forEach((single) => {
            SetUsers((prev) => [
              ...prev,
              {
                value: single.id,
                label: single.full_name,
                email: single.email,
                url_image: single.url_image,
              },
            ]);
          });
        }
        setLoading(false);
      });
    });
  }, []);

  //generate teams link

  // const msParams = {
  //   subject: events.stepOne.name,
  //   body: {
  //     contentType: "HTML",
  //     content: events.stepOne.details,
  //   },
  //   start: {
  //     dateTime: events.stepTwo.starts_at,
  //     timeZone: "UTC",
  //   },
  //   end: {
  //     dateTime: events.stepTwo.ends_at,
  //     timeZone: "UTC",
  //   },
  //   isOnlineMeeting: generateLink,
  //   location: {
  //     displayName: "",
  //   },
  // };

  // function prepareFormDataForRefresh() {
  //   let formData = new FormData();

  //   formData.append("grant_type", "refresh_token");
  //   formData.append(
  //     "refresh_token",
  //     localStorage.getItem("ms_teams_refresh_token")
  //   );
  //   formData.append("client_id", "dca72320-8cd6-4ce1-b860-211b240a3bce");
  //   formData.append(
  //     "redirect_uri",
  //     "https://staging.bookify.space/api/saml2/cs"
  //   );
  //   formData.append("scope", "offline_access  Calendars.ReadWrite");
  //   return formData;
  // }

  // const refreshOAuthToken = (data) => {
  //   const formDataStructured = prepareFormDataForRefresh();
  //   fetch("https://login.microsoftonline.com/common/oauth2/v2.0/token", {
  //     method: "POST",
  //     body: formDataStructured,
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       if (data?.access_token && data?.refresh_token) {
  //         localStorage.setItem("ms_teams_access_token", data.access_token);
  //         localStorage.setItem("ms_teams_refresh_token", data.refresh_token);

  //         fetch("https://graph.microsoft.com/v1.0/me/events", {
  //           method: "POST",
  //           headers: {
  //             "content-type": "application/json",
  //             Authorization: `Bearer ${getMsAccessToken}`,
  //           },
  //           body: JSON.stringify(msParams),
  //         })
  //           .then((res) => res.json())
  //           .then((data) => {
  //             if (
  //               data?.error &&
  //               data?.error_description.includes("token has expired")
  //             ) {
  //               refreshOAuthToken();
  //             } else {
  //               if (data?.onlineMeeting?.joinUrl) {
  //                 setTeamsLink(data?.onlineMeeting?.joinUrl);
  //               } else {
  //                 return;
  //               }
  //             }
  //           });
  //       }
  //     });
  // };

  // const getMsAccessToken = localStorage.getItem("ms_teams_access_token");

  // const fetchTeamsLink = () => {
  //   debugger;
  //   fetch("https://graph.microsoft.com/v1.0/me/events", {
  //     method: "POST",
  //     headers: {
  //       "content-type": "application/json",
  //       Authorization: `Bearer ${getMsAccessToken}`,
  //     },
  //     body: JSON.stringify(msParams),
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       debugger;
  //       if (
  //         data?.error &&
  //         data?.error?.code === "UnableToDeserializePostBody"
  //       ) {
  //         refreshOAuthToken();
  //       } else {
  //         if (data?.onlineMeeting?.joinUrl) {
  //           setTeamsLink(data?.onlineMeeting?.joinUrl);
  //         } else {
  //           return;
  //         }
  //       }
  //     });
  // };

  // useEffect(() => {
  //   if (generateLink === "on") {
  //     fetchTeamsLink();
  //   }
  // }, [generateLink]);

  const handleRemoveUser = (id) => {
    setSelectedUsers(selectedUsers.filter((one) => one.user_id !== id));
  };

  const handleRemoveVisitor = (id) => {
    setSelectedVisitors(selectedVisitors.filter((one) => one.user_id !== id));
  };

  const handleRemoveWorkCircleUser = (id) => {
    setWorkCircleUsers(workCircleUsers.filter((one) => one.user_id !== id));
  };

  const handleSelectWorkCircle = (id) => {
    setSelectedWorkCircle(id);
    setWorkCircleUsers([]);
    const filtered = workCirclesList.filter((one) => one.id === id);
    if (filtered[0]?.users.length > 0) {
      filtered[0].users.forEach((element) => {
        setWorkCircleUsers((prev) => [
          ...prev,
          {
            user_id: element.id,
            full_name: element.full_name,
            email: element.email,
            day_pass: false,
            car_park: false,
            url_image: element.url_image,
            type: "Work Circle",
          },
        ]);
      });
    }
  };

  const { defaultAccessData } = useSelector((state) => state.booking);

  return (
    <>
      <div className="event-step3-main">
        <div className="row">
          <div className="col-lg-12 event-select-time rounded p-5">
            {loading === false ? (
              <>
                <span className="font-weight-bolder d-flex justify-content-start">
                  ADD INVITEES
                </span>
                <div className="event-host d-flex justify-content-around mt-3 w-100">
                  <div className="align-items-center d-flex py-3">
                    {hostDetails.url_image !== null &&
                      hostDetails.url_image !== "" ? (
                      <img
                        width="55px"
                        src={
                          hostDetails.url_image
                            ? hostDetails.url_image
                            : toAbsoluteUrl("/media/svg/icons/default_user.svg")
                        }
                        alt="Host"
                        className="max-w-70px rounded-circle ml-2"
                      />
                    ) : (
                      <img
                        width="55px"
                        src={
                          localstorageUserData.url_image
                            ? localstorageUserData.url_image
                            : toAbsoluteUrl("/media/svg/icons/default_user.svg")
                        }
                        alt="Host"
                        className="max-w-70px rounded-circle ml-2"
                      />
                    )}
                    <div className="event-details-main pl-5">
                      <span className="event-lbl font-weight-bold d-block mb-2">
                        {hostDetails?.full_name !== ""
                          ? hostDetails?.full_name
                          : localstorageUserData.full_name}
                      </span>
                      <span className="event-no text-primary">{"HOST"}</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <img
                      src="/media/vehicles/edit.png"
                      alt="edit"
                      style={{ width: "40px", marginRight: "10px" }}
                      className="cursor-pointer"
                      onClick={handleAddHost}
                    />
                  </div>
                </div>
                {/* Teams & Intergration */}
                <div className="teams-main-class">
                  {defaultAccessData?.is_team_link && (
                    <div className="d-flex justify-content-between border-class">
                      <SVG src="/media/event/teams.svg" />
                      <span className="mt-1 link-generate">
                        Generate Teams Link
                      </span>

                      <div className="button-cursor custom-control custom-switch align-self-sm-center">
                        <input
                          name={`teams_integration`}
                          type="checkbox"
                          id={"teams_integration"}
                          className="custom-control-input"
                          checked={generateLink}
                          onChange={() => {
                            setGenerateLink(!generateLink);
                          }}
                        // checked={values?.teams_integration}
                        // onChange={handleChange}
                        />
                        <label
                          title=""
                          htmlFor={"teams_integration"}
                          className="custom-control-label"
                        ></label>
                      </div>
                    </div>
                  )}
                  {generateLink === true && teamsLink !== "" && (
                    <div className="d-flex justify-content-between border-class">
                      <span className="mt-1 link-text">
                        https://bookify/asdnfkdnnreojsdmcljiofj
                      </span>
                      <SVG src="/media/event/copyicon.svg" />
                    </div>
                  )}

                  <div className="d-flex justify-content-between border-class">
                    {/* <SVG src="/media/event/teams.svg" /> */}
                    <span className="mt-1 link-generate">Open Event</span>

                    <div className="button-cursor custom-control custom-switch align-self-sm-center">
                      <input
                        name={`open_event`}
                        type="checkbox"
                        id={"open_event"}
                        className="custom-control-input"
                        checked={openEvent}
                        onChange={() => {
                          setOpenEvent(!openEvent);
                        }}
                      // checked={values?.open_event}
                      // onChange={handleChange}
                      />
                      <label
                        title=""
                        htmlFor={"open_event"}
                        className="custom-control-label"
                      ></label>
                    </div>
                  </div>
                  {/* {addInvitee && (
                    <div>
                      <Button
                        onClick={() => setAddUserVisitors(true)}
                        className="d-flex ml-auto mt-3 btn btn-primary"
                        variant="primary"
                      >
                        {selectedUsers.length > 0 || selectedVisitors.length > 0
                          ? "Edit Invitees"
                          : "+ Add Invitees"}
                      </Button>
                    </div>
                  )} */}
                  {openEvent === false && (
                    <div className="d-flex justify-content-between border-class">
                      <span className="mt-1 link-generate">Add Invitees</span>
                      <div className="button-cursor custom-control custom-switch align-self-sm-center">
                        <input
                          name={`add_invitee`}
                          type="checkbox"
                          id={"add_invitee"}
                          className="custom-control-input"
                          checked={addInvitee}
                          onChange={handleAddInvitee}
                        />
                        <label
                          title=""
                          htmlFor={"add_invitee"}
                          className="custom-control-label"
                        ></label>
                      </div>
                    </div>
                  )}
                  {addInvitee && (
                    <>
                      <div>
                        <Button
                          onClick={() => setAddUserVisitors(true)}
                          className="d-flex w-100 ml-auto mt-3 btn btn-primary"
                          variant="primary"
                        >
                          {selectedUsers.length > 0 ||
                            selectedVisitors.length > 0
                            ? "Edit Invitees"
                            : "+ Add Invitees"}
                        </Button>
                      </div>
                      <div className="d-flex justify-content-between border-class">
                        <div className="event-work-circle">
                          <SVG
                            src="/media/event/work-circle.svg"
                            className=""
                          />
                          <span className="mt-1 ml-1 link-generate">
                            Add Work Circle
                          </span>
                        </div>
                        <div className="button-cursor custom-control custom-switch align-self-sm-center">
                          <input
                            name={`add_workcircle`}
                            type="checkbox"
                            id={"add_workcircle"}
                            className="custom-control-input"
                            checked={workCircle}
                            onChange={handleWorkCircle}
                          />
                          <label
                            title=""
                            htmlFor={"add_workcircle"}
                            className="custom-control-label"
                          ></label>
                        </div>
                      </div>
                      {workCircle && (
                        <div className="select-time bg-white border mt-3">
                          <div className="select-friend-circle">
                            <Row className="font-weight-bolder">
                              <Col
                                md={12}
                                className="d-flex justify-content-between"
                              >
                                <div className="friend-circle-select-title-text">
                                  WORK CIRCLE
                                </div>
                                {/* <div
                                  className="button-cursor add-vehicle"
                                  onClick={handleAddFriendCircleShow}
                                >
                                  + CREATE NEW
                                </div> */}
                              </Col>
                            </Row>
                          </div>

                          <div className="space-1"></div>
                          <div className="p-3">
                            <div className="selcet-vehicle">
                              Select work circle from the list:
                            </div>

                            <Select2
                              placeholder="Select WorkCircle"
                              data={workCirclesListPair}
                              value={selectedWorkCircle}
                              update={(value) => handleSelectWorkCircle(value)}
                              className="mr-3"
                            ></Select2>
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  {selectedUsers.length > 0 &&
                    addUserVisitors === false &&
                    selectedUsers.map((one) => {
                      return (
                        <div className="event-host d-flex mt-3 w-100">
                          <div className="align-items-center d-flex py-3">
                            <img
                              width="55px"
                              src={
                                one?.url_image
                                  ? one?.url_image
                                  : toAbsoluteUrl(
                                    "/media/svg/icons/default_user.svg"
                                  )
                              }
                              alt="Host"
                              className="max-w-70px rounded-circle ml-2"
                            />
                            <div className="event-details-main pl-5">
                              <span className="event-lbl font-weight-bold d-block mb-2">
                                {one?.full_name !== ""
                                  ? one?.full_name
                                  : one?.full_name}
                              </span>
                              <span className="event-no text-primary">
                                {one?.type}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center ml-auto">
                            {/* <img
                              src="/media/vehicles/edit.png"
                              alt="edit"
                              style={{ width: "40px", marginRight: "10px" }}
                              className="cursor-pointer"
                              onClick={handleEditUserVisitor}
                            /> */}
                            <img
                              src="/media/vehicles/delete.png"
                              alt="edit"
                              style={{ width: "40px", marginRight: "10px" }}
                              className="cursor-pointer"
                              onClick={() => handleRemoveUser(one.user_id)}
                            />
                          </div>
                        </div>
                      );
                    })}
                  {selectedVisitors.length > 0 &&
                    addUserVisitors === false &&
                    selectedVisitors.map((one) => {
                      return (
                        <div className="event-host d-flex mt-3 w-100">
                          <div className="align-items-center d-flex py-3">
                            <img
                              width="55px"
                              src={
                                one?.url_image
                                  ? one?.url_image
                                  : toAbsoluteUrl(
                                    "/media/svg/icons/default_user.svg"
                                  )
                              }
                              alt="Host"
                              className="max-w-70px rounded-circle ml-2"
                            />
                            <div className="event-details-main pl-5">
                              <span className="event-lbl font-weight-bold d-block mb-2">
                                {one?.full_name !== ""
                                  ? one?.full_name
                                  : one?.full_name}
                              </span>
                              <span className="event-no text-primary">
                                {one?.type}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center ml-auto">
                            {/* <img
                              src="/media/vehicles/edit.png"
                              alt="edit"
                              style={{ width: "40px", marginRight: "10px" }}
                              className="cursor-pointer"
                              onClick={handleEditUserVisitor}
                            /> */}
                            <img
                              src="/media/vehicles/delete.png"
                              alt="edit"
                              style={{ width: "40px", marginRight: "10px" }}
                              className="cursor-pointer"
                              onClick={() => handleRemoveVisitor(one.user_id)}
                            />
                          </div>
                        </div>
                      );
                    })}
                  {workCircleUsers.length > 0 &&
                    addUserVisitors === false &&
                    workCircleUsers.map((one) => {
                      return (
                        <div className="event-host d-flex mt-3 w-100">
                          <div className="align-items-center d-flex py-3">
                            <img
                              width="55px"
                              src={
                                one?.url_image
                                  ? one?.url_image
                                  : toAbsoluteUrl(
                                    "/media/svg/icons/default_user.svg"
                                  )
                              }
                              alt="Host"
                              className="max-w-70px rounded-circle ml-2"
                            />
                            <div className="event-details-main pl-5">
                              <span className="event-lbl font-weight-bold d-block mb-2">
                                {one?.full_name !== ""
                                  ? one?.full_name
                                  : one?.full_name}
                              </span>
                              <span className="event-no text-primary">
                                Work Circle User
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center ml-auto">
                            {/* <img
                              src="/media/vehicles/edit.png"
                              alt="edit"
                              style={{ width: "40px", marginRight: "10px" }}
                              className="cursor-pointer"
                              onClick={handleEditUserVisitor}
                            /> */}
                            <img
                              src="/media/vehicles/delete.png"
                              alt="edit"
                              style={{ width: "40px", marginRight: "10px" }}
                              className="cursor-pointer"
                              onClick={() =>
                                handleRemoveWorkCircleUser(one.user_id)
                              }
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              </>
            ) : (
              <Typography align="center">
                <CircularProgress disableShrink size={20} color="#000" />
              </Typography>
            )}
          </div>
        </div>
      </div>
      {error &&
        workCircleUsers.length === 0 &&
        selectedUsers.length === 0 &&
        selectedVisitors.length === 0 && (
          <p className="text-danger mt-3">{error}</p>
        )}
      <button
        id="kt_login_signin_submit"
        type="submit"
        className={`btn btn-primary d-flex justify-content-between align-items-center font-weight-bolder mt-5 w-100`}
        onClick={() => {
          if (
            addInvitee &&
            workCircleUsers.length === 0 &&
            selectedUsers.length === 0 &&
            selectedVisitors.length === 0
          ) {
            setError("Please add at least one user or visitor or work circle.");
          } else {
            const invitees = [
              {
                users: selectedUsers,
                visitors: selectedVisitors,
                workCircleUsers,
              },
            ];
            const formData = {
              host: {
                user_id: hostDetails.user_id || localstorageUserData.id,
                user_name:
                  hostDetails.full_name || localstorageUserData.full_name,
                user_image:
                  hostDetails.url_image || localstorageUserData.url_image,
                day_pass: hostDetails.day_pass || false,
                car_park: hostDetails.car_park || false,
              },
              invitees: invitees,
              generateLink: generateLink,
              openEvent: openEvent,
            };

            dispatch(actions.handleChangeStepThree(formData));

            handleNext();
          }
        }}
      >
        <span />
        <span>NEXT</span>
        <span>
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/akar-icons_arrow-right.svg")}
          />
        </span>
      </button>
      <AddInvitee
        show={addUserVisitors}
        handleClose={() => setAddUserVisitors(false)}
        users={users}
        visitors={visitors}
        selectedUsers={selectedUsers}
        selectedVisitors={selectedVisitors}
        setSelectedUsers={setSelectedUsers}
        setSelectedVisitors={setSelectedVisitors}
      />
      <AddHost
        setHostDetails={(details) => setHostDetails(details)}
        users={users}
        show={addHost}
        handleClose={() => setAddHost(false)}
      />
      <AddWorkCircleModal
        show={isShowAddFriendCircle}
        updateList={updateList}
        onHide={handleAddFriendCircleClose}
      />
    </>
  );
};

export default EventStep3;
