export const StatusCssClasses = ["success", "danger", ""]
export const StatusTitles = ["Active", "In Active"]
export const VideoStatusCssClasses = ["", "warning", "success", "info", "danger"]
export const VideoStatusTitles = ["", "Pending", "Approved", "On Hold", "Rejected"]
export const TicketStatusCssClasses = ["", "warning", "info", "info", "success", "danger"]
export const TicketStatusTitles = ["", "Pending", "Replied", "In Progress", "Approved", "Rejected"]

export const WithdrawalStatusCssClasses = ["", "warning", "success", "info", "danger"]
export const WithdrawalStatusTitles = ["", "Pending", "Approved", "On Hold", "Rejected"]
export const WithdrawalModeTitles = ["", "Bank", "UPI", "PayTM", "Amazon Pay"]
export const ConditionCssClasses = ["success", "danger", ""]
export const ConditionTitles = ["New", "Used"]
export const defaultSorted = [{ dataField: "id", order: "asc" }]
export const limit = 10
export const skip = 0
export const page = 1
export const gender = {
  M: "Male",
  F: "Female",
}
export const AdLogType = ["", "Impression", "Click"]
export const AmountType = ["", "Coin", "Avtar"]
// export const AmountType = ['','Point', 'Bonus', 'Reward Deposite', 'Coin', 'Rupee'];
export const TransactionType = ["Pending", "Credit", "Debit", "On Hold", "Rejected"]
export const PaymentType = [
  "Pending",
  "Promotional",
  "join League",
  "Referral",
  "Refund",
  "Add Balance",
  "Withdrawal requested",
  "Withdrawal approved",
  "Withdrawal request rejected",
  "Won the league",
  "Deposit Bonus",
  "SignUp Codes",
  "Penalty or Charges",
  "Signup Bonus",
  "Duplicate Refunds",
  "FE Refunds",
  "DailyReward",
  "Buy Coins",
  "Add Coins",
  "CoinReward Redeem",
  "Buy Coins",
  "Buy Avatar",
  "One time coin bonus",
]
export const bookinTypes = {
  day_pass: "day-pass-bg",
  host: "visitor-bg",
  other: "mitting-bg",
  parking: "parking-bg",
}
export const couponTypes = {
  couponCode: "COUPON CODE",
  promoCode: "PROMO CODE",
  signupCode: "SIGNUP CODE",
  referalCode: "REFERAL CODE",
}

export const bookingType = {
  couponCode: "COUPON CODE",
  promoCode: "PROMO CODE",
  signupCode: "SIGNUP CODE",
  referalCode: "REFERAL CODE",
}

export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "30", value: 30 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
  { text: "200", value: 220 },
]
export const CONTEST_AUDIT_STATUS = ["Pending", "Completed"]
export const CONTEST_AUDIT_STATUS_BG = ["bg-info", "bg-primary"]
export const CONTEST_STATUS = {
  waiting: "waiting",
  started: "started",
  inProcess: "inProcess",
  review: "review",
  cancelled: "cancelled",
  completed: "completed",
}
export const CONTEST_STATUS_BTN = {
  waiting: "btn-dark",
  started: "btn-light",
  inProcess: "btn-info",
  review: "btn-warning",
  cancelled: "btn-danger",
  completed: "btn-primary",
}
export const CONTEST_STATUS_BG = {
  waiting: "bg-dark",
  started: "bg-light",
  inProcess: "bg-info",
  review: "bg-warning",
  cancelled: "bg-danger",
  completed: "bg-primary",
}
export const CONTEST_STATUS_BORDER = {
  waiting: "border-dark",
  started: "border-light",
  inProcess: "border-info",
  review: "border-warning",
  cancelled: "border-danger",
  completed: "border-primary",
}

export const AVAILABLE_COLORS = [
  "Red",
  "CadetBlue",
  "Eagle",
  "Gold",
  "LightSlateGrey",
  "RoyalBlue",
  "Crimson",
  "Blue",
  "Sienna",
  "Indigo",
  "Green",
  "Violet",
  "GoldenRod",
  "OrangeRed",
  "Khaki",
  "Teal",
  "Purple",
  "Orange",
  "Pink",
  "Black",
  "DarkTurquoise",
]

export const AVAILABLE_MANUFACTURES = [
  "Pontiac",
  "Kia",
  "Lotus",
  "Subaru",
  "Jeep",
  "Isuzu",
  "Mitsubishi",
  "Oldsmobile",
  "Chevrolet",
  "Chrysler",
  "Audi",
  "Suzuki",
  "GMC",
  "Cadillac",
  "Infinity",
  "Mercury",
  "Dodge",
  "Ram",
  "Lexus",
  "Lamborghini",
  "Honda",
  "Nissan",
  "Ford",
  "Hyundai",
  "Saab",
  "Toyota",
]

export const camelCaseToNormalCase = (word) => {
  const result = word && word.replace(/([A-Z])/g, " $1")
  const finalResult = result && result.charAt(0).toUpperCase() + result.slice(1)
  return finalResult
}
