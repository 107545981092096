import React, { useState, useEffect } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { useSelector } from "react-redux";
import helperFunc from "../../../../_metronic/_helpers/helperFunc";
import moment from "moment";

export default function MultipleDatePickerAvailabilitySpace(props) {
  const {
    selectedDates,
    setSelectedMultipleDate,
    setIsValidDates,
    bookingType,
  } = props;
  const [availabilityDates, setAvailabilityDates] = useState();

  const datepickerStore = useSelector((state) => state.customDatePicker);

  useEffect(() => {
    let availableDates = {};
    if (datepickerStore?.multiDateSelectedData) {
      let multipleDates = datepickerStore?.multiDateSelectedData;
      for (const key in multipleDates) {
        availableDates[`${key}`] = helperFunc.calculateDateAvailability(
          "dayPass",
          multipleDates[key]
        );
      }
      setAvailabilityDates(availableDates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datepickerStore?.multiDateSelectedData]);
  const handleCloseMultipleDates = (date) => {
    if (selectedDates.includes(date)) {
      setSelectedMultipleDate(
        selectedDates.filter((cancelDate) => cancelDate !== date)
      );
    }
  };
  useEffect(() => {
    if (availabilityDates) {
      const getBackgroundProperty = Object.fromEntries(
        Object.entries(availabilityDates).map(([key, val]) => [
          key,
          val?.backgroundColor,
        ])
      );
      setIsValidDates(Object.values(getBackgroundProperty));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availabilityDates]);

  return (
    <>
      <div className="multi-date-availability-parent-div">
        {bookingType === "dayPass" && (
          <div className="multi-date-availability-space">
            <div className="d-flex multi-date-availability-wrap-div ">
              <div className="d-flex multi-date-availability-status-div">
                <div className="multi-date-availability-status-design red-dot"></div>
                <p>Busy</p>
              </div>
              <div className="d-flex multi-date-availability-status-div">
                <div className="multi-date-availability-status-design yellow-dot"></div>
                <p>Moderate</p>
              </div>
              <div className="d-flex multi-date-availability-status-div">
                <div className="multi-date-availability-status-design green-dot"></div>
                <p>Light</p>
              </div>
            </div>
          </div>
        )}
        <div className="d-flex flex-wrap mt-1">
          {selectedDates &&
            selectedDates.map((date, key) => (
              <div
                key={key}
                className={`d-flex justify-content-center align-items-center p-2 occupancy-label-wrap ${bookingType === "dayPass" && availabilityDates
                  ? availabilityDates[`${date.format("YYYY-MM-DD")}`]
                    ?.backgroundColor + " day-status-section"
                  : ""
                  }`}
              >
                <SVG
                  className="svg-icon"
                  src={toAbsoluteUrl("/media/svg/icons/ticket-white.svg")}
                />{" "}
                &nbsp;
                <b>
                  {moment(date)?.format("MMM DD") === "Invalid date1"
                    ? moment(date?._i, "MM-DD-YYYY")?.format("MMM DD")
                    : moment(date)?.format("MMM DD")}{" "}
                </b>
                <div
                  className="multiple-date-cancel-selected-day d-flex align-items-center justify-content-center"
                  onClick={() => handleCloseMultipleDates(date)}
                >
                  <span className="">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/red-cross.svg")}
                    ></SVG>
                  </span>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}
