import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { put, takeLatest } from "redux-saga/effects"
import { getUserByToken } from "./authCrud"

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
}

const initialAuthState = {
  user: undefined,
  authToken: undefined,
}

export const reducer = persistReducer(
  { storage, key: "v726-demo1-auth", whitelist: ["authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const authToken = action?.payload?.data?.access_token
        localStorage.setItem("token", JSON.stringify(authToken))
        localStorage.setItem(
          "username",
          JSON.stringify(action?.payload?.data?.user[0]?.email)
        )
        localStorage.setItem(
          "userData",
          JSON.stringify(action?.payload?.data?.user[0])
        )
        return { authToken, user: action?.payload?.data?.user[0] }
      }

      case actionTypes.Register: {
        const authToken = action.payload?.authToken?.access_token
        localStorage.setItem("token", JSON.stringify(authToken))
        localStorage.setItem(
          "username",
          JSON.stringify(action?.payload?.authToken?.user[0]?.email)
        )
        localStorage.setItem(
          "userData",
          JSON.stringify(action?.payload?.authToken?.user[0])
        )

        return { authToken, user: action?.payload?.authToken?.user[0] }
      }

      case actionTypes.Logout: {
        localStorage.removeItem("token")
        localStorage.removeItem("username")
        localStorage.removeItem("userData")
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload
        // const user = action.payload.user;
        return { ...state, user }
      }

      case actionTypes.SetUser: {
        const { user } = action.payload
        return { ...state, user }
      }

      default:
        return state
    }
  }
)

export const actions = {
  login: (data) => ({ type: actionTypes.Login, payload: { data } }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
}

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    // const { data: user } = yield getUserByToken();
    // const user = JSON.parse(localStorage.getItem("userData"));
    const user = yield getUserByToken()

    yield put(actions.fulfillUser(user))
  })
}
