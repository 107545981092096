/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import objectPath from "object-path";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { checkIsActive, toAbsoluteUrl } from "../../../_helpers";
import { Brand } from "../brand/Brand";
// import { KTUtil } from "./../../../_assets/js/components/util";
import { Link, useLocation } from "react-router-dom";
import helperFunc from "../../../_helpers/helperFunc";

export function Aside() {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  const layoutProps = useMemo(() => {
    return {
      asideClassesFromConfig: uiService.getClasses("aside", true),
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes("aside_menu"),
      ulClasses: uiService.getClasses("aside_menu_nav", true),
      asideMenuClassesFromConfig: uiService.getClasses("aside_menu", true),
      asideSecondaryDisplay: objectPath.get(
        uiService.config,
        "aside.secondary.display"
      ),
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        "aside.self.minimize.toggle"
      ),
      extrasSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      extrasNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      extrasQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      extrasQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      extrasLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      extrasUserDisplay: objectPath.get(
        uiService.config,
        "extras.user.display"
      ),
    };
  }, [uiService]);

  return (
    <>
      {/* begin::Aside */}
      <div
        id="kt_aside"
        className={`aside aside-left d-flex ${layoutProps.asideClassesFromConfig}`}
      >
        {/* begin::Primary */}
        <div className="aside-primary d-flex flex-column align-items-center flex-row-auto">
          <Brand />
          {/* begin::Nav Wrapper */}
          <div className="aside-nav d-flex flex-column align-items-center flex-column-fluid w-100 pr-0 mr-0 scroll scroll-pull">
            <div id="kt_aside_menu" className="aside-menu w-100">
              <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                {helperFunc.isMenuShow("normalDashboard") && (
                  <li
                    className={`menu-item mb-3 ${getMenuItemActive(
                      "/dashboard"
                    )}`}
                  >
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip id="latest-project">Dashboard</Tooltip>}
                    >
                      <Link
                        className="menu-link nav-link btn btn-icon btn-clean btn-lg my-menu-item"
                        to="/dashboard"
                      >
                        <span className="svg-icon svg-icon-lg menu-icon">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/menu/dashboard-icon.svg"
                            )}
                          />
                        </span>
                        <span className="menu-text">Dashboard</span>
                      </Link>
                    </OverlayTrigger>
                  </li>
                )}
                {helperFunc.isMenuShow("calendar") && (
                  <li
                    className={`menu-item mb-3 ${getMenuItemActive(
                      "/calendar/list"
                    )}`}
                  >
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="latest-project" className="tooltip-class" style={{ minWidth: "500px !important" }}>
                          <span style={{ minWidth: "500px !important" }} >Meeting Room Availability</span>
                        </Tooltip>
                      }
                    >
                      <Link
                        className="menu-link nav-link btn btn-icon btn-clean btn-lg my-menu-item"
                        to="/calendar/list"
                      >
                        <span className="svg-icon svg-icon-lg menu-icon">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/meeting-room-icn.svg"
                            )}
                          />
                        </span>
                        <span className="menu-text">
                          Meeting Room Availability
                        </span>
                      </Link>
                    </OverlayTrigger>
                  </li>
                )}
                {helperFunc.getUserData().account_id == 7 && (
                  <li
                    className={`menu-item mb-3 ${getMenuItemActive(
                      "/bulletin/list"
                    )}`}
                  >
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="latest-project" className="tooltip-class">
                          <span  >Bulletin</span>
                        </Tooltip>
                      }
                    >
                      <Link
                        className="menu-link nav-link btn btn-icon btn-clean btn-lg my-menu-item"
                        to="/bulletin/list"
                      >
                        <span className="svg-icon svg-icon-lg menu-icon">

                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/menu/news.svg"
                            )}
                          />
                        </span>
                        <span className="menu-text">
                          Bulletin
                        </span>
                      </Link>
                    </OverlayTrigger>
                  </li>
                )}
                {helperFunc.isMenuShow("calendar") && (
                  <li
                    className={`menu-item mb-3 ${getMenuItemActive(
                      "/calendar/view"
                    )}`}
                  >
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip id="latest-project">Calendar</Tooltip>}
                    >
                      <Link
                        className="menu-link nav-link btn btn-icon btn-clean btn-lg my-menu-item"
                        to="/calendar/view"
                      >
                        <span className="svg-icon svg-icon-lg menu-icon">
                          <SVG
                            src={toAbsoluteUrl("/media/svg/menu/calendar.svg")}
                          />
                        </span>
                        <span className="menu-text">Calendar</span>
                      </Link>
                    </OverlayTrigger>
                  </li>
                )}
                {console.log("feedback", helperFunc.isMenuShow("feedback"))}
                {helperFunc.isMenuShow("feedback") && (
                  <li
                    className={`menu-item mb-3 ${getMenuItemActive(
                      "/calendar/viewfeedback/list"
                    )}`}
                  >
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip id="latest-project">Feedback</Tooltip>}
                    >
                      <Link
                        className="menu-link nav-link btn btn-icon btn-clean btn-lg my-menu-item"
                        to="/feedback/list"
                      >
                        <span className="svg-icon svg-icon-lg menu-icon">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/feedback-logo.svg"
                            )}
                          />
                        </span>
                        <span className="menu-text">FeedBack</span>
                      </Link>
                    </OverlayTrigger>
                  </li>
                )}
                {/* {helperFunc.isMenuShow("roomAvailability") && (
                  <li
                    className={`menu-item mb-3 ${getMenuItemActive(
                      "/calendar/room-availability"
                    )}`}
                  >
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="latest-project">Room Availability</Tooltip>
                      }
                    >
                      <Link
                        className="menu-link nav-link btn btn-icon btn-clean btn-lg my-menu-item"
                        to="/calendar/room-availability"
                      >
                        <span className="svg-icon svg-icon-lg menu-icon">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/menu/room-availability.svg"
                            )}
                          />
                        </span>
                        <span className="menu-text">Room Availability</span>
                      </Link>
                    </OverlayTrigger>
                  </li>
                )} */}
                {/* {helperFunc.isMenuShow("roomAvailability") && (
                  <li className={`menu-item mb-3 ${getMenuItemActive("/availability-scheduler")}`}>
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip id="latest-project">Availability Scheduler</Tooltip>}
                    >
                      <Link
                        className="menu-link nav-link btn btn-icon btn-clean btn-lg my-menu-item"
                        to="/availability-scheduler"
                      >
                        <span className="svg-icon svg-icon-lg menu-icon">
                          <SVG src={toAbsoluteUrl("/media/svg/menu/room-availability.svg")} />
                        </span>
                        <span className="menu-text">Availability Scheduler</span>
                      </Link>
                    </OverlayTrigger>
                  </li>
                )}
                {helperFunc.isMenuShow("roomAvailability") && (
                  <li className={`menu-item mb-3 ${getMenuItemActive("/live-preview")}`}>
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip id="latest-project">Live Preview</Tooltip>}
                    >
                      <Link
                        className="menu-link nav-link btn btn-icon btn-clean btn-lg my-menu-item"
                        to="/live-preview"
                      >
                        <span className="svg-icon svg-icon-lg menu-icon">
                          <SVG src={toAbsoluteUrl("/media/svg/menu/live-preview.svg")} />
                        </span>
                        <span className="menu-text">Live Preview</span>
                      </Link>
                    </OverlayTrigger>
                  </li>
                )} */}
                {helperFunc.isMenuShow("reporting") && (
                  <li
                    className={`menu-item mb-3 ${getMenuItemActive(
                      "/reporting"
                    )}`}
                  >
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip id="latest-project">Reporting</Tooltip>}
                    >
                      <Link
                        className="menu-link nav-link btn btn-icon btn-clean btn-lg my-menu-item"
                        to="/reporting"
                      >
                        <span className="svg-icon svg-icon-lg menu-icon">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/menu/reporting-icon.svg"
                            )}
                          />
                        </span>
                        <span className="menu-text">Reporting</span>
                      </Link>
                    </OverlayTrigger>
                  </li>
                )}
                {helperFunc.isMenuShow("accounts") && helperFunc.getUserData().role == "super_super_admin" && (
                  <li
                    className={`menu-item mb-3 ${getMenuItemActive(
                      "/accounts"
                    )}`}
                  >
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip id="latest-project">Accounts</Tooltip>}
                    >
                      <Link
                        className="menu-link nav-link btn btn-icon btn-clean btn-lg my-menu-item"
                        to="/accounts"
                      >
                        <span className="svg-icon svg-icon-lg menu-icon">
                          <SVG
                            src={toAbsoluteUrl("/media/svg/menu/accounts.svg")}
                          />
                        </span>
                        <span className="menu-text">Accounts</span>
                      </Link>
                    </OverlayTrigger>
                  </li>
                )}

                {helperFunc.isMenuShow("accounts") && helperFunc.getUserData().role == "super_admin" && (
                  <li
                    className={`menu-item mb-3 ${getMenuItemActive(
                      "/accounts/profile"
                    )}`}
                  >
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip id="latest-project">Sites</Tooltip>}
                    >
                      <Link
                        className="menu-link nav-link btn btn-icon btn-clean btn-lg my-menu-item"
                        to={`/accounts/profile/${helperFunc.getUserData().account_id}/sites`}
                      >
                        <span className="svg-icon svg-icon-lg menu-icon">
                          <SVG
                            src={toAbsoluteUrl("/media/svg/menu/accounts.svg")}
                          />
                        </span>
                        <span className="menu-text">Sites</span>
                      </Link>
                    </OverlayTrigger>
                  </li>
                )}

                <li
                  className={`menu-item mb-3 ${getMenuItemActive("/events")}`}
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip id="latest-project">Events</Tooltip>}
                  >
                    <Link
                      className="menu-link nav-link btn btn-icon btn-clean btn-lg my-menu-item"
                      to="/events"
                    >
                      <span className="svg-icon svg-icon-lg menu-icon">
                        <SVG
                          src={toAbsoluteUrl("/media/event/Frame.svg")}
                          className="svg-color"
                        />
                      </span>
                      <span className="menu-text">Events</span>
                    </Link>
                  </OverlayTrigger>
                </li>

                {/* <li
                  className={`menu-item mb-3 ${getMenuItemActive("/coverage")}`}
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip id="latest-project">Coverage</Tooltip>}
                  >
                    <Link
                      className="menu-link nav-link btn btn-icon btn-clean btn-lg my-menu-item"
                      to="/coverage"
                    >
                      <span className="svg-icon svg-icon-lg menu-icon">
                        <SVG
                          src={toAbsoluteUrl("/media/coverage/coverage.svg")}
                        />
                      </span>
                      <span className="menu-text">Coverage</span>
                    </Link>
                  </OverlayTrigger>
                </li> */}

                <li
                  className={`menu-item mb-3 ${getMenuItemActive(
                    "/social-wall"
                  )}`}
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip id="latest-project">Social Wall</Tooltip>}
                  >
                    <Link
                      className="menu-link nav-link btn btn-icon btn-clean btn-lg my-menu-item"
                      to="/social-wall"
                    >
                      <span className="svg-icon svg-icon-lg menu-icon">
                        <SVG
                          src={toAbsoluteUrl("/media/svg/menu/social_wall.svg")}
                        />
                      </span>
                      <span className="menu-text">Social Wall</span>
                    </Link>
                  </OverlayTrigger>
                </li>

                <li
                  className={`menu-item mb-3 ${getMenuItemActive(
                    "/livefloorplan"
                  )}`}
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="latest-project">Live Floor Plan</Tooltip>
                    }
                  >
                    <Link
                      className="menu-link nav-link btn btn-icon btn-clean btn-lg my-menu-item"
                      to="/livefloorplan"
                    >
                      <span className="svg-icon svg-icon-lg menu-icon">
                        <SVG
                          src={toAbsoluteUrl("/media/svg/menu/live-floor.svg")}
                        />
                      </span>
                      <span className="menu-text">Live Floor Plan</span>
                    </Link>
                  </OverlayTrigger>
                </li>

                {helperFunc.isMenuShow("booking") && (
                  <li
                    className={`menu-item mb-3 ${getMenuItemActive(
                      "/booking"
                    )}`}
                  >
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="latest-project">Booking Defaults</Tooltip>
                      }
                    >
                      <Link
                        className="menu-link nav-link btn btn-icon btn-clean btn-lg my-menu-item"
                        to="/booking"
                      >
                        <span className="svg-icon svg-icon-lg menu-icon">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/menu/booking-icon.svg"
                            )}
                          />
                        </span>
                        <span className="menu-text">Booking Defaults</span>
                      </Link>
                    </OverlayTrigger>
                  </li>
                )}
                {/* {helperFunc.isMenuShow("settings") && (
                  <li
                    className={`menu-item mb-3 ${getMenuItemActive(
                      "/settings"
                    )}`}
                  >
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip id="latest-project">Settings</Tooltip>}
                    >
                      <Link
                        className="menu-link nav-link btn btn-icon btn-clean btn-lg my-menu-item"
                        to="/settings"
                      >
                        <span className="svg-icon svg-icon-lg menu-icon">
                          <SVG
                            src={toAbsoluteUrl("/media/svg/menu/settings.svg")}
                          />
                        </span>
                        <span className="menu-text">Settings</span>
                      </Link>
                    </OverlayTrigger>
                  </li>
                )} */}
                <li
                  className={`menu-item mb-3 ${getMenuItemActive("/logout")}`}
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip id="latest-project">LOGOUT</Tooltip>}
                  >
                    <Link
                      className="menu-link nav-link btn btn-icon btn-clean btn-lg my-menu-item"
                      to="/logout"
                      onClick={() => {
                        if (localStorage.getItem("ms_teams_access_token")) {
                          localStorage.removeItem("ms_teams_access_token");
                        }
                        if (localStorage.getItem("ms_teams_refresh_token")) {
                          localStorage.removeItem("ms_teams_refresh_token");
                        }
                      }}
                    >
                      <span className="svg-icon svg-icon-lg menu-icon">
                        <SVG
                          src={toAbsoluteUrl("/media/svg/menu/logout.svg")}
                        />
                      </span>
                      <span className="menu-text">LOGOUT</span>
                    </Link>
                  </OverlayTrigger>
                </li>
              </ul>
            </div>
          </div>
          {/* end::Nav Wrapper */}
        </div>
        {/* end::Primary */}
      </div>
      {/* end::Aside */}
    </>
  );
}
