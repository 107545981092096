import React, { useEffect } from "react";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
//css
import "./EventStep4.scss";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  createEvent,
  handleChangeStepFour,
  getEvents,
} from "../_redux/Actions";
import { Button } from "@material-ui/core";
import AddInvitee from "./Invitees/AddInvitee";
import helperFunc from "../../../../../_metronic/_helpers/helperFunc";
import * as dashboardActions from "../../../../pages/_redux/Actions";

const EventStep4 = (props) => {
  const { selectType, onHide, setActiveStep } = props;

  const dispatch = useDispatch();

  const { events, closeModel } = useSelector((state) => state.events);
  // console.log("events.stepThree.openEvent", events.stepThree.openEvent);
  const dashboardStore = useSelector((state) => state.dashboards);

  const handlePublishEvent = () => {
    const inviteeUser = events?.stepThree?.invitees?.[0]?.users;
    const inviteeVisitors = events?.stepThree?.invitees?.[0]?.visitors;
    const inviteeWorkCircleUsers =
      events?.stepThree?.invitees?.[0]?.workCircleUsers;

    let tempArr = [];
    inviteeUser.forEach((element) => {
      tempArr.push(element);
    });

    inviteeVisitors.forEach((element) => {
      tempArr.push(element);
    });

    inviteeWorkCircleUsers.forEach((element) => {
      tempArr.push(element);
    });

    let finalArr = [];

    tempArr.forEach((element) => {
      finalArr.push({
        book_visitor: "undefined",
        car_park: element.car_park,
        day_pass: element.day_pass,
        invitee_type: element.type,
        isWorkCircle: element.type === "Work Circle" ? true : false,
        user_id: element.user_id,
      });
    });

    let dataForSubmit;

    let site_id = helperFunc.getUserData();

    if (events?.stepOne?.event_type === "external") {
      // if(){
      //   let invieesData
      // }
      dataForSubmit = {
        name: events.stepOne.name,
        details: events.stepOne.details,
        created_at: moment(events.stepTwo.created_at).format(
          "YYYY-MM-DDTHH:mm:ss[Z]"
        ),
        starts_at: moment(events.stepTwo.starts_at).format(
          "YYYY-MM-DDTHH:mm:ss[Z]"
        ),
        ends_at: moment(events.stepTwo.ends_at).format(
          "YYYY-MM-DDTHH:mm:ss[Z]"
        ),
        event_type: events.stepOne.event_type,
        address_1: events.stepTwo.address_1,
        address_2: events.stepTwo.address_2,
        city: events.stepTwo.city,
        postcode: events.stepTwo.postcode,
        map_url: events.stepTwo.map_url,
        invitees: finalArr,
        is_open:
          events.stepThree.openEvent === true ? parseInt(1) : parseInt(0),
        host: events.stepThree.host,
        cover_image: events.stepOne.cover_image,
        document: events.stepOne.document,
        duration: moment
          .duration(events.stepTwo.ends_at.diff(events.stepTwo.starts_at))
          .asMinutes(),
      };
    } else if (events?.stepOne?.event_type === "internal") {
      dataForSubmit = {
        name: events.stepOne.name,
        details: events.stepOne.details,
        created_at: moment(events.stepTwo.created_at).format(
          "YYYY-MM-DDTHH:mm:ss[Z]"
        ),
        starts_at: moment(events.stepTwo.starts_at).format(
          "YYYY-MM-DDTHH:mm:ss[Z]"
        ),
        ends_at: moment(events.stepTwo.ends_at).format(
          "YYYY-MM-DDTHH:mm:ss[Z]"
        ),
        event_type: events.stepOne.event_type,
        floor_id: events.stepTwo.floor_id,
        space_id: events.stepTwo.site_id,
        site_id: site_id?.site_id,
        invitees: finalArr,
        is_open:
          events.stepThree.openEvent === true ? parseInt(1) : parseInt(0),
        host: events.stepThree.host,
        cover_image: events.stepOne.cover_image,
        document: events.stepOne.document,
        duration: moment
          .duration(events.stepTwo.ends_at.diff(events.stepTwo.starts_at))
          .asMinutes(),
      };
    } else {
      dataForSubmit = {
        name: events.stepOne.name,
        details: events.stepOne.details,
        created_at: moment(events.stepTwo.created_at).format(
          "YYYY-MM-DDTHH:mm:ss[Z]"
        ),
        starts_at: moment(events.stepTwo.starts_at).format(
          "YYYY-MM-DDTHH:mm:ss[Z]"
        ),
        ends_at: moment(events.stepTwo.ends_at).format(
          "YYYY-MM-DDTHH:mm:ss[Z]"
        ),
        event_type: events.stepOne.event_type,
        invitees: finalArr,
        is_open:
          events.stepThree.openEvent === true ? parseInt(1) : parseInt(0),
        host: events.stepThree.host,
        cover_image: events.stepOne.cover_image,
        document: events.stepOne.document,
        duration: moment
          .duration(events.stepTwo.ends_at.diff(events.stepTwo.starts_at))
          .asMinutes(),
      };
    }

    const formData = new FormData();

    Object.keys(dataForSubmit).forEach((key) => {
      if (key === "cover_image" || key === "document") {
        if (dataForSubmit[key] !== undefined) {
          formData.append(
            key,
            dataForSubmit[key].file,
            dataForSubmit[key].path
          );
        }
      } else if (key === "invitees" || key === "host") {
        formData.append(key, JSON.stringify(dataForSubmit[key]));
      } else {
        formData.append(key, dataForSubmit[key]);
      }
    });

    dispatch(createEvent(formData)).then((res) => {
      onHide();
      dispatch(
        dashboardActions.fetchBookingList(dashboardStore?.dashboardFilters)
      );
      setTimeout(() => {
        const params = { page: 1 };
        dispatch(handleChangeStepFour());
        dispatch(getEvents(params));
        setActiveStep(0);
      }, 1000);
    });
  };

  return (
    <>
      <div className="d-flex justify-content-center pb-5 event-step4-main">
        <div className="event-image position-relative">
          {events?.stepOne?.cover_image !== undefined ? (
            <img
              src={URL.createObjectURL(events?.stepOne?.cover_image?.file)}
              alt="event"
              height={200}
              width={400}
              style={{ borderRadius: "10px" }}
            />
          ) : (
            <img
              src="/media/bg/300.jpg"
              alt="event"
              height={200}
              width={400}
              style={{ borderRadius: "10px" }}
            />
          )}
          <div className="position-absolute d-flex event-inner-class">
            <img
              width="70px"
              src={events?.stepThree?.host?.user_image ? events?.stepThree?.host?.user_image : "/media/users/user1.jpg"}
              alt="Vehicle"
              className="max-w-70px rounded-circle"
            />
            <div className="host-main">
              <p className="host-style">Host:</p>
              <p className="host-name-style mb-0">
                {events?.stepThree?.host?.user_name}

              </p>
            </div>
            <div className="event-name-main">
              <p className="event-name">{events.stepOne.name}</p>
              {events?.stepOne?.event_type === "external" ? (
                <p className="event-floor">
                  {events.stepTwo.address_1}, {events.stepTwo.address_2} -{" "}
                  {moment(events.stepTwo.starts_at).format("hh:mm a")}
                </p>
              ) : events?.stepOne?.event_type === "internal" ? (
                <p className="event-floor">
                  {events.stepTwo.floor_name}, {events.stepTwo.site_name} -{" "}
                  {moment(events.stepTwo.starts_at).format("hh:mm a")}
                </p>
              ) : (
                <p className="event-floor">Virtual</p>
              )}
            </div>
            <div className="event-time">
              <p className="event-month">
                {moment(events.stepTwo.created_at).format("MMMM")}
              </p>
              <p className="event-date">
                {moment(events.stepTwo.created_at).format("DD")}
              </p>
              <p className="event-time-inner">
                {moment(events.stepTwo.starts_at).format("hh")}-
                {moment(events.stepTwo.ends_at).format("hh a")}
              </p>
            </div>
          </div>
          {/* invites */}
          <div className={`d-flex justify-content-between mt-5`}>
            <span>
              <SVG
                className="d-block mx-auto mb-2"
                src={toAbsoluteUrl("/media/event/user-dark-blue.svg")}
              ></SVG>
              <span>
                {events?.stepThree?.invitees?.[0]?.users?.length +
                  events?.stepThree?.invitees?.[0]?.visitors?.length +
                  events?.stepThree?.invitees?.[0]?.workCircleUsers?.length +
                  1}{" "}
                Invited
              </span>
            </span>

            <span className="cursor-pointer">
              <SVG
                className="d-block mx-auto mb-2"
                src={toAbsoluteUrl("/media/event/event-daypass.svg")}
              ></SVG>
              <span>
                {events?.stepThree?.invitees?.[0]?.users?.length} Users
              </span>
            </span>

            <span className="cursor-pointer">
              <SVG
                className="d-block mx-auto mb-2"
                src={toAbsoluteUrl("/media/event/user.svg")}
              ></SVG>
              <span>
                {events?.stepThree?.invitees?.[0]?.visitors?.length} Visitors
              </span>
            </span>
          </div>
          <div className="mt-3">
            {events?.stepOne?.event_type === "external" ? (
              <div className="meeting-room-event p-3">
                <SVG src="/media/event/meeting-room.svg" />
                <span className="ml-3">
                  {events.stepTwo.address_1}, {events.stepTwo.address_2}
                </span>
              </div>
            ) : events?.stepOne?.event_type === "internal" ? (
              <div className="meeting-room-event p-3">
                <SVG src="/media/event/meeting-room.svg" />
                <span className="ml-3">
                  {events.stepTwo.floor_name}, {events.stepTwo.site_name}
                </span>
              </div>
            ) : (
              <div className="meeting-room-event p-3">
                <SVG src="/media/event/meeting-room.svg" />
                <span className="ml-3">Virtual Event</span>
              </div>
            )}

            <div className="meeting-time-event mt-3 p-3">
              <SVG src="/media/event/calendar.svg" />
              <span className="ml-3">
                {moment(events.stepTwo.created_at).format("dddd[, ]Do MMM")},{" "}
                {moment(events.stepTwo.starts_at).format("hh:mm a")} -{" "}
                {moment(events.stepTwo.ends_at).format("hh:mm a")}
              </span>
            </div>
            {events.stepThree.generateLink === "on" && (
              <div className="meeting-teams-event mt-3 p-3">
                <SVG src="/media/event/teams-small.svg" />
                <span className="ml-3 font-weight-light">
                  Teams Link Generated
                </span>
              </div>
            )}
            <div className="day-status-event mt-3 p-3 d-flex justify-content-between rounded">
              <SVG src="/media/event/calendar.svg" />
              <span className="font-weight-light mt-2">
                <span className="font-weight-light">Day Status:</span>
                <span className="font-weight-bold">
                  {moment(events.stepTwo.created_at).format("dddd[, ]Do MMM")}
                </span>
              </span>
              <SVG src="/media/event/event-traffic.svg" className="mt-2" />
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <span className="font-weight-bold mt-3">
                {(events?.stepThree?.invitees?.[0]?.users?.length > 0 ||
                  events?.stepThree?.invitees?.[0]?.visitors?.length > 0 ||
                  events?.stepThree?.invitees?.[0]?.workCircleUsers?.length >
                  0) &&
                  "Invited"}
              </span>
              {/* <div>
                <Button
                  // onClick={() => setAddUserVisitors(true)}
                  className="d-flex ml-auto mt-3 btn btn-primary"
                  variant="primary"
                >
                  {selectedUsers.length > 0 || selectedVisitors.length > 0
                  ? "Edit Invitees"
                  : "+ Add Invitees"}
                </Button>
              </div> */}
            </div>
            <div className="attendees-list">
              {events?.stepThree?.invitees?.[0]?.users?.length > 0 &&
                events?.stepThree?.invitees?.[0]?.users?.map((one) => {
                  return (
                    <div className="event-host d-flex mt-3 w-100">
                      <div className="align-items-center d-flex py-3">
                        <img
                          width="55px"
                          src={
                            one?.url_image
                              ? one?.url_image
                              : toAbsoluteUrl(
                                "/media/svg/icons/default_user.svg"
                              )
                          }
                          alt="Host"
                          className="max-w-70px rounded-circle ml-2"
                        />
                        <div className="event-details-main pl-5">
                          <span className="event-lbl font-weight-bold d-block mb-2">
                            {one?.full_name !== ""
                              ? one?.full_name
                              : one?.full_name}
                          </span>
                          <span className="event-no text-primary">
                            {one?.type}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center ml-auto">
                        {/* <img
                              src="/media/vehicles/edit.png"
                              alt="edit"
                              style={{ width: "40px", marginRight: "10px" }}
                              className="cursor-pointer"
                              onClick={handleEditUserVisitor}
                            /> */}
                        {/* <img
                        src="/media/vehicles/delete.png"
                        alt="edit"
                        style={{ width: "40px", marginRight: "10px" }}
                        className="cursor-pointer"
                        // onClick={() => handleRemoveUser(one.user_id)}
                      /> */}
                      </div>
                    </div>
                  );
                })}
              {events?.stepThree?.invitees?.[0]?.visitors?.length > 0 &&
                events?.stepThree?.invitees?.[0]?.visitors?.map((one) => {
                  return (
                    <div className="event-host d-flex mt-3 w-100">
                      <div className="align-items-center d-flex py-3">
                        <img
                          width="55px"
                          src={
                            one?.url_image
                              ? one?.url_image
                              : toAbsoluteUrl(
                                "/media/svg/icons/default_user.svg"
                              )
                          }
                          alt="Host"
                          className="max-w-70px rounded-circle ml-2"
                        />
                        <div className="event-details-main pl-5">
                          <span className="event-lbl font-weight-bold d-block mb-2">
                            {one?.full_name !== ""
                              ? one?.full_name
                              : one?.full_name}
                          </span>
                          <span className="event-no text-primary">
                            {one?.type}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center ml-auto">
                        {/* <img
                              src="/media/vehicles/edit.png"
                              alt="edit"
                              style={{ width: "40px", marginRight: "10px" }}
                              className="cursor-pointer"
                              onClick={handleEditUserVisitor}
                            /> */}
                        {/* <img
                        src="/media/vehicles/delete.png"
                        alt="edit"
                        style={{ width: "40px", marginRight: "10px" }}
                        className="cursor-pointer"
                        // onClick={() => handleRemoveVisitor(one.user_id)}
                      /> */}
                      </div>
                    </div>
                  );
                })}
              {events?.stepThree?.invitees?.[0]?.workCircleUsers?.length > 0 &&
                events?.stepThree?.invitees?.[0]?.workCircleUsers?.map(
                  (one) => {
                    return (
                      <div className="event-host d-flex mt-3 w-100">
                        <div className="align-items-center d-flex py-3">
                          <img
                            width="55px"
                            src={
                              one?.url_image
                                ? one?.url_image
                                : toAbsoluteUrl(
                                  "/media/svg/icons/default_user.svg"
                                )
                            }
                            alt="Host"
                            className="max-w-70px rounded-circle ml-2"
                          />
                          <div className="event-details-main pl-5">
                            <span className="event-lbl font-weight-bold d-block mb-2">
                              {one?.full_name !== ""
                                ? one?.full_name
                                : one?.full_name}
                            </span>
                            <span className="event-no text-primary">
                              Work Circle User
                            </span>
                          </div>
                        </div>
                        <div className="d-flex align-items-center ml-auto">
                          {/* <img
                              src="/media/vehicles/edit.png"
                              alt="edit"
                              style={{ width: "40px", marginRight: "10px" }}
                              className="cursor-pointer"
                              onClick={handleEditUserVisitor}
                            /> */}
                          {/* <img
                        src="/media/vehicles/delete.png"
                        alt="edit"
                        style={{ width: "40px", marginRight: "10px" }}
                        className="cursor-pointer"
                        // onClick={() => handleRemoveWorkCircleUser(one.user_id)}
                      /> */}
                        </div>
                      </div>
                    );
                  }
                )}
            </div>
          </div>
          <button
            id="kt_login_signin_submit"
            type="submit"
            className={`btn btn-primary d-flex justify-content-between align-items-center font-weight-bolder mt-5 w-100`}
            onClick={() => {
              handlePublishEvent();
            }}
          >
            <span />
            <span>PUBLISH EVENT</span>
            <span>
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/akar-icons_arrow-right.svg"
                )}
              />
            </span>
          </button>
        </div>
        {/* end invites */}
      </div>

      {/* <AddInvitee
        show={addUserVisitors}
        handleClose={() => setAddUserVisitors(false)}
        users={users}
        visitors={visitors}
        selectedUsers={selectedUsers}
        selectedVisitors={selectedVisitors}
        setSelectedUsers={setSelectedUsers}
        setSelectedVisitors={setSelectedVisitors}
      /> */}
    </>
  );
};

export default EventStep4;
