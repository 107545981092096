import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  selectedSite: null,
  vehiclesList: [],
  notificationsList: [],
  friendRequestResp: [],
  notificationUpdatedResp: null,
  workCircleList: [],
  usersList: [],
  circleCreated: false,
  workCircleObj: [],
  calendarSettingsList: [],
  calendarSettingsUpdatedResp: null,
  allFriendsWithRequestsList: [],
  friendRequest: [],
  addFriendToWorkCircle: [],
}

export const callTypes = {
  list: "list",
  action: "action",
}

export const Slice = createSlice({
  name: "settings",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      // console.log(action);
      state.error = action.payload.error.response.data.error
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
    },
    startCall: (state, action) => {
      state.error = null
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    fetchedSiteDetails: (state, action) => {
      state.selectedSite = action.payload
    },
    fetchedVehiclesDetails: (state, action) => {
      state.vehiclesList = action.payload
    },
    fetchedNotificationsList: (state, action) => {
      state.notificationsList = action.payload
    },
    fetchedFriendRequestResponse: (state, action) => {
      state.friendRequestResp = action.payload
    },
    fetchedUpdateUserNotificationSettings: (state, action) => {
      state.notificationUpdatedResp = action.payload
    },
    fetchWorkCircleList: (state, action) => {
      state.workCircleList = action.payload
    },
    fetchedUsersList: (state, action) => {
      state.usersList = action.payload
    },
    fetchedCircleList: (state, action) => {
      state.circleCreated = action.payload
    },
    fetchWorkCircleDetails: (state, action) => {
      state.workCircleObj = action.payload
    },
    fetchedCalendarSettingsList: (state, action) => {
      state.calendarSettingsList = action.payload
    },
    fetchedUpdateCalendarSettings: (state, action) => {
      state.calendarSettingsUpdatedResp = action.payload
    },
    fetchedAllFriendsWithRequests: (state, action) => {
      state.allFriendsWithRequestsList = action.payload
    },
    fetchedFriendRequest: (state, action) => {
      state.friendRequest = action.payload
    },
    fetchAddFriendToCircle: (state, action) => {
      state.addFriendToWorkCircle = action.payload
    },
    workCircleDeleted: (state, action) => {
      state.error = null
      state.actionsLoading = false
    },
  },
})
