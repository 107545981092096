import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";
import { API_URL } from "./_redux/Crud";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { useSelector, useDispatch } from "react-redux";
import { Form, Formik } from "formik";
import moment from "moment";
import { Image } from "react-bootstrap";
import {
    Button,
    FormControl,
    InputLabel,
    Menu,
    MenuItem,
    MenuList,
    Select,
    TextField,
    Typography,
} from "@material-ui/core";

import "./VehicalReg.scss";
import { useParams } from "react-router-dom";
import * as actions from "../../Vehicle/_redux/Action";

const VehicalRegPolicy = (
    props
) => {
    console.log(props.location, "location");
    const formDataProp = props.location.state.formData ?   props.location.state.formData  : "" ;
    const bookingDataForm = props.location.state.bookingDataForm;
    const [workstationCoordinates, setWorkStationCoordinates] = useState([]);
    const [xyzCirc, setXyzCirc] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const BASE_URL = process.env.REACT_APP_API_URL;

    const [selectedItemsPath, setSelectedItemsPath] = useState([]);



    const [floorData, setFloorData] = useState([]);
    const [thankyou, setThankyou] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [bookingData, setBookingData] = useState(bookingDataForm);
    const [parkingData, setParkingData] = useState([]);
    const [file, setFile] = useState(undefined);
    const [selectedFile, setSelectedFile] = useState(undefined);
    const dispatch = useDispatch();

    const { booking_id } = useParams();
    const getImageUrl = (event) => {
        if (event.target.files && event.target.files[0]) {
            setSelectedFile({
                file: event.target.files[0],
                path: event.target.value,
            });
            let img = event.target.files[0];
            var reader = new FileReader();
            reader.readAsDataURL(img);

            reader.onloadend = function (e) {
                setFile(reader.result);
            };
        }
    };
    const handleSubmitForm = () => {

    }

    console.log(file, "file");


    return (
        <>
            <div className="pad-12-reg">
                <img
                    className="img_header_reg"
                    src={toAbsoluteUrl("/media/svg/menu/logo-icon.svg")}
                    width="50px"
                    alt="Tick Icon"
                />
            </div>

            <div className="rect_header_reg">
                <img
                    className="img_logo_reg"
                    src={bookingData?.account_setting?.kiosk_logo_url}
                    width="50px"
                    alt="Tick Icon"
                />
            </div>


            <div className="menu_reg">

            </div>
            {thankyou ?
                <div className="content_header_reg green_tick_reg">
                    <span>Thank your for confirming your Vehicle details</span>
                </div>
                :
                <div className="content_header_reg ">
                    <span>Vehicle Details</span>
                </div>
            }

            {thankyou ? <div className="content_paragraph_reg ">
                <img
                    className="img_reg"
                    src={toAbsoluteUrl("/media/vehicles/green_tick.png")}
                    width="50px"
                    alt="Tick Icon"
                />
            </div>
                :
                <div className="content_paragraph_reg">
                    {bookingData?.site?.id == 1 ?

                        <span>  Hi Lee, for your visit to No5. The Heights on  {moment(bookingData?.starts_at).format("DD/MM/YYYY")} at {moment(bookingData?.starts_at).format("HH:mm")} can you please provide vehicle details below</span>
                        :
                        <span>Hi Lee, for your visit to {bookingData?.site?.title} on  {moment(bookingData?.starts_at).format("DD/MM/YYYY")} , {moment(bookingData?.starts_at).format("HH:mm")} please provide car reg details below before you arrive at site </span>}

                </div>
            }

            {thankyou ? <></> :
                <div className="pop_up_reg">
                    <Formik
                        className="width-full mb-form"
                        enableReinitialize={true}
                        initialValues={{
                            reg: formDataProp.reg ? formDataProp.reg : " ",
                            model: formDataProp.model ? formDataProp.model : " ",
                            color: formDataProp.color ? formDataProp.color : " ",
                            motorbike: formDataProp.motorbike ? formDataProp.motorbike : 0,
                            electric: formDataProp.electric ? formDataProp.electric : 0,
                            disability: formDataProp.disability ? formDataProp.disability : 0,
                           // image: formDataProp.image ? formDataProp.image : null,

                        }}

                        onSubmit={(values) => {
                            // debugger;
                            setSubmit(true);

                            console.log(formDataProp, "formDataProp");
                            if(formDataProp)
                            {
                                axios.post(`${BASE_URL}/api/booking-visitor-vehical`, formDataProp).then((response) => {

                                    if (response) {
                                        setThankyou(true)
                                        // window.location.replace('https://portal.bookify.space/auth/login');
                                    }
                                });
                            }
                            else{
                                setThankyou(true)
                            }
                            


                            // dispatch(actions.updateGlobalAccountDetails(formData)).then(
                            //     (response) => {

                            //     }
                            // );
                        }}
                    >
                        {({
                            handleSubmit,
                            values,
                            errors,
                            touched,
                            handleChange,
                            setFieldValue,
                        }) => (
                            <Form onSubmit={handleSubmit} className="width-full mb-form">

                                <div className="terms-conditions">
                                    <div className="content-terms">
                                        <span>
                                            {bookingDataForm.account_setting.terms_n_conditions}
                                        </span>
                                    </div>
                                </div>

                                <div    >
                                    {submit ?
                                        <button className="button-form-reg remove-click-reg" type="submit">
                                            <span className="ml-3 spinner spinner-white spinner-reg "></span>
                                        </button>
                                        :
                                        //<button className="button-form-reg " type="submit">


                                        <button className="button-form-reg button-top-none" onClick={handleSubmitForm()} >
                                            <span className="text-sign-reg m-l-10-p"> Confirm </span>
                                        </button>

                                    }
                                </div>

                            </Form>
                        )}
                    </Formik>

                </div >
            }



        </>
    );
};

export default VehicalRegPolicy;
