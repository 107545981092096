import React from "react"
import { useField, useFormikContext } from "formik"
import DateRangePicker from "react-bootstrap-daterangepicker"
import moment from "moment-timezone"
import "bootstrap-daterangepicker/daterangepicker.css"

export function DateRangPickerField({ ...props }) {
  const { setFieldValue, errors, touched } = useFormikContext()
  const [field] = useField(props)

  const UpdateValue = (start, end, label) => {
    const rang = {
      from: start,
      to: end,
    }
    setFieldValue(field.name, rang)
    if (props?.onDateSet) {
      props.onDateSet(rang)
    }
  }
  return (
    <>
      {props.label && <label className={props.isRequired ? "required" : ""}>{props.label}</label>}
      <DateRangePicker
        initialSettings={{
          startDate: props.dateRang.from.toDate(),
          endDate: props.dateRang.to.toDate(),
          ranges: {
            Today: [moment().toDate(), moment().toDate()],
            Yesterday: [
              moment()
                .subtract(1, "days")
                .toDate(),
              moment()
                .subtract(1, "days")
                .toDate(),
            ],
            "Last 7 Days": [
              moment()
                .subtract(6, "days")
                .toDate(),
              moment().toDate(),
            ],
            "Last 30 Days": [
              moment()
                .subtract(29, "days")
                .toDate(),
              moment().toDate(),
            ],
            "This Month": [
              moment()
                .startOf("month")
                .toDate(),
              moment()
                .endOf("month")
                .toDate(),
            ],
            "Last Month": [
              moment()
                .subtract(1, "month")
                .startOf("month")
                .toDate(),
              moment()
                .subtract(1, "month")
                .endOf("month")
                .toDate(),
            ],
          },
        }}
        onCallback={UpdateValue}
      >
        <input type="text" name="daterange" className="form-control" />
      </DateRangePicker>
      {errors[field.name] && touched[field.name] ? (
        <div className="invalid-datepicker-feedback">{errors[field.name].toString()}</div>
      ) : (
        <div className="feedback">
          {/* Please enter <b>{props.label}</b> in 'mm/dd/yyyy' format */}
        </div>
      )}
    </>
  )
}
