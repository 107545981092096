import * as requestFromServer from "./Crud";
import { Slice, callTypes } from "./Slice";
import { NotificationManager } from "react-notifications";

const { actions } = Slice;

export const selectedFriendCircleList = (item) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  // return requestFromServer
  //     .createItem(item)
  // .then((response) => {
  //     console.log("resp", response)
  //     const data = response.data
  //     NotificationManager.success("Data saved successfully!", "success")
  dispatch(actions.selectedFriendCircleList(item));
  // return true
};

export const getDayPassListing = (params) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer.getDayPassListing(params).then((response) => {
    const data = response.data;
    dispatch(actions.dayPassListing(data));
    return true;
  });
};
