import * as requestFromServer from "./Crud";
import { Slice, callTypes } from "./Slice";
import { NotificationManager } from "react-notifications";

const { actions } = Slice;

export const createItem = (item) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createItem(item)
    .then((response) => {
      NotificationManager.success("Data saved successfully!", "Success");
      dispatch(actions.createdAddDayPassData({ item }));
      return response;
    })
    .catch((error) => {
      const errMsg = error.response.data.message;
      NotificationManager.error(errMsg, "Error");
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return error;
    });
};
export const createMultiDayPasses = (item) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createMultiDayPasses(item)
    .then((response) => {
      if (response.data.massage && response.data.massage !== "") {
        NotificationManager.error(response.data.massage, "Error");
      } else {
        NotificationManager.success("Data saved successfully!", "Success");
        dispatch(actions.createMultiDayPasses(response.data));
        return response;
      }
    })
    .catch((error) => {
      const errMsg = error.response.data.message;
      NotificationManager.error(errMsg, "Error");
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return error;
    });
};
export const createMultiDatePassesForSameUser = (item) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createMultiDatePassesForSameUser(item)
    .then((response) => {
      console.log(response);
      NotificationManager.success("Data saved successfully!", "Success");
      dispatch(actions.createMultiDatePassesForSameUser(response.data));
      return response;
    })
    .catch((error) => {
      const errMsg = error.response.data.message;
      NotificationManager.error(errMsg, "Error");
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return error;
    });
};
export const updateItem = (item) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateItem(item)
    .then((response) => {
      NotificationManager.success("Data updated successfully!", "Success");
      dispatch(actions.updateDayPassData({ item }));
      return response;
    })
    .catch((error) => {
      const errMsg = error?.response?.data?.message;
      NotificationManager.error(errMsg, "Error");
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const setEditItem = (item) => (dispatch) => {
  dispatch(actions.setEditItem({ item }));
};
export const clearData = () => (dispatch) => {
  dispatch(actions.clearData());
};

export const getWorkCircleList = (userId) => (dispatch) => {
  requestFromServer.getWorkCircleList(userId).then((response) => {
    const item = response.data;
    dispatch(actions.fetchWorkCircleList(item));
  });
};
