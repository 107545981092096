import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TextareaAutosize,
  TextField,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { useDispatch, useSelector } from "react-redux";
//constants & helpers
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import * as eventsAction from "../_redux/Actions";
import * as Yup from "yup";

// css
import "./EventStep1.scss";

const EventStep1 = (props) => {
  const { selectType, setSelectType, handleNext } = props;
  const [file, setFile] = useState(undefined);
  const [doc, setDoc] = useState(undefined);
  const [selectedFile, setSelectedFile] = useState(undefined);
  const [selectedDocument, setSelectedDocument] = useState(undefined);
  const { events } = useSelector((state) => state.events);

  const getImageUrl = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile({
        file: event.target.files[0],
        path: event.target.value,
      });
      let img = event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(img);

      reader.onloadend = function(e) {
        setFile(reader.result);
      };
    }
  };

  const getDocUrl = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedDocument({
        file: event.target.files[0],
        path: event.target.value,
      });
      let img = event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(img);

      reader.onloadend = function(e) {
        setDoc(reader.result);
      };
    }
  };

  const dispatch = useDispatch();

  const validations = Yup.object().shape({
    name: Yup.string().required("Event Name is required."),
    details: Yup.string().required("Event Details is required."),
  });

  return (
    <div className="d-flex justify-content-center event-step1-main">
      <div className="form-group row w-100">
        <Formik
          initialValues={{
            name: events.stepOne.name || "",
            details: events.stepOne.details || "",
            event_type: events.stepOne.event_type || "external",
            cover_image: events.stepOne.cover_image || selectedFile,
            document: events.stepOne.document || selectedDocument,
          }}
          validationSchema={validations}
          onSubmit={(values, { setSubmitting }) => {
            const formData = {
              name: values.name,
              details: values.details,
              event_type: values.event_type,
              cover_image: selectedFile,
              document: selectedDocument,
            };

            dispatch(eventsAction.handleChangeStepOne(formData));
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            errors,
            touched,
            handleBlur,
          }) => (
            <Form className="w-100">
              <div className="col-lg-12">
                <div
                  className={`square-image-display ${
                    file ? "align-items-baseline" : "align-items-center"
                  } `}
                >
                  <label
                    className="align-items-center d-flex flex-column flex-wrap justify-content-center min-h-120px"
                    data-action="change"
                    title="Upload Image"
                  >
                    {!file && !values.cover_image && (
                      <>
                        <Image
                          src={toAbsoluteUrl("/media/event/camera.svg")}
                          style={{ width: "50px" }}
                          alt="progile-image"
                        />
                        <span>Add Cover Photo...</span>
                      </>
                    )}
                    {values.cover_image && file === undefined && (
                      <Image
                        src={URL.createObjectURL(values.cover_image.file)}
                        style={{
                          width: "100%",
                          height: "18vh",
                          backgroundSize: "cover",
                        }}
                        alt="progile-image"
                      />
                    )}
                    {file && (
                      <Image
                        src={file}
                        style={{
                          width: "100%",
                          height: "18vh",
                          backgroundSize: "cover",
                        }}
                        alt="progile-image"
                      />
                    )}
                    {/* <i className="fa fa-pen icon-sm text-muted"></i> */}
                    <input
                      type="file"
                      name="file"
                      accept=".png, .jpg, .jpeg"
                      onChange={getImageUrl}
                    />
                  </label>
                </div>
              </div>
              <div className="col-lg-12 mt-3">
                <span className="d-flex justify-content-center font-weight-bolder">
                  SELECT TYPE
                </span>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  // defaultValue={values.event_type}
                  value={values.event_type}
                  name="event_type"
                  onChange={handleChange}
                >
                  <div className="d-flex justify-content-between">
                    <div className="radio_parent_choose_event">
                      <FormControlLabel
                        value="external"
                        control={<Radio />}
                        label="External"
                        name="event_type"
                      />
                      <span
                        className="cursor-pointer"
                        onClick={() => setSelectType("external")}
                      >
                        <SVG
                          className="d-block"
                          src={toAbsoluteUrl(
                            values.event_type === "external"
                              ? "/media/event/external-active.svg"
                              : "/media/event/external.svg"
                          )}
                        ></SVG>
                        <span
                          className={`mr-1 ${
                            values.event_type === "external"
                              ? "active-text"
                              : "default-text"
                          }`}
                        >
                          External
                        </span>
                      </span>
                    </div>
                    <div className="radio_parent_choose_event">
                      <FormControlLabel
                        value="internal"
                        control={<Radio />}
                        label="Internal"
                        name="event_type"
                      />
                      <span
                        onClick={() => setSelectType("internal")}
                        className="cursor-pointer"
                      >
                        <SVG
                          className="d-block"
                          src={toAbsoluteUrl(
                            values.event_type === "internal"
                              ? "/media/event/internal-active.svg"
                              : "/media/event/internal.svg"
                          )}
                        ></SVG>
                        <span
                          className={`mr-1 ${
                            values.event_type === "internal"
                              ? "active-text"
                              : "default-text"
                          }`}
                        >
                          Internal
                        </span>
                      </span>
                    </div>
                    <div className="radio_parent_choose_event">
                      <FormControlLabel
                        value="virtual"
                        control={<Radio />}
                        label="Virtual"
                        name="event_type"
                      />
                      <span
                        onClick={() => setSelectType("virtual")}
                        className="cursor-pointer"
                      >
                        <SVG
                          className="d-block"
                          src={toAbsoluteUrl(
                            values.event_type === "virtual"
                              ? "/media/event/virtual-active.svg"
                              : "/media/event/virtual.svg"
                          )}
                        ></SVG>
                        <span
                          className={`${
                            values.event_type === "virtual"
                              ? "active-text"
                              : "default-text"
                          }`}
                        >
                          Virtual
                        </span>
                      </span>
                    </div>
                  </div>
                </RadioGroup>
              </div>
              <div className="col-lg-12 mt-3 d-flex justify-content-center event-details w-100">
                <>
                  <TextField
                    id="outlined-basic"
                    label="Event Name..."
                    variant="outlined"
                    className=""
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.name && touched.name && (
                    <p className="text-danger mb-0">{errors.name}</p>
                  )}
                  <TextareaAutosize
                    aria-label="event_details"
                    placeholder="Event Details"
                    className="event-text-aria"
                    style={{
                      width: "100%",
                      borderRadius: "20px",
                      height: "100px",
                      padding: "10px",
                    }}
                    value={values.details}
                    name="details"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.details && touched.details && (
                    <p className="text-danger mb-0">{errors.details}</p>
                  )}
                  <div
                    className={`square-image-display ${
                      file ? "align-items-baseline" : "align-items-center"
                    } `}
                  >
                    <label
                      className="align-items-center d-flex flex-column flex-wrap justify-content-center min-h-120px"
                      data-action="change"
                      title="Upload title"
                    >
                      {!doc && !values.document && (
                        <>
                          <Image
                            src={toAbsoluteUrl(
                              "/media/svg/icons/cloud-plus.svg"
                            )}
                            style={{ width: "50px" }}
                            alt="progile-image"
                          />
                          <span>Upload Documentation</span>
                        </>
                      )}
                      {values.document && selectedDocument === undefined && (
                        <p className="mb-0">{values.document.file.name}</p>
                      )}
                      {selectedDocument && (
                        <p className="mb-0">{selectedDocument.file.name}</p>
                      )}
                      {/* <i className="fa fa-pen icon-sm text-muted"></i> */}
                      <input
                        type="file"
                        name="file"
                        accept=".doc, .pdf, .docx"
                        onChange={getDocUrl}
                      />
                    </label>
                  </div>
                </>
              </div>
              <button
                id="kt_login_signin_submit"
                type="submit"
                className={`btn btn-primary d-flex justify-content-between align-items-center font-weight-bolder mt-5 w-100`}
                onClick={() => {
                  handleSubmit();
                  if (
                    Object.entries(errors).length === 0 &&
                    values.name !== "" &&
                    values.details !== ""
                  ) {
                    handleNext();
                  }
                }}
              >
                <span />
                <span>NEXT</span>
                <span>
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/akar-icons_arrow-right.svg"
                    )}
                  />
                </span>
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EventStep1;
