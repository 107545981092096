import React, { useEffect } from "react"
import moment from "moment"
import { useSelector } from "react-redux"
import helperFunc from "../../../_metronic/_helpers/helperFunc"

export default function CommonOccupancy(props) {
  const { setDateData, selectedDate, dateData } = props

  const datepickerStore = useSelector((state) => state.customDatePicker)

  const changeDateFormat = (date) => {
    let selectedDate = date
    if (!moment.isMoment(date)) {
      selectedDate = moment(date)
    }
    return selectedDate.format("dddd[,]Do MMM")
  }

  useEffect(() => {
    setDateData(calculateDateData())
  }, [datepickerStore])

  const calculateDateData = () => {
    return  helperFunc.calculateDateAvailability(props?.type, datepickerStore)
  }
  return (
    <>
      <div
        className={`day-status-section d-flex justify-content-between ${
          dateData?.backgroundColor ? dateData?.backgroundColor : ""
        }`}
      >
        <div className="d-flex justify-content-center align-items-center">
          <div className="edit-icon d-inline-flex ">
            <img src="/media/svg/icons/Booking/calendar.svg" />
          </div>
          <div className="day-status-text d-inline-flex pl-2 pr-5">
            <strong> {selectedDate ? changeDateFormat(selectedDate) : ""} </strong>
          </div>
          <div className="d-inline-flex  pr-5 occupancy-status">
            {dateData?.occupied && dateData.occupied}
          </div>
        </div>
        <div className="d-inline-flex">
          {selectedDate ? (
            <div className="day-status-loading">
              <div
                className={`day-status light-dot-first ${
                  dateData?.activeLight && dateData.activeLight === 1 ? "active" : ""
                }`}
              ></div>
              <div
                className={`day-status light-dot-second ${
                  dateData?.activeLight && dateData.activeLight === 2 ? "active" : ""
                }`}
              ></div>
              <div
                className={`day-status light-dot-third ${
                  dateData?.activeLight && dateData.activeLight === 3 ? "active" : ""
                }`}
              ></div>
            </div>
          ) : (
            "loading"
          )}
        </div>
      </div>
    </>
  )
}
