import axios from "axios";

export const API_URL = `${process.env.REACT_APP_API_URL}/api`;

// READ
export function getSearchList(queryParams) {
  if (
    queryParams?.filter &&
    queryParams?.filter !== undefined &&
    queryParams?.filter !== null
  ) {
    return axios.get(`${API_URL}/${queryParams.source}?${queryParams.filter}`);
  } else if (queryParams.source === "floors") {
    return axios.get(
      `${API_URL}/${queryParams.source}?site_id=${queryParams?.site_id}`,
      {
        params: queryParams.filter,
      }
    );
  } else {
    return axios.get(`${API_URL}/${queryParams.source}`, {
      params: queryParams.filter,
    });
  }
}
export function getInfiniteScrollList(queryParams) {
  return axios.get(
    `${API_URL}/user/listing?site_id=${queryParams.site_id}&perPage=${queryParams.perPage}&page=${queryParams.page}`
  );
}
export function getGameList(queryParams) {
  return axios.post(
    `${API_URL}/${queryParams.source}/list`,
    queryParams.filter
  );
}
export function getFriendCircleList(src, queryParams) {
  return axios.post(`${API_URL}/${src}`, queryParams);
}
