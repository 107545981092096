import * as requestFromServer from "./Crud";
import { Slice, callTypes } from "./Slice";
import { NotificationManager } from "react-notifications";

const { actions } = Slice;

export const createItem = (item) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createItem(item)
    .then((response) => {
      const { item } = response.data;
      NotificationManager.success("Data saved successfully!", "Success");
      dispatch(actions.itemCreated({ item }));
      return true;
    })
    .catch((error) => {
      // const errMsg = error.response && error.response.data && error.response.data.errors ? error.response.data.errMsgerrors[0].msg : error.message ;
      // console.log(error.response.data.errMsgerrors[0].msg)
      // console.log(error.message);
      //   NotificationManager.error(errMsg, 'Error');
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchCounts = (params) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  let queUrl = "";
  if (params?.floor_id) {
    queUrl = `floor_id= ${params?.floor_id}`;
  }
  return requestFromServer
    .getCounts(queUrl)
    .then((response) => {
      const data = response.data;
      dispatch(actions.fetchedCount(data));
      return true;
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const updateCounts = (params) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .updateCounts(params)
    .then((response) => {
      NotificationManager.success("Data saved successfully!", "Success");
      dispatch(actions.fetchedCount(params));
      return true;
    })
    .catch((error) => {
      const errMsg =
        error.response && error.response.data && error.response.data.message;
      // console.log(error.response.data.errMsgerrors[0].msg)
      // console.log(error.message);
      NotificationManager.error(errMsg, "Error");
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const defaultAccountDetails = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .defaultAccountDetails()
    .then((res) => {
      const item = res.data;
      dispatch(actions?.defaultAccess(item));
      return item;
    })
    .catch((error) => {
      const errMsg =
        error.response && error.response.data && error.response.data.message;
      // console.log(error.response.data.errMsgerrors[0].msg)
      // console.log(error.message);
      NotificationManager.error(errMsg, "Error");
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
