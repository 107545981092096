import React, { useEffect, useState } from "react"
import SVG from "react-inlinesvg"
import { useSelector } from "react-redux"
import NightMoonPopUp from "../../../../../app/modules/Booking/Meeting/_redux/NightMoonPopUp"
import { toAbsoluteUrl } from "../../../../_helpers"
import ActionModalMeeting from "../ActionModalComponant/ActionModalMeeting"

const modalInitialValues = {
  name: "addMeetingRoom",
  title: "Book Meeting Room",
}

export const AddMeetingRoom = (props) => {
  const [show, setShow] = useState(false)
  const [warningMenu, setWarningMenu] = useState(false)
  const [proceedWarningMenu, setProceedWarningMenu] = useState(false)
  const [warningPopUpTimeStart, setWarningPopUpTimeStart] = useState()
  const [warningPopUpTimeEnd, setWarningPopUpTimeEnd] = useState()
  const [rediractionValue, setRediractionValue] = useState()
  const [modalProps, setModalProps] = useState(modalInitialValues)
  const [id, setId] = useState()

  const handleClose = () => setShow(false)

  const handleShow = () => {
    setShow(true)
    props.handleParentClose()
  }

  const meetingStore = useSelector((state) => state.meetingRooms)
  const { meetingEditItem } = meetingStore

  useEffect(() => {
    if (meetingEditItem) {
      let props = { ...modalProps }
      props.name = "editMeetingRoom"
      props.title = "Edit Meeting Room Booking"
      setModalProps(props)
      handleShow()
      setId(meetingEditItem?.id)
    } else {
      setModalProps(modalInitialValues)
      setId(undefined)
    }
  }, [meetingEditItem])

  const WarningandleMenuOpen = () => {
    setWarningMenu(true)
  }
  const WarninghandleMenuClose = () => {
    setWarningMenu(false)
  }
  const WarningMenuProceed = () => {
    setProceedWarningMenu(true)
  }
  const getWarningPopUpTime = (startTime, endTime) => {
    setWarningPopUpTimeStart(startTime)
    setWarningPopUpTimeEnd(endTime)
  }
  const rediractionLink = (data) => {
    setRediractionValue(data)
  }
  return (
    <>
      <div className="button-cursor pt-5" onClick={() => handleShow()}>
        <div className="d-inline-flex pl-5">
          <span className="svg-icon">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Booking/meetingroom.svg")} />
          </span>
        </div>
        <div className="d-inline-flex pl-5 pt-3">
          <h6>BOOK MEETING ROOM</h6>
        </div>
      </div>
      <ActionModalMeeting
        show={show}
        onHide={handleClose}
        modalProps={modalProps}
        WarningandleMenuOpen={WarningandleMenuOpen}
        proceedWarningMenu={proceedWarningMenu}
        getWarningPopUpTime={getWarningPopUpTime}
        WarninghandleMenuClose={WarninghandleMenuClose}
        rediractionLink={(data) => rediractionLink(data)}
        id={id}
      />
      <NightMoonPopUp
        WarninghandleMenuClose={WarninghandleMenuClose}
        warningMenu={warningMenu}
        WarningMenuProceed={WarningMenuProceed}
        warningPopUpTimeStart={warningPopUpTimeStart}
        warningPopUpTimeEnd={warningPopUpTimeEnd}
        rediractionValue={rediractionValue}
      />
    </>
  )
}
