import React, { useState } from "react";
import SVG from "react-inlinesvg";
//components
import BulletinForm from "../../../../../app/modules/Booking/Bulletin/BulletinForm";
// constanst & helpers
import { toAbsoluteUrl } from "../../../../_helpers";
//css
import "./AddNewEvent.scss";

const modalInitialValues = {
    name: "addBulletin",
    title: "Add bulletin content",
};
export const AddNewBulletin = (props) => {
    const [show, setShow] = useState(false);

    const [modalProps, setModalProps] = useState(modalInitialValues);
    const [id, setId] = useState();

    const handleClose = () => {
        setShow(false);
        setId(undefined);
    };
    const handleShow = () => {
        setShow(true);
        props.handleParentClose();
    };

    return (
        <>
            <div
                className="button-cursor pb-5 event-main"
                onClick={() => handleShow()}
            >
                <div className="d-inline-flex pl-5">
                    <span className="svg-icon h-100 bg-color-bulletin">
                        <SVG
                            className="bulletin-svg"
                            src={toAbsoluteUrl("/media/svg/menu/news.svg")}
                        ></SVG>
                    </span>
                </div>
                <div className="d-inline-flex pl-5 pt-3">
                    <h6>ADD BULLETIN</h6>
                </div>
            </div>
            <BulletinForm show={show} onHide={handleClose} modalProps={modalProps} />
        </>
    );
};
