import axios from "axios"

export const API_URL = `${process.env.REACT_APP_API_URL}/api`

// READ api/booking/available/counters
export function getBookingAvailableList(queryParams) {
  return axios.get(`${API_URL}/booking/available/counters`, { params: { ...queryParams } })
}
export function friendDayPassList(queryParams) {
  return axios.post(`${API_URL}/booking/friends/day_pass/list`, queryParams)
}
export function disableDateList(queryParams) {
  return axios.get(`${API_URL}/booking/dayPass-non-available`, {params: queryParams})
}
export function multipleDateSelection(queryParams) {
  return axios.post(`${API_URL}/booking/multiple-available/counters`, queryParams)
}