import React, { useEffect, useState } from "react"
import { useDispatch, shallowEqual, useSelector } from "react-redux"
import * as actions from "./_redux/Actions"
import { FieldFeedbackLabel } from "../../../_metronic/_partials/controls/forms/FieldFeedbackLabel"
// import { Select } from "../../../_metronic/_partials/controls";
import { useFormikContext } from "formik"
import CardRadioInput from "./CardRadioInput"
import ModalImage from "react-modal-image"

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control button-cursor"]
  if (touched && errors) {
    classes.push("is-invalid-select")
  }

  if (touched && !errors) {
    classes.push("is-valid-select")
  }
  return classes.join(" ")
}

const SiteSelectInput = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  children,
  filter,
  ...props
}) => {
  const [options, setOptions] = useState([])
  const [count, setCount] = useState(0)
  const [source, setSource] = useState(props.source)
  const [selectedSiteId, setSelectedSiteId] = useState("")
  const dispatch = useDispatch()

  const [toggleList, setToggleList] = useState(false)

  const { setFieldValue } = useFormikContext()
  useEffect(() => {
    setFieldValue("")
    dispatch(
      actions.getSearchList({
        source: props.source,
        filter: filter,
        returnData: props.returnData,
      })
    )
  }, [source, props.count])

  const { currentState } = useSelector(
    (state) => ({ currentState: state.searchData }),
    shallowEqual
  )
  const { searchData, selectedSite } = currentState
  useEffect(() => {
    if (currentState.siteId) {
      setSelectedSiteId(currentState.siteId)
    }
  }, [currentState])
  useEffect(() => {
    let data = searchData[props.source]
    if (searchData && searchData[props.source]) {
      data = searchData[props.source].map((item) => {
        return {
          value: item.id,
          id: item.id,
          label: item.name || item.title || item.reg || item.full_name,
          url_image: item.url_image,
          badges: [
            {
              title: "FLOORS",
              count: item?.floors?.length,
            },
            {
              title: "MEETING ROOMS",
              count: item?.space_types?.length,
            },
          ],
          isSelected: false,
        }
      })

      if (props.isShowAll) {
        data.unshift({ value: "all", label: "All" })
      }
      setOptions(data)
      setCount(count + 1)
    }
  }, [searchData])

  const handleClick = () => {
    setToggleList(!toggleList)
  }

  const handleRadioChange = (value, cleanFloorState) => {
    if (cleanFloorState) {
      dispatch(actions.getFloor({ floorId: null }))
    }

    setSelectedSiteId(value)
    setToggleList(!toggleList)
    dispatch(actions.getSite({ siteId: value }))
    // props?.handleOnChange(value)
  }

  const headerSection = () => {
    return (
      <div className="selected-site-section">
        <div className="d-flex justify-content-between align-items-center ">
          {currentState?.siteId != null ? (
            <>
              <div className="d-flex align-items-center">
                {/* <img
                  src={selectedSite.url_image}
                  alt="display"
                  className="display-image-height"
                /> */}
                <ModalImage
                  small={selectedSite.url_image}
                  large={selectedSite.url_image}
                  className="display-image-height"
                  alt="display"
                />
                <span className="ml-1 font-weight-bold selected-site-label">
                  {selectedSite.title}
                </span>
              </div>
            </>
          ) : (
            ""
          )}
          <div className="button-cursor edit-icon" onClick={handleClick}>
            <img src="/media/svg/icons/Booking/edit-sign.svg" alt="display" />
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {!selectedSite && (
        <div
          className={getFieldCSSClasses(
            touched[field.name],
            errors[field.name]
          )}
          onClick={handleClick}
        >
          <div className="d-flex align-items-center selected-text">
            {" "}
            Select Site
            <div
              className={
                toggleList ? "selection-arrow open" : "selection-arrow"
              }
            >
              <b></b>
            </div>
          </div>
          {withFeedbackLabel && (
            <FieldFeedbackLabel
              error={errors[field.name]}
              touched={touched[field.name]}
              label={label}
              customFeedbackLabel={customFeedbackLabel}
            />
          )}
        </div>
      )}
      {selectedSite && headerSection()}
      {toggleList && options && (
        <div
          role="group"
          aria-labelledby="site-radio-group"
          className="custom-card-radio-group"
        >
          {options.map((item, key) => {
            return (
              <div
                key={key}
                className={`item ${
                  item.id === parseInt(selectedSiteId) ? "selected" : ""
                }`}
              >
                <CardRadioInput
                  item={item}
                  onChange={(value, cleanFloorState) =>
                    handleRadioChange(value, cleanFloorState)
                  }
                  field="site"
                  isChecked={item.id === parseInt(selectedSiteId)}
                />
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}
export default SiteSelectInput
