import { createSlice } from "@reduxjs/toolkit"
import moment from "moment"

const initialState = {
  feedbackFilters: {
    starts_at: moment(),
    ends_at: moment(),
    site_id: 1,
    floor_obj: {},
    space_obj: {},
    rating: null,
  },
  getFeedbackList: [],
  fetchCsvReport: [],
}

export const Slice = createSlice({
  name: "feedback",
  initialState,
  reducers: {
    setFeedbackFilter: (state, action) => {
      const data = action.payload
      state.feedbackFilters = { ...state.feedbackFilters, ...data }
    },
    resetItemFilter: (state, action) => {
      state.feedbackFilters = initialState.feedbackFilters
    },
    fetchGetFeedBackList: (state, action) => {
      state.getFeedbackList = action?.payload
    },
    fetchCsvReport: (state, action) => {
      state.fetchCsvReport = action.payload
    },
  },
})
