/* eslint-disable no-restricted-imports */
import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";

const DeleteDialog = ({
  item,
  name,
  show,
  onHide,
  onDelete,
  item_label,
  popupLabel,
  customMessage,
  custom_title,
  custom_description,
  type,
}) => {
  // if !id we should close modal
  useEffect(() => {
    if (!item) {
      onHide();
    }
  }, [item]);
  // looking for loading/dispatch

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/* {isLoading && <ModalProgressBar variant="query" />} */}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {customMessage && custom_title !== "" ? (
            custom_title
          ) : (
            <>Delete {name}</>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className={name === "Post" || name === "Community" ? `py-0` : ""}
      >
        <span>
          {customMessage && custom_description !== "" ? (
            custom_description
          ) : (
            <>Are you sure to permanently Delete this record {name}?</>
          )}
        </span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="border border-1 btn btn-elevate rounded-pill"
          >
            {type ? "No" : "Cancel"}
          </button>
          <> </>
          <button
            type="button"
            onClick={onDelete}
            className="btn btn-elevate btn-danger rounded-pill"
            // className="btn btn-delete btn-elevate"
          >
            {type ? "Yes" : "Delete"}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default DeleteDialog;
