import React, { useEffect, useState } from "react";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import * as actions from "./_redux/Actions";
import { FieldFeedbackLabel } from "../../../_metronic/_partials/controls/forms/FieldFeedbackLabel";
// import { Select } from "../../../_metronic/_partials/controls";
import { Select2 } from "select2-react-component";
import { useFormikContext } from "formik";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["rounded-pill"];
  if (touched && errors) {
    classes.push("is-invalid-select");
  }

  if (touched && !errors) {
    classes.push("is-valid-select");
  }
  return classes.join(" ");
};

const SearchSelectInput = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  children,
  filter,
  listUpdate,
  ...props
}) => {
  const [options, setOptions] = useState([]);
  const [fvalue, setFvalue] = useState(undefined);
  const [count, setCount] = useState(0);
  const [fdata, setFdata] = useState(filter);
  const [source, setSource] = useState(props.source);
  const dispatch = useDispatch();

  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    if (props?.customOptions) {
      setOptions(props?.customOptions);
    }
  }, [props.customOptions]);

  useEffect(() => {
    setFvalue(undefined);
    setFieldValue("");
    if (props.fieldName === "select_friend_circle") {
      dispatch(
        actions.getFriendCircleList(props.source, {
          user_id: props.user_id,
        })
      );
    } else {
      dispatch(
        actions.getSearchList({
          source: props.source,
          filter: filter,
          returnData: props.returnData,
          storeEntityName: props?.storeEntityName,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fdata,
    source,
    props.count,
    props.addedNewData,
    props.source,
    listUpdate,
  ]);

  // useEffect(() => {
  //   dispatch(actions.getFriendCircleList({
  //     source: props.source,
  //     user_id: props.user_id,
  //   }))
  // }, [props.fieldName, props.user_id])

  const { currentState } = useSelector(
    (state) => ({ currentState: state.searchData }),
    shallowEqual
  );
  const { searchData, selectedSite } = currentState;

  useEffect(() => {
    let data = searchData[props.source];
    if (searchData && searchData[props.source]) {
      data = searchData[props.source].map((item) => {
        return {
          value: item.id,
          label: item.name || item.title || item.reg || item.full_name,
        };
      });

      if (props.isShowAll) {
        data.unshift({ value: "all", label: "All" });
      }
      setOptions(data);
      setCount(count + 1);
      if (field.value) {
        setFvalue(parseInt(field.value));
      } else {
        setFvalue(undefined);
      }
    }
  }, [searchData, field.value]);

  // useEffect(() => {
  //   setFvalue(field.value)
  // }, [field && field.value])

  const UpdateValue = (value) => {
    setFieldValue(field.name, value);
    setFvalue(value);
    if (props.onSelect) {
      let item = {};
      if (props?.customOptions) {
        item = props.customOptions.find((item) => item.value === value);
      } else {
        item = searchData[props?.source]?.find((item) => item.id === value);
      }
      props.onSelect(item);
    }
  };

  return (
    <>
      <div
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
      >
        {label && (
          <label className={props.isRequired ? "required" : ""}>
            Select {label}
          </label>
        )}
        <Select2
          className={
            (getFieldCSSClasses(touched[field.name], errors[field.name]),
            "rounded-pill")
          }
          data={options}
          {...field}
          {...props}
          value={fvalue}
          update={(value) => UpdateValue(value)}
        ></Select2>
        {withFeedbackLabel && (
          <FieldFeedbackLabel
            error={errors[field.name]}
            touched={touched[field.name]}
            label={label}
            customFeedbackLabel={customFeedbackLabel}
          />
        )}
      </div>
    </>
  );
};
export default SearchSelectInput;
