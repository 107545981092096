/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { Button, Form, Modal } from "react-bootstrap";
import { Typography } from "@material-ui/core";
import helperFunc from "../../../_metronic/_helpers/helperFunc";
import { useDispatch } from "react-redux";
import * as actions from "./_redux/Actions";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import InfiniteScroll from "react-infinite-scroll-component";
import * as action from "../../modules/Settings/_redux/Actions";
import axios from "axios";

export default function SelectInfiniteScrollDropDown(props) {
  const {
    isShowInfiniteScrollPopUp,
    fieldName,
    handleInfiniteScrollPopUp,
  } = props;
  const dispatch = useDispatch();
  const currentUser = helperFunc.getUserData();

  //List of User List
  const [scrollUsersList, setScrollUsersList] = useState([]);
  //For Selected User
  const [circleUsers, setCircleUser] = useState(undefined);
  const [moreRecords, setMoreRecords] = useState(true);
  const [popupSearch, setPopupSearch] = useState(true);
  const [loadDefault, setDefault] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [frmInitialValues, setFrmInitialValues] = useState(undefined);

  const handleSearchFunctionality = (e) => {
    if (e != null) {
      console.log("trst4");

      const val = e.target.value;
      setTimeout(() => {
        if (val !== "") {
          setDefault(false);
          const params = {
            site_id: currentUser?.site_id,
            search: val,
          };
          dispatch(action.searchUserList(params)).then((response) => {
            if (response.users.length > 0) {
              setMoreRecords(false);
              setScrollUsersList(response.users);
            } else {
              setScrollUsersList(response.users);
              setMoreRecords(false);
            }
          });
        } else {
          setScrollUsersList([]);
          setDefault(true);
          setPageCount(1);
          // loadMoreUsers();
        }
      }, 600);
    }

  };
  const BASE_URL = process.env.REACT_APP_API_URL;
  const handleAddUser = (user) => {
    if (circleUsers !== user?.id) {
      setCircleUser(user.id);
      props.setUserData(user);
      // props.setUserId(user.id)
    } else {
      setCircleUser(undefined);
      props.setUserData(undefined);
      // props.setUserId(undefined);
    }
  };

  useEffect(() => {
    console.log("trst");
    setFrmInitialValues({
      title: "",
      user_id: [],
      host_id: null,
    });
    setPageCount(pageCount + 1);
  }, []);

  useEffect(() => {
    console.log("trst1");
    if (
      currentUser.site_id !== "" &&
      fieldName === "select_infinite_scroll_drop_down"
    ) {
      console.log("trst2");
      const queryParams = {
        userID: currentUser.id,
        site_id: currentUser.site_id,
        perPage: 10,
        page: pageCount,
      };
      dispatch(actions.getInfiniteScrollData(queryParams)).then((response) => {
        response.has_more === 0 ? setMoreRecords(false) : setMoreRecords(true);
        if (pageCount === 1) {
          setScrollUsersList(response.users);
        } else {
          setScrollUsersList([...scrollUsersList, ...response.users]);
        }
        setDefault(false);
      });
    }
  }, [pageCount, loadDefault, fieldName]);

  const loadMoreUsers = () => {
    setPageCount(pageCount + 1);
  };
  console.log(isShowInfiniteScrollPopUp, "isShowInfiniteScrollPopUp")
  if (isShowInfiniteScrollPopUp == false && popupSearch == true) {
    setPopupSearch(false)
    setTimeout(() => {

      setDefault(false);
      const params = {
        site_id: currentUser?.site_id,
      };
      dispatch(action.searchUserList(params)).then((response) => {
        if (response.users.length > 0) {
          setMoreRecords(false);
          setScrollUsersList(response.users);
        } else {
          setScrollUsersList(response.users);
          setMoreRecords(false);
        }
      });
    }, 600);
  }
  return (
    <>
      <Modal
        show={isShowInfiniteScrollPopUp}
        onHide={handleInfiniteScrollPopUp}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <img src="/media/work-circle/group-icon.svg" alt="" />
            &nbsp;&nbsp;&nbsp;&nbsp;<b>{props.name}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik initialValues={frmInitialValues}>
            {({
              values,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="space-1"></div>
                <Typography component="div">
                  <div className="d-flex w-100">
                    <div className="bg-white border col-md-12 d-flex flex-center rounded-pill">
                      <input
                        type="text"
                        className="form-control border-0 font-weight-bold pl-2"
                        name="q"
                        placeholder="Search"
                        onChange={(e) => {
                          handleSearchFunctionality(e);
                        }}
                      />
                      {/* <span className="svg-icon svg-icon-lg svg-icon-primary">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/Search.svg"
                          )}
                        />
                      </span> */}
                    </div>
                  </div>
                  <div id="scrollableDiv">
                    <InfiniteScroll
                      dataLength={scrollUsersList.length}
                      next={() => loadMoreUsers()}
                      hasMore={moreRecords}
                      pageStart={0}
                      loader={
                        <img
                          style={{ margin: "13px 45%" }}
                          src={toAbsoluteUrl(
                            "/media/work-circle/small-loader.svg"
                          )}
                          alt="loader"
                          className="img"
                        />
                      }
                      style={{ padding: "10px" }}
                      height={900}
                      endMessage={
                        <p style={{ textAlign: "center", padding: "5px" }}>
                          <b></b>
                        </p>
                      }
                      scrollableTarget="scrollableDiv"
                    >
                      {scrollUsersList.length > 0 ? <></> :
                        <div style={{ textAlign: "center" }}>
                          <p>No Users Found</p>
                        </div>
                      }

                      {scrollUsersList ? (
                        <ul className="list-group">
                          {scrollUsersList?.map((user, index) => {
                            return (
                              <li
                                key={index}
                                className="notification-item list-group-item mt-4 d-flex"
                              >
                                <div className="align-items-center d-flex title mr-auto work-circle-info">
                                  <img
                                    width="70px"
                                    src={
                                      user?.url_image
                                        ? user?.url_image
                                        : toAbsoluteUrl(
                                          "/media/vehicles/sample_user.svg"
                                        )
                                    }
                                    alt="Profile_Pic"
                                    className="max-w-70px rounded-pill"
                                  />
                                  <div className="notification-details-main pl-5">
                                    <span className="notification-sender font-weight-bolder h5 text-dark">
                                      {user?.full_name}
                                    </span>
                                  </div>
                                </div>

                                <div className="actions align-items-center d-flex justify-content-end">
                                  <a
                                    // href={() => false}
                                    className="d-flex"
                                    onClick={() => handleAddUser(user)}
                                  >
                                    {circleUsers === user?.id ? (
                                      <img
                                        src={toAbsoluteUrl(
                                          "/media/work-circle/tickIcon.svg"
                                        )}
                                        width="50px"
                                        alt="Remove Vehicle"
                                      />
                                    ) : (
                                      <img
                                        src={toAbsoluteUrl(
                                          "/media/work-circle/plusIcon.svg"
                                        )}
                                        width="50px"
                                        alt="Remove Vehicle"
                                      />
                                    )}
                                  </a>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        <></>
                      )}
                    </InfiniteScroll>
                  </div>
                </Typography>
                <Modal.Footer className="pt-5 pl-0 pr-0">
                  <Button
                    className="w-100 font-weight-bolder custom-button"
                    variant="primary"
                    onClick={handleInfiniteScrollPopUp}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    DONE
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}
