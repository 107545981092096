import React from "react";
import { useField, useFormikContext } from "formik";
import { TimePicker } from "react-tempusdominus-bootstrap";
import { useSelector } from "react-redux";

export function TimePickerField({ ...props }) {
  const { setFieldValue, errors, touched } = useFormikContext();
  const [field] = useField(props);

  const UpdateValue = (value) => {
    setFieldValue(field.name, value.date);
    if (props.onDateSet) {
      props.onDateSet(value.date);
    }
  };

  return (
    <>
      {props.label && <label>{props.label}</label>}
      <TimePicker
        key={field.name}
        format={props.format ? props.format : "hh:mm a"}
        stepping={props.stepping ? props.stepping : 1}
        onChange={(val) => UpdateValue(val)}
        date={props.date || field.value || null}
        minDate={props.minDate || false}
        maxDate={props.maxDate ? props.maxDate : false}
        readOnly={props.readOnly ? props.readOnly : false}
        defaultDate={props.defaultDate ? props.defaultDate : ""}
      />

      {errors[field.name] && touched[field.name] ? (
        <div className="invalid-datepicker-feedback">
          {errors[field.name].toString()}
        </div>
      ) : null}
    </>
  );
}
