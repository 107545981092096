import axios from "axios";

export const API_URL = `${process.env.REACT_APP_API_URL}`;

// CREATE =>  POST: add a new StaffUser to the server
export function createItem(item) {
  // return item/
  return axios.post(`${API_URL}/api/vehicle `, item);
}
export function getCounts(queUrl) {
  return axios.get(`${API_URL}/api/scheduler/counters?${queUrl} `);
}
export function updateCounts(params) {
  return axios.put(`${API_URL}/api/scheduler/update `, params);
}
// default Account details
export function defaultAccountDetails() {
  return axios.get(`${API_URL}/api/accounts/defaultAccountDetails`);
}
