import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import helperFunc from "../../../../_metronic/_helpers/helperFunc";

const site_id = helperFunc.getUserData();
const initialState = {
  listLoading: false,
  fetchReportDashboard: [],
  fetchCsvReport: [],
  fetchDayPassesDetails: [],
  fetchParkingDetails: [],
  fetchVisitorBooking: [],
  fetchMeetingRoom: [],
  reportingFilters: {
    starts_at: moment(),
    ends_at: moment(),
    site_id: site_id?.site_id,
    floor_id: [],
    floor_obj: {},
    space_obj: {},
  },
};

export const callTypes = {
  list: "list",
  actions: "actions",
};

export const Slice = createSlice({
  name: "reporting",
  initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    fetchReportDashboard: (state, action) => {
      state.listLoading = false;
      state.fetchReportDashboard = action.payload;
    },
    fetchCsvReport: (state, action) => {
      state.fetchCsvReport = action.payload;
    },
    fetchDayPassesDetails: (state, action) => {
      state.listLoading = false;
      state.fetchDayPassesDetails = action.payload;
    },
    fetchParkingDetails: (state, action) => {
      state.listLoading = false;
      state.fetchParkingDetails = action.payload;
    },
    fetchVisitorBooking: (state, action) => {
      state.listLoading = false;
      state.fetchVisitorBooking = action.payload;
    },
    fetchMeetingRoom: (state, action) => {
      state.listLoading = false;
      state.fetchMeetingRoom = action.payload;
    },
    setItemFilter: (state, action) => {
      const data = action.payload;
      state.reportingFilters = { ...state.reportingFilters, ...data };
    },
  },
});
