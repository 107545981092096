import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  integrationItemEdit: {},
  refreshList: false,
};
export const callTypes = {
  list: "list",
  action: "action",
};
export const Slice = createSlice({
  name: "Integrations",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // singleItemFetched
    singleItemFetched: (state, action) => {
      state.actionsLoading = false;
      state.integrationItemEdit = action.payload.integrationItemEdit;
      state.error = null;
    },

    // itemUpdated
    itemUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = [];
      state.refreshList = true;
    },
  },
});
