import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  listLoading: false,
  actionsLoading: false,
  lastError: null,
  error: null,
  selectFriend: undefined,
  todayDayPassList: undefined,
}
export const callTypes = {
  list: "list",
  action: "action",
}

export const Slice = createSlice({
  name: "visitorSignIn",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.lastError = true
      state.error = action.payload.error.response.data.errors
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
    },
    clearError: (state, action) => {
      state.error = null
    },
    startCall: (state, action) => {
      state.error = null
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    selectedFriendCircleList: (state, action) => {
      state.listLoading = false
      state.actionsLoading = false
      state.lastError = null
      state.error = null
      state.selectFriend = action.payload
    },
    dayPassListing: (state, action) => {
      state.todayDayPassList = action.payload || undefined
    },
  },
})
