import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
// import Header from "./Header";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { Field, Formik } from "formik";
import VisitorVehicleSelect from "./component/VisitorVehicleSelect";
import VisitorSignInThankYou from "./VisitorSignInThankYou";
import * as receptionistAction from "./_redux/Actions";
import * as Yup from "yup";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import axios from "axios";

const initialValues = {
  vehicle_id: undefined,
};
export default function VisitorSignInSelectVehicle(props) {
  const {
    handleIsNewVehicleAddShow,
    handleThankYouShow,
    bookingData,
    setCurrentStep,
    bookingId,
  } = props;
  const [formInitialValues, setFormInitialValues] = useState(initialValues);
  const [vehicleList, setVehicleList] = useState(initialValues);
  const [selectedVehicle, setSelectedVehicle] = useState();
  const dispatch = useDispatch();

  const { visitorSingInState } = useSelector(
    (state) => ({ visitorSingInState: state.visitorSignIn }),
    shallowEqual
  );
  useEffect(() => {
    if (visitorSingInState?.vehicleList) {
      setVehicleList(visitorSingInState?.vehicleList);
    }
  }, [visitorSingInState]);

  // Validation schem
  const LevelsEditSchema = Yup.object().shape({
    //   vehicle_id: Yup.string().required("Vehicle is required."),
  });

  return (
    <>
      <Formik
        initialValues={formInitialValues}
        validationSchema={LevelsEditSchema}
        onSubmit={(values, { setSubmitting }) => {
          const inputs = {
            vehicle_id: values.vehicle_id,
            booking_id: bookingId?.id,
            visitor_id: bookingId?.guest.id,
          };
          // handleThankYouShow();
          dispatch(receptionistAction.visitorIn(inputs))
            .then((res) => {
              handleThankYouShow();
              // window.location.reload();
            })
            .catch(() => {
              console.log("Ddd");
            });
        }}
        className=""
      >
        {({
          values,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form
            className="form form-label-right visitor-vehicle-select-sing-in-modal"
            onSubmit={handleSubmit}
          >
            {/* Message section */}
            <div className="text-vehicle-select-visitor">
              {bookingId?.guest?.vehicles?.length > 0 ? (
                <>
                  <h4 className="vehicle-select-body-text">
                    Your host <b>{bookingId?.host?.full_name}</b> will be with
                    you shortly
                  </h4>
                  <h4 className="vehicle-select-body-text">
                    We have noticed you have been to site before ,we
                  </h4>
                  <h4 className="vehicle-select-body-text">
                    have {vehicleList?.length} cars already registered to you?
                  </h4>
                </>
              ) : (
                <h4 className="vehicle-select-body-text">
                  Are you in a vehicle ?
                </h4>
              )}
            </div>{" "}
            <Field
              name="vehicle_id"
              component={VisitorVehicleSelect}
              placeholder="Select Vehicle"
              handleSelectedVehicle={(vehicleId) => {
                setSelectedVehicle(vehicleId);
              }}
            />
            <div className="d-flex justify-content-center select-option-visitor-signin">
              <div className="d-flex justify-content-around">
                <div className="select-option-div">
                  <div className="mt-5">
                    <button
                      className="visitor-signin-btn text-light back-button"
                      onClick={() => {
                        setCurrentStep("sign_in_options");
                      }}
                    >
                      Back
                    </button>
                  </div>
                </div>
                <div className="select-option-div">
                  <div className="mt-5">
                    {bookingId?.guest?.vehicles?.length > 0 ? (
                      <button
                        className="btn visitor-signin-btn text-light"
                        type="submit"
                      >
                        YES{" "}
                        <SVG
                          src={toAbsoluteUrl(
                            "media/svg/icons/akar-icons_arrow-right.svg"
                          )}
                        />
                      </button>
                    ) : (
                      <button
                        className="btn visitor-signin-btn text-light"
                        type="submit"
                      >
                        NO CAR
                        <SVG
                          src={toAbsoluteUrl(
                            "media/svg/icons/akar-icons_arrow-right.svg"
                          )}
                        />
                      </button>
                    )}
                  </div>
                </div>
                <div className="select-option-div">
                  <div className="rounded-circle mt-5">
                    <button
                      // type="submit"
                      className="visitor-signin-btn text-light"
                      onClick={handleIsNewVehicleAddShow}
                    >
                      I'M IN A NEW CAR TODAY{" "}
                      <SVG
                        src={toAbsoluteUrl(
                          "media/svg/icons/akar-icons_arrow-right.svg"
                        )}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div className="rounded-circle mt-5">
                <button
                  type="button"
                  className="visitor-signin-btn text-light"
                  onClick={props?.handlePopUpClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
