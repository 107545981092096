import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  bookingList: {
    entities: [],
    hasMore: false,
    totalCount: 0,
    counts: 0,
    friends: [],
    dateCounts: [],
    filters: undefined,
  },
  entities: [],
  blogForEdit: {},
  lastError: null,
  dashboardFilters: {
    search: "",
    date: moment(),
    site_id: undefined,
    floor_id: [],
    floor_obj: {},
    type: "all",
    page: 1,
    per_page: 20,
  },
};
export const callTypes = {
  list: "list",
  action: "action",
};
export const Slice = createSlice({
  name: "common",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // singleItemFetched
    singleItemFetched: (state, action) => {
      state.actionsLoading = false;
      state.blogForEdit = action.payload.blogForEdit;
      state.error = null;
    },
    // listFetched
    bookingListFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.bookingList.entities = data.data;
      state.bookingList.totalCount = data.total;
      state.bookingList.friends = data.friends;
      state.bookingList.dateCounts = data.countDate;
      state.bookingList.counts = data.counts;
      state.bookingList.hasMore = data.next_page_url ? true : false;
    },
    // listFetched
    listFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // itemCreated
    itemCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities = [];
      // state.entities.push(action.payload.item);
    },
    // itemUpdated
    itemUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = [];
    },
    // itemUpdated
    itemDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = [];
    },
    // itemStatusUpdated
    itemStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    setBookingFilterData: (state, action) => {
      state.bookingList.filters = action.payload;
    },
    setCalItemFilter: (state, action) => {
      const data = action.payload;
      state.dashboardFilters = { ...state.dashboardFilters, ...data };
      if (data && !data.page) {
        state.dashboardFilters.page = 1;
      }
    },
  },
});
