import React, { Fragment, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { BookingCard } from "../../layout/components/bookingCard";
import FilterComponent from "../../../app/components/FilterComponent";
import moment from "moment";
import { toAbsoluteUrl } from "../../_helpers";
import SVG from "react-inlinesvg";
import SignInOption from "../../../app/modules/Reception/VisitorSignIn/SignInOption";
import CalendarUserListComponent from "../../../app/components/CalendarUserListComponent";
import InfiniteScroll from "react-infinite-scroll-component";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";
import axios from "axios";
import UserDashboardNew from "./UserDashboardNew";
import { CircularProgress, Typography } from "@material-ui/core";

const typeCountObj = {
  day_pass: "dayPassesCountDate",
  parking: "parkingCountDate",
  other: "otherCountDate",
  host: "hostCountDate",
};
const loadingArraydiv = [
  "loading1",
  "loading2",
  "loading3",
  "loading4",
  "loading5",
  "loading6",
  "loading7",
  "loading8",
];
export function NormalUserDashboard(props) {
  const [visitorId, setVisitorId] = useState(undefined);
  const [bookingType, setBookingType] = useState("all");
  const [visitorSignInShow, setVisitorSignInShow] = useState(false);
  const handleVisitorSignInClose = () => setVisitorSignInShow(false);

  const [lists, setLists] = useState({});
  const [tampLists, setTampLists] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isShowUserList, setIsShowUserList] = useState(false);
  const [userData, setUserData] = useState(undefined);
  const [listCount, setListCount] = useState(undefined);

  const dispatch = useDispatch();

  const { currentState } = useSelector(
    (state) => ({ currentState: state.dashboards }),
    shallowEqual
  );
  // console.log(currentState, "currentState");
  const { bookingList, listLoading } = currentState;
  const {
    entities,
    totalCount,
    counts,
    friends,
    hasMore,
    dateCounts,
  } = bookingList;

  const { latestDashFilters } = useSelector(
    (state) => ({ latestDashFilters: state.dashboards.dashboardFilters }),
    shallowEqual
  );

  // console.log(latestDashFilters, "latestDashFilters");

  useEffect(() => {
    window.addEventListener("resize", () => setScreenWidth(window.innerWidth));
  }, []);

  useEffect(() => {
    if (!listLoading) {
      getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestDashFilters, latestDashFilters.page]);

  const getList = () => {
    if (latestDashFilters.page === 1) setLists([]);

    dispatch(props.actions.fetchBookingList(latestDashFilters));
  };

  const loadMoreData = () => {
    // console.log(latestDashFilters.page, "latestDashFilters.page")
    if (!listLoading) {
      const newData = { page: latestDashFilters.page + 1 };
      dispatch(props?.actions?.setCalItemFilter(newData));
    }
  };

  useEffect(() => {
    let newDate = undefined;
    let oldDate = undefined;
    setTampLists([...tampLists, ...entities]);
    if (latestDashFilters.page === 1) setLists([]);
    const today = moment().format("YYYY-MM-DD");
    const tommorow = moment()
      .add(1, "days")
      .format("YYYY-MM-DD");
    const thisMonth = moment()
      .endOf("month")
      .format("YYYY-MM-DD");
    const inFuture = moment()
      .endOf("month")
      .add(1, "days")
      .format("YYYY-MM-DD");

    let dateArray = {};
    let newArray = {};
    if (latestDashFilters.page > 1) {
      dateArray = { ...lists };
    }
    newArray[today] = {
      day_pass: [],
      parking: [],
      host: [],
      other: [],
      count: 0,
      friends: [],
      events: [],
      bulletin: [],
    };
    newArray[tommorow] = {
      day_pass: [],
      parking: [],
      host: [],
      other: [],
      count: 0,
      friends: [],
      events: [],
      bulletin: [],
    };
    newArray[thisMonth] = {
      day_pass: [],
      parking: [],
      host: [],
      other: [],
      count: 0,
      friends: [],
      events: [],
      bulletin: [],
    };
    newArray[inFuture] = {
      day_pass: [],
      parking: [],
      host: [],
      other: [],
      count: 0,
      friends: [],
      events: [],
      bulletin: [],
    };
    // friends
    if (entities && entities.length > 0) {
      let tampItems = [...entities];
      if (bookingType === "myBooking") {
        tampItems = entities.filter((item) => item.created_by_me);
        setListCount(tampItems.length);
      }
      if (bookingType === "other") {
        tampItems = entities.filter((item) => !item.created_by_me);
        setListCount(tampItems.length);
      }
      if (bookingType === "all") {
        setListCount(undefined);
      }

      if (tampItems && tampItems.length > 0) {
        tampItems.forEach((item) => {
          newDate = moment(item.starts_at).format("YYYY-MM-DD");

          if (today === newDate) {
            newArray[today][item.type].push(item);
          } else if (tommorow === newDate) {
            newArray[tommorow][item.type].push(item);
          } else if (
            moment(newDate).isAfter(tommorow) &&
            moment(newDate).isSameOrBefore(thisMonth)
          ) {
            newArray[thisMonth][item.type].push(item);
          } else if (moment(newDate).isSameOrAfter(thisMonth)) {
            newArray[inFuture][item.type].push(item);
          }

          if (newDate === oldDate) {
            dateArray[newDate]["all"].push(item);
            dateArray[newDate][item.type].push(item);
          } else {
            if (!dateArray[newDate]) {
              dateArray[newDate] = {
                all: [],
                day_pass: [],
                parking: [],
                host: [],
                other: [],
                count: 0,
                friends: [],
                dateCounts: {},
                events: [],
                bulletin: [],
              };
            }
            dateArray[newDate]["all"].push(item);
            dateArray[newDate][item.type].push(item);
          }

          oldDate = moment(item.starts_at).format("YYYY-MM-DD");
        });

        if (friends && Object.keys(friends).length > 0) {
          Object.keys(friends).map((item) => {
            const tampDate = item.split("-");
            const tampDate2 =
              tampDate[2] + "-" + tampDate[1] + "-" + tampDate[0];
            const newDate = moment(tampDate2).format("YYYY-MM-DD");
            dateArray[newDate]["friends"] = friends[item];
            // if (today === newDate) {
            // } else if (tommorow === newDate) {
            //   newArray[tommorow]["friends"] = friends[item]
            // } else if (
            //   moment(newDate).isAfter(tommorow) &&
            //   moment(newDate).isSameOrBefore(thisMonth)
            // ) {
            //   newArray[thisMonth]["friends"] = [
            //     ...newArray[thisMonth]["friends"],
            //     ...friends[item],
            //   ]
            // } else if (moment(newDate).isSameOrAfter(thisMonth)) {
            //   newArray[inFuture]["friends"] = [
            //     ...newArray[inFuture]["friends"],
            //     ...friends[item],
            //   ]
            // }
          });
        }

        if (dateCounts && Object.keys(dateCounts).length > 0) {
          Object.keys(dateCounts).forEach((item) => {
            if (dateArray[item]) {
              if (latestDashFilters.type === "all") {
                dateArray[item]["dateCounts"] = Object.values(
                  dateCounts[item]
                ).reduce((a, b) => a + b, 0);
              } else {
                const key =
                  typeCountObj[latestDashFilters.type] || "dayPassesCountDate";
                dateArray[item]["dateCounts"] = dateCounts[item][key] || 0;
              }
            }
          });
        }

        Object.keys(dateArray).forEach((item) => {
          Object.keys(dateArray[item]).map((item2) => {
            if (dateArray[item].count < dateArray[item][item2].length) {
              dateArray[item].count = dateArray[item][item2].length;
            }
          });
        });

        if (newArray[thisMonth]?.friends?.length > 0) {
          newArray[thisMonth].friends = [
            ...new Map(
              newArray[thisMonth]?.friends.map((m) => [m.id, m])
            ).values(),
          ];
        }

        if (newArray[inFuture]?.friends?.length > 0) {
          newArray[inFuture].friends = [
            ...new Map(
              newArray[inFuture]?.friends.map((m) => [m.id, m])
            ).values(),
          ];
        }

        // if (latestDashFilters.page > 1) {
        //   // Object.keys(newArray).map((item) => {
        //   //   newArray[item].day_pass = [
        //   //     ...lists[item].day_pass,
        //   //     ...newArray[item].day_pass,
        //   //   ]
        //   //   newArray[item].parking = [
        //   //     ...lists[item].parking,
        //   //     ...newArray[item].parking,
        //   //   ]
        //   //   newArray[item].host = [...newArray[item].host, ...lists[item].host]
        //   //   newArray[item].other = [
        //   //     ...lists[item].other,
        //   //     ...newArray[item].other,
        //   //   ]
        //   //   newArray[item].friends = [
        //   //     ...lists[item].friends,
        //   //     ...newArray[item].friends,
        //   //   ]
        //   //   newArray[item].count = newArray[item].count + lists[item].count
        //   // })
        //   // setLists(newArray)
        // } else {
        //   // setLists(newArray)
        //   console.log(dateArray, "dateArray")
        //   setLists(dateArray)
        // }
        // console.log(dateArray, "newArray")

        setLists(dateArray);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entities, bookingType]);

  const EmptyBookingBLock = ({ count, length }) => {
    const newcount = count - length;

    if (newcount > 0) {
      let emptyDiv = [];
      for (let i = 0; i < newcount; i++) {
        emptyDiv.push(i);
      }
      return emptyDiv.map((item, ind) => (
        <div
          key={`empty-div-${ind}${newcount}`}
          className="booking-item d-lg-flex d-md-flex d-sm-none mr-4 mb-10 no-booking-card"
        >
          No Booking
        </div>
      ));
    } else {
      return false;
    }
  };

  const showUsers = (data) => {
    setIsShowUserList(true);
    setUserData(data);
  };

  const setHidePopup = (data) => {
    setIsShowUserList(false);
    setUserData(undefined);
  };

  // to get ms teams token

  // function prepareFormData() {
  //   let formData = new FormData();

  //   formData.append("grant_type", "authorization_code");
  //   formData.append(
  //     "code",
  //     "0.AVwA5Bu2A5m_4UmUhR370d_nuSAjp9zWjOFMuGAhGyQKO85LAF8.AgABAAIAAAD--DLA3VO7QrddgJg7WevrAgDs_wQA9P85IPYokXEjRbGByan11Ci-NsmlKnYHnUGGSW_ATTEpr6lpGqtQe11zAyFgZGdcBVZM44I5d00NkaQAT6BSBJvkJlM-MG8WWxtFpqh1H81DslZOlV4eypRD1x-xUKuHHNsJChT4z_sQTK2MuTjln1A9eVDgilKoemj7ZyyBhGqaUeble5ukmdsaObgu_hSvTio8jqvxklkL6fDPJd7YGZV4zk1BXoAqJpU7pkJ66sIsrxnACJaswWyVSamJeGJBlURpK7UQ5NCKAmPijxzplXfpUFhWMjQ_BeUCHPC0nOwlVHBGWqLiLNSfIJhHRKj-Cd6Ianw7sVJOe_RkVXM_XE8Fq1zS1TaZ1bXbcQMuUMlV4Cw9ykKyPwQ1ja9Z9ouh74G6PYed9VP0GBUsj4fppqyBtKywYQ6bRs5ZtrtQZ3sPKUTfecY1zGPYHkwixIj3EvsPaslP9bQb78Tr4nn6q1ZHXnMb6bVRp-WD-VJrP9XAPbYa_6JCJvqzzeV7OXRoZwEAd2NsrVkCeY73UpqT6RM0Yni94nevRjbrTOKMSGh-t0gYc4toM6X4paG9D6Q5o58cZ6-cyD64T_odGKKm3E5ic8mHXGwOu4MRpeuxIAB1sfKVNTdKGezy1ylewulGXNwc1-U-RmnVr8BrnUtRCymgYQSBLK81yeGnP-BEZcmXzyRt5EtaqwZ-GtaZdp8zpd0GewpM71p0TydFkl4kRIF07KkrD_717ljOiQiD-cc2EDQfMPmvtybqhn-CpWB4n2Fb_TrcgUIKfg"
  //   );
  //   formData.append("client_id", "dca72320-8cd6-4ce1-b860-211b240a3bce");
  //   formData.append(
  //     "code_challenge",
  //     "Nj9Youq443xUOCe_HsmBXJy5dKC8YsqlUdn1sga3CR0"
  //   );
  //   formData.append("code_challenge_method", "S256");
  //   formData.append(
  //     "redirect_uri",
  //     "https://staging.bookify.space/api/saml2/cs"
  //   );
  //   formData.append("scope", "offline_access  Calendars.ReadWrite");
  //   formData.append("code_verifier", "S256");
  //   return formData;
  // }

  // function prepareFormDataForRefresh() {
  //   let formData = new FormData();

  //   formData.append("grant_type", "refresh_token");
  //   formData.append(
  //     "refresh_token",
  //     localStorage.getItem("ms_teams_refresh_token")
  //   );
  //   formData.append("client_id", "dca72320-8cd6-4ce1-b860-211b240a3bce");
  //   formData.append(
  //     "redirect_uri",
  //     "https://staging.bookify.space/api/saml2/cs"
  //   );
  //   formData.append("scope", "offline_access  Calendars.ReadWrite");
  //   // formData.append("code_verifier", "S256");
  //   return formData;
  // }

  // const refreshOAuthToken = (data) => {
  //   const formDataStructured = prepareFormDataForRefresh();
  //   fetch("https://login.microsoftonline.com/common/oauth2/v2.0/token", {
  //     method: "POST",
  //     body: formDataStructured,
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       localStorage.setItem("ms_teams_access_token", data.access_token);
  //       localStorage.setItem("ms_teams_refresh_token", data.refresh_token);
  //     });
  // };

  // const getCalendarToken = () => {
  //   const formDataStructured = prepareFormData();
  //   fetch("https://login.microsoftonline.com/common/oauth2/v2.0/token", {
  //     method: "POST",
  //     body: formDataStructured,
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       debugger;
  //       if (
  //         data?.error !== undefined &&
  //         data?.error_description.includes("token has expired")
  //       ) {
  //         refreshOAuthToken();
  //       } else {
  //         if (data?.access_token && data?.refresh_token) {
  //           localStorage.setItem("ms_teams_access_token", data.access_token);
  //           localStorage.setItem("ms_teams_refresh_token", data.refresh_token);
  //         } else {
  //           return;
  //         }
  //       }
  //     });
  // };

  // useEffect(() => {
  //   getCalendarToken();
  // }, []);

  useEffect(() => {
    console.log(lists, "lists");
  }, [lists]);

  return (
    <>
      <SignInOption
        bookingId={visitorId}
        show={visitorSignInShow}
        setVisitorSignInShow={setVisitorSignInShow}
        handleVisitorSignInClose={handleVisitorSignInClose}
        dashboardActions={currentState}
      />

      {latestDashFilters && (
        <div className="dashboard-mobile-fixed-position-component">
          <FilterComponent
            parentActions={props?.actions}
            parentFilters={latestDashFilters}
            fromLoad={"Dashboard"}
            counts={counts}
          />
        </div>
      )}
      <div className="bg-white py-7 row px-md-10">
        <div className="d-flex justify-content-start w-100 dashboard-booking-button-block">
          <div className="daskboard-booking-filter-buttons">
            <button
              type="button"
              onClick={() => setBookingType("all")}
              className={`btn btn-blue rounded-pill ${bookingType === "all" &&
                "active"}`}
            >
              All
            </button>
            <button
              type="button"
              onClick={() => setBookingType("myBooking")}
              className={`btn btn-blue rounded-pill ml-2 ${bookingType ===
                "myBooking" && "active"}`}
            >
              MY BOOKINGS
            </button>
            <button
              type="button"
              onClick={() => setBookingType("other")}
              className={`btn btn-blue rounded-pill ml-2 ${bookingType ===
                "other" && "active"}`}
            >
              BOOKED FOR OTHERS
            </button>
            <button
              type="button"
              className="btn d-sm-none ml-2 p-0 rounded-pill"
            >
              <SVG src={toAbsoluteUrl("/media/svg/icons/reverse-arrows.svg")} />
            </button>
          </div>
        </div>
        {latestDashFilters?.type && latestDashFilters?.type !== "all" && (
          <h4 className="mt-10 w-100">
            {latestDashFilters?.type === "day_pass" && "Day Passes Booking"}
            {latestDashFilters?.type === "parking" && "Parking"}
            {latestDashFilters?.type === "host" && "Visitor"}
            {latestDashFilters?.type === "other" && "Meeting Rooms"}
            {/* {latestDashFilters?.type === "event" && "Event"} */}
          </h4>
        )}
        {/* {latestDashFilters?.type && latestDashFilters?.type === "all" && (
          <div className="dashboard-filter-boxes d-lg-flex d-md-flex d-sm-none justify-content-start my-10 px-10 w-100">
            <div className={`col-md-3`}>
              <div className="align-items-center d-flex day-pass-bg h-50px justify-content-center text-white w-100">
                <SVG src={toAbsoluteUrl("/media/svg/dashboard/day-pass.svg")} />
                <h5 className="font-20px font-weight-bolder mb-0 ml-5 text-uppercase">
                  Day Passes
                </h5>
              </div>
            </div>
            <div className={`col-md-3`}>
              <div className="d-flex parking-bg justify-content-center p-2 text-white w-100 align-items-center h-50px">
                <SVG src={toAbsoluteUrl("/media/svg/dashboard/parking.svg")} />
                <h5 className="font-20px font-weight-bolder mb-0 ml-5 text-uppercase">
                  Parking
                </h5>
              </div>
            </div>
            <div className={`col-md-3`}>
              <div className="visitor-bg justify-content-center p-2 d-flex text-white w-100 align-items-center h-50px">
                <SVG src={toAbsoluteUrl("/media/svg/dashboard/visitor.svg")} />
                <h5 className="font-20px font-weight-bolder mb-0 ml-5 text-uppercase">
                  Visitor
                </h5>
              </div>
            </div>
            <div className={`col-md-3`}>
              <div className="d-flex mitting-bg justify-content-center p-2 text-white w-100 align-items-center h-50px">
                <SVG src={toAbsoluteUrl("/media/svg/dashboard/mitting.svg")} />
                <h5 className="font-20px font-weight-bolder mb-0 ml-5 text-uppercase">
                  Meeting Rooms
                </h5>
              </div>
            </div>
          </div>
        )} */}
        {/* <div className="btn-section d-flex">
            <button
              className="button-cursor ml-auto btn btn-primary-outline select-vehicle add-vehicle"
              onClick={() => { setIsShowInfiniteScrollPopUp(!isShowInfiniteScrollPopUp) }}
            >
              <img width="15px" className="mb-1" src="/media/vehicles/plus.svg" alt="Plus" />
              &nbsp;&nbsp;&nbsp;ADD NEW WORK CIRCLE
            </button>
          </div> */}
        {listLoading && latestDashFilters?.page === 1 ? (
          <div className="px-12 w-100 d-flex flex-wrap justify-content-center">
            <Typography align="center" style={{ marginTop: 20 }}>
              <img
                style={{ margin: "13px 45%" }}
                src={toAbsoluteUrl("/media/work-circle/small-loader.svg")}
                alt="loader"
                className="img"
              />
            </Typography>
          </div>
        ) : (
          <>
            {lists &&
              lists.length < 1 &&
              listCount === 0 &&
              listCount !== undefined && (
                <div
                  className="w-100 d-flex flex-column justify-content-center align-items-center"
                  style={{ height: "45vh" }}
                >
                  {/* {console.log(lists, "lists")} */}
                  <h3> No Data Found! </h3>
                </div>
              )}
            {Object.keys(lists).length > 0 && (
              <div
                id="scrollableDiv"
                className="dashboard-divs w-100"
                style={{ marginTop: 30 }}
              >
                <InfiniteScroll
                  dataLength={tampLists.length} //This is important field to render the next data load
                  next={() => loadMoreData()}
                  hasMore={hasMore}
                  loader={
                    <img
                      style={{ margin: "13px 45%" }}
                      src={toAbsoluteUrl("/media/work-circle/small-loader.svg")}
                      alt="loader"
                      className="img"
                    />
                  }
                // height={500}
                // onScroll={(e) => scollEvent(e)}
                // scrollableTarget="root"
                >
                  <UserDashboardNew
                    setVisitorId={setVisitorId}
                    getList={getList}
                    action={props?.actions}
                    lists={lists}
                    setVisitorSignInShow={setVisitorSignInShow}
                  />
                </InfiniteScroll>
              </div>
            )}
          </>
        )}
      </div>
      <CalendarUserListComponent
        show={isShowUserList}
        setHidePopup={setHidePopup}
        bookingUserData={userData}
      />
    </>
  );
}
