import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  socialWorkcircleList: [],
  socialPosts: [],
  likeDislike: [],
  loading: true,
  lastCreatedPost: {},
  likes: [],
  createComment: [],
  deletComment: [],
  communityDetails: [],
  requestCommunityData: [],
  singleCommunity: {},
};
export const callTypes = {
  list: "list",
  action: "action",
};
export const Slice = createSlice({
  name: "socialwall",
  initialState: initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    socialWorkCircleList: (state, action) => {
      state.socialWorkcircleList = action.payload;
    },
    socialPosts: (state, action) => {
      state.socialPosts = action.payload;
    },
    clearSocialPosts: (state, action) => {
      state.socialPosts = [];
    },
    clearListOfTheLikes: (state, action) => {
      state.likes = [];
    },
    likeDislike: (state, action) => {
      state.likeDislike = action.payload;
    },
    lastCreatedPost: (state, action) => {
      state.lastCreatedPost = action.payload;
    },
    listOfLikes: (state, action) => {
      state.likes = action.payload;
    },
    createComment: (state, action) => {
      state.createComment = action.payload;
    },
    deletComment: (state, action) => {
      state.deleteComment = action.payload;
    },
    getAllCommunity: (state, action) => {
      state.communityDetails = action.payload;
    },
    getCommunityDetails: (state, action) => {
      state.singleCommunity = action.payload;
    },
  },
});
