import axios from "axios";

export const API_URL = `${process.env.REACT_APP_API_URL}`;

export function getVisitorVehicleList(src, queryParams) {
  return axios.post(`${API_URL}/${src}`, queryParams);
}
export function addNewVehicle(item) {
  return axios.post(`${API_URL}/api/vehicle `, item);
}
export function createKiosk(receptionist_id, visitor_id, booking_id) {
  // return item/
  return axios.get(`${API_URL}/api/booking/notification-kiosk?type=4`, {
    params: {
      visitor_id: visitor_id,
      receptionist_id: receptionist_id,
      booking_id: booking_id,
    },
  });
}
export function cancelKiosk(receptionist_id, visitor_id, booking_id) {
  // return item/
  return axios.get(`${API_URL}/api/booking/notification-kiosk?type=cancle`, {
    params: {
      visitor_id: visitor_id,
      receptionist_id: receptionist_id,
      booking_id: booking_id,
    },
  });
}

export function visitorIn(inputs) {
  // return item/
  return axios.post(`${API_URL}/api/booking/visitor/sign_in_post`, inputs);
}
