import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  listLoading: false,
  actionsLoading: false,
  visitorEntities: [],
  visitorEditItem: undefined,
  lastError: {},
  errorMessage: {},
  visitorsList: [],
  error: null,
  status: null,
}
export const callTypes = {
  list: "list",
  action: "action",
}
export const visitorSlice = createSlice({
  name: "visitor",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      // console.log(action.payload.error.response.data.errors.duration[0])
      Object.assign(state.errorMessage, {
        duration: action.payload.error.response.data.errors.duration[0],
      })
      // 			console.log("here", action.payload.error.response.data.errors.visitors {
      // 				0{
      // 					{
      // 					number{
      // 				0
      // 						},
      // 					},
      // 	},
      // })
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
    },
    startCall: (state, action) => {
      state.error = null
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    fetchedVisitorsList: (state, action) => {
      state.visitorsList = action.payload
    },
    // itemCreated
    itemCreated: (state, action) => {
      state.actionsLoading = false
      state.lastError = null
      if (action.payload.updateList === undefined) {
        state.visitorEntities = action.payload.item
      }
    },
    multiDateBookingCreated: (state, action) => {
      state.actionsLoading = false
      state.lastError = null
      state.visitorEntities = action.payload
    },
    updateVisitorData: (state, action) => {
      state.actionsLoading = false
      state.error = null
      state.lastError = null
      state.status = "update"
    },
    updateBookingVisitorData: (state, action) => {
      state.actionsLoading = false
      state.error = null
      state.lastError = null
      state.status = "update"
      state.visitorEntities = []
      state.visitorEntities.push(action.payload.item)
    },
    favouriteActionDone: (state, action) => {
      state.error = null
      state.actionsLoading = false
      state.entities = []
    },
    itemDeleted: (state, action) => {
      state.error = null
      state.actionsLoading = false
      state.visitorEntities = state.visitorEntities?.filter((el) => el?.id !== action.payload?.id)
    },
    // setEditItem
    setEditItem: (state, action) => {
      state.visitorEditItem = action.payload.item
    },
    clearData: (state, action) => {
      state.status = null
      state.visitorEntities = []
      state.visitorEditItem = undefined
    },
  },
})
