import React from "react";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import moment from "moment";
import { Modal } from "react-bootstrap";

const TNCComponent = (props) => {
  return (
    <Modal
      show={props?.show}
      className="calendar-booking-craete-popup"
      centered
    >
      <Modal.Header>
        <Modal.Title>
          Terms and Conditions
          <button
            type="button"
            onClick={() => props?.setHidePopup(false)}
            className="btn btn-close-poup p-0"
          >
            <SVG src={toAbsoluteUrl("/media/svg/icons/close.svg")} />
          </button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div className="align-items-center d-flex full-width-block justify-content-start w-100 mb-5">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur
        </div>
        <div className="align-items-center d-flex full-width-block justify-content-start w-100 mb-5">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur
        </div>
        <div className="align-items-center d-flex full-width-block justify-content-start w-100 mb-5">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur
        </div>
        <div className="align-items-center d-flex full-width-block justify-content-start w-100 mb-5">
          <div className="d-flex col-md-12 pr-0">
            <button
              type="button"
              onClick={() => props?.setHidePopup(false)}
              className="btn btn-block btn-primary rounded-pill"
            >
              Ok
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default TNCComponent;
