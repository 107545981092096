import React from 'react'
import { Col, Row } from "react-bootstrap";
import {  useSelector } from "react-redux";

export default function CommonErrorShow(props) {
    let txt=[]
    for (let x in props.error) {
        txt.push( props.error[x])
    };
    return (
        <>
            <Row className="pt-3" >
            {
                <ul>
                {
                    props.error &&txt.map((e,index)=>(
                        <li key={index} className='text-danger'>{e}</li>
                    ))
                }
                </ul>
            }
            </Row>
   
        </>
    )
}
