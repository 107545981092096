import * as requestFromServer from "./Crud";
import { Slice } from "./Slice";
import { NotificationManager } from "react-notifications";

const { actions } = Slice;

export const createBulletin = (params) => (dispatch) => {
  return requestFromServer
    .createBulletin(params)
    .then((response) => {
      const item = response.data;
      NotificationManager.success("Bulletin created successfully", "Success");
      return item;
    })
    .catch((error) => {
      const errMsg = error.response.data.message;
      NotificationManager.error(errMsg, "Error");
      return error;
    });
};

export const handleChangeStepOne = (queryParams) => (dispatch) => {
  dispatch(actions.handleStateChangeStepOne(queryParams));
};

export const handleChangeStepTwo = (queryParams) => (dispatch) => {
  dispatch(actions.handleStateChangeStepTwo(queryParams));
};

export const handleChangeStepThree = (queryParams) => (dispatch) => {
  dispatch(actions.handleStateChangeStepThree(queryParams));
};

export const handleChangeStepFour = () => (dispatch) => {
  dispatch(actions.handleStateChangeStepFour());
};

export const handleChangeStepPublish = () => (dispatch) => {
  dispatch(actions.handleStateChangeStepPublish());
};



export const getEvents = (params, filterParams) => (dispatch) => {
  return requestFromServer
    .getEvents(params, filterParams)
    .then((response) => {
      const item = response.data;
      dispatch(actions.getEventsDetails(item));
      return item;
    })
    .catch((error) => {
      const errMsg = error.response.data.message;
      NotificationManager.error(errMsg, "Error");
      return error;
    });
};

export const getAllEventFloor = (params) => (dispatch) => {
  return requestFromServer
    .getAllEventFloor(params)
    .then((response) => {
      const item = response.data;
      dispatch(actions.getFloorEvent(item));
      return item;
    })
    .catch((error) => {
      const errMsg = error.response.data.message;
      NotificationManager.error(errMsg, "Error");
      return error;
    });
};

export const getBeacons = (params) => (dispatch) => {
  return requestFromServer
    .getBecons(params)
    .then((response) => {
      const item = response.data;
      dispatch(actions.getBeacons(item));
      return item;
    })
    .catch((error) => {
      const errMsg = error.response.data.message;
      NotificationManager.error(errMsg, "Error");
      return error;
    });
};


export const getCommunityList = (params) => (dispatch) => {
  return requestFromServer
    .getCommunityList(params)
    .then((response) => {
      const item = response.data;
      dispatch(actions.getCommunity(item));
      return item;
    })
    .catch((error) => {
      const errMsg = error.response.data.message;
      NotificationManager.error(errMsg, "Error");
      return error;
    });
};

export const getCategoryList = (params) => (dispatch) => {
  return requestFromServer
    .getCategoryList(params)
    .then((response) => {
      const item = response.data;
      dispatch(actions.getCategory(item));
      return item;
    })
    .catch((error) => {
      const errMsg = error.response.data.message;
      NotificationManager.error(errMsg, "Error");
      return error;
    });
};

export const getAllEventHost = (params) => (dispatch) => {
  return requestFromServer
    .getEventHost(params)
    .then((response) => {
      const item = response.data;
      dispatch(actions.getEventHost(item));
      return item;
    })
    .catch((error) => {
      const errMsg = error.response.data.message;
      NotificationManager.error(errMsg, "Error");
      return error;
    });
};

export const getAllEventSpace = (params) => (dispatch) => {
  return requestFromServer
    .getAllEventSpace(params)
    .then((response) => {
      const item = response.data;
      dispatch(actions.getSpaceEvent(item));
      return item;
    })
    .catch((error) => {
      const errMsg = error.response.data.message;
      NotificationManager.error(errMsg, "Error");
      return error;
    });
};

export const confirmEvent = (params) => (dispatch) => {
  return requestFromServer
    .confirmEvent(params)
    .then((response) => {
      const item = response.data;
      dispatch(actions.confirmEvent(item));
      NotificationManager.success("Events Confirmed Successfully!", "Success");
      return item;
    })
    .catch((error) => {
      const errMsg = error.response.data.message;
      NotificationManager.error(errMsg, "Error");
      return error;
    });
};

export const cancelvent = (params) => (dispatch) => {
  return requestFromServer
    .cancelEvent(params)
    .then((response) => {
      const item = response.data;
      dispatch(actions.cancelvent(item));
      NotificationManager.success("Events Canceled Successfully!", "Success");
      return item;
    })
    .catch((error) => {
      const errMsg = error.response.data.message;
      NotificationManager.error(errMsg, "Error");
      return error;
    });
};

export const StoreFilterData = (params) => (dispatch) => {
  dispatch(actions.filterData(params));
};

export const resendRequestToInvitee = (params) => (dispatch) => {
  requestFromServer
    .resendRequestToInvitee(params)
    .then((response) => {
      // const item = response.data;
      // dispatch(actions.resendOptiondata(item));
      NotificationManager.success("Event Reqest Sent Successfully!", "Success");
      // return item;
    })
    .catch((error) => {
      const errMsg = error.response.data.message;
      NotificationManager.error(errMsg, "Error");
      return error;
    });
};
