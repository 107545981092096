import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../_helpers";
import SVG from "react-inlinesvg";
import moment from "moment";
import { BookingCard } from "../../layout/components/bookingCard";
import { Typography } from "@material-ui/core";

const UserDashboardNew = ({
  lists,
  action,
  getList,
  setVisitorId,
  setVisitorSignInShow,
}) => {
  const [allList, setAllList] = useState([]);
  const dispatch = useDispatch();

  const cancelItem = (id) => {
    dispatch(action?.deleteItem(id)).then((res) => (res ? getList() : null));
  };

  const handleVisitorSignInShow = (visitorId) => {
    setVisitorId(visitorId);
    setVisitorSignInShow(true);
  };

  useEffect(() => {
    setAllList(lists);
  }, []);
  return (
    <>
      {Object.keys(lists).length > 0 && (
        <>
          {Object.entries(lists).map((entry, ind) => {
            return (
              <>
                <div className="dashboard-booking-main-block position-relative">
                  <div className="dashboard-booking-title-block">
                    <SVG
                      className="dashboard-booking-main-block-icon"
                      src={toAbsoluteUrl("/media/svg/icons/round.svg")}
                    />
                    <h2
                      className="dashboard-booking-title"
                      key={`timeline-title-${ind}`}
                    >
                      {moment(entry[0], "YYYY-MM-DD").calendar(null, {
                        lastDay: "[Yesterday], dddd Do MMMM",
                        sameDay: "[Today], dddd Do MMMM",
                        nextDay: "[Tomorrow], dddd Do MMMM",
                        lastWeek: "[last] dddd Do MMMM",
                        nextWeek: "dddd Do MMMM",
                        sameElse: "dddd Do MMMM",
                      })}{" "}
                      {/* ({lists[item].dateCounts}) */}
                      {/* {ind === 2 && "This Month"}
                                    {ind === 3 && "In The Future"} */}
                    </h2>
                  </div>
                  <div className="row">
                    {entry[1]["all"].length > 0 &&
                      entry[1]["all"].map((citem, ind) => (
                        <>
                          <div className="col-xl-4 col-lg-6 col-md-12">
                            <BookingCard
                              key={`bookingList-${ind}`}
                              item={citem}
                              type={citem.type}
                              handleSignInClick={(props) => {
                                handleVisitorSignInShow(props);
                              }}
                              cancelItem={cancelItem}
                            />
                          </div>
                        </>
                      ))}
                  </div>
                </div>
              </>
            );
          })}
        </>
      )}
    </>
  );
};

export default UserDashboardNew;
