import * as requestFromServer from "./Crud"
import { Slice, callTypes } from "./Slice"
import { NotificationManager } from "react-notifications"
import { camelCaseToNormalCase } from "../../../components/UIHelpers"

const { actions } = Slice

export const getUserById = (params) => async (dispatch) => {
  try {
    dispatch(actions.startCall({ callType: callTypes.list }))
    const response = await requestFromServer.getUserById(params)
    const item = response?.data

    dispatch(actions.fetchUserById(item))
  } catch (error) {
    throw new error()
  }
}

export const getSiteDetails = (site) => (dispatch) => {
  const siteID = site.siteId
  requestFromServer.getSiteByID(siteID).then((response) => {
    const item = response.data
    dispatch(actions.fetchedSiteDetails(item))
  })
}

export const getVehiclesList = (site) => (dispatch) => {
  const userID = site.userID
  requestFromServer.getVehiclesList(userID).then((response) => {
    const item = response.data
    dispatch(actions.fetchedVehiclesDetails(item))
  })
}

export const getWorkCircleList = (userId) => (dispatch) => {
  requestFromServer.getWorkCircleList(userId).then((response) => {
    const item = response.data
    dispatch(actions.fetchWorkCircleList(item))
  })
}

export const getWorkCircleIDsList = (userId) => (dispatch) => {
  requestFromServer.getWorkCircleIDsList(userId).then((response) => {
    const item = response.data
    dispatch(actions.fetchWorkCircleList(item))
  })
}

export const getWorkCircleDetails = (workCircleID) => (dispatch) => {
  requestFromServer.getWorkCircleDetails(workCircleID).then((response) => {
    const item = response.data
    dispatch(actions.fetchWorkCircleDetails(item))
  })
}

export const createWorkCircle = (params) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }))
  return requestFromServer.createWorkCircle(params).then((response) => {
    const item = response
    NotificationManager.success("Work Circle Created Successfully!", "success")
    return item.status === 200 ? true : false
  })
}

export const updateWorkCircleDetails = (params) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }))
  return requestFromServer.updateWorkCircleDetails(params).then((response) => {
    const item = response
    NotificationManager.success("Work Circle Updated Successfully!", "success")
    return item.status === 200 ? true : false
  })
}

export const getUsersList = (params) => (dispatch) => {
  return requestFromServer.getUsersList(params).then((response) => {
    const item = response.data
    dispatch(actions.fetchedUsersList(item))
    return response.data
  })
}
export const getEditCircleList = (params) => (dispatch) => {
  return requestFromServer.getEditCircleList(params).then((response) => {
    const item = response.data
    dispatch(actions.fetchedUsersList(item))
    return response.data
  })
}

export const searchUserList = (params) => (dispatch) => {
  return requestFromServer.searchUser(params).then((response) => {
    const item = response.data
    // dispatch(actions.fetchedUsersList(item))
    return item
  })
}
export const getNotificationsList = () => (dispatch) => {
  requestFromServer.getNotificationsList().then((response) => {
    const item = response.data
    dispatch(actions.fetchedNotificationsList(item))
  })
}

export const getCalendarSettingsList = () => (dispatch) => {
  requestFromServer.getCalendarSettingsList().then((response) => {
    const item = response.data
    dispatch(actions.fetchedCalendarSettingsList(item))
  })
}

export const friendRequestResponse = (params) => (dispatch) => {
  return requestFromServer
    .friendRequestResponse(params)
    .then((response) => {
      const item = response.data
      dispatch(actions.fetchedFriendRequestResponse(item))
      return item
    })
    .catch((error) => {
      // error.clientMessage = "Can't update item";
      const errMsg =
        error.response && error.response.data && error.response.data.errors
          ? error.response.data.errors[0].msg
          : error.message
      NotificationManager.error(errMsg, "Error")
      dispatch(actions.catchError({ errMsg, callType: callTypes.action }))
      return false
    })
}

export const updateUserNotificationSettings = (params) => (dispatch) => {
  return requestFromServer
    .updateUserNotificationSettings(params)
    .then((response) => {
      const item = response.data
      dispatch(actions.fetchedUpdateUserNotificationSettings(item))
    })
    .catch((error) => {
      // error.clientMessage = "Can't update item";
      const errMsg =
        error.response && error.response.data && error.response.data.errors
          ? error.response.data.errors[0].msg
          : error.message
      NotificationManager.error(errMsg, "Error")
      dispatch(actions.catchError({ errMsg, callType: callTypes.action }))
      return false
    })
}
export const updateCalendarSettings = (params) => (dispatch) => {
  return requestFromServer
    .updateCalendarSettings(params)
    .then((response) => {
      const item = response.data
      dispatch(actions.fetchedUpdateCalendarSettings(item))
    })
    .catch((error) => {
      // error.clientMessage = "Can't update item";
      const errMsg =
        error.response && error.response.data && error.response.data.errors
          ? error.response.data.errors[0].msg
          : error.message
      NotificationManager.error(errMsg, "Error")
      dispatch(actions.catchError({ errMsg, callType: callTypes.action }))
      return false
    })
}

export const getAllFriendsWithRequests = (params) => (dispatch) => {
  return requestFromServer.getAllFriendsWithRequests(params).then((response) => {
    const item = response.data
    dispatch(actions.fetchedAllFriendsWithRequests(item))
    return response.data
  })
}

export const addFriendRequest = (params) => (dispatch) => {
  return requestFromServer
    .addFriendRequest(params)
    .then((response) => {
      const item = response.data
      dispatch(actions.fetchedFriendRequest(item))
      return item
    })
    .catch((error) => {
      const errMsg =
        error.response && error.response.data && error.response.data.errors
          ? error.response.data.errors[0].error
          : error.message

      console.log(errMsg, "errMsg")
      NotificationManager.error(errMsg, "Error")
      dispatch(actions.catchError({ errMsg, callType: callTypes.action }))
      return false
    })
}

export const addFriendToWorkCircle = (params) => (dispatch) => {
  return requestFromServer
    .addFriendToWorkCircle(params)
    .then((response) => {
      const item = response.data
      dispatch(actions.fetchAddFriendToCircle(item))
      return item
    })
    .catch((error) => {
      const errMsg =
        error.response && error.response.data && error.response.data.errors
          ? error.response.data.errors.error[0]
          : error.message
      NotificationManager.error(camelCaseToNormalCase(errMsg), "Error")
      dispatch(actions.catchError({ errMsg, callType: callTypes.action }))
      return false
    })
}

export const deleteWorkCircle = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }))
  return requestFromServer
    .deleteWorkCircle(id)
    .then((response) => {
      dispatch(actions.workCircleDeleted({ id }))
      NotificationManager.success("Work Circle Deleted Successfully!", "success")
    })
    .catch((error) => {
      const errMsg =
        error.response && error.response.data && error.response.data.errors
          ? error.response.data.errors[0].msg
          : error.message
      NotificationManager.error(errMsg, "Error")
      dispatch(actions.catchError({ error, callType: callTypes.action }))
    })
}


