import { createSlice } from "@reduxjs/toolkit"
import moment from "moment"

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  availabilityCounts: undefined,
  calendarBooking: undefined,
  calendarAvailability: undefined,
  // blogForEdit: {},
  lastError: null,
  AVCFilters: {
    date: moment(),
    site_id: 1,
    floor_id: [],
    floor_obj: {},
    type: "all",
  },
}
export const callTypes = {
  list: "list",
  action: "action",
}
export const Slice = createSlice({
  name: "schedulerAvailability",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
    },
    startCall: (state, action) => {
      state.error = null
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    // singleItemFetched
    singleItemFetched: (state, action) => {
      state.actionsLoading = false
      // state.blogForEdit = action.payload.blogForEdit
      state.error = null
    },
    // listFetched
    listFetched: (state, action) => {
      const { totalCount, entities } = action.payload
      state.listLoading = false
      state.error = null
      state.entities = entities
      state.totalCount = totalCount
    },
    // listFetched
    countsFetched: (state, action) => {
      state.listLoading = false
      state.error = null
      state.availabilityCounts = action.payload
    },
    // listFetched
    bookingCalendarFetched: (state, action) => {
      state.listLoading = false
      state.error = null
      state.calendarBooking = action.payload?.bookings
    },
    // listFetched
    bookingCalendarAvailabilityFetched: (state, action) => {
      state.listLoading = false
      state.error = null
      state.calendarAvailability = action.payload
    },
    // itemCreated
    itemCreated: (state, action) => {
      state.actionsLoading = false
      state.error = null
      state.entities = []
      // state.entities.push(action.payload.item);
    },
    setCalItemFilter: (state, action) => {
      const data = action.payload
      state.AVCFilters = { ...state.AVCFilters, ...data }
    },
  },
})
