import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "./_redux/Crud";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { useSelector } from "react-redux";

import "./WorkstationFloorMobile.scss";
import { useParams } from "react-router-dom";

const WorkstationFloorMobile = (
  {
    // start_date,
    // ends_date,
    // floor_id,
    // url_image,
    // setWorkStationId,
  }
) => {
  const [workstationCoordinates, setWorkStationCoordinates] = useState([]);
  const [xyzCirc, setXyzCirc] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const BASE_URL = process.env.REACT_APP_API_URL;

  const [selectedItemsPath, setSelectedItemsPath] = useState([]);

  const { starts_time, ends_time, floor_id } = useParams();

  const [floorData, setFloorData] = useState([]);
  useEffect(() => {
    axios.get(`${BASE_URL}/api/floors/${floor_id}`).then((res) => {
      setFloorData(res.data);
      if (res.data) {
        if (starts_time && ends_time) {
          const params = {
            starts_at: starts_time,
            floor_id: floor_id,
            ends_at: ends_time,
          };
          axios
            .post(`${API_URL}/api/spaces/is-available-workstation`, params)
            .then((response) => setWorkStationCoordinates(response.data));
        }
      }
    });
  }, [ends_time]);

  useEffect(() => {
    if (workstationCoordinates?.length > 0) {
      return workstationCoordinates.forEach((element) => {
        if (element?.poly_coordinates !== null) {
          const parsed = element.poly_coordinates;
          if (parsed[0].circles.length > 0 && parsed[0].circles !== null) {
            parsed[0].circles.forEach((onecircle) => {
              setXyzCirc((prev) => [
                ...prev,
                {
                  x: onecircle.x,
                  y: onecircle.y,
                  type: onecircle.type,
                  id: element.id,
                },
              ]);
            });
          }
        }
      });
    }
  }, [workstationCoordinates]);

  window.onclick = (e) => {
    e.stopPropagation();

    if (e.path[0].localName === "circle") {
      if (
        e.target.classList.contains("selected") &&
        !e.target.classList.contains("occupied")
      ) {
        if (selectedItems.length < 1) {
          e.target.classList.add("occupied");
          // setWorkStationId(e.target.id);
          setSelectedItems([
            ...selectedItems,
            {
              x: e.target.attributes.cx.nodeValue,
              y: e.target.attributes.cy.nodeValue,
              id: e.target.id,
              type: e.path[0].localName,
            },
          ]);
        }
      } else if (
        e.target.classList.contains("selected") &&
        e.target.classList.contains("occupied")
      ) {
        e.target.classList.remove("occupied");
        // setWorkStationId(undefined);
        setSelectedItems(() =>
          selectedItems.filter(
            (one) => one.x !== e.target.attributes.cx.nodeValue
          )
        );
      }
    }
    // path
    if (e.path[0].localName === "path") {
      if (
        e.target.classList.contains("selected") &&
        !e.target.classList.contains("occupied")
      ) {
        if (selectedItems.length < 1) {
          e.target.classList.add("occupied");
          // setWorkStationId(e.target.id);
          setSelectedItemsPath([
            ...selectedItemsPath,
            {
              d: e.target.attributes.d.nodeValue,
              type: e.path[0].localName,
            },
          ]);
        }
      } else if (
        e.target.classList.contains("selected") &&
        e.target.classList.contains("occupied")
      ) {
        e.target.classList.remove("occupied");
        e.target.classList.add("selected");
        // setWorkStationId(undefined);
        setSelectedItemsPath(() =>
          selectedItemsPath.filter(
            (one) => one.d !== e.target.attributes.d.nodeValue
          )
        );
      }
    }
  };

  useEffect(() => {
    if (xyzCirc?.length > 0) {
      setLoading(true);
    }
    if (xyzCirc.length > 0) {
      const assignedCircls = xyzCirc;
      const allCircles = document.querySelectorAll("svg circle");

      if (assignedCircls && assignedCircls.length > 0) {
        allCircles.forEach((element) => {
          assignedCircls.forEach((circle) => {
            if (
              element.attributes.cx.nodeValue === circle.x &&
              element.attributes.cy.nodeValue === circle.y &&
              element.localName === circle.type
            ) {
              element.classList.add("selected");
              element.setAttribute("id", circle.id);
              setLoading(false);
            }
          });
        });
      }
    }
  }, [xyzCirc]);
  useEffect(() => {
    if (window.location.pathname.includes("/workstationFloor")) {
      document.querySelector("#root").style.height = "auto";
      document.querySelector("body").style.height = "auto";
      document.querySelector("#splash-screen").style.display = "none";
    }
  }, []);

  return (
    <>
      <div className="main_svg_wrapper_workstation_mobile">
        {/* <TransformWrapper>
          <TransformComponent> */}
        <SVG
          src={toAbsoluteUrl(floorData?.url_image)}
          className="heleon_svg"
        ></SVG>
        {/* </TransformComponent>
        </TransformWrapper> */}
      </div>
    </>
  );
};

export default WorkstationFloorMobile;
