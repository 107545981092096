import moment from "moment";
import React, { useState, useEffect } from "react";
import { Calendar } from "react-multi-date-picker";
import { NotificationManager } from "react-notifications";

const format = "MM/DD/YYYY";

export default function CustomMultiDatePicker(props) {
  const {
    value: tabName,
    bookedDates,
    disableList,
    handleSelectedDates,
    selectedDates,
  } = props;
  const [dates, setDates] = useState([]);
  console.log(dates);
  useEffect(() => {
    if (tabName === 1) {
      selectedDates ? setDates(selectedDates) : setDates([]);
    } else {
      setDates(new Date());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabName]);

  const [momentedDates, setmMmentedDates] = useState([]);

  useEffect(() => {
    if (dates) {
      if (tabName === 0) {

        handleSelectedDates([dates]);
      } else if (tabName === 1) {

        handleSelectedDates(momentedDates);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates]);

  const handleMonthChange = (date) => {
    if (props?.handleMonthChange) {
      props.handleMonthChange(date.format("DD/MM/YYYY"));
    }
  };
  const handleDateSelection = (myDates) => {

    if (Object?.keys(myDates).length <= 5 && Array.isArray(myDates)) {
      let tempArr = [];
      myDates.forEach((element) => {
        tempArr.push(
          moment(
            `${element.month.index + 1}-${element.day}-${element.year}`,
            "MM-DD-YYYY"
          )
        );
      });
      setmMmentedDates(tempArr);
      setDates(myDates);
    }
    else if (!Array.isArray(myDates)) {
      console.log(myDates, "here Reached")
      let tempArr = [];
      tempArr.push(
        moment(
          `${myDates.month.index + 1}-${myDates.day}-${myDates.year}`,
          "MM-DD-YYYY"
        )
      );

      setmMmentedDates(tempArr);
      setDates(moment(`${myDates.month.index + 1}-${myDates.day}-${myDates.year}`, "DD-MM-yyyy"));
      console.log("");
    }
    else {
      var lastDateArray = [...dates];
      setDates(lastDateArray);
      NotificationManager.error("Maximum Five Dates Selection Allowed");
    }
  };
  return (
    <div style={{ textAlign: "center" }}>
      <Calendar
        value={dates}
        onChange={(myDates) => handleDateSelection(myDates)}
        sort
        format={format}
        minDate={new Date()}
        calendarPosition="bottom-center"
        onMonthChange={(date) => {
          handleMonthChange(date);
        }}
        mapDays={({ date }) => {
          let bookings;
          if (bookedDates) {
            bookings = bookedDates.filter((item) => {
              if (item.date === date.format("YYYY-MM-DD")) {
                return item;
              }
            });
          }
          let disableDetails = {};
          if (disableList && disableList.includes(date.format("YYYY-MM-DD"))) {
            NotificationManager.error("Sorry! Booking full for requested date.")
            // disableDetails = {
            //   disabled: true,
            //   style: { color: "#ccc" },

            // };
          }

          return {
            children: (
              <div className="day-section">
                <div>{date.format("D")}</div>
                <div className="d-flex booking-dots">
                  {bookings &&
                    bookings.map((item, key) => {
                      return (
                        <div
                          key={key}
                          className={`booking-dot ${item.class}`}
                          style={{ backgroundColor: item?.color }}
                        ></div>
                      );
                    })}
                </div>
              </div>
            ),
            ...disableDetails,
          };
        }}
      />
    </div>
  );
}
