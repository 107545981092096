import axios from "axios";

export const API_URL = `${process.env.REACT_APP_API_URL}`;

// CREATE =>  POST: add a new StaffUser to the server
export function createItem(item) {
  // return item/
  return axios.post(`${API_URL}/api/parking_bookings`, item);
}
export function updateItem(id, item) {
  return axios.put(`${API_URL}/api/parking_bookings/${id}`, item);
}