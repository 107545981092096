import axios from "axios";

export const API_URL = `${process.env.REACT_APP_API_URL}`;

// CREATE =>  POST: add a new StaffUser to the server
export function createItem(item) {
	// return item/
	return axios.post(`${API_URL}/api/vehicle `, item);
}

// DELETE => delete the StaffUser from the server
export function deleteItem(id) {
	return axios.delete(`${API_URL}/api/vehicle/${id}`);
}

// UPDATE => PUT: update the StaffUser on the server
export function updateItem(item) {
	return axios.put(`${API_URL}/api/vehicle/${item.id}`, item);
}
