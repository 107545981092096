import React from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export default function Header(props) {
  const { bookingData, isThankYouMsg } = props;
  return (
    <>
      <div
        className={
          "d-flex justify-content-between w-100 align-items-center" +
          (isThankYouMsg ? " " : "")
        }
      >
        <div className="d-flex align-items-center">
          <div className="visitor-popup-header-img-wrap">
            <div className="image">
              <img
                className="img"
                src={
                  bookingData?.guest?.url_image
                    ? `${bookingData?.guests?.url_image}`
                    : `media/svg/icons/default_user.svg`
                }
                alt={`${bookingData?.guests[0]?.full_name}`}
              />
            </div>
          </div>
          <div>
            <h2 className="owner-name">
              {!isThankYouMsg && <b>Hi, </b>}
              <b className={isThankYouMsg ? "text-white" : "owner-name-color"}>
                {bookingData?.guest?.full_name}
              </b>
            </h2>
            {isThankYouMsg ? (
              <div>
                <SVG src={toAbsoluteUrl("/media/svg/icons/Frame949.svg")} />
              </div>
            ) : (
              <h4 className="site-name-visitor">
                Welcome to {bookingData?.site[0]?.title}!
              </h4>
            )}
          </div>
        </div>

        <div className="d-flex align-items-end signin-option-header-end-div booking-details-wrap">
          <div className="booking-details">
            <p className="site-details">
              {" "}
              {bookingData?.site[0]?.title}, Floor 1
            </p>
            <h6 className="visitor-details">{bookingData?.guest?.full_name}</h6>
            <p className="host-details">
              Host: <b>{bookingData?.host?.full_name}</b>
            </p>
          </div>
          {bookingData?.parking_id !== 0 && (
            <div>
              <span className="">
                <SVG
                  src={toAbsoluteUrl("media/svg/icons/task_parking.svg")}
                  className="parking-icon"
                />
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
