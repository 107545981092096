import React, { useState } from "react";
import { Tabs, Tab } from "@material-ui/core";
import { Button, Modal } from "react-bootstrap";
import { Select2 } from "select2-react-component";
import axios from "axios";
//css
import "./AddInvitee.scss";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import moment from "moment";
import { TimePickerField } from "../../../../../../_metronic/_partials/controls";
import SVG from "react-inlinesvg";
import { TimePicker } from "react-tempusdominus-bootstrap";
import { useSelector } from "react-redux";

const AddInvitee = ({
  show,
  handleClose,
  users,
  visitors,
  setSelectedUsers,
  setSelectedVisitors,
  selectedUsers,
  selectedVisitors,
}) => {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const [value, setValue] = useState(0);
  const [selectUser, setSelectUser] = useState("");
  const [selectVisitor, setSelectVisitor] = useState("");
  const [nightTime, setNightTime] = useState();
  const [daypass, setDaypass] = useState(false);
  const [carpark, setCarpark] = useState(false);

  var format = "hh:mm:ss";
  var time = moment(nightTime, format);
  var afterTime = moment("18:01:00", format);
  var beforeTime = moment("07:00:00", format).add(1, "day");
  var timeBetween = time.isBetween(afterTime, beforeTime);
  const { events } = useSelector((state) => state.events);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  const saveAndAddAnother = () => {
    if (selectVisitor !== "") {
      const filtered = visitors.filter(
        (one) => parseInt(one.value, 10) === parseInt(selectVisitor, 10)
      );
      setSelectedVisitors((prev) => [
        ...prev,
        {
          user_id: filtered[0].value,
          full_name: filtered[0].label,
          email: filtered[0].email,
          day_pass: daypass,
          car_park: carpark,
          url_image: filtered[0].url_image,
          type: "Visitor",
        },
      ]);
      setSelectVisitor("");
    }
    if (selectUser !== "") {
      const filtered = users.filter(
        (one) => parseInt(one.value, 10) === parseInt(selectUser, 10)
      );
      setSelectedUsers((prev) => [
        ...prev,
        {
          user_id: filtered[0].value,
          full_name: filtered[0].label,
          email: filtered[0].email,
          day_pass: daypass,
          car_park: carpark,
          url_image: filtered[0].url_image,
          type: "User",
        },
      ]);
      setSelectUser("");
    }
    setDaypass(false);
    setCarpark(false);
  };

  const handleRemoveUser = (id) => {
    setSelectedUsers(selectedUsers.filter((one) => one.user_id !== id));
  };

  const handleRemoveVisitor = (id) => {
    setSelectedVisitors(selectedVisitors.filter((one) => one.user_id !== id));
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="modal add-invitee-main"
      backdrop="static"
    >
      <Modal.Header className="d-flex justify-content-between">
        <Modal.Title>
          <span>ADD INVITEES</span>
        </Modal.Title>
        <Modal.Title>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={
              selectedUsers.length === 0 && selectedVisitors.length === 0
            }
            className={`btn btn-primary d-flex justify-content-between align-items-center font-weight-bolder w-100`}
            onClick={handleClose}
          >
            DONE
          </button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="event-form-main pt-0">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="secondary"
          className="d-flex justify-content-center mb-4"
        >
          <Tab label="USER" />
          <Tab label="VISITOR" />
        </Tabs>
        {value === 0 && (
          <>
            <Select2
              placeholder="Select User"
              data={users}
              value={selectUser}
              update={(value) => {
                setSelectUser(value);
              }}
              className="mr-3 mt-4"
            ></Select2>
            {events.stepOne.event_type === "internal" && (
              <>
                <div className="d-flex justify-content-between border-class mt-2">
                  <span className="mt-1 link-generate">Add Day Pass</span>
                  <div className="button-cursor custom-control custom-switch align-self-sm-center">
                    <input
                      name={`add_daypass`}
                      type="checkbox"
                      id={"add_daypass"}
                      className="custom-control-input"
                      checked={daypass}
                      onChange={() => {
                        setDaypass(!daypass);
                      }}
                    />
                    <label
                      title=""
                      htmlFor={"add_daypass"}
                      className="custom-control-label"
                    ></label>
                  </div>
                </div>
                <div className="d-flex justify-content-between border-class mt-2">
                  <span className="mt-1 link-generate">
                    Add Vehicle Parking Space?
                  </span>
                  <div className="button-cursor custom-control custom-switch align-self-sm-center">
                    <input
                      name={`add_vehicle`}
                      type="checkbox"
                      id={"add_vehicle"}
                      className="custom-control-input"
                      checked={carpark}
                      onChange={() => {
                        setCarpark(!carpark);
                      }}
                    />
                    <label
                      title=""
                      htmlFor={"add_vehicle"}
                      className="custom-control-label"
                    ></label>
                  </div>
                </div>
              </>
            )}
            {selectedUsers.length > 0 &&
              selectedUsers.map((one) => {
                return (
                  <div className="event-host d-flex mt-3 w-100">
                    <div className="align-items-center d-flex py-3">
                      <img
                        width="55px"
                        src={
                          one?.url_image
                            ? one?.url_image
                            : toAbsoluteUrl("/media/svg/icons/default_user.svg")
                        }
                        alt="Host"
                        className="max-w-70px rounded-circle ml-2"
                      />
                      <div className="event-details-main pl-5">
                        <span className="event-lbl font-weight-bold d-block mb-2">
                          {one?.full_name !== ""
                            ? one?.full_name
                            : one?.full_name}
                        </span>
                        <span className="event-no text-primary">
                          {one?.type}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center ml-auto">
                      <img
                        src="/media/vehicles/edit.png"
                        alt="edit"
                        style={{ width: "40px", marginRight: "10px" }}
                        className="cursor-pointer"
                        // onClick={handleAddHost}
                      />
                      <img
                        src="/media/vehicles/delete.png"
                        alt="edit"
                        style={{ width: "40px", marginRight: "10px" }}
                        className="cursor-pointer"
                        onClick={() => handleRemoveUser(one.user_id)}
                      />
                    </div>
                  </div>
                );
              })}
          </>
        )}
        {value === 1 && (
          <>
            <Select2
              placeholder="Select Visitor"
              data={visitors}
              value={selectVisitor}
              update={(value) => {
                setSelectVisitor(value);
              }}
              className="mr-3 mt-4"
            ></Select2>
            {events.stepOne.event_type === "internal" && (
              <>
                <div className="d-flex justify-content-between border-class mt-2">
                  <span className="mt-1 link-generate">
                    Book Visitor - Weybridge-Floor1?
                  </span>
                  <div className="button-cursor custom-control custom-switch align-self-sm-center">
                    <input
                      name={`book_visitor`}
                      type="checkbox"
                      id={"book_visitor"}
                      className="custom-control-input"
                      // checked={values?.book_visitor}
                      // onChange={handleChange}
                    />
                    <label
                      title=""
                      htmlFor={"book_visitor"}
                      className="custom-control-label"
                    ></label>
                  </div>
                </div>
                <div className="d-flex justify-content-between border-class mt-2">
                  <span className="mt-1 link-generate">Full Day</span>
                  <div className="button-cursor custom-control custom-switch align-self-sm-center">
                    <input
                      name={`full_day`}
                      type="checkbox"
                      id={"full_day"}
                      className="custom-control-input"
                      checked={daypass}
                      onChange={() => {
                        setDaypass(!daypass);
                      }}
                    />
                    <label
                      title=""
                      htmlFor={"full_day"}
                      className="custom-control-label"
                    ></label>
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-3 mb-3">
                  <TimePicker />
                  <TimePicker />
                </div>
                <div className="d-flex justify-content-between border-class mt-2">
                  <span className="mt-1 link-generate">Add Day Pass</span>
                  <div className="button-cursor custom-control custom-switch align-self-sm-center">
                    <input
                      name={`add_daypass`}
                      type="checkbox"
                      id={"add_daypass"}
                      className="custom-control-input"
                      checked={carpark}
                      onChange={() => {
                        setCarpark(!carpark);
                      }}
                    />
                    <label
                      title=""
                      htmlFor={"add_daypass"}
                      className="custom-control-label"
                    ></label>
                  </div>
                </div>
                <div className="d-flex justify-content-between border-class mt-2">
                  <span className="mt-1 link-generate">
                    Add Vehicle Parking Space?
                  </span>
                  <div className="button-cursor custom-control custom-switch align-self-sm-center">
                    <input
                      name={`add_vehicle`}
                      type="checkbox"
                      id={"add_vehicle"}
                      className="custom-control-input"
                      // checked={values?.add_vehicle}
                      // onChange={handleChange}
                    />
                    <label
                      title=""
                      htmlFor={"add_vehicle"}
                      className="custom-control-label"
                    ></label>
                  </div>
                </div>
              </>
            )}
            {selectedVisitors.length > 0 &&
              selectedVisitors.map((one) => {
                return (
                  <div className="event-host d-flex mt-3 w-100">
                    <div className="align-items-center d-flex py-3">
                      <img
                        width="55px"
                        src={
                          one?.url_image
                            ? one?.url_image
                            : toAbsoluteUrl("/media/svg/icons/default_user.svg")
                        }
                        alt="Host"
                        className="max-w-70px rounded-circle ml-2"
                      />
                      <div className="event-details-main pl-5">
                        <span className="event-lbl font-weight-bold d-block mb-2">
                          {one?.full_name !== ""
                            ? one?.full_name
                            : one?.full_name}
                        </span>
                        <span className="event-no text-primary">
                          {one?.type}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center ml-auto">
                      <img
                        src="/media/vehicles/edit.png"
                        alt="edit"
                        style={{ width: "40px", marginRight: "10px" }}
                        className="cursor-pointer"
                        // onClick={handleAddHost}
                      />
                      <img
                        src="/media/vehicles/delete.png"
                        alt="edit"
                        style={{ width: "40px", marginRight: "10px" }}
                        className="cursor-pointer"
                        onClick={() => handleRemoveVisitor(one.user_id)}
                      />
                    </div>
                  </div>
                );
              })}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button className="save-button" onClick={saveAndAddAnother}>
          SAVE AND ADD ANOTHER
        </Button>
        <Button
          className="cancle-button"
          onClick={() => {
            handleClose();
            setSelectedUsers([]);
            setSelectedVisitors([]);
          }}
        >
          CANCEL
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddInvitee;
