import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  bulletin: {
    stepOne: {
      video_url: "",
      title: "",
      content: "",
      cover_image: undefined,
      document: undefined,
      category_id: []
    },
    stepTwo: {
      floor_id: "",
      community_id: "",
      is_visiable: "",
      include_exclude_users: ""
    },
    stepThree: {
      start_date: "",
      start_time: "",
      end_date: "",
      end_time: "",

    },
    stepPublish: {
      app_notification: "",
      email_notification: "",
    }
  },
  bulletinDetails: [],
  eventFloor: [],
  bulletinpace: [],
  eventHost: [],
  eventFilter: {},
  confirmEventData: [],
  cancelEventData: [],
  resendOptiondata: [],
};

export const Slice = createSlice({
  name: "bulletin",
  initialState: initialState,
  reducers: {
    handleStateChangeStepOne: (state, action) => {
      state.bulletin.stepOne.title = action.payload.title;
      state.bulletin.stepOne.content = action.payload.content;
      state.bulletin.stepOne.video_url = action.payload.video_url;
      state.bulletin.stepOne.cover_image = action.payload.cover_image;
      state.bulletin.stepOne.document = action.payload.document;
      state.bulletin.stepOne.category_id = action.payload.category_id;
    },
    handleStateChangeStepTwo: (state, action) => {
      state.bulletin.stepTwo.floor_id = action.payload.floor_id;
      state.bulletin.stepTwo.community_id = action.payload.community_id;
      state.bulletin.stepTwo.is_visiable = action.payload.is_visiable;
      state.bulletin.stepTwo.include_exclude_users = action.payload.include_exclude_users;


    },
    handleStateChangeStepThree: (state, action) => {
      console.log(action, "action3");
      state.bulletin.stepThree.start_date = action.payload.start_date;
      state.bulletin.stepThree.start_time = action.payload.start_time;
      state.bulletin.stepThree.end_date = action.payload.end_date;
      state.bulletin.stepThree.end_time = action.payload.end_time;
    },
    handleStateChangeStepPublish: (state, action) => {
      state.bulletin.stepPublish.app_notification = action.payload.app_notification;
      state.bulletin.stepPublish.email_notification = action.payload.email_notification;



    },
    handleStateChangeStepFour: (state, action) => {
      state.bulletin.stepOne.name = "";
      state.bulletin.stepOne.details = "";
      state.bulletin.stepOne.event_type = "";
      state.bulletin.stepOne.cover_image = "";
      state.bulletin.stepOne.document = "";
      state.bulletin.stepTwo.created_at = "";
      state.bulletin.stepTwo.starts_at = "";
      state.bulletin.stepTwo.ends_at = "";
      state.bulletin.stepTwo.map_url = "";
      state.bulletin.stepTwo.city = "";
      state.bulletin.stepTwo.address_1 = "";
      state.bulletin.stepTwo.address_2 = "";
      state.bulletin.stepTwo.postcode = "";
      state.bulletin.stepTwo.site_id = "";
      state.bulletin.stepThree.host = {};
      state.bulletin.stepThree.invitees = [];
      state.bulletin.stepThree.generateLink = false;
    },
    getbulletinDetails: (state, action) => {
      state.bulletinDetails = action.payload;
    },
    getFloorEvent: (state, action) => {
      state.eventFloor = action.payload;
    },
    getBeacons: (state, action) => {
      state.eventFloor = action.payload;
    },
    getCommunity: (state, action) => {
      state.commjunity = action.payload;
    },
    getCategory: (state, action) => {
      state.commjunity = action.payload;
    },
    getSpaceEvent: (state, action) => {
      state.bulletinpace = action.payload;
    },
    getEventHost: (state, action) => {
      state.eventHost = action.payload;
    },
    filterData: (state, action) => {
      state.eventFilter = action.payload;
    },
    confirmEvent: (state, action) => {
      state.confirmEventData = action.payload;
    },
    cancelvent: (state, action) => {
      state.cancelEventData = action.payload;
    },
    resendRequest: (state, action) => {
      state.resendOptiondata = action.payload;
    },
  },
});
