import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { useSelector } from "react-redux";
import AddVisitorSuccess from "../../../../../app/modules/Booking/Visitor/AddVistorSuccess";
import { toAbsoluteUrl } from "../../../../_helpers";
import ActionModalVisitor from "../ActionModalComponant/ActionModalVisitor";
import AddNewVisitor from "./AddNewVisitor";
import * as dashboardActions from "../../../../../app/pages/_redux/Actions";

const modalInitialValues = {
  name: "addVisitor",
  title: "Book Visitor",
};

export const AddVisitor = (props) => {
  const [show, setShow] = useState(false);
  const [showAddNewVisitor, setShowAddNewVisitor] = useState(false);
  const [addedVisitor, setAddedVisitor] = useState(false);
  const [addVisitorSuccess, setAddVisitorSuccess] = useState(false);
  const [modalProps, setModalProps] = useState(modalInitialValues);
  const [id, setId] = useState();
  const [visitorBookingItem, setVisitorBookingItem] = useState();

  const [listUpdate, setListUpdate] = useState(0);

  const handleShow = () => {
    setShow(true);
    props.handleParentClose();
  };
  const handleAddNewVistor = () => setShowAddNewVisitor(true);
  const handleAddNewVistorClose = () => setShowAddNewVisitor(false);

  const visitorStore = useSelector((state) => state.visitors);
  const { visitorEditItem } = visitorStore;

  useEffect(() => {
    if (visitorEditItem) {
      let props = { ...modalProps };
      props.name = "editVisitor";
      props.title = "Edit Visitor Booking";
      setModalProps(props);
      handleShow();
      console.log(visitorEditItem)
      setId(visitorEditItem?.id);
      console.log('visoter add menu ', visitorEditItem);
      setVisitorBookingItem(visitorEditItem);

    } else {
      setModalProps(modalInitialValues);
      setId(undefined);
      console.log('visoter add menu undef ', visitorEditItem)
      setVisitorBookingItem(undefined);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitorStore]);

  // useEffect(() => {
  //   if (visitorEditItem) {
  //     handleShow()
  //   }
  // }, [visitorEditItem])

  const handleClose = () => {
    setShow(false);
    setShowAddNewVisitor(false);
  };

  const handleAddVisitorSuccess = () => setAddVisitorSuccess(true);
  const handleAddVisitorSuccessClose = () => setAddVisitorSuccess(false);

  return (
    <>
      <div className="button-cursor pt-5 pb-5" onClick={() => handleShow()}>
        <div className="d-inline-flex pl-5">
          <span className="svg-icon">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Booking/visitor.svg")}
            ></SVG>
          </span>
        </div>
        <div className="d-inline-flex pl-5 pt-3">
          <h6>BOOK VISITOR</h6>
        </div>
      </div>
      <ActionModalVisitor
        dashboardActions={dashboardActions}
        show={show}
        onHide={handleClose}
        handleAddVisitor={handleAddNewVistor}
        showAddClose={handleAddNewVistorClose}
        handleAddVisitorSuccess={handleAddVisitorSuccess}
        addedVisitor={addedVisitor}
        handleAddVisitorSuccessClose={handleAddVisitorSuccessClose}
        id={id}
        visitorBookingItem={visitorBookingItem}
        modalProps={modalProps}
        listUpdate={listUpdate}
      />
      <AddNewVisitor
        show={showAddNewVisitor}
        showAddClose={handleAddNewVistorClose}
        setAddedVisitor={setAddedVisitor}
        setListUpdate={setListUpdate}
        listUpdate={listUpdate}
      />
      <AddVisitorSuccess
        show={addVisitorSuccess}
        showAddClose={handleAddVisitorSuccessClose}
        id={id}
        status={visitorStore?.status}
      />
    </>
  );
};
