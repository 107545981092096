import * as requestFromServer from "./Crud"
import { Slice, callTypes } from "./Slice"

const { actions } = Slice

export const fetchList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }))
  return requestFromServer
    .getBookingAvailableList(queryParams)
    .then((response) => {
      const { total, available, parameters } = response.data
      dispatch(actions.BookingDateAvailabilityFetched({ total, available, parameters }))
    })
    .catch((error) => {
      // const errMsg = error.response && error.response.data && error.response.data.errors ? error.response.data.errors[0].msg : error.message ;
      //   NotificationManager.error(errMsg, 'Error');
      dispatch(actions.catchError({ error, callType: callTypes.list }))
    })
}

export const friendDayPassList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }))
  return requestFromServer
    .friendDayPassList(queryParams)
    .then((response) => {
      // const { total, available, parameters } = response.data
      dispatch(actions.friendDayPassList(response?.data?.data))
    })
    .catch((error) => {
      // const errMsg = error.response && error.response.data && error.response.data.errors ? error.response.data.errors[0].msg : error.message ;
      //   NotificationManager.error(errMsg, 'Error');
      dispatch(actions.catchError({ error, callType: callTypes.list }))
    })
}

export const disableDateList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }))
  return requestFromServer
    .disableDateList(queryParams)
    .then((response) => {
      // const { total, available, parameters } = response.data
      dispatch(actions.disableDateList(response?.data))
    })
    .catch((error) => {
      // const errMsg = error.response && error.response.data && error.response.data.errors ? error.response.data.errors[0].msg : error.message ;
      //   NotificationManager.error(errMsg, 'Error');
      dispatch(actions.catchError({ error, callType: callTypes.list }))
    })
}
export const multipleDateSelection = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }))
  return requestFromServer
    .multipleDateSelection(queryParams)
    .then((response) => {
      dispatch(actions.multipleDateSelection(response?.data))
    })
    .catch((error) => {
      // const errMsg = error.response && error.response.data && error.response.data.errors ? error.response.data.errors[0].msg : error.message ;
      //   NotificationManager.error(errMsg, 'Error');
      dispatch(actions.catchError({ error, callType: callTypes.list }))
    })
}
export const clearMultipleDateSelection = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }))
  dispatch(actions.clearMultipleDateSelection())
}
export const clearDisableDateList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }))
  dispatch(actions.clearDisableDateList())
}
