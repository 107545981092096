import React, { useEffect, useState } from "react"
import { Formik, Field } from "formik"
import { Col, Form, FormCheck, Modal, Row, Button, resetForm } from "react-bootstrap"
import { Input } from "../../../_metronic/_partials/controls"
import { useDispatch, useSelector } from "react-redux"
import * as actions from "./_redux/Action"
import * as Yup from "yup"
import CommonErrorShow from "../../../_metronic/layout/components/extras/ActionButtonComponents/CommonErrorShow"

const CommonForm = ({ show, showAddClose, item, updateList, user_id }) => {
  const vehicleStore = useSelector((state) => state.vehicles)
  const dispatch = useDispatch()
  const [frmInitialValues, setFrmInitialValues] = useState({
    reg: "",
    make: "",
    model: "",
    color: "",
    ev: false,
    is_bike: false,
    default: false,
    id: "",
  })
  useEffect(() => {
    if (vehicleStore.newAdded && vehicleStore.lastError == null) {
      handleCloseModal()
    }
  }, [vehicleStore])

  useEffect(() => {
    if (item) {
      setFrmInitialValues(item)
    }
  }, [item])

  const handleCloseModal = () => {
    dispatch(actions.clearError())
    showAddClose()
    setFrmInitialValues({
      reg: "",
      make: "",
      model: "",
      color: "",
      ev: false,
      is_bike: false,
      default: false,
      id: "",
    })
  }
  const stringValidateRegex = /^([a-zA-Z0-9]+|[a-zA-Z0-9]+\s{1}[a-zA-Z0-9]{1,}|[a-zA-Z0-9]+\s{1}[a-zA-Z0-9]{3,}\s{1}[a-zA-Z0-9]{1,})/

  // Validation schem
  const LevelsEditSchema = Yup.object().shape({
    reg: Yup.string()
      .matches(stringValidateRegex, "Vehicle reg is not valid.")
      .required("Vehicle reg is required."),
    make: Yup.string()
      .matches(stringValidateRegex, "Vehicle make is not valid.")
      .required("Vehicle make is required."),
    model: Yup.string()
      .matches(stringValidateRegex, "Vehicle Model is not valid.")
      .required("Vehicle Model is required."),
    color: Yup.string()
      .matches(stringValidateRegex, "Vehicle color is not valid.")
      .required("Vehicle color is required."),
  })
  return (
    <Modal show={show} className="add-vehicle-modal" centered>
      <Modal.Header>
        {item && item.id ? (
          <Modal.Title>EDIT VEHICLE</Modal.Title>
        ) : (
          <Modal.Title>ADD NEW VEHICLE</Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body className="pt-0">
        <Formik
          initialValues={frmInitialValues}
          validationSchema={LevelsEditSchema}
          enableReinitialize={true}
          onSubmit={(values, { resetForm }) => {
            const vehicle = {
              reg: values.reg,
              make: values.make,
              color: values.color,
              ev: values.ev,
              is_bike: values.is_bike,
              default: values.default,
              model: values.model,
              user_id: user_id ? user_id : vehicleStore?.commonData?.userId,
            }

            if (item) {
              vehicle.id = item.id
              dispatch(actions.updateItem(vehicle)).then((response) => {
                if (response !== undefined) {
                  if (updateList) {
                    updateList("update")
                  }
                  setFrmInitialValues({
                    reg: "",
                    make: "",
                    model: "",
                    color: "",
                    ev: false,
                    is_bike: false,
                    default: false,
                    id: "",
                  })
                  handleCloseModal()
                }
              })
            } else {
              dispatch(actions.createItem(vehicle)).then((response) => {
                if (response !== undefined) {
                  if (updateList) {
                    updateList("update")
                  }
                  setFrmInitialValues({
                    reg: "",
                    make: "",
                    model: "",
                    color: "",
                    ev: false,
                    is_bike: false,
                    default: false,
                    id: "",
                  })
                  handleCloseModal()
                }
              })
            }
          }}
        >
          {({ values, handleChange, handleSubmit, setFieldValue }) => (
            <Form className="form form-label-right" onSubmit={handleSubmit}>
              <Field component={Input} name="reg" value={values.reg} placeholder="Vehicle Reg *" maxlength="10" />
              <Field
                component={Input}
                name="make"
                type="text"
                placeholder="Make *"
                value={values.make}
                maxlength="10"
              />

              <Field
                component={Input}
                name="model"
                type="text"
                placeholder="Model *"
                value={values.model}
                maxlength="10"
              />
              <Field
                component={Input}
                name="color"
                type="text"
                placeholder="Color *"
                value={values.color}
                maxlength="10"
              />
              <div className="d-flex justify-content-between pt-4">
                <div className="pt-2 pl-2">Set as default</div>
                <div className="pb-5">
                  <FormCheck
                    type="switch"
                    id="custom-switch-vehicle-one"
                    label=""
                    className="button-cursor"
                    checked={values.default}
                    onChange={() => setFieldValue("default", !values.default)}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <div className="pt-2 pl-2">Electric Vehicle</div>
                <div className="pb-5">
                  <FormCheck
                    type="switch"
                    className="button-cursor custom-switch-vehicle"
                    id="custom-switch-vehicle-two"
                    label=""
                    checked={values.ev}
                    onChange={() => setFieldValue("ev", !values.ev)}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="pt-2 pl-2">Motorbike?</div>
                <div className="pb-5">
                  <FormCheck
                    className="button-cursor"
                    type="switch"
                    id="custom-switch-vehicle-three"
                    label=""
                    checked={values.is_bike}
                    onChange={() => setFieldValue("is_bike", !values.is_bike)}
                  />
                </div>
              </div>
              <CommonErrorShow error={vehicleStore?.error} />

              <Modal.Footer className="border-0  pt-0 mt-0">
                <Button className="w-100 vehicle-button-border" variant="" type="submit">
                  Save
                </Button>
                <Button className="w-100 vehicle-button-border" onClick={handleCloseModal} variant="">
                  Cancel
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default CommonForm
