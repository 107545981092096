import axios from "axios";

export const API_URL = `${process.env.REACT_APP_API_URL}`;

export function getVisitorsList(params) {
  return axios.get(
    `${API_URL}/api/user/guest-listing?site_id=${params.site_id}&my_visitor=${params.my_visitor}&perPage=${params.perPage}&page=${params.page}`
  );
}

// CREATE =>  POST: add a new visitor user to the server
export function createVisitor(item) {
  // return item/visitorsList
  return axios.post(`${API_URL}/api/booking/create/visitor`, item);
}

// CREATE =>  POST: add a new visitor booking to the server
export function createBookingVisitor(item) {
  // return item/visitorsList
  return axios.post(`${API_URL}/api/booking/visitors/buy-booking`, item);
}

// CREATE =>  POST: add a new visitor booking to the server
export function createMultiDateBookingVisitor(item) {
  // return item/visitorsList
  return axios.post(`${API_URL}/api/booking/visitors/multiple-booking`, item);
}

// UPDATE => PUT: update the StaffUser on the server
export function updateVisitor(item) {
  return axios.put(`${API_URL}/api/update/guest`, item);
}

// UPDATE => PUT: update the StaffUser on the server
export function updateBookingVisitor(item) {
  console.log(item);
  return axios.patch(`${API_URL}/api/update/booking`, item);
}

// UPDATE => PUT: update the StaffUser on the server
export function favouriteAction(params) {
  return axios.post(`${API_URL}/api/user/favourite/visitor`, params);
}

// DELETE => delete the StaffUser from the server
export function deleteItem(id) {
  return axios.post(`${API_URL}/api/booking/visitor/delete`, { id: id });
}

// RESEND REQUEST TO VISTOR
export function ResendReqVisitor(id) {
  return axios.get(`${API_URL}/api/resend-email?booking_id=${id}`);
}
