import React from "react";
import { ActionButtonPopUp } from "./ActionButtonComponents/ActionButtonPopUp";

export function BookingActionBtn() {
  return (
    // <div id="" className="add-floating-btn">
    //   <span className="add-booking-btn">
    //     <i className="fa fa-plus" onClick={()=><ActionButtonPopUp />}></i>
    //   </span>
    // </div>
    <ActionButtonPopUp />
  );
}
