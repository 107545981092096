import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "./_redux/Crud";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

import "./Workstation.scss";

const WorkstationFloorPlan = ({
  start_date,
  ends_date,
  floor_id,
  url_image,
  setWorkStationId,
}) => {
  const [workstationCoordinates, setWorkStationCoordinates] = useState([]);
  const [xyzCirc, setXyzCirc] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showPlan, setShowPlan] = useState(false);
  const [selectedItemsPath, setSelectedItemsPath] = useState([]);

  useEffect(() => {
    if (start_date && ends_date) {
      setShowPlan(true);
      const params = {
        starts_at: start_date,
        floor_id: floor_id,
        ends_at: ends_date,
      };
      axios
        .post(`${API_URL}/api/spaces/is-available-workstation`, params)
        .then((response) => setWorkStationCoordinates(response.data));
    } else {
      setShowPlan(false);
    }
  }, [ends_date]);

  useEffect(() => {
    if (workstationCoordinates?.length > 0) {
      return workstationCoordinates.forEach((element) => {
        if (element?.poly_coordinates !== null) {
          const parsed = element.poly_coordinates;
          if (parsed[0].circles.length > 0 && parsed[0].circles !== null) {
            parsed[0].circles.forEach((onecircle) => {
              setXyzCirc((prev) => [
                ...prev,
                {
                  x: onecircle.x,
                  y: onecircle.y,
                  type: onecircle.type,
                  id: element.id,
                },
              ]);
            });
          }
        }
      });
    }
  }, [workstationCoordinates]);

  window.onclick = (e) => {
    e.stopPropagation();
    if (e.target.localName === "circle") {
      if (
        e.target.classList.contains("selected") &&
        !e.target.classList.contains("occupied")
      ) {
        if (selectedItems.length < 1) {
          e.target.classList.add("occupied");
          setWorkStationId(e.target.id);
          setSelectedItems([
            ...selectedItems,
            {
              x: e.target.attributes.cx.nodeValue,
              y: e.target.attributes.cy.nodeValue,
              id: e.target.id,
              type: e.target.localName,
            },
          ]);
        }
      } else if (
        e.target.classList.contains("selected") &&
        e.target.classList.contains("occupied")
      ) {
        e.target.classList.remove("occupied");
        setWorkStationId(undefined);
        setSelectedItems(() =>
          selectedItems.filter(
            (one) => one.x !== e.target.attributes.cx.nodeValue
          )
        );
      }
    }
    // path
    if (e.target.localName === "path") {
      if (
        e.target.classList.contains("selected") &&
        !e.target.classList.contains("occupied")
      ) {
        if (selectedItems.length < 1) {
          e.target.classList.add("occupied");
          setWorkStationId(e.target.id);
          setSelectedItemsPath([
            ...selectedItemsPath,
            {
              d: e.target.attributes.d.nodeValue,
              type: e.target.localName,
            },
          ]);
        }
      } else if (
        e.target.classList.contains("selected") &&
        e.target.classList.contains("occupied")
      ) {
        e.target.classList.remove("occupied");
        e.target.classList.add("selected");
        setWorkStationId(undefined);
        setSelectedItemsPath(() =>
          selectedItemsPath.filter(
            (one) => one.d !== e.target.attributes.d.nodeValue
          )
        );
      }
    }
  };

  useEffect(() => {
    if (xyzCirc?.length > 0) {
      setLoading(true);
    }
    if (xyzCirc.length > 0) {
      const assignedCircls = xyzCirc;
      const allCircles = document.querySelectorAll("svg circle");

      if (assignedCircls && assignedCircls.length > 0) {
        allCircles.forEach((element) => {
          assignedCircls.forEach((circle) => {
            if (
              element.attributes.cx.nodeValue === circle.x &&
              element.attributes.cy.nodeValue === circle.y &&
              element.localName === circle.type
            ) {
              element.classList.add("selected");
              element.setAttribute("id", circle.id);
              setLoading(false);
            }
          });
        });
      }
    }
  }, [xyzCirc]);

  return (
    <>
      {showPlan && (
        <div className="main_svg_wrapper_workstation" style={{ padding: 15 }}>
          <TransformWrapper>
            <TransformComponent>
              <SVG src={toAbsoluteUrl(url_image)} className="heleon_svg"></SVG>
            </TransformComponent>
          </TransformWrapper>
        </div>
      )}
    </>
  );
};

export default WorkstationFloorPlan;
