import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  meetingEntities: [],
  meetingEditItem: undefined,
  lastError: {},
  availability: {},
  visitorsList: [],
  status: "",
  meetingData: {},
};
export const callTypes = {
  list: "list",
  action: "action",
};
export const meetingSlice = createSlice({
  name: "meeting",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      // console.log(action);
      state.error = action.payload.error.response?.data.error;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // itemCreated
    itemCreated: (state, action) => {
      console.log(action.payload, "Payload");
      state.actionsLoading = false;
      state.lastError = null;
      state.meetingEntities = [];
      state.meetingEntities.push(action.payload.item);
    },
    // setEditItem
    setEditItem: (state, action) => {
      state.meetingEditItem = action.payload.item;
    },
    clearData: (state, action) => {
      state.meetingEntities = [];
      state.meetingEditItem = undefined;
    },
    meetingRoomList: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.availability = data;
    },
    editMeetingRoom: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.lastError = null;
      state.status = "update";
      state.meetingEntities = [];
      state.meetingEntities.push(action.payload.queryParams);
    },
    createMeetingData: (state, action) => {
      console.log(action.payload, "action.payload");
      state.meetingData = action.payload;
    },
  },
});
