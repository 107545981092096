import React from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (props, touched, errors) => {
  const classes = ["form-control"];
  if (props.className) {
    classes.push(props.className);
  }
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors && props.value !== "" && props.value !== undefined) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function Input({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  ...props
}) {
  // console.log(field, customFeedbackLabel, label, errors, props)
  return (
    <>
      {label && (
        <label className={props.isRequired ? "required" : ""}>
          {/* Enter  */}
          {label}
        </label>
      )}
      <input
        type={type}
        {...field}
        {...props}
        className={getFieldCSSClasses(
          props,
          touched[field.name],
          errors[field.name]
        )}
      />
      {withFeedbackLabel && errors[field.name] && touched[field.name] && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
