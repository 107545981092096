import React, { useEffect, useState } from "react";
import { Formik, Field } from "formik";
import { DatePickerField, Input } from "../../_metronic/_partials/controls";
import SearchSelectInput from "./SelectComponents/SearchSelectInput";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import moment from "moment";
import { useDispatch } from "react-redux";
import SearchMultiSelectInput from "./SelectComponents/SearchMultiSelectInput";

const FilterComponent = (props) => {
  const dispatch = useDispatch();
  const [type, setType] = useState("all");
  const [search, setSearch] = useState("");

  const applyFilter = (name, item) => {
    if (name === "site_id") {
      const newData = { site_id: item.id };
      dispatch(props?.parentActions?.setCalItemFilter(newData));
    }

    if (name === "floor_id") {
      const newArray = item.map((element) => element.id);
      const newData = { floor_id: newArray, floor_obj: item };
      dispatch(props?.parentActions?.setCalItemFilter(newData));
    }
  };

  const applyDateFilter = (data, dateType) => {
    if (dateType === "date") {
      const newData = { date: data };
      dispatch(props?.parentActions?.setCalItemFilter(newData));
    }
  };
  const searchFunction = (e) => {
    if (e.keyCode === 13) {
      const newData = { search: e.target.value };
      dispatch(props?.parentActions?.setCalItemFilter(newData));
    }
  };
  const applysearch = (e) => {
    if (e.button === 0) {
      const newData = { search: search };
      dispatch(props?.parentActions?.setCalItemFilter(newData));
    }

  };

  useEffect(() => {
    const newData = { type: type };
    dispatch(props?.parentActions?.setCalItemFilter(newData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  console.log(props?.counts?.events, "props?.counts?.other");

  return (
    <div id="filter-block" className="filter-block py-3">
      <div className="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
        <div className="align-items-center d-flex flex-wrap w-100">
          <Formik
            initialValues={{
              date: props?.parentFilters?.date,
              site_id: props?.parentFilters?.site_id,
              floor_id: props?.parentFilters?.floor_id,
            }}
            enableReinitialize={true}
            onSubmit={(values) => {
              // applyFilter(values)
            }}
          >
            {({ handleSubmit }) => (
              <form
                onSubmit={handleSubmit}
                className="form form-filter w-100"
                autoComplete="off"
              >
                <div className="row">
                  {props?.fromLoad === "Dashboard" && (
                    <div className="col-xl-3 col-md-6">
                      <Field
                        name="search"
                        component={Input}
                        placeholder="Search"
                        onKeyDown={(e) => searchFunction(e)}
                        onChange={(e) => setSearch(e.target.value)}
                        maxLength={20}
                      />
                      <a
                        onClick={(e) => applysearch(e)}
                      ><SVG
                          className="date-picker-calender"
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/Search.svg"
                          )}
                        /></a>

                    </div>
                  )}
                  {/* {props?.fromLoad !== "Dashboard" && ( */}
                  <div className="col-xl-3 col-md-6">
                    <DatePickerField
                      onDateSet={(date) => applyDateFilter(date, "date")}
                      name="date"
                      format={"dddd DD MMMM"}
                      defaultDate={moment(props.parentFilters?.date).format(
                        "DD-MM-YYYY"
                      )}
                    />
                    <SVG
                      className="date-picker-calender"
                      src={toAbsoluteUrl("/media/svg/icons/calendar.svg")}
                    />
                  </div>
                  {/* )} */}
                  <div className="col-md-6">
                    <div className="filter-icons">
                      <ul
                        className={`filter-icons-list list-inline ${type === "all" ? "all-active" : ""
                          }`}
                      >
                        <li
                          onClick={() =>
                            setType(type === "day_pass" ? "all" : "day_pass")
                          }
                          className={`filter-icons day-pass-bg ${type === "day_pass" ? "active" : ""
                            }`}
                          title="Day Pass Bookings"
                        >
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/dashboard/day-pass.svg"
                            )}
                          />
                          <span className="filter-count">
                            {props?.counts?.day_pass}
                            <span className="inside_name">
                              Day Pass Bookings
                            </span>
                          </span>
                        </li>
                        <li
                          onClick={() =>
                            setType(type === "parking" ? "all" : "parking")
                          }
                          className={`filter-icons parking-bg ${type === "parking" ? "active" : ""
                            }`}
                          title="Parking Bookings"
                        >
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/dashboard/parking.svg"
                            )}
                          />
                          <span className="filter-count">
                            {props?.counts?.parking}
                            <span className="inside_name">
                              Parking Bookings
                            </span>
                          </span>
                        </li>
                        <li
                          onClick={() =>
                            setType(type === "host" ? "all" : "host")
                          }
                          className={`filter-icons visitor-bg ${type === "host" ? "active" : ""
                            }`}
                          title="Visitor Bookings"
                        >
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/dashboard/visitor.svg"
                            )}
                          />
                          <span className="filter-count">
                            {props?.counts?.host}
                            <span className="inside_name">
                              Visitor Bookings
                            </span>
                          </span>
                        </li>
                        <li
                          onClick={() =>
                            setType(type === "other" ? "all" : "other")
                          }
                          className={`filter-icons mitting-bg ${type === "other" ? "active" : ""
                            }`}
                          title="Meeting Room Bookings"
                        >
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/dashboard/mitting.svg"
                            )}
                          />
                          <span className="filter-count">
                            {props?.counts?.other}
                            <span className="inside_name">
                              Meeting Room Bookings
                            </span>
                          </span>
                        </li>
                        {/* <li
                          onClick={() =>
                            setType(type === "events" ? "all" : "events")
                          }
                          className={`filter-icons event-bg ${
                            type === "events" ? "active" : ""
                          }`}
                          title="Events Bookings"
                        >
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/event-white.svg"
                            )}
                          />
                          <span className="filter-count">
                            {props?.counts?.events}
                            <span className="inside_name">Events Bookings</span>
                          </span>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
export default FilterComponent;
