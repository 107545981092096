import React, { useEffect, useState } from "react"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../_helpers"
import DayPassForm from "../../../../../app/modules/Booking/DayPass/DayPassForm"
import { default as AddVehicle } from "../../../../../app/modules/Vehicle/CommonForm"
import AddDaySuccess from "../../../../../app/components/common-success-popup/AddDaySuccess"
import { useDispatch, useSelector } from "react-redux"
import * as dayPassAction from "../../../../../app/modules/Booking/DayPass/_redux/Action"
import * as dashboardActions from "../../../../../app/pages/_redux/Actions"
const modalInitialValues = {
  name: "addDayPass",
  title: "Book Day Pass",
}
export const AddDayPass = (props) => {
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)
  const [showAddVehicle, setShowAddVehicle] = useState(false)
  const [addDaySuccess, setAddDaySuccess] = useState(false)
  const [modalProps, setModalProps] = useState(modalInitialValues)
  const [id, setId] = useState()

  const handleClose = () => {
    console.log('close day pass ')
    setShow(false)
    setId(undefined)
  }
  const handleShow = () => {
    setShow(true)
    props.handleParentClose()
  }

  const dayPassStore = useSelector((state) => state.dayPass)
  const { dayPassEditItem } = dayPassStore
  // const modalProps =

  useEffect(() => {
    if (dayPassEditItem) {
      let props = { ...modalProps }
      props.name = "editDayPass"
      props.title = "Edit Day Pass Booking"
      setModalProps(props)
      handleShow()
      setId(dayPassEditItem?.id)
    } else {
      setModalProps(modalInitialValues)
      setId(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dayPassStore])

  // setShowAdd;
  const handleAddVehicle = () => setShowAddVehicle(true)
  const handleAddVehicleClose = () => setShowAddVehicle(false)

  const handleAddDaySucess = () => setAddDaySuccess(true)
  const handleAddDaySucessClose = () => {
    dispatch(dayPassAction.clearData())
    setAddDaySuccess(false)
  }

  return (
    <>
      <div className="button-cursor" onClick={() => handleShow()}>
        <div className="d-inline-flex pl-5">
          <span className="svg-icon h-100">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Booking/daypass.svg")}></SVG>
          </span>
        </div>
        <div className="d-inline-flex pl-5 pt-3">
          <h6>BOOK DAY PASS</h6>
        </div>
      </div>

      <DayPassForm
        dashboardActions={dashboardActions}
        show={show}
        onHide={handleClose}
        modalProps={modalProps}
        handleAddVehicle={handleAddVehicle}
        handleAddDaySucess={handleAddDaySucess}
        showAdd={showAddVehicle}
        showAddClose={handleAddVehicleClose}
        handleAddDaySucessClose={handleAddDaySucessClose}
        id={id}
        handleShow={handleShow}
      />
      <AddVehicle show={showAddVehicle} showAddClose={handleAddVehicleClose} />
      <AddDaySuccess
        show={addDaySuccess}
        showAddClose={handleAddDaySucessClose}
        id={id}
        status={dayPassStore?.status}
      />
    </>
  )
}
