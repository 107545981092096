export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState()

      config.headers.Accept = "application/json, text/plain, */*"
      config.headers["Content-Type"] = "application/json;charset=UTF-8"
      // config.headers["Access-Control-Allow-Origin"] = "*"
      // config.headers["Access-Control-Allow-Methods"] = " POST, GET, OPTIONS, PUT, DELETE"

      // config.headers["Content-Type"] = "multipart/form-data"
      if (authToken) {
        config.headers["Authorization"] = `Bearer ${authToken}`
      }
      return config
    },
    (err) => Promise.reject(err)
  )

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.error !== "") {
        throw error
      } else if (error.response?.data?.errors[0]?.param === "Authorization") {
        localStorage.removeItem("token")
        localStorage.removeItem("username")
        localStorage.removeItem("userData")
        window.location.reload()
      }
      throw error
    }
  )
}
