import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  dayPassEntities: [],
  dayPassEditItem: undefined,
  lastError: {},
  workCircleList: [],
  error: null,
  status: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};
export const Slice = createSlice({
  name: "dayPass",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.lastError = true;
      state.error = action.payload.error.response.data.errors;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    clearError: (state, action) => {
      state.error = null;
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // itemCreated
    createdAddDayPassData: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.lastError = null;
      state.status = "add";
      state.dayPassEntities.push(action.payload.item);
    },
    // itemCreated
    createMultiDayPasses: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.lastError = null;
      state.status = "add_work_circle";
      state.dayPassEntities = action.payload.bookings;
    },
    // itemCreated for multiple dates
    createMultiDatePassesForSameUser: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.lastError = null;
      state.status = "add_multi_dates";
      state.dayPassEntities = action.payload.bookings;
    },
    // setEditItem
    setEditItem: (state, action) => {
      state.dayPassEditItem = action.payload.item;
    },
    // itemUpdated
    updateDayPassData: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.lastError = null;
      state.status = "update";
      state.dayPassEntities = [];
      state.dayPassEntities.push(action.payload.item);
    },
    clearData: (state, action) => {
      state.status = null;
      state.dayPassEntities = [];
      state.dayPassEditItem = undefined;
    },
    fetchWorkCircleList: (state, action) => {
      state.workCircleList = action.payload;
    },
  },
});
