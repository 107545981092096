import React, { useEffect, useState } from "react"
import { useField, useFormikContext } from "formik"
import { FieldFeedbackLabel } from "./FieldFeedbackLabel"
import { Select2 } from "select2-react-component"

const getFieldCSSClasses = (touched, errors) => {
  // const classes = ["form-control",  "form-control-solid"]
  const classes = ["rounded-pill"]
  if (touched && errors) {
    classes.push("is-invalid-select")
  }

  if (touched && !errors) {
    classes.push("is-valid-select")
  }

  return classes.join(" ")
}

export function CustomeSelect({
  label,

  withFeedbackLabel = true,
  type = "text",
  customFeedbackLabel,
  children,
  ...props
}) {
  const [field, meta] = useField(props)
  const [preSelectedValue, setPreSelectedValue] = useState(undefined)
  const { setFieldValue } = useFormikContext()
  const [options, setOptions] = useState([])
  const { touched, error } = meta

  useEffect(() => {
    let data = props.data
    if (props.valueKey && props.labelKey && props.data) {
      data = props.data.map((item) => {
        return { value: item[props.valueKey], label: item[props.labelKey] }
      })
    }
    setOptions(data)
    if (props?.selectedRoom) {
      setPreSelectedValue(parseInt(props?.selectedRoom))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, props.labelKey, props.valueKey])

  const constUpdateValue = (val) => {
    setFieldValue(field.name, val)
    if (props.onSelect) {
      const item = props?.data.find((item) => item.id === val)
      props.onSelect(item)
    }
  }
  useEffect(() => {
    if (options && props?.selectedRoom) {
      setPreSelectedValue(parseInt(props?.selectedRoom))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  return (
    <>
      <div className={getFieldCSSClasses(touched, error)}>
        {label && <label className={props.isRequired ? "required" : ""}>Select {label}</label>}

        <Select2
          className={getFieldCSSClasses(touched, error)}
          {...field}
          {...props}
          data={options}
          value={preSelectedValue}
          update={(value) => constUpdateValue(value)}
        >
          {/* {children} */}
        </Select2>
        {withFeedbackLabel && (
          <FieldFeedbackLabel
            erros={error}
            touched={touched}
            label={label}
            customFeedbackLabel={customFeedbackLabel}
          />
        )}
      </div>
    </>
  )
}
