import React, { useEffect, useState } from "react"
import { Formik, Field } from "formik"
import { Button, Form, Modal } from "react-bootstrap"
import { Typography } from "@material-ui/core"
import helperFunc from "../../../../_metronic/_helpers/helperFunc"
import { useDispatch } from "react-redux"
import * as actions from "../_redux/Actions"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"
import * as Yup from "yup"
import { Input } from "../../../../_metronic/_partials/controls"
import { NotificationManager } from "react-notifications"
import * as UIHelpers from "../../../components/UIHelpers"
import InfiniteScroll from "react-infinite-scroll-component"

export const initialFilter = {
  filter: {},
  q: "",
  sort: "asc", // asc||desc
  sortBy: "name",
  skip: UIHelpers.skip,
  limit: UIHelpers.limit,
  page: UIHelpers.page,
}
const AddWorkCircleModal = (props) => {
  const { show, onHide, updateList } = props
  const currentUser = helperFunc.getUserData()
  const dispatch = useDispatch()

  const [circleUsers, setCircleUser] = useState([])
  const [frmInitialValues, setFrmInitialValues] = useState(undefined)
  const [moreRecords, setMoreRecords] = useState(true)
  const [pageCount, setPageCount] = useState(0)
  const [usersArray, setUsersArray] = useState([])
  const [loadDefault, setDefault] = useState(false)

  useEffect(() => {
    setFrmInitialValues({
      title: "",
      user_id: [],
      host_id: null,
    })
    setPageCount(pageCount + 1)
  }, [])

  useEffect(() => {
    if (currentUser.site_id !== "") {
      const params = {
        userID: currentUser.id,
        site_id: currentUser.site_id,
        perPage: 10,
        page: pageCount,
      }
      dispatch(actions.getUsersList(params)).then((response) => {
        response.has_more === 0 ? setMoreRecords(false) : setMoreRecords(true)
        if (pageCount === 1) {
          setUsersArray(response.users)
        } else {
          setUsersArray([...usersArray, ...response.users])
        }
        setDefault(false)
      })
    }
  }, [pageCount, loadDefault])

  const handleAdduser = (user) => {
    if (!circleUsers.includes(user.id)) {
      setCircleUser([...circleUsers, user.id])
    } else {
      setCircleUser(circleUsers.filter((d) => d !== user.id))
    }
  }

  const handleSearch = (event) => {
    const val = event.target.value
    setTimeout(() => {
      if (val !== "") {
        setDefault(false)
        const params = {
          site_id: currentUser?.site_id,
          search: val,
        }
        dispatch(actions.searchUserList(params)).then((response) => {
          if (response.users.length > 0) {
            setMoreRecords(false)
            setUsersArray(response.users)
          } else {
            setUsersArray(response.users)
            setMoreRecords(false)
          }
        })
      } else {
        setUsersArray([])
        setDefault(true)
        setPageCount(1)
        // loadMoreUsers();
      }
    }, 600)
  }

  const LevelsEditSchema = Yup.object().shape({
    title: Yup.string()
      .matches(
        /^([a-zA-Z0-9]+|[a-zA-Z0-9]+\s{1}[a-zA-Z0-9]{1,}|[a-zA-Z0-9]+\s{1}[a-zA-Z0-9]{3,}\s{1}[a-zA-Z0-9]{1,})/,
        "Title is not valid."
      )
      .required("Title is required."),
  })

  const loadMoreUsers = () => {
    setPageCount(pageCount + 1)
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      className="modal-right modal-create-work-circle"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <img src="/media/work-circle/group-icon.svg" alt="" />
          &nbsp;&nbsp;&nbsp;&nbsp;<b>CREATE A NEW WORK CIRCLE</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={frmInitialValues}
          validationSchema={LevelsEditSchema}
          onSubmit={(values) => {
            if (circleUsers.length <= 0) {
              NotificationManager.error("Please choose at least one User.", "Error")
              return false
            }
            const workCircle = {
              title: values.title,
              user_id: circleUsers,
              host_id: currentUser.id,
            }
            dispatch(actions.createWorkCircle(workCircle))
              .then((result) => {
                if (result) {
                  setCircleUser([])
                  updateList("update")
                  onHide()
                  setFrmInitialValues({
                    title: "",
                    user_id: [],
                    host_id: null,
                  })
                }
              })
              .catch((error) => {
                console.log("err", error)
              })
          }}
        >
          {({ values, handleChange, handleSubmit, setFieldValue, isSubmitting }) => (
            <Form className="form form-label-right add-workcircle-modal" onSubmit={handleSubmit}>
              <div className="space-1"></div>
              <Typography component="div">
                <div className="form-group">
                  <Field component={Input} name="title" placeholder="Title" value={values.title} />
                </div>
                <div className="d-flex w-100">
                  <div className="bg-white border col-md-12 d-flex flex-center rounded-pill">
                    <input
                      type="text"
                      className="form-control border-0 font-weight-bold pl-2"
                      name="q"
                      placeholder="Search"
                      onChange={(e) => {
                        handleSearch(e)
                      }}
                    />

                  </div>
                </div>
                {/* map here */}
                <div id="scrollableDiv" className="add-work-circle-modal">
                  <InfiniteScroll
                    dataLength={usersArray.length} //This is important field to render the next data
                    next={() => loadMoreUsers()}
                    hasMore={moreRecords}
                    pageStart={0}
                    loader={
                      <img
                        style={{ margin: "13px 45%" }}
                        src={toAbsoluteUrl("/media/work-circle/small-loader.svg")}
                        alt="loader"
                        className="img"
                      />
                    }
                    height={900}
                    style={{ width: "95%" }}
                    endMessage={
                      <p style={{ textAlign: "center", padding: "5px" }}>
                        <b></b>
                      </p>
                    }
                    scrollableTarget="scrollableDiv"
                  >
                    <div style={{ textAlign: "center" }}>
                      {usersArray.length > 0 ? "" : "No Users Found"}
                    </div>
                    {usersArray ? (
                      <ul className="list-group">
                        {usersArray?.map((user, index) => {
                          return (
                            <li
                              key={index}
                              className="notification-item list-group-item mt-4 d-flex"
                            >
                              <div className="align-items-center d-flex title mr-auto work-circle-info">
                                <img
                                  width="70px"
                                  src={
                                    user?.url_image
                                      ? user?.url_image
                                      : toAbsoluteUrl("/media/vehicles/sample_user.svg")
                                  }
                                  alt="Profile_Pic"
                                  className="h-70px max-h-70px max-w-70px ob-fit-cover rounded-pill"
                                />
                                <div className="notification-details-main pl-5">
                                  <span className="notification-sender font-weight-bolder h5 text-dark">
                                    {user?.full_name}
                                  </span>
                                </div>
                              </div>

                              <div className="actions align-items-center d-flex justify-content-end">
                                <a
                                  href={() => false}
                                  className="d-flex"
                                  onClick={() => handleAdduser(user)}
                                >
                                  {circleUsers.includes(user?.id) ? (
                                    <img
                                      src="/media/work-circle/tickIcon.svg"
                                      width="50px"
                                      alt="Remove Vehicle"
                                    />
                                  ) : (
                                    <img
                                      src="/media/work-circle/plusIcon.svg"
                                      width="50px"
                                      alt="Remove Vehicle"
                                    />
                                  )}
                                </a>
                              </div>
                            </li>
                          )
                        })}
                      </ul>
                    ) : (
                      <></>
                    )}
                  </InfiniteScroll>
                </div>
              </Typography>

              <Modal.Footer className="pt-5 pl-0 pr-0">
                <Button
                  className="w-100 font-weight-bolder custom-button"
                  variant="primary"
                  // onClick={() => handleCreateWorkCircle()}
                  type="submit"
                  disabled={isSubmitting}
                >
                  SAVE
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default AddWorkCircleModal
