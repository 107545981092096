/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link, Switch, Redirect, Route } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import LiveFloorMobile from "../../LiveFloorMobile/Index";

export function AuthPage() {
  const [activeStep, setActiveStep] = useState(1);
  const bgImages = {
    1: "SignUp-Step-1.jpg",
    2: "SignUp-Step-2.png",
    3: "SignUp-Step-3.png",
  };
  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div className="login login-4 wizard d-flex flex-column flex-lg-row flex-column-fluid">
          {/* begin::Content */}
          {window.location.pathname === "/livefloorplanmobile" ? (
            <Switch>
              <ContentRoute
                path="/livefloorplanmobile"
                component={LiveFloorMobile}
              />
            </Switch>
          ) : (
            <>
              <div
                className="login-container order-2 order-lg-2 d-flex flex-center flex-row-fluid px-7 pt-lg-0 pb-lg-0 pt-4 pb-6"
                id="kt_login"
              >
                {/* begin::Wrapper */}
                <div className="login-content d-flex flex-column pt-lg-0">
                  {/* begin::Logo */}
                  <Link
                    to="/"
                    className="d-flex justify-content-center login-logo pb-10 w-100"
                  >
                    <img
                      src={toAbsoluteUrl("/media/logos/logo.png")}
                      className="mt-12"
                      alt=""
                    />
                  </Link>
                  {/* end::Logo */}
                  {/* begin::Signin */}
                  <Switch>
                    <ContentRoute path="/auth/login" component={Login} />
                    <ContentRoute path="/login" component={Login} />

                    <ContentRoute
                      path="/auth/registration"
                      // component={Registration}
                      render={(props) => (
                        <Registration
                          {...props}
                          setActiveStep={setActiveStep}
                          activeStep={activeStep}
                        />
                      )}
                    />
                    <ContentRoute
                      path="/auth/forgot-password"
                      component={ForgotPassword}
                    />

                    <Route
                      path="/auth/reset-password/:token"
                      component={ForgotPassword}
                    />
                    <Redirect from="/auth" exact={true} to="/auth/login" />
                    <Redirect to="/auth/login" />
                  </Switch>
                  {/* </div> */}
                  {/* end::Signin */}
                </div>
                {/* end::Wrapper */}
              </div>
              {/* begin::Content */}
              {/* begin::Aside */}
              <div
                className="login-aside order-1 order-lg-1 bgi-no-repeat p-10"
                style={{
                  backgroundImage: `url(${toAbsoluteUrl(
                    "/media/login/" + bgImages[activeStep]
                  )})`,
                }}
              >
                {activeStep === 1 && (
                  <div
                    className="login-conteiner bgi-no-repeat bgi-position-y-bottom "
                    style={{
                      backgroundImage: `url(${toAbsoluteUrl(
                        "/media/logos/login-bottom.png"
                      )})`,
                    }}
                  />
                )}
              </div>
            </>
          )}

          {/* end::Aside */}
        </div>
        {/* end::login */}
      </div>
    </>
  );
}
