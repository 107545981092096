import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "./AssetsHelpers";

const helperFunc = {
  getUserData: function () {
    const user = JSON.parse(localStorage.getItem("userData"));
    return user;
  },
  getUserId: function () {
    const user = JSON.parse(localStorage.getItem("userData"));
    return user.id;
  },
  authData: function () {
    const token = JSON.parse(localStorage.getItem("token"));
    return token;
  },
  getUserType: function () {
    const userData = JSON.parse(localStorage.getItem("userData"));
    return userData.type;
  },
  getUserRole: function () {
    const userData = JSON.parse(localStorage.getItem("userData"));
    return userData.role;
  },
  isLoginUser: function () {
    const token = JSON.parse(localStorage.getItem("token"));
    if (token && (token !== "" || token != null)) {
      return true;
    } else {
      return false;
    }
  },
  isCountryVisible: function () {
    const country = JSON.parse(localStorage.getItem("country"));
    if (country === "all") {
      return true;
    }
    return false;
  },
  getCountryValue: function () {
    const country = JSON.parse(localStorage.getItem("country"));
    if (country === "all") {
      return;
    } else {
      return country._id;
    }
    // return false ;
  },

  isMenuShow: function (url) {
    const userdata = JSON.parse(localStorage.getItem("userData"));

    /*  if (
      (userdata.role &&
        (userdata.role.toLowerCase() === "super_super_admin" ||
          userdata.role.toLowerCase() === "super_admin" ||
          userdata.role.toLowerCase() === "admin")) ||
      url === "index"
    ) {
      return true
    } */

    const permissions = Object.keys(userdata.permissions) || [];
    if (permissions && permissions.length > 0 && userdata?.permissions[url]) {
      if (permissions.filter((el) => el === `${url}`).length > 0) {
        return true;
      }
    }

    return false;
  },
  isPermisstionShow: function (modual) {
    const userdata = JSON.parse(localStorage.getItem("userData"));
    /* if (
      (userdata.role &&
        (userdata.role.toLowerCase() === "super_super_admin" ||
          userdata.role.toLowerCase() === "super_admin" ||
          userdata.role.toLowerCase() === "admin")) ||
      modual === "index"
    ) {
      return true
    } */

    const permissions = Object.keys(userdata.permissions) || [];
    if (
      permissions &&
      permissions.length > 0 &&
      userdata?.permissions[modual]
    ) {
      if (permissions.filter((el) => el === `${modual}`).length > 0) {
        return true;
      }
    }
    return false;
  },
  calculateDateAvailability: function (type, AvailableData) {
    type = type || "dayPass";
    let colors = {
      redLight: "bg-red",
      amberLight: "bg-amber",
      greenLight: "bg-green",
    };
    if (type === "dayPass") {
      type = "day_passes";
    }

    if (AvailableData) {
      const available = AvailableData?.available;
      const total = AvailableData?.total;
      if (available && total) {
        let used = Number(total[`${type}`]) - Number(available[`${type}`]);
        let occupied = parseInt((100 * used) / total[type]) || 0;

        if (occupied >= 90) {
          return {
            activeLight: 1,
            backgroundColor: colors.redLight,
            occupied: occupied + "% Occupancy",
          };
        } else if (occupied >= 80 && occupied < 90) {
          return {
            activeLight: 2,
            backgroundColor: colors.amberLight,
            occupied: occupied + "% Occupancy",
          };
        } else {
          return {
            activeLight: 3,
            backgroundColor: colors.greenLight,
            occupied: occupied + "% Occupancy",
          };
        }
      } else {
        return {
          activeLight: 1,
          backgroundColor: colors.redLight,
          occupied: "",
        };
      }
    }
  },
  setVehicleImage: function (vehicleType, returnType) {
    let imagePath;
    let srcPath = "/media/svg/icons/Booking/";
    switch (vehicleType) {
      case "electric_car":
        imagePath = srcPath + "electric-sign.svg";
        break;
      case "parking":
        imagePath = srcPath + "parking-sign.svg";
        break;
      case "bike":
        imagePath = srcPath + "motorcycle.svg";
        break;
      default:
        imagePath = "";
        break;
    }

    if (imagePath) {
      if (returnType && returnType.toLowerCase() === "svg") {
        return <SVG className="" src={toAbsoluteUrl(`${imagePath}`)} />;
      }
      return <img src={`${imagePath}`} alt={`${returnType}`} />;
    }
  },
  // isPermisstionShow: function(modual, type) {
  //   const userdata = JSON.parse(localStorage.getItem("userData"))
  //   if (
  //     (userdata.role && userdata.role.toLowerCase() === "super_super_admin") ||
  //     modual === "index"
  //   ) {
  //     return true
  //   }
  //   if (userdata.role.permissions && userdata.role.permissions.length > 0) {
  //     if (
  //       userdata.role.permissions.filter((el) => el === `${modual}.${type}`)
  //         .length > 0
  //     ) {
  //       return true
  //     }
  //   }

  //   return false
  // },
};

export default helperFunc;
