import React, { useState, useEffect } from "react"
import { useFormik } from "formik"
import { connect } from "react-redux"
import * as Yup from "yup"
import { Link } from "react-router-dom"
import { injectIntl } from "react-intl"
import * as auth from "../_redux/authRedux"
import { register } from "../_redux/authCrud"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"
import TNCComponent from "../../../components/TNCComponent"
import { Image } from "react-bootstrap"
import { Select2 } from "select2-react-component"
import axios from "axios"
import SVG from "react-inlinesvg"

const initialValues = {
  first_name: "",
  last_name: "",
  file: "",
  email: "",
  number: "",
  password: "",
  password_confirmation: "",
  acceptTerms: false,
  site_id: undefined,
  floor_id: undefined,
}

function Registration(props) {
  const { intl, activeStep, setActiveStep } = props
  const [file, setFile] = useState(undefined)
  const [selectedFile, setSelectedFile] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [isShowTNC, setIsShowTNC] = useState(false)
  const [siteList, setSiteList] = useState([])
  const [siteListOption, setSiteListOption] = useState([])
  const [floorList, setFloorList] = useState([])

  const RegistrationSchema = Yup.object().shape({
    first_name: Yup.string()
      .trim("The First Name cannot include leading and trailing spaces")
      .strict()
      .min(3, "First Name should atleast contain 3 characters.")
      .max(50, "First Name should not be more than 50 characters.")
      .required(
        intl.formatMessage(
          {
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
          },
          { name: "First Name" }
        )
      ),
    last_name: Yup.string()
      .trim("The Last Name cannot include leading and trailing spaces")
      .strict()
      .min(3, "Last Name should atleast contain 3 characters.")
      .max(50, "Last Name should not be more than 50 characters.")
      .required(
        intl.formatMessage(
          {
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
          },
          { name: "Last Name" }
        )
      ),
    email: Yup.string()
      .trim("The Email cannot include leading and trailing spaces")
      .strict()
      .email("Please enter a vaild email address.")
      .min(3, "Email should atleast contain 3 characters.")
      .max(50, "Email Name should not be more than 50 characters.")
      .required(
        intl.formatMessage(
          {
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
          },
          { name: "Email" }
        )
      ),
    number: Yup.string()
      .trim("The Phone Number cannot include leading and trailing spaces")
      .strict()
      .min(10, "Please enter minimum 10 characters."),
    password: Yup.string()
      .trim("The Password cannot include leading and trailing spaces")
      .strict()
      .min(8, "Password should atleast contain 8 characters.")
      .max(50, "Password should not be more than 50 characters.")
      .required(
        intl.formatMessage(
          {
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
          },
          { name: "Password" }
        )
      ),
    password_confirmation: Yup.string()
      .required(
        intl.formatMessage(
          {
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
          },
          { name: "Repeat password" }
        )
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and repeat password didn't match."
        ),
      }),
    // acceptTerms: Yup.bool().required(
    //   "You must accept the terms and conditions"
    // ),
  })

  const enableLoading = () => {
    setLoading(true)
  }

  const disableLoading = () => {
    setLoading(false)
  }

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid"
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid"
    }

    return ""
  }

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true)
      enableLoading()

      const params = {
        first_name: values?.first_name,
        last_name: values?.last_name,
        number: values?.number,
        email: values?.email,
        image: selectedFile.length > 0 ? selectedFile : undefined,
        password: values?.password,
        password_confirmation: values?.password_confirmation,
        acceptTerms: values?.acceptTerms,
        vehicles: [
          {
            reg: values?.reg,
            make: values?.make,
            model: values?.model,
            color: values?.color,
          },
        ],
        site_id: values?.site_id,
        floor_id: values?.floor_id,
      }

      register(params)
        .then(({ data }) => {
          props.register(data)
          disableLoading()
          setSubmitting(false)
        })
        .catch(() => {
          setSubmitting(false)
          setStatus(
            intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_LOGIN",
            })
          )
          disableLoading()
        })
    },
  })

  const getImageUrl = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files)
      let img = event.target.files[0]
      var reader = new FileReader()
      reader.readAsDataURL(img)

      reader.onloadend = function (e) {
        console.log("render", reader)
        setFile(reader.result)
      }
    }
  }

  const getSiteList = async () => {
    const API_URL = `${process.env.REACT_APP_API_URL}/api/common/sites`
    const response = await axios.get(API_URL)
    let data = []
    if (response.data && response.data.length > 0) {
      data = response.data.map((item) => {
        return {
          value: item.id,
          label: item.name || item.title || item.reg || item.full_name,
        }
      })
      setSiteList(response.data)
      setSiteListOption(data)
      // setFloorList(data);
    }
  }

  const UpdateValue = (value) => {
    formik.setFieldValue("site_id", value)
    const ListItem = siteList.filter((item) => item.id === value)
    if (ListItem && ListItem.length > 0 && ListItem[0].floors) {
      let data = []
      if (ListItem[0].floors?.length > 0) {
        data = ListItem[0].floors.map((item) => {
          return {
            value: item.id,
            label: item.name || item.title || item.reg || item.full_name,
          }
        })
      }
      setFloorList(data)
    }
  }

  useEffect(() => {
    getSiteList()
  }, [])

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <form
        id="kt_login_signin_form"
        className="signin_form form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}
        {activeStep === 1 && (
          <>
            <div className="text-center mb-5">
              <h3 className="signup-title">Add Your Personal Information</h3>
            </div>

            {/* begin: Profile Image */}
            <div className="d-flex fv-plugins-icon-container justify-content-center">
              <div
                className={`round-image-display ${file ? "align-items-baseline" : "align-items-center"
                  } `}
              >
                <label data-action="change" title="Change Image">
                  {!file && (
                    <Image
                      src={toAbsoluteUrl("/media/svg/icons/camera.svg")}
                      style={{ width: "50px" }}
                      alt="progile-image"
                    />
                  )}
                  {file && (
                    <Image
                      src={file}
                      style={{
                        width: "100%",
                        height: "18vh",
                        backgroundSize: "cover",
                      }}
                      alt="progile-image"
                    />
                  )}
                  {/* <i className="fa fa-pen icon-sm text-muted"></i> */}
                  <input
                    type="file"
                    name="file"
                    accept=".png, .jpg, .jpeg"
                    onChange={getImageUrl}
                  />
                </label>
              </div>
            </div>
            {/* end: Profile Image */}

            {/* begin: first_name */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="First Name"
                type="text"
                className={`form-control form-control-solid h-auto px-6 ${getInputClasses(
                  "first_name"
                )}`}
                name="first_name"
                {...formik.getFieldProps("first_name")}
              />
              {formik.touched.first_name && formik.errors.first_name ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.first_name}</div>
                </div>
              ) : null}
            </div>
            {/* end: first_name */}
            {/* begin: Last name  */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Last Name"
                type="text"
                className={`form-control form-control-solid h-auto px-6 ${getInputClasses(
                  "last_name"
                )}`}
                name="last_name"
                {...formik.getFieldProps("last_name")}
              />
              {formik.touched.last_name && formik.errors.last_name ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.last_name}</div>
                </div>
              ) : null}
            </div>
            {/* end: Last name  */}

            {/* begin: Email */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Email"
                type="email"
                className={`form-control form-control-solid h-auto px-6 ${getInputClasses(
                  "email"
                )}`}
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            {/* end: Email */}

            {/* begin: Username */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Phone Number (Optional)"
                type="text"
                className={`form-control form-control-solid h-auto px-6 ${getInputClasses(
                  "number"
                )}`}
                name="number"
                {...formik.getFieldProps("number")}
              />
              {formik.touched.number && formik.errors.number ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.number}</div>
                </div>
              ) : null}
            </div>
            {/* end: Username */}

            {/* begin: Password */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Password"
                type="password"
                className={`form-control form-control-solid h-auto px-6 ${getInputClasses(
                  "password"
                )}`}
                name="password"
                {...formik.getFieldProps("password")}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.password}</div>
                </div>
              ) : null}
            </div>
            {/* end: Password */}

            {/* begin: Confirm Password */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Repeat Password"
                type="password"
                className={`form-control form-control-solid h-auto px-6 ${getInputClasses(
                  "password_confirmation"
                )}`}
                name="password_confirmation"
                {...formik.getFieldProps("password_confirmation")}
              />
              {formik.touched.password_confirmation && formik.errors.password_confirmation ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.password_confirmation}</div>
                </div>
              ) : null}
            </div>
            {/* end: Confirm Password */}

            {/* end: Terms and Conditions */}
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                type="button"
                disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
                onClick={() => setActiveStep(2)}
                className={`btn btn-primary d-flex justify-content-between align-items-center font-weight-bolder my-3 w-100`}
              >
                <span />
                <span>Next</span>
                <span>
                  <SVG src={toAbsoluteUrl("/media/svg/icons/akar-icons_arrow-right.svg")} />
                </span>
              </button>
            </div>
            <div className="d-flex flex-wrap w-100 justify-content-center">
              <span className="d-flex text-gray">Already have an account?</span>
              <Link to="/auth/login" className="btn-link d-flex ml-1">
                Sign in.
              </Link>
            </div>
          </>
        )}
        {activeStep === 2 && (
          <>
            <div className="text-center mb-5">
              <h3 className="signup-title">Add Your Default Vehicle</h3>
            </div>
            {/* begin: vehicleReg */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Vehicle Reg."
                type="text"
                className={`form-control form-control-solid h-auto px-6 ${getInputClasses("reg")}`}
                name="reg"
                {...formik.getFieldProps("reg")}
              />
            </div>
            {/* end: vehicleReg */}
            {/* begin: vehicleMake */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Vehicle Make"
                type="text"
                className={`form-control form-control-solid h-auto px-6 ${getInputClasses("make")}`}
                name="make"
                {...formik.getFieldProps("make")}
              />
            </div>
            {/* end: vehicleMake */}
            {/* begin: vehicleModal */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Vehicle Model"
                type="text"
                className={`form-control form-control-solid h-auto px-6 ${getInputClasses(
                  "model"
                )}`}
                name="model"
                {...formik.getFieldProps("model")}
              />
            </div>
            {/* end: vehicleModal */}
            {/* begin: vehicleColor */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Vehicle Color"
                type="text"
                className={`form-control form-control-solid h-auto px-6 ${getInputClasses(
                  "color"
                )}`}
                name="color"
                {...formik.getFieldProps("color")}
              />
            </div>
            {/* end: vehicleColor */}
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                type="button"
                onClick={() => setActiveStep(3)}
                className={`btn btn-primary d-flex justify-content-between align-items-center font-weight-bolder my-3 w-100`}
              >
                <span />
                <span>Next</span>
                <span>
                  <SVG src={toAbsoluteUrl("/media/svg/icons/akar-icons_arrow-right.svg")} />
                </span>
              </button>
            </div>
            <div className="d-flex flex-wrap w-100 justify-content-center">
              <button onClick={() => setActiveStep(3)} className="btn-link btn d-flex ml-1">
                Skip for now
              </button>
            </div>
          </>
        )}
        {activeStep === 3 && (
          <>
            <div className="text-center mb-5">
              <h3 className="signup-title">Add Default Location</h3>
            </div>
            {/* begin: vehicleColor */}
            <div className="form-group fv-plugins-icon-container text-left">
              <div className={`form-control form-control-solid h-auto p-0 rounded-pill`}>
                <Select2
                  name="site_id"
                  placeholder="Site"
                  data={siteListOption}
                  {...formik.getFieldProps("site_id")}
                  update={(value) => UpdateValue(value)}
                />
              </div>
            </div>
            {/* end: vehicleColor */}
            {/* begin: floor */}
            <div className="form-group fv-plugins-icon-container text-left">
              <div className={`form-control form-control-solid h-auto p-0 rounded-pill`}>
                <Select2
                  name="floor_id"
                  placeholder="Floor"
                  data={floorList}
                  {...formik.getFieldProps("floor_id")}
                  update={(value) => formik.setFieldValue("floor_id", value)}
                />
              </div>
            </div>
            {/* end: floor */}
            {/* begin: Terms and Conditions */}
            <div className="form-group">
              <label className="checkbox checkbox-outline d-flex justify-content-end">
                <input
                  type="checkbox"
                  name="acceptTerms"
                  className="m-1"
                  {...formik.getFieldProps("acceptTerms")}
                />
                <span />
                <div className="d-flex ml-1 font-18px">
                  <div className="d-flex text-gray" style={{ textTransform: "none" }}>
                    I agree to
                  </div>

                  <Link
                    onClick={() => setIsShowTNC(true)}
                    className="ml-1 font-weight-bold"
                    rel="noopener noreferrer"
                  >
                    Terms & Conditions.
                  </Link>
                </div>
              </label>
              {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.acceptTerms}</div>
                </div>
              ) : null}
            </div>
            <button
              type="submit"
              className="btn btn-primary position-relative font-weight-bolder mr-3 my-3 px-8 w-100"
              disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
            >
              <span>Submit</span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          </>
        )}
        <TNCComponent show={isShowTNC} setHidePopup={setIsShowTNC} />
      </form>
    </div>
  )
}

export default injectIntl(connect(null, auth.actions)(Registration))
