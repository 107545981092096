import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormCheck, Modal, Row } from "react-bootstrap";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatePicker";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import SiteFloorSelectInput from "../../../components/SelectComponents/SiteFloorSelectInput";
import SearchSelectInput from "../../../components/SelectComponents/SearchSelectInput";
import helperFunc from "../../../../_metronic/_helpers/helperFunc";
import * as actions from "../../Vehicle/_redux/Action";
import * as selectAction from "../../../components/SelectComponents/_redux/Actions";
import * as dayPassAction from "./_redux/Action";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import AddWorkCircleModal from "../../Settings/WorkCircles/AddWorkCircleModal";
import AdjustFriendCircle from "../../FriendCircle/AdjustFriendCircle";
import TodayFriendList from "./Components/TodayFriendList";
import AdjustFriendCircleParking from "../../FriendCircle/AdjustFriendCircleParking";
import DayWiseParkingOptions from "./Components/DayWiseParkingOptions";
import SelectInfiniteScrollDropDown from "../../../components/SelectComponents/SelectInfiniteScrollDropDown";
import { TimePickerField } from "../../../../_metronic/_partials/controls";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { CircularProgress } from "@material-ui/core";
import WorkstationFloorPlan from "./WorkstationFloorPlan";

const initialValues = {
  owner: "",
  starts_at: "",
  select_owner: false,
  multiple_pass: false,
  friend_circle: false,
  all_day_parking: false,
  select_friend_circle: "",
  parking_space: false,
  select_vehicle: "",
  ev: false,
  parking_option_colleagues: false,
  electric_vehicle: false,
  reserve_workstation: false,
  startTime: undefined,
  endTime: undefined,
  is_fullday: false,
};

const initialAvailableState = {
  day_passes: 0,
  motorbike: 0,
  parking: 0,
  parking_ev: 0,
  spaces: 0,
  work_station: 0,
};

const DayPassForm = (props) => {
  const userDetails = helperFunc.getUserData();
  const { show, handleAddVehicle, modalProps, id, handleShow } = props;
  const [toggleOwnerMenu, setToggleOwnerMenu] = useState(false);
  const [toggleFriendCircle, setToggleFriendCircle] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [toggleAllDayParking, setToggleAllDayParking] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [selectedDate, setSelectedDate] = useState({});
  const [selectedSiteId, setSelectedSiteId] = useState();
  const [userId, setUserId] = useState(helperFunc.getUserId());
  const [newVehicleAdded, setNewVehicleAdded] = useState(false);
  const [occupancyShow, setOccupancyShow] = useState(true);
  const [frmInitialValues, setFrmInitialValues] = useState(initialValues);
  const [isFriendCircleChanged, setIsFriendCircleChanged] = useState(false);
  const [updateWorkCircleList, setUpdateWorkCircleList] = useState(false);
  const [selectedFriendCircleId, setSelectedFriendCircleId] = useState();
  const [isShowAddFriendCircle, setIsShowAddFriendCircle] = useState(false);
  const [isShowEditFriendCircle, setIsShowEditFriendCircle] = useState(false);
  const [selectedFCircleUsers, setSelectedFCircleUsers] = useState([]);
  const [selectedParkingUsers, setSelectedParkingUsers] = useState([]);
  const [availableData, setAvailableData] = useState(initialAvailableState);
  // eslint-disable-next-line no-unused-vars
  const [manageDatePickerDate, setManageDatePickerDate] = useState();
  const [isParkingColleagues, setIsParkingColleagues] = useState(false);
  const [multipleDates, setMultipleDates] = useState();
  const [selectedParkingDates, setSelectedParkingDates] = useState();
  const [workCirclesListPair, setWorkCirclesListPair] = useState({});
  const [isValidDates, setIsValidDates] = useState();
  const [isEmpty, setIsEmpty] = useState([]);
  const [disableBtnForSingleDate, setDisableBtnForSingleDate] = useState();
  const [isShowInfiniteScrollPopUp, setIsShowInfiniteScrollPopUp] = useState(
    false
  );
  const [usersData, setUserData] = useState(undefined);

  // Selector section
  const searchDataStore = useSelector((state) => state.searchData);
  const dayPassStore = useSelector((state) => state.dayPass);
  const datepickerStore = useSelector((state) => state.customDatePicker);
  const vehicleStore = useSelector((state) => state.vehicles);
  const dashboardStore = useSelector((state) => state.dashboards);
  // End: selector section
  const dispatch = useDispatch();

  useEffect(() => {
    if (userDetails?.site_id) {
      dispatch(selectAction.getSite({ siteId: userDetails.site_id }));
    }
    if (userDetails?.floor_id) {
      dispatch(selectAction.getFloor({ floorId: userDetails.floor_id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (selectedFriendCircleId) {
      dispatch(selectAction.getSelectedFriendCircle(selectedFriendCircleId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFriendCircleChanged]);

  useEffect(() => {
    const params = {
      user_id: userId,
    };
    dispatch(dayPassAction.getWorkCircleList(params));
    setUpdateWorkCircleList(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateWorkCircleList]);

  const { workCirclesList } = useSelector(
    (state) => ({ workCirclesList: state.dayPass.workCircleList }),
    shallowEqual
  );

  useEffect(() => {
    if (workCirclesList.length) {
      let workCirclesListPair = workCirclesList?.map((item) => {
        return {
          value: item.id,
          label: item.title,
        };
      });
      setWorkCirclesListPair(workCirclesListPair);
    }
  }, [workCirclesList]);
  useEffect(() => {
    if (searchDataStore?.selectedSite) {
      setSelectedSiteId(searchDataStore?.selectedSite?.id);
    }

    if (
      searchDataStore?.selectedFriendCircleDetails?.users &&
      isFriendCircleChanged
    ) {
      const users = searchDataStore?.selectedFriendCircleDetails?.users.map(
        (item) => {
          return item.id;
        }
      );
      setIsFriendCircleChanged(false);
      setSelectedFCircleUsers(users);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDataStore]);

  useEffect(() => {
    if (vehicleStore?.newAdded) {
      setNewVehicleAdded(true);
    }
  }, [vehicleStore]);

  const updateList = (data) => {
    if (data === "update") {
      setUpdateWorkCircleList(true);
      /*  setSelectedVisitor(undefined)
      setShowAddNewVisitor(false)
      setCount(count + 1) */
    }
  };
  useEffect(() => {
    if (isValidDates) {
      let isEmptyArray = isValidDates?.filter((color) => color == "bg-red");
      setIsEmpty(isEmptyArray);
    }
  }, [isValidDates]);

  useEffect(() => {
    let data = { ...initialValues };
    // if (
    //   dayPassStore?.lastError !== {} &&
    //   dayPassStore?.dayPassEntities.length > 0
    // ) {
    //   props.handleAddDaySucess && props.handleAddDaySucess();
    //   props.onHide && props.onHide();
    // }
    if (dayPassStore?.dayPassEditItem) {
      let responseData = {};
      let editItem = dayPassStore?.dayPassEditItem;
      for (const key in editItem) {
        if (Object.hasOwnProperty.call(editItem, key)) {
          if (key === "starts_at") {
            let resKey = key;
            resKey = "starts_at";
            responseData[`${resKey}`] = moment(editItem[key]);
            // responseData[`${resKey}`] = moment(editItem[key]).format('DD-MM-yyyy');
          }
          if (key === "site_id" && editItem[key]) {
            setSelectedSiteId(editItem[`${key}`]);
          }
          if (key === "ev" && editItem[key] === null) {
            responseData[`${key}`] = false;
            continue;
          }
          if (key === "booked_for") {
            responseData[`owner`] = editItem[key]?.id;
            continue;
          }

          responseData[`${key}`] = editItem[key];
        }
      }
      data = { ...data, ...responseData };
      setOccupancyShow(false);
      // For set the selected site Id with store
      dispatch(selectAction.getSite({ siteId: data?.site_id }));
    } else {
      setOccupancyShow(true);
    }

    setFrmInitialValues(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dayPassStore]);

  useEffect(() => {
    let bookingStatus = datepickerStore?.available;

    if (bookingStatus) {
      setManageDatePickerDate(datepickerStore.date);
      setAvailableData(bookingStatus);
    } else {
      setAvailableData(initialAvailableState);
    }
    if (datepickerStore?.multiDateSelectedData) {
      setMultipleDates(datepickerStore?.multiDateSelectedData);
    }
  }, [datepickerStore]);

  const handleToggleOwnerClick = (value) => {
    setToggleOwnerMenu(value);
    if (!value) {
      setUserId(helperFunc.getUserId());
    }
  };

  const handleGetVehicel = (value) => {
    console.log(value);
  };

  const handleToggleFriendCircleClick = (value) => {
    setToggleFriendCircle(value);
  };

  const handleToggleAllDayParkingClick = (value) => {
    setToggleAllDayParking(value);
  };
  useEffect(() => {
    dispatch(actions.setCommonData({ userId: userId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const handleClose = () => {
    if (props?.onHide) {
      props.onHide();
    }
    setToggleFriendCircle(false);
    dispatch(selectAction.resetSiteAndFloor());
    dispatch(selectAction.resetFriendCircleData());
    setToggleOwnerMenu(false);
    dispatch(dayPassAction.setEditItem(undefined));
  };
  useEffect(() => {
    if (dayPassStore?.dayPassEntities?.length !== 0) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dayPassStore]);

  useEffect(() => {
    if (id && dayPassStore.dayPassEditItem?.booked_for?.first_name) {
      setToggleOwnerMenu(true);
    }
    // if (id && dayPassStore.dayPassEditItem?.booked_for?.first_name){

    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const handleAddFriendCircleShow = () => {
    setIsShowAddFriendCircle(true);
    // onHide()
    // setToggleFriendCircle(false)
  };
  const handleAddFriendCircleClose = () => {
    setIsShowAddFriendCircle(false);
  };

  const handleEditFriendCircleShow = () => {
    // onHide()
    setIsShowEditFriendCircle(true);
  };
  const handleEditFriendCircleClose = () => {
    setIsShowEditFriendCircle(false);
  };

  const handleSelectedFCircleClear = () => {
    dispatch(selectAction.resetFriendCircleData());
    setSelectedFriendCircleId(undefined);
    // handleToggleFriendCircleClick(!toggleFriendCircle);
  };
  const handleToggleParkingForColleaguesShow = (isShow) => {
    // onHide()
    if (isShow) setIsParkingColleagues(true);
  };
  const handleToggleParkingForColleaguesClose = () => {
    setIsParkingColleagues(false);
  };

  const handleSelectedParkingDates = (dates) => {
    setSelectedParkingDates(dates);
  };
  const handleInfiniteScrollPopUp = () => {
    setIsShowInfiniteScrollPopUp(false);
  };
  useEffect(() => {
    if (datepickerStore?.multiDateSelectedData) {
      dispatch(selectAction.resetFriendCircleData());
      setToggleFriendCircle(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datepickerStore?.multiDateSelectedData]);

  // console.log(availableData, "availableData");

  // console.log(selectedDate, "selectedDate");

  // Selected Data Store Implementation

  const { selectedSite, selectedFloor } = searchDataStore;

  // console.log(selectedFloor, "selectedSite, selectedFloor");

  const [workstationId, setWorkStationId] = useState(undefined);

  // useEffect(() => {
  //   console.log(workstationId, "workstationId");
  // }, [workstationId]);

  // Default Access

  const { defaultAccessData } = useSelector((state) => state.booking);

  return (
    <>
      <AddWorkCircleModal
        show={isShowAddFriendCircle}
        updateList={updateList}
        onHide={handleAddFriendCircleClose}
      />
      <AdjustFriendCircle
        isShowEditFriendCircle={isShowEditFriendCircle}
        handleEditFriendCircleClose={handleEditFriendCircleClose}
        selectedFriendCircleId={selectedFriendCircleId}
        selectedFCircleUsers={selectedFCircleUsers}
        handleShow={handleShow}
        manageSelectedUsers={(values) => {
          setSelectedFCircleUsers(values);
        }}
      />
      <AdjustFriendCircleParking
        isParkingColleagues={isParkingColleagues}
        selectedParkingUsers={selectedFCircleUsers}
        handleToggleParkingForColleaguesClose={
          handleToggleParkingForColleaguesClose
        }
        handleShow={handleShow}
        availableData={availableData}
        manageSelectedUsers={(values) => {
          setSelectedParkingUsers(values);
        }}
      />
      <SelectInfiniteScrollDropDown
        isShowInfiniteScrollPopUp={isShowInfiniteScrollPopUp}
        fieldName="select_infinite_scroll_drop_down"
        handleInfiniteScrollPopUp={handleInfiniteScrollPopUp}
        name="SELECT OWNER"
        selectType="single"
        setUserData={setUserData}
        setUserId={setUserId}
      />

      <Modal
        show={show}
        onHide={handleClose}
        className="modal-right"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <b>{modalProps?.title.toUpperCase()}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={frmInitialValues}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              const daypassUserID =
                values?.select_owner && usersData?.id
                  ? usersData?.id
                  : helperFunc.getUserId();

              let add_day_pass;

              if (values?.reserve_workstation === true) {
                add_day_pass = {
                  floor_id: searchDataStore.floorId,
                  // "parking_id":,
                  starts_at: values.starts_at.format("YYYY-MM-DDTHH:mm:ss"),
                  user_id: daypassUserID,
                  workstation: {
                    is_fullday: values?.is_fullday,
                    starts_at: values?.is_fullday
                      ? "09:00 am"
                      : moment(values?.startTime).format("hh:mm a"),
                    ends_at: values?.is_fullday
                      ? "05:00 pm"
                      : moment(values?.endTime).format("hh:mm a"),
                    id: workstationId,
                  },
                };
              } else {
                add_day_pass = {
                  floor_id: searchDataStore.floorId,
                  // "parking_id":,
                  starts_at: values.starts_at.format("YYYY-MM-DDTHH:mm:ss"),
                  user_id: daypassUserID,
                };
              }
              if (values.select_vehicle) {
                add_day_pass["vehicle"] = {
                  ev: values.ev,
                  id: values.select_vehicle,
                };
              }
              if (id) {
                add_day_pass["type"] = "space";
                add_day_pass["parking_id"] = frmInitialValues?.parking_id;
                add_day_pass["booking_id"] = frmInitialValues?.id;
                dispatch(dayPassAction.updateItem(add_day_pass)).then((res) => {
                  if (res.data && props?.dashboardActions) {
                    dispatch(
                      props.dashboardActions.fetchBookingList(
                        dashboardStore?.dashboardFilters
                      )
                    );
                  }
                }); // Update booking
              } else {
                if (values?.friend_circle) {
                  let inputs = {
                    starts_at: moment(values.starts_at).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    ),
                    user_id: daypassUserID,
                  };
                  let day_pass = selectedFCircleUsers.map((user_id) => {
                    return {
                      user_id: user_id,
                      car_park: selectedParkingUsers.includes(user_id)
                        ? true
                        : false,
                    };
                  });
                  inputs["day_pass"] = day_pass;
                  dispatch(dayPassAction.createMultiDayPasses(inputs)).then(
                    (res) => {
                      if (
                        res?.data &&
                        !res?.response?.data.errors &&
                        props?.dashboardActions
                      ) {
                        dispatch(
                          props.dashboardActions.fetchBookingList(
                            dashboardStore?.dashboardFilters
                          )
                        );
                      }
                    }
                  ); // Create booking
                } else if (values?.friend_circle === false) {
                  console.log(multipleDates, "realissue")
                  if (multipleDates && Object.keys(multipleDates).length > 0) {
                    let inputs = {
                      users: [
                        {
                          car_park: values?.friend_circle,
                          user_id: daypassUserID,
                        },
                      ],
                    };
                    let day_pass = [];
                    for (const date in multipleDates) {
                      if (Object.hasOwnProperty.call(multipleDates, date)) {
                        // const date = multipleDates[key]

                        let input_data = {};
                        input_data["car_park"] = selectedParkingDates.includes(
                          date
                        );
                        input_data["starts_at"] = moment
                          .utc(date)
                          .format("YYYY-MM-DD");
                        input_data["vehicle_id"] = values.vehicle_id;
                        input_data["ev"] = values.electric_vehicle;
                        day_pass.push(input_data);
                      }
                    }
                    inputs["day_pass"] = day_pass;

                    dispatch(
                      dayPassAction.createMultiDatePassesForSameUser(inputs)
                    ).then((res) => {
                      if (
                        res?.data &&
                        !res?.response?.data?.errors &&
                        props?.dashboardActions
                      ) {
                        dispatch(
                          props.dashboardActions.fetchBookingList(
                            dashboardStore?.dashboardFilters
                          )
                        );
                      }
                    }); // Create multiple day booking
                  } else {
                    dispatch(dayPassAction.createItem(add_day_pass)).then(
                      (res) => {
                        if (
                          res?.data &&
                          !res?.response?.data?.errors &&
                          props?.dashboardActions
                        ) {
                          dispatch(
                            props.dashboardActions.fetchBookingList(
                              dashboardStore?.dashboardFilters
                            )
                          );
                        }
                      }
                    ); // Create single day booking
                  }
                }
              }
              setToggleOwnerMenu(false);
            }}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <Form className="form form-label-right" onSubmit={handleSubmit}>
                {!id && (
                  <>
                    <SiteFloorSelectInput setSelectedDate={setSelectedDate} />
                    <div className="space-1"></div>
                  </>
                )}
                <div className="select-time">
                  {id ? (
                    <div className="title-text font-weight-bolder">
                      CHANGE DATE
                    </div>
                  ) : (
                    <div className="title-text font-weight-bolder">
                      SELECT DATE
                    </div>
                  )}
                  <div className="space-1"></div>
                  <div className="p-3">
                    <CustomDatePicker
                      className="bookify-date-picker"
                      name="starts_at"
                      type="dayPass"
                      date={values.startTime}
                      occupancyShow={occupancyShow}
                      multiple_date_picker="multiple_date_picker"
                      floor_id={userDetails?.floor_id}
                      setIsValidDates={setIsValidDates}
                      editId={id}
                      setDisableBtnForSingleDate={setDisableBtnForSingleDate}
                    />
                    {!id && <TodayFriendList start_date={values.starts_at} />}

                    {!id && (
                      <>
                        <div className="mt-5">
                          <div className="d-flex justify-content-between">
                            <div className="pt-5 pr-5 pb-1 pl-2">
                              The booking is for someone else
                            </div>
                            <div
                              className="pt-5 pb-1 "
                              onClick={() => {
                                if (searchDataStore?.siteId == null) {
                                  NotificationManager.error(
                                    "Please select Site.",
                                    "Error"
                                  );
                                }
                              }}
                            >
                              <FormCheck
                                type="switch"
                                name="select_owner"
                                id="select_owner"
                                label=""
                                className="button-cursor"
                                checked={
                                  id &&
                                    dayPassStore.dayPassEditItem?.booked_for
                                      ?.first_name
                                    ? !values.select_owner
                                    : values.select_owner
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "select_owner",
                                    !values.select_owner
                                  )
                                }
                                onClick={() => {
                                  handleToggleOwnerClick(!toggleOwnerMenu);
                                }}
                                disabled={searchDataStore?.siteId == null}
                              />
                            </div>
                          </div>

                          {toggleOwnerMenu ? (
                            <div className="space-1">
                              <div className="btn-section d-flex">
                                <button
                                  className="border border-1 border-dark btn rounded-pill w-100"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setIsShowInfiniteScrollPopUp(
                                      !isShowInfiniteScrollPopUp
                                    );
                                  }}
                                >
                                  {usersData !== undefined ? (
                                    <>
                                      <img
                                        width="15px"
                                        className="mb-1"
                                        src={toAbsoluteUrl(
                                          "/media/svg/icons/Booking/edit-sign.svg"
                                        )}
                                        alt="Plus"
                                      />
                                      &nbsp;&nbsp;&nbsp;{usersData?.full_name}
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        width="15px"
                                        className="mb-1"
                                        src={toAbsoluteUrl(
                                          "/media/vehicles/plus.svg"
                                        )}
                                        alt="Plus"
                                      />
                                      &nbsp;&nbsp;&nbsp;SELECT OWNER
                                    </>
                                  )}
                                </button>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </>
                    )}
                  </div>

                  {/* Selected Work Circle Toggle Switch Section */}
                  {datepickerStore?.multiDateSelectedData === undefined && !id && (
                    <>
                      <div className="mt-3 select-time rounded">
                        <div className="d-flex justify-content-between">
                          <div className="p-5">Add Work Circle</div>
                          <div className=" p-5 pb-4 p-07">
                            <FormCheck
                              type="switch"
                              id="friend_circle"
                              name="friend_circle"
                              label=""
                              className="button-cursor"
                              checked={values.friend_circle}
                              onChange={() =>
                                setFieldValue(
                                  "friend_circle",
                                  !values.friend_circle
                                )
                              }
                              onClick={() => {
                                if (values.friend_circle) {
                                  setFieldValue("select_friend_circle", "");
                                  setFieldValue(
                                    "parking_option_colleagues",
                                    false
                                  );
                                  handleSelectedFCircleClear();
                                }
                                handleToggleFriendCircleClick(
                                  !toggleFriendCircle
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      {defaultAccessData?.is_workstation && (
                        <div className="mt-3 select-time rounded">
                          <div className="d-flex justify-content-between">
                            <div className="p-5">Reserve Workstation</div>
                            <div className=" p-5 pb-4 p-07">
                              <FormCheck
                                type="switch"
                                id="reserve_workstation"
                                name="reserve_workstation"
                                label=""
                                className="button-cursor"
                                checked={values.reserver_workstation}
                                onChange={() =>
                                  setFieldValue(
                                    "reserve_workstation",
                                    !values.reserve_workstation
                                  )
                                }
                              // onClick={() => {
                              //   if (values.friend_circle) {
                              //     setFieldValue("select_friend_circle", "");
                              //     setFieldValue(
                              //       "parking_option_colleagues",
                              //       false
                              //     );
                              //     handleSelectedFCircleClear();
                              //   }
                              //   handleToggleFriendCircleClick(
                              //     !toggleFriendCircle
                              //   );
                              // }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="d-flex justify-content-end align-items-center mt-5 mr-4 pb-3">
                        <span className="available-status-label">
                          Available:
                        </span>{" "}
                        <span className="ml-2 mb-1">
                          <img
                            src="/media/svg/icons/Booking/daypass-sign.svg"
                            alt="daypass-sign"
                          />
                        </span>
                        <span className="available-counter-text">
                          {" "}
                          {availableData?.day_passes}
                        </span>{" "}
                      </div>
                    </>
                  )}
                </div>

                {/* Workstation Task Her */}

                {values?.reserve_workstation && (
                  <div>
                    {/* <SiteFloorSelectInput
                      work_station={availableData.work_station}
                    /> */}

                    <div
                      style={{
                        border: "1px solid lightgray",
                        borderRadius: "5px",
                        padding: "10px",
                        fontWeight: "500",
                        margin: "10px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span className="ml-1 font-weight-bold selected-site-label">
                        {selectedSite?.title},&nbsp; {selectedFloor?.title}
                      </span>
                      <div>
                        <img
                          src="/media/svg/icons/workstation.svg"
                          alt="display"
                          className="mr-2"
                        />
                        {availableData?.work_station} available
                      </div>
                    </div>

                    <div className="d-flex justify-content-between">
                      <div className="p-5">Full Day</div>
                      <div className=" p-5 pb-4 p-07">
                        <FormCheck
                          type="switch"
                          id="is_fullday"
                          name="is_fullday"
                          label=""
                          className="button-cursor"
                          checked={values.is_fullday}
                          onChange={() =>
                            setFieldValue("is_fullday", !values.is_fullday)
                          }
                        />
                      </div>
                    </div>

                    {!values.is_fullday && (
                      <div className=" time-picker-main">
                        <Row>
                          <Col md={6}>
                            <div
                              className={
                                "time-picker-inner" +
                                (values.startTime ? " time-picker-blue" : "")
                              }
                            >
                              <SVG
                                className=""
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/clock.svg"
                                )}
                              />

                              <TimePickerField
                                // onDateSet={(data) => {
                                //   changeData();
                                //   applyTimeFilter(data, "startTime", setFieldValue, values)
                                // }}
                                // minDate={moment().format("hh:mm a")}
                                // maxDate={values.endTime}
                                name="startTime"
                                placeholder={"Start Time"}
                                date={null}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div
                              className={
                                "time-picker-inner" +
                                (values.endTime ? " time-picker-blue" : "")
                              }
                            >
                              <SVG
                                className=""
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/clock.svg"
                                )}
                              />
                              {values?.startTime ? (
                                <TimePickerField
                                  // onDateSet={(data) =>
                                  //   console.log(values.startTime, "event data")
                                  // }
                                  //minDate={values?.startTime}
                                  // minDate={moment().format("hh:mm a")}
                                  // onChange={(val) => console.log(val, "Event")}
                                  name="endTime"
                                  placeholder={"End Time"}
                                  // date={values.endTime}
                                  date={null}
                                />
                              ) : (
                                <TimePickerField
                                  // onDateSet={(data) =>
                                  //   applyTimeFilter(data, "endTime", setFieldValue, values)
                                  // }
                                  name="endTime"
                                  placeholder={"End Time"}
                                // date={null}
                                />
                              )}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    )}

                    <WorkstationFloorPlan
                      start_date={values?.startTime}
                      ends_date={values?.endTime}
                      floor_id={selectedFloor?.id}
                      url_image={selectedFloor?.url_image}
                      setWorkStationId={setWorkStationId}
                    />
                    {/* <div
                      className="main_svg_wrapper_live_floor"
                      style={{ padding: 15 }}
                    >
                      <TransformWrapper>
                        <TransformComponent>
                          {console.log(
                            selectedFloor?.url_image,
                            "selectedFloor?.url_image"
                          )}
                          <SVG
                            src={toAbsoluteUrl(selectedFloor?.url_image)}
                          ></SVG>
                        </TransformComponent>
                      </TransformWrapper>
                    </div> */}
                  </div>
                )}

                {/* Selected Work Circle edit close section */}
                {datepickerStore?.multiDateSelectedData === undefined && (
                  <>
                    {searchDataStore?.selectedFriendCircleDetails ? (
                      <>
                        <div className="mt-3 p-3 pt-0 select-time rounded">
                          <div className="d-flex mt-5 selected-friend-circle-div justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                              <div className="selected-group-circle-image-wrap">
                                <div>
                                  <span className="svg-icon">
                                    <SVG
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/friend_circle_group.svg"
                                      )}
                                      className="selected-group-circle-image"
                                    ></SVG>
                                  </span>
                                </div>
                              </div>
                              <div className="colleagues-count-div">
                                <p className="friend-circle-colleagues-title">
                                  Colleagues
                                </p>
                                <div className="d-flex">
                                  <span className="svg-icon">
                                    <SVG
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/color-daypass.svg"
                                      )}
                                      className="mr-3"
                                    ></SVG>
                                  </span>
                                  <p>{selectedFCircleUsers.length} People</p>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex">
                              <div
                                className="friend-circle-action-icon-wrap"
                                onClick={handleEditFriendCircleShow}
                              >
                                <span className="svg-icon">
                                  <SVG
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/Booking/edit-sign.svg"
                                    )}
                                  ></SVG>
                                </span>
                              </div>
                              <div
                                className="friend-circle-action-icon-wrap"
                                onClick={() => {
                                  setFieldValue("select_friend_circle", "");
                                  handleSelectedFCircleClear();
                                }}
                              >
                                <span className="svg-icon">
                                  <SVG
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/red-cross.svg"
                                    )}
                                  ></SVG>
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="d-flex justify-content-between">
                            <div className="p-5">
                              Add parking options for Colleagues
                            </div>
                            <div className=" p-5 pb-4 p-07">
                              <FormCheck
                                type="switch"
                                id="parking_option_colleagues"
                                name="parking_option_colleagues"
                                label=""
                                className="button-cursor"
                                checked={values.parking_option_colleagues}
                                onChange={() =>
                                  setFieldValue(
                                    "parking_option_colleagues",
                                    !values.parking_option_colleagues
                                  )
                                }
                                onClick={() => {
                                  handleToggleParkingForColleaguesShow(
                                    !values.parking_option_colleagues
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      /* Work Circle Selection and  Create New Work Circle Section */

                      <>
                        <div className="space-1"></div>
                        {toggleFriendCircle && (
                          <div className="select-time bg-white border">
                            <div className="select-friend-circle">
                              <Row className="font-weight-bolder">
                                <Col
                                  md={12}
                                  className="d-flex justify-content-between"
                                >
                                  <div className="friend-circle-select-title-text">
                                    WORK CIRCLE
                                  </div>
                                  <div
                                    className="button-cursor add-vehicle"
                                    onClick={handleAddFriendCircleShow}
                                  >
                                    + CREATE NEW
                                  </div>
                                </Col>
                              </Row>
                            </div>

                            <div className="space-1"></div>
                            <div className="p-3">
                              <div className="selcet-vehicle">
                                Select work circle from the list:
                              </div>
                              <Field
                                name="select_friend_circle"
                                type="text"
                                component={SearchSelectInput}
                                id="select_friend_circle"
                                placeholder="Select work circle"
                                source={`friend-circle/lsiting`}
                                customOptions={workCirclesListPair}
                                user_id={userId}
                                fieldName="select_friend_circle"
                                value={values.select_friend_circle}
                                addedNewData={newVehicleAdded}
                                onSelect={(item) => {
                                  if (item?.value) {
                                    setIsFriendCircleChanged(true);
                                    setSelectedFriendCircleId(item?.value);
                                  }
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}

                {/* Select Vehicle Section with Friend Circle Condition */}
                {!toggleFriendCircle && (
                  <>
                    {multipleDates && Object.keys(multipleDates).length > 0 && (
                      <DayWiseParkingOptions
                        value={values.all_day_parking}
                        // handleToggleAllDayParkingClick={handleToggleAllDayParkingClick}
                        handleSelectedParkingDates={handleSelectedParkingDates}
                      />
                    )}
                    <div className="space-1"></div>
                    <div className="select-time bg-white border">
                      <div className="select-vehicle">
                        <Row className="font-weight-bolder">
                          <Col
                            md={12}
                            className="d-flex justify-content-between"
                          >
                            <div className=" select-vehicle title-text">
                              SELECT VEHICLE
                            </div>
                            <div
                              className="button-cursor select-vehicle add-vehicle"
                              onClick={handleAddVehicle}
                            >
                              + ADD NEW
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="space-1"></div>
                      <div className="p-3">
                        <div className="selcet-vehicle">
                          Select vehicle from the list:
                        </div>
                        <Field
                          name="vehicle_id"
                          type="text"
                          component={SearchSelectInput}
                          id="vehicle_id"
                          placeholder="Select Vehicle"
                          source={`vehicle/user/list?user_id=${userId}`}
                          onChange={handleChange}
                          value={values.vehicle_id}
                          storeEntityName="vehicleData"
                        />
                      </div>
                      {
                        <>
                          <div className="p-3">
                            <div className="d-flex justify-content-between">
                              <div className="pr-5 pb-3 pl-2">
                                Electric Charging Point?
                              </div>
                              <div className="pb-3">
                                <FormCheck
                                  type="switch"
                                  name="electric_vehicle"
                                  id="electric_vehicle"
                                  label=""
                                  className="button-cursor"
                                  checked={values.electric_vehicle}
                                  onChange={() => {
                                    setFieldValue(
                                      "electric_vehicle",
                                      !values.electric_vehicle
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      }
                      <div className="d-flex justify-content-end align-items-center mt-5 mr-4 pb-3">
                        <span className="available-status-label">
                          Available:
                        </span>{" "}
                        <span className="ml-2 mb-1">
                          <img
                            src="/media/svg/icons/Booking/electric-sign.svg"
                            alt="electric"
                          />
                        </span>
                        <span className="available-counter-text">
                          {" "}
                          {availableData?.parking_ev}
                        </span>{" "}
                        <span className="ml-2 mb-1">
                          <img
                            src="/media/svg/icons/Booking/parking-sign.svg"
                            alt="parking"
                          />
                        </span>
                        <span className="available-counter-text">
                          {" "}
                          {availableData?.parking}
                        </span>{" "}
                        <span className="ml-2 mb-1">
                          <img
                            src="/media/svg/icons/Booking/motorcycle.svg"
                            alt="motorcycle"
                          />
                        </span>
                        <span className="available-counter-text">
                          {" "}
                          {availableData?.motorbike}
                        </span>{" "}
                      </div>
                    </div>
                  </>
                )}

                <Modal.Footer className="pt-5 pl-0 pr-0">
                  {values.friend_circle && !values.select_friend_circle ?
                    <Button
                      className="w-100 font-weight-bolder custom-button"
                      variant="primary"
                      type="submit"
                      disabled="disabled"

                    >
                      Done
                    </Button>
                    :
                    <Button
                      className="w-100 font-weight-bolder custom-button"
                      variant="primary"
                      type="submit"
                      disabled={
                        isEmpty.length === 0 &&
                          disableBtnForSingleDate?.backgroundColor != "bg-red"
                          ? ""
                          : "disabled"
                        // (disableBtnForSingleDate?.backgroundColor == 'bg-red') ? "" : " disabled"
                      }

                    >
                      Done
                    </Button>
                  }

                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal >
    </>
  );
};

export default DayPassForm;
