import * as requestFromServer from "./Crud";
import { Slice, callTypes } from "./Slice";
import { NotificationManager } from "react-notifications";
import moment from "moment";
// import { useHistory } from "react-router-dom";

const { actions } = Slice;

// const history = useHistory();

export const fetchBookingList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  let urlString = "";
  if (queryParams) {
    const dateFilter =
      moment(queryParams?.date)
        .utc()
        .format("YYYY-MM-DD") ||
      moment()
        .utc()
        .format("YYYY-MM-DD");
    urlString += `now=${dateFilter}`;
  }
  if (queryParams && queryParams?.type) {
    urlString += `&type=${queryParams?.type}`;
  }
  // if (queryParams && queryParams?.site_id) {
  //   urlString += `&site_id=${queryParams?.site_id}`;
  // }
  // if (queryParams && queryParams?.floor_id?.length) {
  //   const floors = queryParams?.floor_id?.toString();
  //   urlString += `&floor_id=${floors}`;
  // }
  // if (queryParams && queryParams?.per_page) {
  //   urlString += `&per_page=${queryParams?.per_page}`;
  // }
  if (queryParams && queryParams?.page) {
    urlString += `&page=${queryParams?.page}`;
  }
  if (queryParams && queryParams?.search) {
    urlString += `&search=${queryParams?.search}`;
  }
  if (queryParams && queryParams?.page) {
    urlString += `&source=web`;
  }
  // urlString += `&friends=true&source=web`;
  return requestFromServer
    .getBookingList(urlString)
    .then((response) => {
      const data = response.data;
      dispatch(actions.bookingListFetched({ data }));
    })
    .catch((error) => {
      if (error.response.status === 403 || error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("userData");
        window.location.replace("/auth/login");
        // history.push("/auth/login");
      }
      const errMsg =
        error.response && error.response.data && error.response.data.errors
          ? error.response.data.errors[0].msg
          : error.message;
      NotificationManager.error(errMsg, "Error");
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getList(queryParams)
    .then((response) => {
      // const { totalCount, entities } = response.data;
      const totalCount = response.data.count;
      const entities = response.data.list;
      dispatch(actions.listFetched({ totalCount, entities }));
    })
    .catch((error) => {
      const errMsg =
        error.response && error.response.data && error.response.data.errors
          ? error.response.data.errors[0].msg
          : error.message;
      NotificationManager.error(errMsg, "Error");
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchSingle = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.singleItemFetched({ blogForEdit: undefined }));
  }
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getItemById(id)
    .then((response) => {
      const item = response.data;
      dispatch(actions.singleItemFetched({ blogForEdit: item }));
    })
    .catch((error) => {
      const errMsg =
        error.response && error.response.data && error.response.data.errors
          ? error.response.data.errors[0].msg
          : error.message;
      NotificationManager.error(errMsg, "Error");
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createItem = (item) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createItem(item)
    .then((response) => {
      const { item } = response.data;
      NotificationManager.success("success", "Data saved successfully!");
      dispatch(actions.itemCreated({ item }));
      return true;
    })
    .catch((error) => {
      const errMsg =
        error.response && error.response.data && error.response.data.errors
          ? error.response.data.errors[0].msg
          : error.message;
      NotificationManager.error(errMsg, "Error");
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const deleteItem = (id, type) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteItem(id, type)
    .then((response) => {
      NotificationManager.success("success", "Booking Cancelled successfully!");
      dispatch(actions.itemDeleted());
      return true;
    })
    .catch((error) => {
      NotificationManager.error(
        "There is some Problem With server, Please Try After Some Timt",
        "Error"
      );
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const setCalItemFilter = (newData) => async (dispatch) => {
  try {
    dispatch(actions.setCalItemFilter(newData));
  } catch (error) {
    throw new error();
  }
};
