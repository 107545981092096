import React from "react"
import { Button, Col, Form, FormCheck, Modal, Row } from "react-bootstrap"
import SVG from "react-inlinesvg"
import { useState } from "react"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { Formik } from "formik"
import { toAbsoluteUrl } from "../../../_metronic/_helpers"
import * as friendCircleAction from "./_redux/Actions"

let formInitialValues = {}

export default function AdjustFriendCircle(props) {
  const {
    isShowEditFriendCircle,
    handleEditFriendCircleClose,
    handleShow,
    selectedFriendCircleId,
    selectedFCircleUsers,
  } = props

  const [selectedFriendCircle, setSelectedFriendCircle] = useState([])
  const friendCircleStore = useSelector((state) => state.searchData)

  useEffect(() => {
    if (selectedFCircleUsers) {
      // let formInitValues = {};
      // selectedFCircleUsers.forEach((element) => {
      //   formInitValues[`user_${element}`] = element;
      // });
      // formInitialValues = { ...formInitialValues, ...formInitValues };
      setSelectedFriendCircle(selectedFCircleUsers)
    }
  }, [selectedFCircleUsers])

  // console.log(selectedFriendCircle);

  const handleSelectedFriends = (ele) => {
    if (!selectedFriendCircle.includes(ele?.id)) {
      setSelectedFriendCircle([...selectedFriendCircle, ele?.id])
    } else {
      setSelectedFriendCircle(selectedFriendCircle.filter((d) => d !== ele.id))
    }
  }
  const AdustFriendCircle = () => {
    handleEditFriendCircleClose()
    handleShow()
  }
  return (
    <>
      <Modal
        show={isShowEditFriendCircle}
        onHide={AdustFriendCircle}
        className="modal-right edit-friend-circle-pop-up"
      >
        <Modal.Header closeButton>
          <div className="d-flex edit-friend-circle-header">
            <div>
              <span className="svg-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/task_friend_circle_blue.svg")}
                  className="friend-circle-header-image"
                ></SVG>
              </span>
            </div>
            <b className="edit-friend-circle-text-header">ADJUST COLLEAGUE CIRCLE</b>
          </div>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <Formik
            initialValues={selectedFriendCircle}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false)
              if (props?.manageSelectedUsers) {
                props.manageSelectedUsers(selectedFriendCircle)
              }
              AdustFriendCircle()
            }}
          >
            {({ values, handleChange, handleSubmit, setFieldValue, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <div>
                  {friendCircleStore?.selectedFriendCircleDetails?.users?.map((e, index) => {
                    return (
                      <div
                        className="d-flex align-items-center edit-friend-circle-wrapper"
                        key={index}
                      >
                        <div className="edit-friend-circle-friend-image">
                          <span className="svg-icon">
                            {e?.url_image ? (
                              <img
                                src={e?.url_image || ""}
                                alt={e?.full_name || ""}
                                className="selected-friend-circle-user-img"
                              ></img>
                            ) : (
                              <SVG
                                src={toAbsoluteUrl("media/svg/icons/default_user.svg")}
                                className="selected-friend-circle-user-img"
                              ></SVG>
                            )}
                          </span>
                        </div>
                        <div>
                          <p className="edit-friend-circle-friend-name">{e?.full_name}</p>
                        </div>
                        <div className="edit-friend-circle-switch">
                          <FormCheck
                            type="switch"
                            className="button-cursor custom-switch-vehicle"
                            id={e?.id}
                            name={`user_` + e?.id}
                            label=""
                            // checked={(values[`user_${e.id}`] && values[`user_${e.id}`] === e.id ) ? true : false}
                            checked={selectedFriendCircle.includes(e?.id) ? true : false}
                            onChange={() => handleSelectedFriends(e)}
                          />
                        </div>
                      </div>
                    )
                  })}
                </div>
                <Modal.Footer>
                  <Button
                    className="w-100 font-weight-bolder custom-button"
                    variant="primary"
                    type="submit"
                  >
                    Update
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  )
}
